import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { DeleteDriverComponent } from '../delete-driver/delete-driver.component';
import { LogEvent } from '../../model/log-event';
import { DriverData } from '../../model/driver';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Validations, PhoneFormattingPipe, DateTimePipe } from '@xpoc/ngx-common';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { State } from '../../model/state';
import { Country } from '../../model/country';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { LookupService } from '../../services/lookup.service';
import { AssetManagementService } from '../../services/asset-management.service';
import { KeystoneService } from '../../services/keystone.service';
import { XPOConstants } from '../../xpo-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-driver-form',
  templateUrl: './create-driver-form.component.html',
  styleUrls: [ './create-driver-form.component.scss' ]
})
export class CreateDriverFormComponent implements OnInit {
  @Input() dialogAction: string;
  @Input() driverDetails: any;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  public driverForm: FormGroup;
  public phoneTypeList: string[];
  public isNAUser: boolean;
  public cityList: any[];
  private messageConfig: any = {};
  public disablePhoneTypeOne: boolean;
  public disablePhoneTypeTwo: boolean;
  public disableTrackingPhoneType: boolean;
  public countries: Country[];
  public states: State[];
  public savedDataAction: string;
  public dialogConfig: MatDialogConfig;
  public userActions: any;
  public phoneNoMaxLength: number;
  public disableEmailCheckbox: boolean;
  public emailRegex: any;
  public validlocation: any;
  public InvalidZipcode: any;
  public validUserName: any;
  public nameAlreadyExists: any;

  constructor(
    private formBuilder: FormBuilder,
    private appSettings: AppSettings,
    public authService: AuthService,
    public datePipe: DateTimePipe,
    public lookupService: LookupService,
    public lookup: LookupService,
    public assetMangementService: AssetManagementService,
    public dialog: MatDialog,
    public customPhoneFormatting: PhoneFormattingPipe,
    private keystoneService: KeystoneService,
    private translateService: TranslateService
  ) {
    this.isNAUser = this.authService.checkNAUser();
    this.phoneNoMaxLength = this.isNAUser ? XPOConstants.defaultPhoneNoLengthNA : XPOConstants.defaultPhoneNoLengthEU;
    this.userActions = XPOConstants.userActions;
    this.assetMangementService.getPhoneType().subscribe(phoneTypes => {
      this.phoneTypeList = phoneTypes;
    });
    this.emailRegex = XPOConstants.emailRegex;
    this.dialogConfig = new MatDialogConfig();
    this.cityList = [];
    this.countries = XPOConstants.countryListNA;
  }

  ngOnInit() {
    this.driverForm = this.createForm();
    this.translateService.get('select9795').subscribe((value) => {
      this.validlocation = this.translateService.instant('select9795');
      this.InvalidZipcode = this.translateService.instant('invali4942');
      this.validUserName = this.translateService.instant('validU2060');
      this.nameAlreadyExists = this.translateService.instant('userna50');

      this.messageConfig = {
        validLocation: this.validlocation,
        invalidZipcode: this.InvalidZipcode,
        phoneTypeError: this.validUserName
      };
      this.initalizeValidations();
    });
  }

  private createForm() {
    const validatePhoneNumber = (this.isNAUser) ? Validations.phone : Validations.euPhone;
    const licenseExpiryDate = (this.driverDetails && this.driverDetails.licenseExpirationDate) ?
      this.datePipe.transform(this.driverDetails.licenseExpirationDate, XPOConstants.dbDateFormat) + 'T00:00:00' : '';
    const dateOfBirth = (this.driverDetails && this.driverDetails.dateOfBirth) ?
      this.datePipe.transform(this.driverDetails.dateOfBirth, XPOConstants.dbDateFormat) + 'T00:00:00' : '';
    const group = this.formBuilder.group({
      userName: [ {
        value: (this.driverDetails && this.driverDetails.userName) ? this.driverDetails.userName :
          '', disabled: this.dialogAction !== this.userActions.create ? true : false
      } ],
      firstName: [ (this.driverDetails && this.driverDetails.firstName) ? this.driverDetails.firstName :
        '', [ Validations.firstName, Validators.required ] ],
      lastName: [ (this.driverDetails && this.driverDetails.lastName) ? this.driverDetails.lastName :
        '', [ Validations.lastName, Validators.required ] ],
      streetAddress: [ (this.driverDetails && this.driverDetails.address1) ? this.driverDetails.address1 : '' ],
      cityStateCountry: [ (this.driverDetails && this.driverDetails.city) ?
        this.driverDetails.city + ', ' + this.driverDetails.stateCode + ', ' + this.driverDetails.countryCode : '' ],
      city: [ (this.driverDetails && this.driverDetails.city) ? this.driverDetails.city : '' ],
      countryCode: [ (this.driverDetails && this.driverDetails.countryCode) ? this.driverDetails.countryCode : '' ],
      stateCode: [ (this.driverDetails && this.driverDetails.stateCode) ? this.driverDetails.stateCode : '' ],
      ianaTimeZone: [ '' ],
      zipCode: [ (this.driverDetails && this.driverDetails.postalCode) ? this.driverDetails.postalCode : '' ],
      phoneNoOne: [ (this.driverDetails && this.driverDetails.mobilePhoneNumber) ?
        this.customPhoneFormatting.transform(this.driverDetails.mobilePhoneNumber,
          this.authService.tenantId) : '', [ validatePhoneNumber ] ],
      phoneNoTwo: [ (this.driverDetails && this.driverDetails.alternatePhoneNumber) ?
        this.customPhoneFormatting.transform(this.driverDetails.alternatePhoneNumber,
          this.authService.tenantId) : '', [ validatePhoneNumber ] ],
      trackingPhoneNo: [ {
        value: (this.driverDetails && this.driverDetails.trackingPhoneNumber) ?
          this.customPhoneFormatting.transform(this.driverDetails.trackingPhoneNumber, this.authService.tenantId) : '',
        disabled: this.driverDetails.isDriveXPO ? true : false
      }, [ validatePhoneNumber ] ],
      phoneNoTypeOne: [ (this.driverDetails && this.driverDetails.mobilePhoneTypeCode) ?
        this.driverDetails.mobilePhoneTypeCode : XPOConstants.defaultPhoneType ],
      phoneNoTypeTwo: [ (this.driverDetails && this.driverDetails.alternatePhoneTypeCode) ?
        this.driverDetails.alternatePhoneTypeCode : '' ],
      trackingPhoneNoType: [ {
        value: (this.driverDetails && this.driverDetails.trackingPhoneTypeCode) ?
          this.driverDetails.trackingPhoneTypeCode : '',
        disabled: this.driverDetails.isDriveXPO ? true : false
      } ],
      mobileNumberNotification: [ (this.driverDetails && this.driverDetails.mobileNumberNotification) ?
        this.driverDetails.mobileNumberNotification : false ],
      alternateNumberNotification: [ (this.driverDetails && this.driverDetails.alternateNumberNotification) ?
        this.driverDetails.alternateNumberNotification : false ],
      trackingNumberNotification: [ (this.driverDetails && this.driverDetails.trackingNumberNotification) ?
        this.driverDetails.trackingNumberNotification : false ],
      email: [ (this.driverDetails && this.driverDetails.emailAddress) ? this.driverDetails.emailAddress : '', [ Validations.email ] ],
      emailNotification: [ (this.driverDetails && this.driverDetails.emailNotification) ? this.driverDetails.emailNotification : false ],
      licenseNo: [ (this.driverDetails && this.driverDetails.licenseNumber) ? this.driverDetails.licenseNumber : '' ],
      licenseStateCode: [ (this.driverDetails && this.driverDetails.licenseStateCode) ? this.driverDetails.licenseStateCode : '' ],
      licenseCountryCode: [ (this.driverDetails && this.driverDetails.licenseCountryCode) ?
        this.driverDetails.licenseCountryCode : XPOConstants.defaultCountryCode ],
      licenseExpiryDate: [ licenseExpiryDate ],
      dob: [ dateOfBirth ],
      notes: [ (this.driverDetails && this.driverDetails.comments) ? this.driverDetails.comments : '' ]
    }, {
        validator: this.validateDriverForm.bind(this)
      });
    return group;
  }

  onCountrySelect(countryId: string, isCountryCodeChng: boolean) {
    this.lookup.states(countryId).then(response => {
      this.states = response;
      if (isCountryCodeChng) {
        this.driverForm.get('licenseStateCode').setValue(''); /*Reset state*/
      }
    }).catch();
  }

  formatPhone(type: number) {
    const driverForm = this.driverForm.value;
    switch (type) {
      case 1:
        this.driverForm.get('phoneNoOne').setValue(this.customPhoneFormatting.transform(driverForm.phoneNoOne, this.authService.tenantId));
        break;
      case 2:
        this.driverForm.get('phoneNoTwo').setValue(this.customPhoneFormatting.transform(driverForm.phoneNoTwo, this.authService.tenantId));
        break;
      case 3:
        this.driverForm.get('trackingPhoneNo').setValue(
          this.customPhoneFormatting.transform(driverForm.trackingPhoneNo, this.authService.tenantId));
        break;
      default:
        break;

    }
  }

  initalizeValidations() {
    const countryCode = (this.driverDetails && this.driverDetails.licenseCountryCode) ?
      this.driverDetails.licenseCountryCode : XPOConstants.defaultCountryCode;
    this.onCountrySelect(countryCode, false);
    this.driverForm.get('phoneNoTypeOne').setErrors(
      (this.driverDetails && this.driverDetails.mobilePhoneNumber && !this.driverForm.get('phoneNoTypeOne').value) ?
        { invalid: { error: this.messageConfig.phoneTypeError } } : null);
    this.driverForm.get('phoneNoTypeTwo').setErrors(
      (this.driverDetails && this.driverDetails.alternatePhoneNumber && !this.driverDetails.alternatePhoneTypeCode) ?
        { invalid: { error: this.messageConfig.phoneTypeError } } : null);
    this.driverForm.get('trackingPhoneNoType').setErrors(
      (this.driverDetails && this.driverDetails.trackingPhoneNumber && !this.driverDetails.trackingPhoneTypeCode) ?
        { invalid: { error: this.messageConfig.phoneTypeError } } : null);
    if (!this.driverDetails || !this.driverDetails.mobilePhoneNumber) {
      this.disablePhoneTypeOne = true;
      this.driverForm.get('phoneNoTypeOne').disable();
      this.driverForm.get('mobileNumberNotification').disable();
    }
    if (!this.driverDetails || !this.driverDetails.alternatePhoneNumber) {
      this.disablePhoneTypeTwo = true;
      this.driverForm.get('phoneNoTypeTwo').disable();
      this.driverForm.get('alternateNumberNotification').disable();
    }
    if (!this.driverDetails || !this.driverDetails.trackingPhoneNumber) {
      this.disableTrackingPhoneType = true;
      this.driverForm.get('trackingPhoneNoType').disable();
      this.driverForm.get('trackingNumberNotification').disable();
    }
    if (!this.driverDetails || !this.driverDetails.emailAddress) {
      this.disableEmailCheckbox = true;
      this.driverForm.get('emailNotification').disable();
    }
    this.driverForm.get('cityStateCountry').valueChanges.subscribe(search => {
      this.lookupService.cities(search).then(response => {
        this.cityList = response;
      }).catch(error => {
        this.cityList = [];
      });
    });
    this.driverForm.get('phoneNoOne').valueChanges.subscribe(valueChange => {
      if (valueChange) {
        this.driverForm.get('phoneNoTypeOne').enable();
        this.driverForm.get('mobileNumberNotification').enable();
        this.disablePhoneTypeOne = false;
        if (!this.driverForm.get('phoneNoTypeOne').value) {
          this.driverForm.controls.phoneNoTypeOne.setErrors({ invalid: { error: this.messageConfig.phoneTypeError } });
        }
      } else {
        this.driverForm.get('phoneNoTypeOne').setValue('');
        this.driverForm.controls.phoneNoTypeOne.setErrors(null);
        this.driverForm.get('phoneNoTypeOne').disable();
        this.driverForm.get('mobileNumberNotification').disable();
        this.disablePhoneTypeOne = true;
        this.driverForm.get('mobileNumberNotification').setValue(false);
      }
    });
    this.driverForm.get('phoneNoTwo').valueChanges.subscribe(valueChange => {
      if (valueChange) {
        this.driverForm.get('phoneNoTypeTwo').enable();
        this.driverForm.get('alternateNumberNotification').enable();
        this.disablePhoneTypeTwo = false;
        if (!this.driverForm.get('phoneNoTypeTwo').value) {
          this.driverForm.controls.phoneNoTypeTwo.setErrors({ invalid: { error: this.messageConfig.phoneTypeError } });
        }
      } else {
        this.driverForm.get('phoneNoTypeTwo').setValue('');
        this.driverForm.controls.phoneNoTypeTwo.setErrors(null);
        this.driverForm.get('phoneNoTypeTwo').disable();
        this.driverForm.get('alternateNumberNotification').disable();
        this.disablePhoneTypeTwo = true;
        this.driverForm.get('alternateNumberNotification').setValue(false);
      }
    });
    this.driverForm.get('trackingPhoneNo').valueChanges.subscribe(valueChange => {
      if (valueChange) {
        this.driverForm.get('trackingPhoneNoType').enable();
        this.driverForm.get('trackingNumberNotification').enable();
        this.disableTrackingPhoneType = false;
        if (!this.driverForm.get('trackingPhoneNoType').value) {
          this.driverForm.controls.trackingPhoneNoType.setErrors({ invalid: { error: this.messageConfig.phoneTypeError } });
        }
      } else {
        this.driverForm.get('trackingPhoneNoType').setValue('');
        this.driverForm.controls.trackingPhoneNoType.setErrors(null);
        this.driverForm.get('trackingPhoneNoType').disable();
        this.driverForm.get('trackingNumberNotification').disable();
        this.disableTrackingPhoneType = true;
        this.driverForm.get('trackingNumberNotification').setValue(false);
      }
    });
    this.driverForm.get('email').valueChanges.subscribe(valueChange => {
      if (valueChange) {
        this.disableEmailCheckbox = false;
        this.driverForm.get('emailNotification').enable();
      } else {
        this.disableEmailCheckbox = true;
        this.driverForm.get('emailNotification').disable();
        this.driverForm.get('emailNotification').setValue(false);
      }
    });
    if (!this.driverForm.get('userName').value) {
      this.driverForm.controls.userName.setErrors({ invalid: { error: this.validUserName } });
    }
    this.driverForm.get('userName').valueChanges.subscribe(valueChange => {
      if (!valueChange || !this.emailRegex.test(valueChange)) {
        this.driverForm.controls.userName.setErrors({ invalid: { error: this.validUserName } });
      } else {
        this.driverForm.controls.userName.setErrors(null);
      }
    });
  }

  validateDriverForm(driverForm: FormGroup) {
    let invalidCityOrZip = false;
    driverForm.get('cityStateCountry').setErrors(null);
    driverForm.get('zipCode').setErrors(null);
    const countryCode = (driverForm.get('countryCode').value).trim();
    const postalCodeRegExp = (XPOConstants.postalCodeRegexp.hasOwnProperty(countryCode)) ?
      XPOConstants.postalCodeRegexp[ countryCode ] : XPOConstants.postalCodeRegexp.DEFAULT;
    if (!driverForm.get('countryCode').value && driverForm.get('cityStateCountry').value) {
      driverForm.get('cityStateCountry').setErrors({ invalid: { error: this.messageConfig.validLocation } });
    }
    if (driverForm.get('zipCode').value && !postalCodeRegExp.test(driverForm.get('zipCode').value)) {
      driverForm.get('zipCode').setErrors({ invalid: { error: this.messageConfig.invalidZipcode } });
      invalidCityOrZip = true;
    }
    return (invalidCityOrZip) ? { invalidCityOrZip: true } : null;
  }

  onSelectCity(location?: any) {
    if (location) {
      this.driverForm.get('cityStateCountry').setValue(location.label);
      // Setting City, State, Country and TimeZone
      this.driverForm.get('city').setValue(location.cityName);
      this.driverForm.get('countryCode').setValue(location.countryCode);
      this.driverForm.get('stateCode').setValue(location.stateCode);
      this.driverForm.get('ianaTimeZone').setValue(location.ianaTimeZone);
    } else {
      this.driverForm.get('city').setValue('');
      this.driverForm.get('countryCode').setValue('');
      this.driverForm.get('stateCode').setValue('');
      this.driverForm.get('ianaTimeZone').setValue('');
    }
  }

  getCity() {
    if (this.driverForm.controls.zipCode.valid && this.driverForm.value.zipCode.length > XPOConstants.minZipCodeLength) {
      this.lookupService.getCityFromPincode(this.driverForm.value.zipCode.toUpperCase())
        .then((stop) => {
          if (stop.city) {
            this.driverForm.get('cityStateCountry').setValue(stop.city + ', ' + stop.stateCode + ', ' + stop.countryCode);
            this.driverForm.get('city').setValue(stop.city);
            this.driverForm.get('countryCode').setValue(stop.countryCode);
            this.driverForm.get('stateCode').setValue(stop.stateCode);
          } else {
            this.driverForm.get('cityStateCountry').setValue('');
            this.driverForm.get('city').setValue('');
            this.driverForm.get('countryCode').setValue('');
            this.driverForm.get('stateCode').setValue('');
            this.driverForm.get('ianaTimeZone').setValue('');
          }
          this.driverForm.get('zipCode').setValue(this.driverForm.value.zipCode.toUpperCase());
        });
    }
  }

  onCancel() {
    this.cancel.emit(this.savedDataAction);
  }

  saveDriver() {
    const driverData = new DriverData();
    const driverFormData = this.driverForm.value;
    if (this.driverDetails) {
      driverData.driverId = this.driverDetails.driverId;
      driverData.photo = driverFormData.photo;
    }
    driverData.userName = (this.dialogAction !== this.userActions.create) ? this.driverDetails.userName : driverFormData.userName;
    driverData.firstName = driverFormData.firstName;
    driverData.lastName = driverFormData.lastName;
    driverData.dateOfBirth = driverFormData.dob !== '' ?
      this.datePipe.transform(driverFormData.dob, XPOConstants.dbDateFormat) : driverFormData.dob;
    driverData.licenseNumber = driverFormData.licenseNo;
    driverData.licenseStateCode = driverFormData.licenseStateCode;
    driverData.licenseCountryCode = driverFormData.licenseCountryCode;
    driverData.licenseExpirationDate = driverFormData.licenseExpiryDate !== '' ?
      this.datePipe.transform(driverFormData.licenseExpiryDate, XPOConstants.dbDateFormat) : driverFormData.licenseExpiryDate;
    driverData.hireDate = (this.driverDetails && this.driverDetails.hireDate) ?
      this.driverDetails.hireDate : this.datePipe.transform(new Date(), XPOConstants.dbDateFormat);
    driverData.address1 = driverFormData.streetAddress;
    driverData.city = driverFormData.city;
    driverData.stateCode = driverFormData.stateCode;
    driverData.postalCode = driverFormData.zipCode;
    driverData.countryCode = driverFormData.countryCode;
    driverData.mobilePhoneNumber = driverFormData.phoneNoOne;
    driverData.alternatePhoneNumber = driverFormData.phoneNoTwo;
    driverData.trackingPhoneNumber = this.driverDetails.isDriveXPO ? ((this.driverDetails && this.driverDetails.trackingPhoneNumber) ?
      this.customPhoneFormatting.transform(this.driverDetails.trackingPhoneNumber, this.authService.tenantId) : '')
      : driverFormData.trackingPhoneNo;
    driverData.alternatePhoneTypeCode = driverFormData.phoneNoTypeTwo;
    driverData.mobilePhoneTypeCode = driverFormData.phoneNoTypeOne;
    driverData.trackingPhoneTypeCode = this.driverDetails.isDriveXPO ? ((this.driverDetails && this.driverDetails.trackingPhoneTypeCode) ?
      this.driverDetails.trackingPhoneTypeCode : '') : driverFormData.trackingPhoneNoType;
    driverData.mobileNumberNotification = driverFormData.mobileNumberNotification;
    driverData.alternateNumberNotification = driverFormData.alternateNumberNotification;
    driverData.trackingNumberNotification = driverFormData.trackingNumberNotification;
    driverData.emailNotification = driverFormData.emailNotification;
    driverData.emailAddress = driverFormData.email;
    driverData.comments = driverFormData.notes;
    driverData.isDriveXPO = (this.driverDetails && this.driverDetails.isDriveXPO) ? true : false;
    if (this.dialogAction === this.userActions.create) {
      this.assetMangementService.addDriver(driverData).subscribe(response => {
        if (response === 0) {
          this.driverForm.controls.userName.setErrors({ invalid: { error: this.nameAlreadyExists } });
        } else {
          this.savedDataAction = this.userActions.confirm;
          this.keystoneEntry(driverData);
          this.onCancel();
        }
      });
    } else if (this.dialogAction === this.userActions.edit) {
      this.assetMangementService.editDriver(driverData).subscribe(response => {
        if (response === 0) {
          this.driverForm.controls.userName.setErrors({ invalid: { error: this.nameAlreadyExists } });
        } else {
          this.savedDataAction = this.userActions.confirm;
          this.keystoneEntry(driverData);
          this.onCancel();
        }
      });
    }
  }

  keystoneEntry(driverData) {
    if (driverData.mobileNumberNotification || driverData.alternateNumberNotification ||
      driverData.trackingNumberNotification || driverData.emailNotification) {
      this.logKeystone(XPOConstants.keystoneEventName.SMSNotification, XPOConstants.keystoneEventAction.enabled);
    } else {
      this.logKeystone(XPOConstants.keystoneEventName.SMSNotification, XPOConstants.keystoneEventAction.disabled);
    }
  }

  deleteDriver() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.deleteDriver,
      XPOConstants.keystoneEventAction.initiate, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.driver);
    this.keystoneService.logEvent(logEvent);
    this.dialogConfig.data = {
      driver: this.driverDetails
    };
    this.dialog
      .open(DeleteDriverComponent, this.dialogConfig)
      .afterClosed()
      .subscribe((isDriverDeleted) => {
        if (isDriverDeleted) {
          this.savedDataAction = this.userActions.refresh;
          this.onCancel();
        }
      });
  }

  logKeystone(eventName, eventAction) {
    const eventIdentifier = {
      userName: this.driverForm.value.userName,
      licenseNumber: this.driverForm.value.licenseNo
    };
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName,
      eventAction, XPOConstants.keystonePage.assetManagement, null, eventIdentifier);
    this.keystoneService.logEvent(logEvent);
  }
}
