import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/core/app-settings';
import { DriverListResponse } from '../model/driver-list-response';
import { EquipmentRequest } from '../model/equipment-request';
import { DriverData } from '../model/driver';
import { EquipmentListRequest } from '../model/equipment-list-request';

@Injectable({
  providedIn: 'root'
})
export class AssetManagementService {
  private baseUrl: string;

  constructor(private http: HttpClient,
              private appSettings: AppSettings) {
    this.baseUrl = this.appSettings.apiUrl;
  }

  getDriverList(request: any): Observable<DriverListResponse> {
    const url = this.getUri('drivers');
    let params = new HttpParams();
    params = params.append('searchText', request.searchText);
    params = params.append('pageNumber', request.pageNumber);
    params = params.append('pageSize', request.pageSize);
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  deleteDriver(driverPartnerId: number, driverRequest: any) {
    const url = this.getUri(`drivers/driverpartner/${driverPartnerId}`);
    return this.http
      .request('delete', url, { body: driverRequest, headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  driverXPOAccess(driverStagingGuid: string) {
    const url = this.getUri(`drivers/drivexpo/access/request/${driverStagingGuid}`);
    return this.http
      .post(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  deleteEquipment(equipmentIdentifier: number) {
    const url = this.getUri(`equipment/${equipmentIdentifier}`);
    return this.http
      .delete(url, { headers: this.getHeaders() })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  getPhoneType() {
    const url = this.getUri('drivers/phonetypes');
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  addDriver(driverData: DriverData): Observable<any> {
    const url = this.getUri('drivers/');
    return this.http
      .post(url, driverData, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  addEquipment(equipmentData: EquipmentRequest): Observable<any> {
    const url = this.getUri('equipment/upsert');
    return this.http
      .post(url, equipmentData, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  editDriver(driverData: DriverData): Observable<any> {
    const url = `${this.baseUrl}drivers/${driverData.driverId}`;
    return this.http
      .put(url, driverData, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getDriverRole(driverData: DriverData): Observable<string> {
    const driverUserName = encodeURIComponent(driverData.userName);
    const isDriveXpoUser = String(driverData.isDriveXPO);
    const url = `${this.baseUrl}drivers/${driverUserName}/driverrole`;
    let params = new HttpParams();
    params = params.append('isDriveXpo', isDriveXpoUser);
    return this.http
      .get(url, { params })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getequipmentList(request: EquipmentListRequest): Observable<any> {
    const url = this.getUri(`equipment`);
    let params = new HttpParams();
    params = params.append('searchText', request.searchText);
    params = params.append('equipmentTypeCode', request.equipmentTypeCode);
    params = params.append('pageNumber', request.pageNumber.toString());
    params = params.append('pageSize', request.pageSize.toString());
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(response => {
        return response as any;
      }, catchError(this.handleError));
  }

  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + endpoint;
  }

  private getOptions() {
    const options = { headers: this.getHeaders(), withCredentials: true };
    return options;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
