import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KeystoneService } from 'src/app/shared/services';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { AppSettings } from '../app-settings';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { LoyaltyService } from 'src/app/shared/services/loyalty.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwitchCarrierComponent } from './switch-carrier/switch-carrier.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: [ './my-account.component.scss' ]
})
export class MyAccountComponent implements OnInit {

  public userName: string;
  public isHidden: boolean;
  private dialogConfig: MatDialogConfig;
  public permissions: any = [];
  public isNAUser: boolean;
  public privacyPolicyLink: string;
  public euCookiePolicyLink: string;
  public nameAbbreviation: string;
  public rating: string;
  public tiers: string;
  public showRewards = false;
  public num: number;
  public isRewardsPageActive = false;
  public balanceCredit: number;
  public tenandId: number;
  public baseCurrencyCode: string;
  public carrierName: string;
  public isMultiplePartnersAccount: boolean;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private keystoneService: KeystoneService,
    private elementRef: ElementRef,
    public authService: AuthService,
    private appSettings: AppSettings,
    public dashboardService: DashboardService,
    public loyaltyService: LoyaltyService,
    public sharedService: SharedService) {

    this.carrierName = this.authService.partnerName;
    this.tenandId = this.authService.tenantId;
    this.dialogConfig = new MatDialogConfig();
    this.baseCurrencyCode = this.appSettings.getApplicationConstant('BaseCurrencyCode');
    const permissions = this.authService.permissions;
    this.showRewards = permissions.findIndex(permission => permission === XPOConstants.permissionTypes.rewardsView) > -1;
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.authService.getUserProfile().subscribe(userProf => {
        this.setUserName(userProf);
      });
    }
    const userProfile = this.authService.userProfile ? this.authService.userProfile : {};
    this.setUserName(userProfile);
    if (this.showRewards) {
      this.dashboardService.getRewards().subscribe(response => {
        this.rating = response.rating ? response.rating : 'N/A';
        this.tiers = response.tier ? response.tier : '';
        switch (this.tiers) {
          case 'BRONZE':
            this.num = 1;
            break;
          case 'SILVER':
            this.num = 2;
            break;
          case 'GOLD':
            this.num = 3;
            break;
          case 'PLATINUM':
            this.num = 4;
            break;
          default:
            break;
        }
      });
      this.getCredits();
    }
    this.isNAUser = this.authService.checkNAUser();
    this.isMultiplePartnersAccount = userProfile.isMultiplePartnersAccount;
    this.isHidden = true;
    this.permissions = (this.authService && this.authService.permissions) ? this.authService.permissions : [];
    this.setPrivacyPolicyURL();
  }

  private getCredits() {
    this.loyaltyService.getCredits().subscribe(response => {
      this.balanceCredit = response.credits.remainingCreditAmount;
    });
  }

  setUserName(userProfile) {
    this.userName = userProfile ? userProfile.firstName + ' ' + userProfile.lastName : '';
    this.nameAbbreviation = userProfile ? `${userProfile.firstName.substr(0, 1)}${userProfile.lastName.substr(0, 1)}` : '';
  }

  navigatetoLoyalty() {
    this.router.navigateByUrl('/rewards-program');
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, null,
      XPOConstants.keystoneEventAction.click, XPOConstants.keystonePage.loyalty);
    this.keystoneService.logEvent(logEvent);
  }

  navigatetoRewards() {
    this.isHidden = !this.isHidden;
    this.navigatetoLoyalty();
  }

  private setPrivacyPolicyURL() {
    const euPrivacyPolicyLink = this.appSettings.apiUrl.replace('api/', 'policy/privacy');
    this.privacyPolicyLink = this.isNAUser ? XPOConstants.privacyPolicyUrl : euPrivacyPolicyLink;
    this.euCookiePolicyLink = this.appSettings.apiUrl.replace('api/', 'policy/cookie');
  }

  @HostListener('document:click', [ '$event.target' ])
  public onMyAccountClick(targetElement) {
    const clickedInsideMyAcc = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInsideMyAcc) {
      this.isHidden = true;
    }
  }

  openMenu() {
    this.isHidden = !this.isHidden;
    if (this.showRewards) {
      this.getCredits();
    }
  }

  onProfileClick() {
    this.logKeystone(XPOConstants.keystoneEventName.profileSettings, XPOConstants.keystonePage.profileSettings);
    this.isHidden = !this.isHidden;
    this.router.navigate([ '/profile' ]);
  }

  onFeedbackClick() {
    this.logKeystone(XPOConstants.keystoneEventName.feedback, XPOConstants.keystonePage.feedback);
    this.isHidden = !this.isHidden;
    this.dialogConfig.panelClass = 'feedback-dialog-container';
    this.dialog.open(FeedbackFormComponent, this.dialogConfig).afterClosed();
  }

  onPrivacyClick() {
    this.logKeystone(XPOConstants.keystoneEventName.privacyPolicy, XPOConstants.keystonePage.privacyPolicy);
    this.isHidden = !this.isHidden;
  }

  onSwitchCarrier() {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.width = '350px';
    this.dialogConfig.panelClass = 'switch-info-dialog';
    this.dialogConfig.autoFocus = false;
    this.isHidden = !this.isHidden;
    this.dialog.open(SwitchCarrierComponent, this.dialogConfig).afterClosed().subscribe((result) => {
      if (result === 'switchUser') {
        this.logKeystone(XPOConstants.keystoneEventName.switchCarrierConfirm, XPOConstants.keystonePage.switchCarrierDialog);
        window.location.href = this.appSettings.apiUrl.replace('api/', 'partner/select');
      } else {
        this.logKeystone(XPOConstants.keystoneEventName.switchCarrierCancel, XPOConstants.keystonePage.switchCarrierDialog);
      }
    });
  }

  public logout() {
    this.logKeystone(XPOConstants.keystoneEventName.signOut, XPOConstants.keystonePage.signOut);
    sessionStorage.clear();
  }

  public permissionCheck(permission: string) {
    return (this.permissions.indexOf(permission) !== -1);
  }

  logKeystone(keystoneEventName: string, keystonePage: string) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName,
      XPOConstants.keystoneEventAction.click, keystonePage);
    this.keystoneService.logEvent(logEvent);
  }
}
