<div mat-dialog-title>
  <div class="text-bold-uppercase" fxLayout="row" fxLayoutAlign="space-between center">
    <span [translate]="'delete2640'">delete driver</span>
    <span class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </span>
  </div>
  <div *ngIf="!assetAction" class="error-message" fxLayout="row" fxLayoutGap="5px">
    <div class="icon-exclaim"><i class="icon-exclaim_btn"></i></div>
    <div [translate]="'youdonothav280'">You do not have permission to delete driver.</div>
  </div>
</div>
<div *ngIf="!driverDeleted; else deleteConfirm">
  <mat-dialog-content>
    <p>
      <span>
        {{'select2961' | translate}}
      </span><span> </span>
      <b class="text-capitalize">{{driver.firstName}} {{driver.lastName}}.</b>
    </p>
    <p>
      <span>
        {{'delete5030' | translate}}
      </span><span> </span>
      <b [translate]="'driver3172'">Driver?</b>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="padding-bottom-20">
      <button mat-raised-button class="text-uppercase secondary-btn" (click)="onClose()">
        {{'no9227' | translate}}
      </button>
      <button [disabled]="!assetAction" mat-raised-button class="text-uppercase" (click)="deleteDriver()"
        color="accent">
        {{'yes7992' | translate}}
      </button>
    </div>
  </mat-dialog-actions>
</div>
<ng-template #deleteConfirm>
  <mat-dialog-content>
    <b class="text-capitalize">{{driver.firstName}} {{driver.lastName}} </b>
    <span *ngIf="!driverNotDeleted; else notDeletedMessage" [translate]="'hasBee7458'">has been deleted!</span>
    <ng-template #notDeletedMessage>
      <span>could not be deleted due to an unknown error, please try after some
        time.</span>
    </ng-template>
  </mat-dialog-content>
</ng-template>