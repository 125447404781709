<section class="share-tracking-container" *ngIf="!isLoading;else loader;">
    <header class="share-header">
        <span class="close-icon cursor-pointer">
            <i class="icon-close" (click)="onClose(false)"></i>
        </span>
        <span class="text-bold-uppercase" *ngIf="!isSharingDone" [translate]="'shareTracki926'">Share Tracking
            Link</span>
    </header>
    <main *ngIf="!isSharingDone;else sharingSuccessSection">
        <article class="share-details">
            <div class="share-help-text margin-top-15" *ngIf="shouldShowHelpText" [translate]="'youcanshare6115'">
                You can share this load tracking link with your drivers via email or text to perform check calls. This
                link does not require login.
            </div>
            <div fxLayout="row" class="margin-top-bottom-25">
                <div fxFlex="33%" fxFlexAlign="start">
                    <button mat-icon-button *ngIf="shouldShowBackButton" class="text-capitalize" (click)="onBack()">
                        <i class="icon-chevron-left margin-right-5"></i>
                        <span [translate]="'back1752'">back</span>
                    </button>
                </div>
                <div fxFlex="33%" fxLayout="column" class="text-capitalize sub-header text-align-center">
                    <div [translate]="'load4639'">Load#</div>
                    <div class="text-bold-capitalize">
                        {{loadDetails.isTravelOrder?loadDetails.tripCode:loadDetails.number}}
                    </div>
                </div>
                <div fxFlex="33%"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="share-link-container margin-top-bottom-20">
                <div fxFlex="10%" class="link-icon">
                    <i fxLayoutAlign="center center" class="icon-insert-link icon-font-size"></i>
                </div>
                <div [fxFlex]="shouldShowCopyButton? '70%': '90%'" class="link-box">
                    <a mat-button color="accent" class="text-overflow-ellipsis" [attr.href]="copyLink" target="_blank">
                        {{copyLink}}
                    </a>
                </div>
                <div *ngIf="shouldShowCopyButton" fxFlex>
                    <button mat-raised-button type="button" color="accent" class="text-uppercase"
                        (click)="onCopy(copyLink)" id="copy-btn"
                        [ngClass]="{'btn-green': isLinkCopied, 'cursor-pointer': !isLinkCopied}">
                        {{copyButtonText | translate}}
                    </button>
                </div>
            </div>
            <div [ngSwitch]="sharingMedium">
                <ng-container *ngSwitchCase="'email'">
                    <app-share-tracking-email [loadDetails]="loadDetails" [locationData]="locationData"
                        [supportedLanguages]="languageList" [linkToShare]="linkToShare"
                        (shareSuccess)="onShareSuccess($event)" (cancel)="onBack($event)"
                        (updateLink)="updateTrackingLink($event)">
                    </app-share-tracking-email>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                    <app-share-tracking-text [loadDetails]="loadDetails" [locationData]="locationData"
                        [supportedLanguages]="languageList" [linkToShare]="linkToShare"
                        (shareSuccess)="onShareSuccess($event)" (cancel)="onBack($event)"
                        (updateLink)="updateTrackingLink($event)">
                    </app-share-tracking-text>
                </ng-container>
            </div>
        </article>
        <article class="share-primary-actions" *ngIf="shouldShowPrimaryActions" fxLayout="row"
            fxLayoutAlign="start center" fxLayoutGap="15px">
            <div fxLayoutAlign="start center">
                <span class="text-bold" [translate]="'sharevia4908'">
                    Share via
                </span>
                <span> :</span>
                <div>
                    <button mat-button type="button" color="accent" id="btn-share-email"
                        class="text-bold-uppercase wrapped-btn" (click)="showEmailSharingOption();">
                        <i class="icon-email icon-font-size"></i>
                        <span class="margin-left-10" [translate]="'email8704'">Email</span>
                    </button>
                </div>
                <div>
                    <button mat-button type="button" color="accent" btn="btn-share-text"
                        class="text-bold-uppercase wrapped-btn" (click)="showTextSharingOption();">
                        <i class="icon-sms icon-font-size"></i>
                        <span class="margin-left-10" [translate]="'text7427'">Text</span>
                    </button>
                </div>
            </div>
        </article>
    </main>
    <ng-template #sharingSuccessSection>
        <app-share-tracking-success [successData]="shareResponse" [loadDetails]="loadDetails"
            (successAction)="performSuccessAction($event)">
        </app-share-tracking-success>
    </ng-template>
</section>
<ng-template #loader>
    <app-page-loader></app-page-loader>
</ng-template>