import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from '../xpo-constants';

@Component({
  selector: 'app-online-load-req',
  templateUrl: './online-load-req.component.html',
  styleUrls: [ './online-load-req.component.scss' ]
})
export class OnlineLoadReqComponent implements OnInit {

  @Input() loadDetails: any;
  @Input() notesWordLength: number;
  public dateTimeFormat: string;
  public isViewMore = [];
  public isNAUser: boolean;

  constructor(private authService: AuthService) {
    this.isNAUser = this.authService.checkNAUser();
  }
  ngOnInit() {
    this.dateTimeFormat = this.isNAUser ? XPOConstants.dateFormat : XPOConstants.euDateFormat;
    if (this.loadDetails && this.loadDetails.notes) {
      this.loadDetails.notes.forEach(note => {
        if (note.text.length > this.notesWordLength) {
          this.isViewMore.push(true);
        } else {
          this.isViewMore.push(false);
        }
      });
    }
  }

  clickedViewMore(i: number) {
    this.isViewMore[ i ] = false;
  }

  clickedViewLess(i: number) {
    this.isViewMore[ i ] = true;
  }

}
