import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AssetManagementService } from '../../services/asset-management.service';
import { KeystoneService } from '../../services/keystone.service';
import { LogEvent } from '../../model/log-event';
import { XPOConstants } from '../../xpo-constants';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-delete-driver',
  templateUrl: './delete-driver.component.html',
  styleUrls: [ './delete-driver.component.scss' ]
})
export class DeleteDriverComponent implements OnInit {

  public driver: any;
  public driverDeleted: boolean;
  public driverNotDeleted = false;
  public assetAction: boolean;
  public isNAUser: boolean;

  constructor(
    private dialogRef: MatDialogRef<DeleteDriverComponent>,
    private assetManagementService: AssetManagementService,
    private keystoneService: KeystoneService,
    private authService: AuthService) {
    this.dialogRef.disableClose = true;
    this.driver = this.dialogRef._containerInstance._config.data.driver;
  }

  ngOnInit() {
    this.isNAUser = this.authService.checkNAUser();
    this.assetAction = true;
    if (!this.isNAUser) {
      this.assetAction = false;
      const permissions = this.authService.permissions;
      if (permissions.findIndex(permission => permission === XPOConstants.permissionTypes.assetAction) > -1) {
        this.assetAction = true;
      }
    }
  }

  onClose() {
    if (!this.driverDeleted) {
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.deleteDriver,
        XPOConstants.keystoneEventAction.cancel, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.driver);
      this.keystoneService.logEvent(logEvent);
    }
    this.dialogRef.close(this.driverDeleted);
  }

  deleteDriver() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.deleteDriver,
      XPOConstants.keystoneEventAction.submit, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.driver);
    this.keystoneService.logEvent(logEvent);
    const driverRequest = {
      username: this.driver.userName,
      isDriveXPO: this.driver.isDriveXPO
    };
    this.assetManagementService.deleteDriver(this.driver.driverPartnerId, driverRequest).then(response => {
      this.driverDeleted = true;
      if (response.body === 'false') {
        this.driverNotDeleted = true;
      }
    });
  }


}
