import { Component, OnInit, OnDestroy } from '@angular/core';
import { AvailableLoad } from '../model/available-load';
import { LoadBid } from '../model/load-bid';
import { Subscription } from 'rxjs';
import { Representative } from '../model/representative';
import { AvailableLoadEmailResponse } from '../model/available-load-email-response';
import { Currency } from '../model/currency';
import { GoogleAnalyticsEventsService } from 'src/app/google-analytics/google-analytics-events.service';
import { AvailableLoadsService } from '../services/available-loads.service';
import { UserProfileService } from '../services/user-profile.service';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { Router } from '@angular/router';
import { LookupService } from '../services/lookup.service';
import { KeystoneService } from '../services/keystone.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LogEvent } from '../model/log-event';
import { XPOConstants } from '../xpo-constants';
import { LoyaltyService } from '../services/loyalty.service';
import { CreditRedemption } from '../model/credit-redemption';
import { CreditRedemptionReversal } from '../model/credit-redemption-reversal';
import { TermsAndConditionsLoyaltyComponent } from '../terms-and-conditions-loyalty/terms-and-conditions-loyalty.component';

@Component({
  selector: 'app-buy-it-now',
  templateUrl: './buy-it-now.component.html',
  styleUrls: [ './buy-it-now.component.scss' ]
})
export class BuyItNowComponent implements OnInit, OnDestroy {
  public getBidAmount: boolean;
  public onProcessing: boolean;
  public load: AvailableLoad;
  public isSubmitted = false;
  public isError = false;
  public isUnauthenticated = false;
  private guid;
  public buyPrice;
  public loadNumber;
  private loadBid: LoadBid;
  private pendingRequest: Subscription;
  private intervalTimer;
  private bidCounter = 0;
  public bidStatuses = XPOConstants.bidStatus;
  public currentStatus = XPOConstants.bidStatus.noStatus;
  public currencies: Currency[];
  public currency: string = XPOConstants.defaultCurrency;
  public carrierRep: Representative;
  public buyItNowPlacedLoadNo: number;
  private landingPage: string;
  private landingSubPage: string;
  private keystoneEventName: string;
  public autoOffersLoad: AvailableLoadEmailResponse;
  public actionConfirmed = true;
  public buyItNowCheckpoint = false;
  public dialogTimer: any;
  public eventIdentifiers: any;
  public isRewardRedeemable: boolean;
  public isNAUser: boolean;
  public isCreditDisabled: boolean;
  public buyPriceWithRedeemedCredit: number;
  public remainingCreditAmount: number;
  public creditAmountToRedeem: number;
  public showRedeemOption: boolean;
  public headerWithRedeemOption = false;
  public isRedemptionDone = false;
  public hasAlreadyRedeemed: boolean;
  public checkboxMsg: string = XPOConstants.buyNowCheckboxMsg;
  public tenandId: number;
  public errorType: number;
  public hasAcceptedTermsAndConditions = false;
  public username: string;
  public isValidationRequired = false;
  public isDispatchInfoOptionalOrMandatoryCheckRequired: boolean;
  public isDispatchInfoIncomplete: boolean;
  public supportEmailAddress: string;
  public userEmailAddress: string;
  public reloadRequest: any;

  constructor(
    public dialogRef: MatDialogRef<BuyItNowComponent>,
    public gaService: GoogleAnalyticsEventsService,
    public availableLoadsService: AvailableLoadsService,
    private userProfileService: UserProfileService,
    public appSettings: AppSettings,
    private userAuthService: AuthService,
    public router: Router,
    private lookup: LookupService,
    private keystoneService: KeystoneService,
    private loyaltyService: LoyaltyService,
    private dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;
    this.tenandId = this.userAuthService.tenantId;
    this.isUnauthenticated = this.dialogRef._containerInstance._config.data.isUnauthenticated;
    this.guid = this.dialogRef._containerInstance._config.data.tenderGuid;
    this.buyPrice = this.dialogRef._containerInstance._config.data.buyPrice;
    this.loadNumber = this.dialogRef._containerInstance._config.data.orderNo;
    this.reloadRequest = this.dialogRef._containerInstance._config.data.reloadRequest;
    this.creditAmountToRedeem = 0;
    this.isNAUser = this.userAuthService.checkNAUser();
  }

  ngOnInit() {
    this.startDialogTimer();
    this.eventIdentifiers = {};
    this.supportEmailAddress = this.appSettings.getApplicationConstant('XpoConnectSupportEmailAddress');
    this.userEmailAddress = this.isUnauthenticated ? '' : this.userAuthService.userProfile.email;
    this.landingPage = this.dialogRef._containerInstance._config.data.landingPage;
    this.landingSubPage = this.dialogRef._containerInstance._config.data.landingSubPage;
    this.load = this.dialogRef._containerInstance._config.data;
    this.eventIdentifiers.tripCode = this.dialogRef._containerInstance._config.data.tripCode;
    this.autoOffersLoad = this.dialogRef._containerInstance._config.data.autoOffersLoad;
    this.showRedeemOption = this.dialogRef._containerInstance._config.data.showRedeemOption ?
      this.dialogRef._containerInstance._config.data.showRedeemOption : false;
    this.isValidationRequired = this.dialogRef._containerInstance._config.data.isValidationRequired ?
      this.dialogRef._containerInstance._config.data.isValidationRequired : false;
    this.isDispatchInfoOptionalOrMandatoryCheckRequired =
      this.dialogRef._containerInstance._config.data.isDispatchInfoOptionalOrMandatoryCheckRequired ?
        this.dialogRef._containerInstance._config.data.isDispatchInfoOptionalOrMandatoryCheckRequired : false;
    this.isDispatchInfoIncomplete = this.dialogRef._containerInstance._config.data.isDispatchInfoIncomplete ?
      this.dialogRef._containerInstance._config.data.isDispatchInfoIncomplete : false;
    this.eventIdentifiers.isValidationRequired = this.isValidationRequired;
    this.eventIdentifiers.isDispatchInfoSkipped = this.dialogRef._containerInstance._config.data.isSkipForNow;

    if (!this.load.isAppointmentConfirmed) {
      this.eventIdentifiers.isAppointmentNotConfirmed = !this.load.isAppointmentConfirmed;
    }

    if (!this.isUnauthenticated) {
      this.username = this.userAuthService.username;
      this.buyPrice = this.load.dmpRate;
      this.loadNumber = this.load.number;
      this.eventIdentifiers.tripCode = this.load.alternateNumber;
      this.buyPriceWithRedeemedCredit = this.buyPrice;
      if (this.showRedeemOption) {
        this.getLoyaltyCredit(this.load.alternateNumber);
      }
    }
    this.lookup.currencies().then(response => {
      this.currencies = response.filter(x => x.code !== 'CAD');
      this.currency = this.isNAUser ? this.currencies[ 0 ].code : XPOConstants.defaultEUCurrencyCode;
    }).catch();
    if (this.load && this.load.salesRep) {
      this.carrierRep = this.load.salesRep;  // Kept the RHS as salesRep as the json has dependency with backend api
    }
    this.intialiseFlags();
    this.eventIdentifiers.orderNumber = this.loadNumber;
  }

  intialiseFlags() {
    this.getBidAmount = true;
    this.onProcessing = false;
    this.isSubmitted = false;
    this.isError = false;
  }

  onClose(isAutoClose = false) {
    const cancelEventAction = isAutoClose ? XPOConstants.keystoneEventAction.autoClose : XPOConstants.keystoneEventAction.cancel;
    this.clearDialogTimer();
    this.dialogRef.close({
      buyItNowCheckpoint: this.buyItNowCheckpoint,
      buyItNowPlacedLoadNo: (this.currentStatus === XPOConstants.bidStatus.accepted) ? this.buyItNowPlacedLoadNo : ''
    });
    if (this.getBidAmount) {
      if (!this.isUnauthenticated) {
        const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.buyItNow,
          cancelEventAction, this.landingPage, this.landingSubPage, this.eventIdentifiers);
        this.keystoneService.logEvent(logEvent);
      } else {
        const logEvent = new LogEvent(XPOConstants.keystoneLayer.email, XPOConstants.keystoneEventName.buyItNow,
          cancelEventAction, this.landingPage, this.landingSubPage, this.eventIdentifiers);
        this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
      }
    }
  }

  buyItNow() {
    this.clearDialogTimer();
    this.gaService.emitEvent(XPOConstants.eventCategory.avaiable, XPOConstants.eventAction.buyItNow, 'BuyItNow', 10);
    this.onProcessing = true;
    this.getBidAmount = false;
    this.buyItNowCheckpoint = true;
    this.eventIdentifiers.dmpAmount = this.buyPrice;
    this.eventIdentifiers.dmpCurrencyCode = this.currency;
    this.eventIdentifiers.isHazmat = this.isUnauthenticated ? this.autoOffersLoad.orderItems[ 0 ].isHazmat : this.load.isHazmat;
    this.eventIdentifiers.numberOfStops = this.isUnauthenticated ? this.autoOffersLoad.stopItems.length : this.load.stops.length;
    this.eventIdentifiers.equipmentCategory = this.isUnauthenticated ?
      this.autoOffersLoad.orderItems[ 0 ].equipmentCategory : this.load.equipmentCategory;
    this.eventIdentifiers.equipmentType = this.isUnauthenticated ?
      this.autoOffersLoad.orderItems[ 0 ].equipmentType : this.load.equipmentType;
    if (this.isUnauthenticated) {
      if (this.dialogRef._containerInstance._config.data.carrierRep) {
        this.carrierRep = this.dialogRef._containerInstance._config.data.carrierRep;
      }
      this.callUnauthenticatedBid(XPOConstants.loadBidTenderStatus.buyNow);
    } else {
      if (this.load.salesRep) {
        this.carrierRep = this.load.salesRep; // Kept the RHS as salesRep as the json has dependency with backend api
      }
      this.eventIdentifiers.isUpdateDispatchMandatory = this.dialogRef._containerInstance._config.data.isFieldsMandatory ?
        this.dialogRef._containerInstance._config.data.isFieldsMandatory : false;
      this.eventIdentifiers.isDispatchInfoIncomplete = this.isDispatchInfoIncomplete;
      this.loadBid = new LoadBid(this.load.alternateNumber, this.buyPrice, this.currency, '');
      this.loadBid.loadId = this.load.id;
      this.loadBid.suggestionId = '';
      this.loadBid.loadSystemId = this.load.number;
      this.loadBid.carrierRepUserName = this.load.salesRep.userName;
      this.loadBid.carrierRepContactEmail = this.load.salesRep.email;
      this.loadBid.isIberianTrip = this.load.isIberianTrip;
      this.loadBid.tenderStatus = XPOConstants.loadBidTenderStatus.portalBuynow;
      this.loadBid.responseStatus = 'BuyItNow';
      this.loadBid.buyPrice = this.load.dmpRate;
      this.loadBid.transactionFlag = XPOConstants.loadTransactionFlag.buyLoad;
      this.loadBid.priceSourceCode = this.load.priceSourceCode;
      this.loadBid.wtpEntityId = this.load.wtpEntityId;
      this.eventIdentifiers.transactionFlag = XPOConstants.loadTransactionFlag.buyLoad;
      this.eventIdentifiers.priceSourceCode = this.load.priceSourceCode;
      this.eventIdentifiers.wtpEntityId = this.load.wtpEntityId;
      this.eventIdentifiers.genericDMPRate = this.load.genericDMPRate;
      this.buyPrice = this.load.dmpRate;
      this.loadNumber = this.load.number;
      if (this.userAuthService.userProfile === null && this.userAuthService.username === '') {
        this.userProfileService.getUserProfile().then(user => {
          this.loadBid.contactName = user.username;
          if (this.showRedeemOption && this.isRewardRedeemable
            && this.creditAmountToRedeem > 0 && !this.isCreditDisabled) {
            if (!this.hasAcceptedTermsAndConditions) {
              this.acceptLoyaltyTermsConditions();
            }
            const creditRedemption = new CreditRedemption(this.load.alternateNumber, this.creditAmountToRedeem);
            this.loyaltyService.creditRedemption(creditRedemption).subscribe((response) => {
              this.setBid(this.loadBid, true);
            });
          } else {
            this.setBid(this.loadBid);
          }
        });
      } else {
        this.loadBid.contactName = this.userAuthService.username;
        if (this.showRedeemOption && this.isRewardRedeemable
          && this.creditAmountToRedeem > 0 && !this.isCreditDisabled) {
          if (!this.hasAcceptedTermsAndConditions) {
            this.acceptLoyaltyTermsConditions();
          }
          const creditRedemption = new CreditRedemption(this.load.alternateNumber, this.creditAmountToRedeem);
          this.loyaltyService.creditRedemption(creditRedemption).subscribe((response) => {
            this.setBid(this.loadBid, true);
          });
        } else {
          this.setBid(this.loadBid);
        }
      }
    }
  }

  setBid(loadBidObj: any, revertCredit?: boolean) {
    this.availableLoadsService.setBidAmount(loadBidObj)
      .subscribe((bidResponse) => {
        this.buyItNowPlacedLoadNo = loadBidObj.loadSystemId;
        if (bidResponse.isSuccess) {
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.buyItNow,
            XPOConstants.keystoneEventAction.submitted, this.landingPage, this.landingSubPage,
            { ...this.eventIdentifiers, isRewardsRedeemed: this.isRewardRedeemable });
          this.keystoneService.logEvent(logEvent);
          this.intervalTimer = setInterval(() => {
            this.checkBid(bidResponse.createdId);
          }, parseInt(this.appSettings.getApplicationConstant('XpoConnectBidTimer'), 10));
        } else {
          if (revertCredit) {
            this.loyaltyService.creditRedemptionReversal(new CreditRedemptionReversal(this.load.alternateNumber)).subscribe();
          }
          this.startDialogTimer();
          this.errorType = bidResponse.errorType;
          this.isSubmitted = true;
          this.currentStatus = XPOConstants.bidStatus.noStatus;
          if (bidResponse.errors && bidResponse.errors.length) {
            this.eventIdentifiers = { messages: bidResponse.errors.map((err: any) => err.Message), ...this.eventIdentifiers };
          }
          this.logBINErrorKeystone(this.errorType);
          this.resetProcessing();
        }
      }, (error) => {
        if (revertCredit) {
          this.loyaltyService.creditRedemptionReversal(new CreditRedemptionReversal(this.load.alternateNumber)).subscribe();
        }
        this.handleError();
      });
  }

  private logBINErrorKeystone(errorCode: number) {
    const bidErrorTypes = XPOConstants.bidErrorTypes;
    const keystoneActionsList = XPOConstants.keystoneEventAction;
    let keystoneAction = '';
    switch (errorCode) {
      case bidErrorTypes.loadUnavailable:
        keystoneAction = keystoneActionsList.loadUnavailableForBid;
        break;
      case bidErrorTypes.counterOfferExist:
        keystoneAction = keystoneActionsList.coExist;
        break;
      case bidErrorTypes.counterOfferAccepted:
        keystoneAction = keystoneActionsList.coAccepted;
        break;
      case bidErrorTypes.counterOfferRejected:
        keystoneAction = keystoneActionsList.coRejected;
        break;
      case bidErrorTypes.counterOfferUnavailable:
        keystoneAction = keystoneActionsList.coNotAvailable;
        break;
      case bidErrorTypes.bidUnderReview:
        keystoneAction = keystoneActionsList.bidUnderReview;
        break;
      case bidErrorTypes.carrierUnassigned:
        keystoneAction = keystoneActionsList.loadUnAssigned;
        break;
      case bidErrorTypes.invalidBidAmount:
        keystoneAction = keystoneActionsList.invalidBid;
        break;
      default:
        keystoneAction = keystoneActionsList.genericException;
    }
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.binFailure,
      keystoneAction, this.landingPage, this.landingSubPage, this.eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

  private sendBINAcceptanceMail(binRequest) {
    this.availableLoadsService.sendBINNotification(binRequest)
      .subscribe(response => {
      });
  }

  checkBid(tenderResponseId: any) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.availableLoadsService.checkBidStatus(tenderResponseId)
      .subscribe(response => {
        const status = +response.body;
        if (status === XPOConstants.bidStatus.accepted || status === XPOConstants.bidStatus.rejected) {
          this.isSubmitted = true;
          this.currentStatus = status;
          if (!this.isNAUser && status === XPOConstants.bidStatus.accepted) {
            this.sendBINAcceptanceMail(this.loadBid);
          }
          this.bidCounter = this.bidCounter + parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10);
          this.keystoneEventName = status === XPOConstants.bidStatus.accepted ?
            XPOConstants.keystoneEventAction.approved : XPOConstants.keystoneEventAction.rejected;
          this.startDialogTimer();
          this.resetProcessing();
        }
      }, error => {
        this.handleError();
      });

    // Check bid status will continue until timer expires or get Acceptance or Rejection for a bid
    if (this.bidCounter > parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10)) {
      this.resetProcessing();
      this.startDialogTimer();
      // Showing error message after trying to set a bid
      if (!this.isSubmitted) {
        this.isError = true;
        this.isSubmitted = true;
        this.keystoneEventName = XPOConstants.keystoneEventAction.errorOrTimeOut;
      }
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.buyItNow,
        this.keystoneEventName, this.landingPage, this.landingSubPage, this.eventIdentifiers);
      this.keystoneService.logEvent(logEvent);
    } else {
      this.bidCounter++;
    }
  }

  private callUnauthenticatedBid(action) {
    let req: any;
    req = {
      responseStatus: action,
      comments: '',
      amount: this.buyPrice,
      currencyCode: '',
      tenderGuid: this.guid,
      transactionFlag: XPOConstants.loadTransactionFlag.buyLoad,
      priceSourceCode: this.autoOffersLoad.priceSourceCode,
      wtpEntityId: this.autoOffersLoad.wtpEntityId,
      buyPrice: this.buyPrice
    };
    req.tenderStatus = action;
    this.eventIdentifiers.transactionFlag = XPOConstants.loadTransactionFlag.buyLoad;
    this.eventIdentifiers.priceSourceCode = this.autoOffersLoad.priceSourceCode;
    this.eventIdentifiers.wtpEntityId = this.autoOffersLoad.wtpEntityId;
    this.eventIdentifiers.buyPrice = this.buyPrice;
    this.setBidWithoutAuth(req);
  }

  setBidWithoutAuth(loadBidObj: any) {
    this.availableLoadsService.setBidAmountWithOutAuth(loadBidObj)
      .subscribe(bidResponse => {
        if (bidResponse.isSuccess) {
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.email, XPOConstants.keystoneEventName.buyItNow,
            XPOConstants.keystoneEventAction.submitted, this.landingPage, this.landingSubPage, this.eventIdentifiers);
          this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
          this.intervalTimer = setInterval(() => {
            this.checkBidWithoutAuth(bidResponse.createdId);
          }, parseInt(this.appSettings.getApplicationConstant('XpoConnectBidTimer'), 10));
        } else {
          this.startDialogTimer();
          this.errorType = bidResponse.errorType;
          this.isSubmitted = true;
          this.currentStatus = XPOConstants.bidStatus.noStatus;
          this.resetProcessing();
          if (bidResponse.errors && bidResponse.errors.length) {
            this.eventIdentifiers = { messages: bidResponse.errors.map((err: any) => err.Message), ...this.eventIdentifiers };
          }
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.buyItNow,
            XPOConstants.keystoneEventAction.errorOrTimeOut, this.landingPage, this.landingSubPage, this.eventIdentifiers);
          this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
        }
      }, error => {
        this.handleError();
      });
  }

  checkBidWithoutAuth(tenderResponseId: any) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.availableLoadsService.checkBidStatusWithOutAuth(tenderResponseId)
      .subscribe(response => {
        const status = +response.body;
        if (status === XPOConstants.bidStatus.accepted || status === XPOConstants.bidStatus.rejected) {
          this.isSubmitted = true;
          this.currentStatus = status;
          this.bidCounter = this.bidCounter + parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10);
          this.keystoneEventName = (status === XPOConstants.bidStatus.accepted) ?
            XPOConstants.keystoneEventAction.approved : XPOConstants.keystoneEventAction.rejected;
        }
      }, error => {
        this.handleError();
      });

    // Check bid status will continue until timer expires or get Acceptance or Rejection for a bid
    if (this.bidCounter > parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10)) {
      this.resetProcessing();
      this.startDialogTimer();
      // Showing error message after trying to set a bid
      if (!this.isSubmitted) {
        this.isError = true;
        this.isSubmitted = true;
        this.keystoneEventName = XPOConstants.keystoneEventAction.errorOrTimeOut;
      }
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.buyItNow,
        this.keystoneEventName, this.landingPage, this.landingSubPage, this.eventIdentifiers);
      this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
    } else {
      this.bidCounter++;
    }
  }

  resetProcessing() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.onProcessing = false;
    this.bidCounter = 0;
  }

  handleError() {
    this.resetProcessing();
    this.onClose(true);
    this.router.navigate([ '/error-message' ]);
  }

  confirmAction(event) {
    this.actionConfirmed = event.isRequire && event.isValid && event.isAppointmentConfirmed ? false : true;
  }

  startDialogTimer() {
    this.dialogTimer = setTimeout(() => {
      this.onClose(true);
    }, parseInt(this.appSettings.getApplicationConstant('XpoConnectBidConfirmationModelTimer'), 10));
  }

  clearDialogTimer() {
    if (this.dialogTimer) {
      clearInterval(this.dialogTimer);
    }
  }

  ngOnDestroy() {
    if (this.dialogTimer) {
      clearInterval(this.dialogTimer);
    }
  }

  addCredit() {
    this.isRewardRedeemable = !this.isRewardRedeemable;
    this.buyPriceWithRedeemedCredit = this.isRewardRedeemable ? this.buyPrice + this.creditAmountToRedeem : this.buyPrice;
  }

  acceptLoyaltyTermsConditions() {
    const upgradeInfo = {
      loyaltyAlertTypeCode: 'TCNV2',
      username: this.username,
    };
    this.loyaltyService.upgradeInfoUpdate(upgradeInfo).subscribe();
  }

  getLoyaltyCredit(tripCode) {
    this.loyaltyService.getCredits(tripCode)
      .subscribe((response) => {
        if (response) {
          this.isCreditDisabled = response.isDisabled;
          this.hasAlreadyRedeemed = response.hasAlreadyRedeemed;
          this.hasAcceptedTermsAndConditions = response.hasAcceptedTermsAndConditions;
          if (response.credits) {
            this.remainingCreditAmount = response.credits.remainingCreditAmount;
            this.creditAmountToRedeem = response.credits.creditAmountToRedeem;
          }
          this.isRewardRedeemable = this.hasAlreadyRedeemed;
          this.buyPriceWithRedeemedCredit = this.isRewardRedeemable ? this.buyPrice + this.creditAmountToRedeem : this.buyPrice;
          this.headerWithRedeemOption = this.isNAUser && !this.isUnauthenticated &&
            (this.remainingCreditAmount !== 0 || (this.remainingCreditAmount === 0 && this.hasAlreadyRedeemed));
        }
      });
  }

  showTermsConditions() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.profileGeneralInfo,
      XPOConstants.keystoneEventAction.loyaltyTermsAgreementDialog, this.landingPage, this.landingSubPage);
    this.keystoneService.logEvent(logEvent);
    this.dialog.open(TermsAndConditionsLoyaltyComponent);
  }

  closeDialog(event) {
    this.onClose();
  }
}
