<div class="text-bold mob-head" fxHide fxShow.lt-sm id="carrier-requirement-label">Carrier Requirements</div>
<div class="carrier-req" *ngIf="load.requirements && load.requirements.length>0; else noReq" fxLayout="column"
  fxLayoutGap="10px">
  <div *ngFor="let loadReq of load.requirements" fxLayout="row">
    <div fxFlex="30%" class="text-bold">{{loadReq.name}}</div>
    <div fxFlex="70%" fxLayoutAlign="start center">
      <i class="icon-done"></i>
    </div>
  </div>
</div>

<ng-template #noReq>
  <div class="nocarrier-msg" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div><i class="icon-info"></i></div>
    <p *ngIf="!isNAUser; else notesMsg" [translate]="'nocarrierre8008'" id="carrier-no-requirement">No carrier
      requirements</p>
    <ng-template #notesMsg>
      <p [translate]="'checkn5559'" id="carrier-requirement-notes">Check notes for requirements</p>
    </ng-template>
  </div>
</ng-template>