import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions-loyalty',
  templateUrl: './terms-and-conditions-loyalty.component.html',
  styleUrls: [ './terms-and-conditions-loyalty.component.scss' ]
})
export class TermsAndConditionsLoyaltyComponent {

  public rewardsDetails = [
    { tier: 'bronze', monthlyLoadVolume: 'None required', minimumCarrierScore: 'Not applicable' },
    { tier: 'silver', monthlyLoadVolume: '2', minimumCarrierScore: '70' },
    { tier: 'gold', monthlyLoadVolume: '4', minimumCarrierScore: '80' },
    { tier: 'platinum', monthlyLoadVolume: '8', minimumCarrierScore: '90' }
  ];

  constructor(private dialogRef: MatDialogRef<TermsAndConditionsLoyaltyComponent>) {
    this.dialogRef.disableClose = true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
