import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Validations } from '@xpoc/ngx-common';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { Load } from 'src/app/shared/model/load';
import { MyLoadsService, KeystoneService } from 'src/app/shared/services';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { LogEvent } from 'src/app/shared/model';
import { XPOConstants } from 'src/app/shared';

@Component({
  selector: 'app-share-tracking-text',
  templateUrl: './share-tracking-text.component.html',
  styleUrls: [ './share-tracking-text.component.scss' ]
})
export class ShareTrackingTextComponent implements OnInit {
  @Input()
  public loadDetails: Load;

  @Input()
  public linkToShare: string;

  @Input()
  public locationData: any;

  @Input()
  public supportedLanguages: any;

  @Output()
  private cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  private shareSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() private updateLink: EventEmitter<any> = new EventEmitter<any>();
  public shareTextForm: FormGroup;
  public countryList = [];
  public totalNumberOfContacts = 0;
  public readonly maximumLimitOfInvitees = 10;
  public isLoading = false;
  public messageConfig;
  private carrierCountryCode: string;
  public whatsAppSupportedCountryCode = '+34'; // At the moment only Spain supports it
  private languageCode: string;
  constructor(
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private myLoadsService: MyLoadsService,
    private authService: AuthService,
    private keystoneService: KeystoneService) { }

  ngOnInit() {
    if (this.authService.preferredLanguageCode) {
      const language = this.authService.preferredLanguageCode;
      this.languageCode = XPOConstants.shareTrackingLanguageCodes.indexOf(language) > -1 ? language : XPOConstants.euDefaultLanguageCode;
    }
    this.shareTextForm = this.createForm();
    this.recipients.push(this.initializePhoneNumberField());
    this.totalNumberOfContacts = this.shareTextForm.controls.recipients.value.length;
    this.fetchCountryList();
    this.configureMessages();
    this.setCarrierCountryCode();
    this.shareTextForm.get('language').valueChanges.subscribe(valueChange => {
      this.updateLink.emit(valueChange);
    });
  }

  private setCarrierCountryCode() {
    let userCountryCode = this.authService.userProfile.countryCode;
    userCountryCode = userCountryCode.indexOf('+') === -1 ? `+${userCountryCode}` : userCountryCode;
    this.carrierCountryCode = userCountryCode;
  }

  private configureMessages() {
    this.messageConfig = {
      success: 'trackinglin68', // 'Tracking link was successfully sent to the numbers provided'
      partial: 'deliverytof4594', // 'Delivery to following numbers have failed. Please verify the details and try again.'
      failure: 'wehaveencou683' // 'We have encountered a server error. Please verify numbers and try again later.'
    };
  }

  private createForm(): FormGroup {
    const newForm = this.formBuilder.group({
      recipients: this.formBuilder.array([], Validations.required),
      shouldCopyMe: [ false ],
      mode: [ 'SMS' ],
      language: [ this.languageCode ]
    });
    return newForm;
  }

  public switchMessagingMode(mode) {
    if (mode.value === 'WHATSAPP') {
      this.shareTextForm.get('language').setValue(XPOConstants.euDefaultLanguageCode);
      this.shareTextForm.get('language').disable();
      this.recipients.controls.forEach((recipientControl) => {
        recipientControl.get('countryCode').setValue(this.whatsAppSupportedCountryCode);
        recipientControl.get('countryCode').disable();
      });
      if (this.carrierCountryCode !== this.whatsAppSupportedCountryCode) {
        this.shareTextForm.get('shouldCopyMe').setValue(false);
        this.shareTextForm.get('shouldCopyMe').disable();
      }
    } else {
      this.recipients.controls.forEach((recipientControl) => {
        recipientControl.get('countryCode').enable();
      });
      this.shareTextForm.get('shouldCopyMe').enable();
      this.shareTextForm.get('language').enable();
    }
  }

  private initializePhoneNumberField() {
    const modeOfMessaging = this.shareTextForm.get('mode').value;
    const countryCodeValue = modeOfMessaging === 'SMS' ? '' : { value: this.whatsAppSupportedCountryCode, disabled: true };
    return this.formBuilder.group({
      contact: [ '', Validations.required ],
      countryCode: [ countryCodeValue, [ Validations.required ] ]
    }, {
      validator: this.validateContact.bind(this)
    });
  }

  private fetchCountryList() {
    this.lookupService.getNotificationEnabledCountries().subscribe(response => {
      this.countryList = response;
    });
  }

  private validateContact(contactForm: FormGroup) {
    const contactData = contactForm.value;
    const allContacts = this.recipients.getRawValue();
    const contactsWithCountryCode = allContacts.map((contact) => `${contact.countryCode}-${contact.contact}`);
    const isHavingDuplicateContact = contactsWithCountryCode.some((contact, index) => {
      return contactsWithCountryCode.indexOf(contact) !== index;
    });
    const MOBILE_REGEXP = /^[0-9-+ ]{0,15}$/;
    if (!contactForm.controls.countryCode.value && contactForm.controls.contact.value) {
      contactForm.controls.countryCode.setErrors({ invalid: { error: 'countryCode4153' } });
    }
    if ((!contactData.contact && contactData.countryCode) || !MOBILE_REGEXP.test(contactData.contact)) {
      contactForm.controls.contact.setErrors({ invalid: { error: 'pleaseenter9811' } });
    }
    if (!contactForm.controls.contact.value) {
      contactForm.controls.contact.setErrors({ invalid: { error: 'phonenumber8013' } });
    }
    if (isHavingDuplicateContact) {
      contactForm.controls.contact.setErrors({ invalid: { error: 'duplicateph3073' } });
    }
  }

  get recipients(): FormArray {
    return this.shareTextForm.get('recipients') as FormArray;
  }

  public addPhone() {
    this.recipients.push(this.initializePhoneNumberField());
    this.totalNumberOfContacts++;
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingPhoneActions, XPOConstants.keystoneEventAction.create);
  }

  public removePhone(phoneIndex: number) {
    this.recipients.removeAt(phoneIndex);
    this.totalNumberOfContacts--;
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingPhoneActions, XPOConstants.keystoneEventAction.delete);
  }

  private logShareTrackingKeyStone(eventName, eventAction, eventIdentifiers?) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction,
      this.loadDetails.pageInfo ? this.loadDetails.pageInfo : XPOConstants.keystonePage.loads,
      this.loadDetails.subPageInfo, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

  public sendText() {
    this.isLoading = true;
    const loadDetails = this.loadDetails;
    const shareTextFormValues = this.shareTextForm.getRawValue();

    const userPhoneNumber = this.authService.userProfile.phoneNumber.replace(this.carrierCountryCode, '');
    const textPayloadRequest = {
      ...this.locationData,
      number: loadDetails.isTravelOrder ? loadDetails.alternateNumber : loadDetails.number,
      tripIdentifier: loadDetails.tripNumber,
      url: this.linkToShare + '?lang=' + shareTextFormValues.language,
      recipients: shareTextFormValues.recipients,
      copyMe: shareTextFormValues.shouldCopyMe,
      userMobileNumber: userPhoneNumber,
      userMobileCountryCode: this.carrierCountryCode,
      partnerName: this.authService.userProfile.currentPartner.partnerName,
      mode: shareTextFormValues.mode,
      language: shareTextFormValues.language
    };
    const textKeystoneEventName = shareTextFormValues.mode === 'SMS' ?
      XPOConstants.keystoneEventName.shareTrackingText : XPOConstants.keystoneEventName.shareTrackingWhatsapp;
    this.logShareTrackingKeyStone(textKeystoneEventName, XPOConstants.keystoneEventAction.initiate);
    this.myLoadsService.shareTrackingLink(textPayloadRequest)
      .subscribe((response) => {
        this.isLoading = false;
        const responseDataToShow = {
          ...response,
          messages: this.messageConfig
        };
        this.shareSuccess.emit(responseDataToShow);
      }, (error) => {
        this.onCancel(true);
      });
  }

  public onCancel(shouldClose = false) {
    this.cancel.emit(shouldClose);
    const eventName = shouldClose ? XPOConstants.keystoneEventAction.back : XPOConstants.keystoneEventAction.cancel;
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingPhoneActions, eventName);
  }

}
