<div class="dialog-width">
  <div class="session-dialog">
    <div *ngIf="!timedOut; else relogin_session">
      <div [translate]="'yourse7375'" mat-dialog-title class="session-message-header">
        Your
        session is about to expire.
      </div>
      <br>
      <mat-dialog-content>
        <div class="session-message">
          <div [translate]="'foryou5151'">For your security, this online
            session is
            about to end due to inactivity.</div>
          <div [translate]="'ifyouw9855'">If you want to extend your
            session,
            please
            select the ‘Continue’ button.</div>
          <div [translate]="'ifyous6770'">If you select the ‘Log Out’
            button
            or do
            not respond, your session will automatically close.</div>
        </div>
        <br>
        <i class="timer-icon icon-clock"></i>
        <div class="session-message">
          <span [translate]="'youwil4437'">You will be logged out in</span>
          <span class="session-message-timer">&nbsp;{{ idleState }}</span>
        </div>
        <br>
      </mat-dialog-content>
    </div>
    <ng-template #relogin_session>
      <div mat-dialog-title class="session-message-header text-bold" [translate]="'yoursession1984'">Your session
        has expired.</div>
      <br>
      <mat-dialog-content class="session-message">
        <div [translate]="'foryou9995'">For your security, this online session has
          ended.</div>
        <div [translate]="'ifyouw8479'">If you would like to continue working,
          please log back in.</div>
      </mat-dialog-content>
      <br>
    </ng-template>
    <div *ngIf="!timedOut; else relogin_button">
      <mat-dialog-actions align="end">
        <div fxLayout="row">
          <div fxFlex="50%">
            <form ngNoForm action="/session/end" method="POST">
              <button id="session-logout-btn" type="submit" mat-button class="text-bold-uppercase" (click)="logout()">
                {{'logOut933' | translate}}
              </button>
            </form>
          </div>
          <div fxFlex="50%">
            <form ngNoForm action="/session/refresh" method="POST">
              <button id="session-continue-btn" type="submit" mat-button class="text-bold-uppercase" (click)="reset()">
                {{'continue8568' | translate}}
              </button>
            </form>
          </div>
        </div>
      </mat-dialog-actions>
    </div>
    <ng-template #relogin_button>
      <mat-dialog-actions align="end">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
          <span>
            <form ngNoForm action="/session/end" method="POST">
              <button id="session-logout-btn" type="submit" mat-raised-button class="text-bold-uppercase secondary-btn"
                (click)="logout()">
                {{'logOut933' | translate}}
              </button>
            </form>
          </span>
          <span>
            <form ngNoForm action="/session/create" method="GET">
              <button id="session-login-btn" type="submit" mat-raised-button class="text-bold-uppercase" color="accent"
                (click)="login()">
                {{'restartSess6342' | translate}}
              </button>
            </form>
          </span>
        </div>
      </mat-dialog-actions>
    </ng-template>
  </div>
</div>