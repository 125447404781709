import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: [ './cookie-banner.component.scss' ]
})
export class CookieBannerComponent implements OnInit {

  public isCookieAccepted: boolean;
  public cookiePolicyUrl: string;
  public isNAUser: boolean;

  constructor(
    private cookieService: CookieService,
    private appSettings: AppSettings,
    private authService: AuthService) { }

  ngOnInit() {
    this.isNAUser = this.authService.checkNAUser();
    this.getCookieValue();
    this.cookiePolicyUrl = this.appSettings.apiUrl.replace('api/', 'policy/cookie');
  }

  getCookieValue() {
    this.isCookieAccepted = this.cookieService.check('xpoCookieAccepted');
  }

  acceptCookies() {
    this.cookieService.set('xpoCookieAccepted', 'true', 30);
    this.isCookieAccepted = true;
  }

}
