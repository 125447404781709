<div *ngIf="!getBidAmount && onProcessing; else buyitnow_msg">
  <app-page-loader></app-page-loader>
</div>
<ng-template #buyitnow_msg>
  <div class="buyitnow-dialog-container">
    <div mat-dialog-title>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span>
          <span *ngIf="getBidAmount; else status_message" class="text-bold-uppercase" [translate]="'bookno3285'">book
            now</span>
          <ng-template #status_message>
            <div *ngIf="!onProcessing">
              <span
                *ngIf="isSubmitted && isError && !isDispatchInfoOptionalOrMandatoryCheckRequired; else confirmation_header"
                class="text-bold-uppercase" [translate]="'contactus4988'">contact us</span>
            </div>
            <ng-template #confirmation_header>
              <span
                *ngIf="currentStatus === bidStatuses.accepted && !isDispatchInfoOptionalOrMandatoryCheckRequired; else load_unavailable"
                class="text-bold-uppercase" [translate]="'confirmatio8018'">confirmation
              </span>
              <ng-template #load_unavailable>
                <span *ngIf="!isDispatchInfoOptionalOrMandatoryCheckRequired" class="text-bold-uppercase"
                  [translate]="'loadunavail227'">
                  load unavailable</span>
              </ng-template>
            </ng-template>
          </ng-template>
        </span>
        <span *ngIf=" !onProcessing" class="dialog-close-icon">
          <i class="icon-close" (click)="onClose()"></i>
        </span>
      </div>
    </div>

    <mat-dialog-content>
      <div *ngIf="!isSubmitted && !onProcessing">
        <div *ngIf="!headerWithRedeemOption else withRedeemOption" class="confirmation-message">
          <span [translate]="'youhavechos3579'">You've chosen to purchase</span>
          <b>
            &nbsp;<span [translate]="'loadNo3662'">Load # </span>&nbsp;{{loadNumber}}&nbsp;</b>
          <span [translate]="'atthepriceo5682'"> at the price of </span>
          <b>&nbsp;{{buyPrice | xpocCurrency: currency: true : tenandId}}</b>.
          <span [translate]="'pleaseconfi7063'">Please confirm this action.</span>
          <div class="margin-top-15" *ngIf="!isNAUser">
            <b [translate]="'note836'">Note</b>
            <span>:&nbsp;</span>
            <span [translate]="'thispriceis1010'">This price is fixed and cannot be further negotiated.</span>
          </div>
        </div>
      </div>

      <ng-template #withRedeemOption>
        <div fxLayout="column" class="confirmation-message" fxLayoutGap="5px">
          <div class="msg-header">
            <span [translate]="'youhavechos3579'">You've chosen to purchase</span>
            <b>&nbsp;<span [translate]="'loadNo3662'">Load #
              </span>&nbsp;{{loadNumber}}&nbsp;</b>
            <span [translate]="'atthepriceo5682'"> at the price of </span>
            <b>&nbsp;{{buyPriceWithRedeemedCredit | xpocCurrency: currency: true : tenandId}}</b>.
            <span [translate]="'pleaseconfi7063'">Please confirm this action.</span>
          </div>
          <div class="rewards-container margin-bottom-10" fxLayout="row" fxLayoutGap="15px">
            <mat-checkbox [disabled]="isCreditDisabled || creditAmountToRedeem == 0 || hasAlreadyRedeemed"
              [checked]="isRewardRedeemable" (change)="addCredit($event)"></mat-checkbox>
            <div fxLayout="column" fxLayoutGap="5px">
              <span class="text-bold-capitalize">Claim
                {{creditAmountToRedeem | xpocCurrency: currency: true : tenandId : '1.0-0'}} from Reward
                Balance</span>
              <span class="rewards-balance text-capitalize">available reward balance:
                {{ (isRewardRedeemable ? (remainingCreditAmount > 0 && !hasAlreadyRedeemed? remainingCreditAmount -
                creditAmountToRedeem: remainingCreditAmount) : remainingCreditAmount) | xpocCurrency: currency: true :
                tenandId : '1.0-0'}}
              </span>
              <div class="terms-condition-section"
                *ngIf="showRedeemOption && !hasAcceptedTermsAndConditions && isRewardRedeemable">
                <span>By selecting this you are accepting the
                  <span (click)="showTermsConditions()" class="terms-condition cursor-pointer"
                    [translate]="'termsA2934'">Terms and
                    conditions</span> of the rewards program</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div fxLayout="column" fxLayoutGap="10px" *ngIf="getBidAmount; else status_content" class="content-block"
        [ngClass]="{'withredeem': headerWithRedeemOption}">
        <app-load-requirements [load]="load" [isValidationRequired]=true [checkboxValidationMsg]="checkboxMsg"
          (confirm)="confirmAction($event)" [isUnAuthenticated]="isUnauthenticated"></app-load-requirements>

        <div class="final-price-container" *ngIf="isRewardRedeemable">
          <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="text-bold text-uppercase">
              final price details
            </span>
          </div>
          <div class="price-details-block" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center" fxFlex="100%">
              <div class="text-capitalize">
                original load price
              </div>
              <div>{{buyPrice | xpocCurrency: currency: true : tenandId}}</div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center" fxFlex="100%">
              <div class="text-capitalize">
                claimed XPO rewards balance
              </div>
              <div>{{creditAmountToRedeem | xpocCurrency: currency: true : tenandId}}</div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center" fxFlex="100%">
              <div class="text-bold-capitalize ">
                total load price
              </div>
              <div class="text-bold">
                {{buyPriceWithRedeemedCredit | xpocCurrency: currency: true : tenandId}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="getBidAmount">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button type="button" mat-raised-button id="place-bid-cancel-btn" class="text-uppercase secondary-btn"
          (click)="onClose()">
          {{'cancel7093' | translate}}</button>
        <button type="button" mat-raised-button id="place-bid-confurm-btn" color="accent" class="text-uppercase"
          [disabled]="actionConfirmed" (click)="buyItNow()"> {{'confirm4093' | translate}}</button>
      </div>
    </mat-dialog-actions>
  </div>
  <ng-template #status_content>
    <div *ngIf="!onProcessing && !isDispatchInfoOptionalOrMandatoryCheckRequired">
      <div *ngIf="isSubmitted && !isError">
        <mat-card>
          <mat-card-content>
            <p *ngIf="currentStatus === bidStatuses.accepted; else rejectedBid">
              <span [translate]="'congratulat7797'">Congratulations! </span>
              <br>
              <span *ngIf="isRewardRedeemable || hasAlreadyRedeemed">Rewards credit redeemed! </span>
              <span [translate]="'youhavesucc9473'">You've successfully
                purchased </span>
              <b>
                &nbsp;<span [translate]="'loadNo3662'">Load # </span>&nbsp;{{loadNumber}}&nbsp;</b>
              <span [translate]="'atthepriceo5682'"> at the price of </span>
              <span *ngIf="isRewardRedeemable || hasAlreadyRedeemed; else buyprice_normal">
                <b>&nbsp;{{buyPriceWithRedeemedCredit | xpocCurrency: currency: true : tenandId}}</b>.
              </span>
              <ng-template #buyprice_normal>
                <b>&nbsp;{{buyPrice | xpocCurrency: currency: true : tenandId}}</b>.
              </ng-template>
              <br>
            </p>
            <ng-template #rejectedBid>
              <p *ngIf="errorType !== 7">
                <span [translate]="'weapologize2732'">We apologize, </span>
                <b>
                  &nbsp;<span [translate]="'loadNo3662'">Load # </span>&nbsp;{{loadNumber}}&nbsp;</b>
                <span [translate]="'isnolongera3530'"> is no longer
                  available</span>
              </p>
              <p *ngIf="errorType === 7">
                <span>Sorry, you did not match the requirements
                  and hence were unassigned earlier from this load {{loadNumber}}.</span>
              </p>
            </ng-template>
          </mat-card-content>
        </mat-card>
      </div>
      <div *ngIf="isSubmitted && isError">
        <mat-card>
          <mat-card-content>
            <p>
              <span [translate]="'yourrequest1723'">Your request is taking
                longer than expected. Please contact </span>&nbsp;{{carrierRep.name}}&nbsp;
              <span [translate]="'at1900'" *ngIf="carrierRep.phoneNumber"> at </span>&nbsp;{{carrierRep.phoneNumber}}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div *ngIf="!onProcessing && isDispatchInfoOptionalOrMandatoryCheckRequired" class="status-block">
      <div *ngIf="isSubmitted && !isError" class="confirm-content text-align-center">
        <div *ngIf="currentStatus === bidStatuses.accepted; else rejectedBid">
          <p *ngIf="!isValidationRequired; else validationRequired" fxLayout="column" fxLayoutAlign="center center"
            class="margin-zero">
            <i class="icon-Yes success-icon"></i>
            <br><br>
            <span *ngIf="isRewardRedeemable" class="confirm-message">
              Rewards credit redeemed!</span>
            <span class="confirm-message">You have successfully booked <b>Load #
                {{loadNumber}}.</b></span><br>
            <span class="confirm-message"> A rate confirmation of <b>
                {{buyPriceWithRedeemedCredit | xpocCurrency: currency: true : tenandId}}</b>
              is available in your 'My Loads' tab and has also been sent to {{userEmailAddress}}</span>
            <br>
            <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" *ngIf="isDispatchInfoIncomplete">
              <i fxFlexAlign="start" class="icon-warning_rounded warning-icon margin-top-5"></i>
              <span class="warning-message confirm-message text-align-left">
                Dispatch information is required prior to the day of pickup to avoid negative impact
                on your Carrier Score and your ability to haul future loads with XPO.</span>
            </span>
          </p>
          <ng-template #validationRequired>
            <p fxLayout="column" fxLayoutAlign="center center" class="margin-zero">
              <img src="assets/img/CallGraphic.svg">
              <br><br>
              <span *ngIf="isRewardRedeemable" class="confirm-message">
                Rewards credit redeemed!</span>
              <span class="confirm-message">You have successfully booked
                <b>Load # {{loadNumber}}.</b>
              </span>
              <br>
              <span class="confirm-message">
                <div class="text-bold-uppercase">call to get rate con</div>
                <div class="text-bold">and confirm that you meet load requirements.</div>
                <br>
                <div>{{carrierRep.name}}</div>
                <div>{{carrierRep.phoneNumber}}</div>
              </span>
              <br>
              <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" *ngIf="isDispatchInfoIncomplete">
                <i fxFlexAlign="start" class="icon-warning_rounded warning-icon margin-top-5"></i>
                <span class="warning-message confirm-message text-align-left">
                  Dispatch information is required prior to the day of pickup to avoid negative impact
                  on your Carrier Score and your ability to haul future loads with XPO.</span>
              </span>
            </p>
          </ng-template>
        </div>
        <ng-template #rejectedBid>
          <p *ngIf="errorType !== 7" fxLayout="column" fxLayoutAlign="start center" class="confirm-message">
            <i class="icon-info1 error-icon"></i><br><br>
            <span>
              <span [translate]="'weapologize2732'">We apologize, </span>
              <b>
                &nbsp;<span [translate]="'loadNo3662'">Load #</span>&nbsp;{{loadNumber}}&nbsp;</b>
              <span [translate]="'isnolongera3530'"> is no longer
                available</span>
            </span>
          </p>
          <p *ngIf="errorType === 7" fxLayout="column" fxLayoutAlign="start center" class="confirm-message">
            <i class="icon-info1 error-icon"></i><br><br>
            <span>Sorry, you did not match the requirements
              and hence were unassigned earlier from this load {{loadNumber}}.</span>
          </p>
        </ng-template>
      </div>
      <div *ngIf="isSubmitted && isError">
        <p *ngIf="isDispatchInfoOptionalOrMandatoryCheckRequired" fxLayout="column" fxLayoutAlign="center center"
          class="margin-zero">
          <i class="icon-info1 error-icon"></i><br><br>
          <span class="confirm-message">We were unable to process booking your load at this time. Please try
            again or
            contact us so we can help.</span><br>
          <span fxLayout="row" fxLayoutAlign="center center"><i class="icon-mail email-icon"></i>
            <label class="confirm-message">{{supportEmailAddress}}</label></span><br>
          <span fxLayout="row" fxLayoutAlign="center center" *ngIf="carrierRep.phoneNumber"><i
              class="icon-phone phone-icon"></i>
            <label class="confirm-message"> {{carrierRep.phoneNumber}}</label></span>
        </p>
      </div>
    </div>
  </ng-template>
</ng-template>
<app-reload-count-info *ngIf="currentStatus === bidStatuses.accepted && reloadRequest" [reloadRequest]="reloadRequest"
  [orderNumber]="loadNumber" [page]="'bids'" (closeDialog)="closeDialog($event)">
</app-reload-count-info>