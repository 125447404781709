import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Country } from 'src/app/shared/model/country';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { PhoneFormattingPipe, Validations } from '@xpoc/ngx-common';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { KeystoneService } from 'src/app/shared/services';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model';
import { SupportRequest } from 'src/app/shared/model/support-request';

@Component({
  selector: 'app-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: [ './support-form.component.scss' ]
})
export class SupportFormComponent implements OnInit {

  public supportForm: FormGroup;
  public isNAUser: boolean;
  public countries: Country[];
  public phoneNoMaxLength: number;
  public typeOfSupport = [];
  public showSupportRequired: boolean;
  public showConfirmationMsg: boolean;
  public disableBtn: boolean;

  constructor(

    private formBuilder: FormBuilder,
    private lookup: LookupService,
    private dialogRef: MatDialogRef<SupportFormComponent>,
    private authService: AuthService,
    public customPhoneFormatting: PhoneFormattingPipe,
    private dashboardService: DashboardService,
    private keystoneService: KeystoneService
  ) {
    this.dialogRef.disableClose = true;
    this.typeOfSupport = XPOConstants.typeOfSupport;
    this.isNAUser = this.authService.checkNAUser();
    this.phoneNoMaxLength = (this.isNAUser) ?
      XPOConstants.defaultPhoneNoLengthNA : XPOConstants.defaultPhoneNoLengthEU;
    if (this.isNAUser) {
      this.countries = XPOConstants.countryListNA;
    } else {
      this.lookup.countries().then(response => {
        this.countries = response;
      });
    }
  }

  ngOnInit() {
    this.logKeystone(XPOConstants.keystoneEventAction.initiate);
    this.supportForm = this.createForm();
    this.dashboardService.getHomeLocation().then(response => {
      const mcOrDotNumber = response.mcNumber ? response.mcNumber : (response.dotNumber ? response.dotNumber : '');
      this.supportForm.get('mcOrDotNo').setValue(mcOrDotNumber);
    });
    this.supportForm.get('supportRequired').valueChanges.subscribe(valueChange => {
      if (this.showSupportRequired) {
        this.supportForm.get('supportRequired').setErrors(!valueChange ? { invalid: true } : null);
      }
    });
  }

  private createForm() {
    const validatePhoneNumber = this.isNAUser ? Validations.phone : Validations.euPhone;
    const group = this.formBuilder.group({
      firstName: [ this.authService.userProfile ? this.authService.userProfile.firstName : '',
      [ Validations.firstName, Validators.required ] ],
      lastName: [ this.authService.userProfile ? this.authService.userProfile.lastName : '',
      [ Validations.lastName, Validators.required ] ],
      email: [ this.authService.userProfile ? this.authService.userProfile.email : '', [ Validations.email, Validators.required ] ],
      country: [ XPOConstants.defaultCountryCode, [ Validators.required ] ],
      phoneNumber: [ this.authService.userProfile ? this.customPhoneFormatting.transform(this.authService.userProfile.nationalPhone,
        this.authService.tenantId) : '', [ validatePhoneNumber, Validators.required ] ],
      supportType: [ '', [ Validators.required ] ],
      supportRequired: [ '' ],
      xpoRelationship: [ {
        value: XPOConstants.defaultXPORelation,
        disabled: true
      }, [ Validators.required ] ],
      mcOrDotNo: [ '', [ Validators.required ] ],
      xpoRepresentative: [ '', [ Validators.required ] ],
      notes: [ '', [ Validators.required ] ],
    }, {
        validator: this.validateSupportForm.bind(this)
      });
    return group;
  }

  validateSupportForm(supportForm: FormGroup) {
    const supportFormValue = supportForm.value;
    if (supportFormValue.mcOrDotNo) {
      this.supportForm.get('mcOrDotNo').setErrors(
        this.validateEmptySpace(supportFormValue.mcOrDotNo) ? { required: true } : null);
    }
    if (supportFormValue.xpoRepresentative) {
      this.supportForm.get('xpoRepresentative').setErrors(
        this.validateEmptySpace(supportFormValue.xpoRepresentative) ? { required: true } : null);
    }
    if (supportFormValue.notes) {
      this.supportForm.get('notes').setErrors(
        this.validateEmptySpace(supportFormValue.notes) ? { required: true } : null);
    }
  }

  validateEmptySpace(value) {
    return (value.trim().length > 0 ? false : true);
  }

  logKeystone(offerAction: string) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.supportEmailForm,
      offerAction, XPOConstants.keystonePage.support);
    this.keystoneService.logEvent(logEvent);
  }

  onClose() {
    if (!this.showConfirmationMsg) {
      this.logKeystone(XPOConstants.keystoneEventAction.cancel);
    }
    this.dialogRef.close();
  }

  formatPhone() {
    const supportFormValue = this.supportForm.value;
    this.supportForm.get('phoneNumber').setValue(this.customPhoneFormatting.transform(
      supportFormValue.phoneNumber, this.authService.tenantId));
  }

  onSelectingSupportType() {
    if (this.supportForm.get('supportType').value && this.supportForm.get('supportType').value === this.typeOfSupport[ 6 ].code) {
      this.supportForm.get('supportRequired').setErrors({ invalid: true });
      this.showSupportRequired = true;
    } else {
      this.showSupportRequired = false;
      this.supportForm.get('supportRequired').setErrors(null);
      this.supportForm.get('supportRequired').setValue('');
    }
  }

  sentSupportMail() {
    this.disableBtn = true;
    const supportFormValue = this.supportForm.value;
    const supportReqObj = new SupportRequest(
      supportFormValue.mcOrDotNo,
      supportFormValue.firstName,
      supportFormValue.lastName,
      supportFormValue.email,
      supportFormValue.phoneNumber,
      supportFormValue.supportType,
      supportFormValue.supportRequired,
      supportFormValue.xpoRepresentative,
      supportFormValue.notes
    );
    this.dashboardService.sendSupportMail(supportReqObj).subscribe(response => {
      this.logKeystone(XPOConstants.keystoneEventAction.submit);
      this.showConfirmationMsg = true;
      this.disableBtn = false;
    });
  }

}

