import { TruckTrailerDeatils } from './truck-trailer-details';

export class LoadInfoUpdate {
  constructor(
    public responseStatusId?: number,
    public tripCode?: string,
    public carrierProNumber?: string,
    public driverPhoneNumber?: string,
    public driverName?: string,
    public truckLicenseCountry?: string,
    public truck?: TruckTrailerDeatils,
    public trailer?: TruckTrailerDeatils,
    public trailerLicenseCountry?: string,
    public respondedBy?: string,
    public response?: string,
    public carrierCode?: string,
    public tripId?: string,
    public orderCode?: string,
    public isTracking?: boolean,
    public emailRecipients?: string,
    public emailSubject?: string,
    public frequency?: number,
    public driverId?: number,
    public driverPartnerId?: number,
    public isNewDriverPhoneNumber?: boolean,
    public newDriverPhoneNumberFieldCode?: string,
    public notifyMail?: string,
    public notifyMobileNumbers?: string[],
    public origin?: string,
    public destination?: string,
    public loadDate?: string,
    public deliveryLocation?: string,
    public truckEmptyDate?: string,
    public truckEmptyTime?: string,
    public etaToNextStopDate?: string,
    public etaToNextStopTime?: string,
    public isTruckEmpty?: boolean,
    public trackingMethodCode?: string,
    public useDriverPhoneAsTracking?: boolean,
    public trackingPhoneNumber?: string,
    public truckCurrentLocation?: string,
    public isDriveXPO?: boolean
  ) { }
}
