import { DateFilter } from './date-filter';
import { LaneFilter } from './lane-filter';
import { TripLengthParam } from './trip-length-param';

export class LoadboardGridFilter {
  constructor(
    public page?: number,
    public pageSize?: number,
    public dateFilter?: DateFilter,
    public originLocations?: LaneFilter[],
    public originRadius?: number,
    public destinationLocations?: LaneFilter[],
    public destinationRadius?: number,
    public equipmentCategory?: any[],
    public equipmentSize?: string[],
    public additionalStops?: any[],
    public availableDays?: any[],
    public statusFilter?: any[],
    public orderCommodity?: string[],
    public isExcludeCommodity?: boolean,
    public weightUnit?: string,
    public weight?: number,
    public tripLength?: TripLengthParam,
    public ratePerMileLengthMin?: number,
    public loadNumber?: string,
    public laneFilter?: any,
    public isHotDeal?: number,
    public tripNumber?: string,
    public originSearch?: any[],
    public destinationSearch?: any[],
    public isHazmat?: boolean,
    public minWeight?: number,
    public maxWeight?: number

  ) { }
}
