import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private messageSource = new BehaviorSubject<number>(null);
  currentMessage = this.messageSource.asObservable();

  changeMessage(message) {
    this.messageSource.next(message);
  }

}
