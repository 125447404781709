<section *ngIf="showConfirmationMsg;else confirmationContent">
    <div class="suppportContainer">
        <div mat-dialog-title fxLayout="column" class="container" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="100%">
                    <span class="text-bold-uppercase contact-suppport" [translate]="'contactsupp5328'">CONTACT
                        SUPPORT</span>
                </div>
                <span class="close-icon cursor-pointer">
                    <i class="icon-close" (click)="onClose(false)"></i>
                </span>
            </div>
            <div fxLayout="row" class="center">
                <i class="icon-mail contact-mail"></i>
                <a class="support-link">{{emailAddress}}</a>
            </div>
            <div fxLayout="row" class="required-field">
                <span class="required">*&nbsp;</span>
                <span [translate]="'requir4016'">Required fields</span>
            </div>
        </div>
        <form [formGroup]="euSupportForm">
            <div class="formContainer">
                <mat-dialog-content class="support-form-container" fxLayout="column" fxLayoutGap="5px">
                    <div class="section-title">
                        <span class="text-bold-uppercase" [translate]="'issuedetail6041'">issue details</span>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'typeof2413'|translate}}</mat-label>
                        <mat-select formControlName="supportType" required>
                            <mat-option value="{{support.text}}" *ngFor="let support of euSupportTypes">
                                {{support.translationKey |translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                        *ngIf="euSupportForm.value.supportType=='Missing Orders/Loads'
            ||euSupportForm.value.supportType=='Bidding/Booking Issues'||euSupportForm.value.supportType=='Tracking/Check calls'">
                        <mat-label>{{'order4880'|translate}}/{{'trip3812'|translate}}&nbsp;#</mat-label>
                        <input matInput formControlName="tripId">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'note836'|translate}}</mat-label>
                        <textarea matInput formControlName="note" required maxlength="250"></textarea>
                        <mat-hint align="end">{{euSupportForm.value.note?.length||0}}/250</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'xpoRepresen4869'|translate}}</mat-label>
                        <input matInput formControlName="xpoRepresentative">
                    </mat-form-field>
                    <div class="section-title padding-bottom-15">
                        <span class="text-bold-uppercase" [translate]="'contactInfo9045'">contact info</span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field appearance="outline" fxFlex="50%">
                            <mat-label>{{'firstName9858'|translate}}</mat-label>
                            <input matInput formControlName="firstName" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50%">
                            <mat-label>{{'lastName1947'|translate}}</mat-label>
                            <input matInput formControlName="lastName" required>
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline" fxFlex="50%">
                        <mat-label>{{'email8704'|translate}}</mat-label>
                        <input matInput formControlName="email" required>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field appearance="outline" fxFlex="50%">
                            <mat-label>{{'countryCode5026' | translate}}</mat-label>
                            <mat-select type="select" id="country-code" name="countryCode" formControlName="countryCode"
                                required>
                                <mat-option *ngFor="let country of countryList" [value]="country.callingCode">
                                    {{country.description}} {{country.callingCode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50%">
                            <mat-label>{{'mobile7555' | translate | titlecase}}</mat-label>
                            <input matInput id="phone-number" name="phoneNumber" formControlName="phoneNumber"
                                (keyup)="formatPhone()" required>
                        </mat-form-field>
                    </div>
                </mat-dialog-content>
            </div>
        </form>
        <mat-dialog-actions align="end">
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                <button mat-raised-button class="text-uppercase" type="button" (click)="onClose()">
                    {{'cancel7093' | translate}}
                </button>
                <button mat-raised-button class="text-uppercase blue-bg-btn" type="button"
                    [disabled]="!euSupportForm.valid" (click)="contactSupport()">
                    {{'submit4579' | translate}}
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</section>
<ng-template #confirmationContent>
    <div fxLayout="row" fxLayoutAlign="end"> <span class="close-icon cursor-pointer">
            <i class="icon-close" (click)="onClose(false)"></i>
        </span></div>
    <mat-dialog-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

        <div><i class="icon-Yes success-icon"></i></div>
        <div class="popup-msg-title text-bold"><span [translate]="'emailsentsu4139'">Email sent successfully to
                XPO support team.</span></div>
        <div class="popup-msg"><a class="support-link">{{emailAddress}}</a>
        </div>
    </mat-dialog-content>
</ng-template>