export class LogEvent {
  constructor(
    public layer: string,
    public eventName: string,
    public action: string,
    public page?: string,
    public subPage?: string,
    public eventIdentifiers?: any
  ) { }
}
