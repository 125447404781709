<section *ngIf="!isPostTruckSuccess;else responseMsgBlock;">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="post-truck-title">
        <span class="text-bold" [translate]="'postaTruck8115'">
            Post a Truck
        </span>
        <span class="dialog-close-icon">
            <i class="icon-close" (click)="closeDialog()"></i>
        </span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="margin-bottom-15">
        <mat-divider fxFlex="1 0"></mat-divider>
    </div>
    <form [formGroup]="postTruckForm" class="post-truck-form">
        <mat-dialog-content>
            <div fxLayout="column" fxLayoutGap="10px" class="margin-bottom-15">
                <div fxLayout="row" fxLayoutGap="10px">
                    <div fxFlex="65%">
                        <mat-form-field class="form-field-input" appearance="outline">
                            <mat-label>{{'origin7355' | translate}}</mat-label>
                            <input placeholder="{{'cityzipcode207' | translate}}" matInput formControlName="origin"
                                [matAutocomplete]="originAuto" class="form-field-input" required
                                (focusout)="validateOrigin()">
                            <mat-autocomplete #originAuto="matAutocomplete" [displayWith]="getAutocompleteDisplayValue"
                                autoActiveFirstOption>
                                <mat-option *ngFor="let originOption of originOptions" [value]="originOption">
                                    {{originOption.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxFlex="35%">
                        <mat-form-field class="form-field-input" appearance="outline">
                            <mat-select class="dead-head-select" id="origin-dead-head" formControlName="originRadius"
                                disableOptionCentering>
                                <mat-option *ngFor="let deadhead of deadheads" [value]="deadhead">{{deadhead}}
                                    {{distanceUnit}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <div fxFlex="65%">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'destination2571' | translate}}</mat-label>
                            <input placeholder="{{ (isNAUser ? 'cityStateor2739' : 'cityCountry8047')  | translate}}"
                                matInput formControlName="destination" [matAutocomplete]="destAuto"
                                class="form-field-input" (focusout)="validateDestination()">
                            <mat-autocomplete #destAuto="matAutocomplete" [displayWith]="getAutocompleteDisplayValue"
                                autoActiveFirstOption>
                                <mat-option *ngFor="let destinationOption of destinations" [value]="destinationOption">
                                    {{destinationOption.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div fxFlex="35%">
                        <mat-form-field class="form-field-input" appearance="outline">
                            <mat-select class="dead-head-select" id="dests-dead-head"
                                formControlName="destinationRadius" disableOptionCentering>
                                <mat-option *ngFor="let deadhead of deadheads " [value]="deadhead">{{deadhead}}
                                    {{distanceUnit}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <div class="notify margin-bottom-15">
                        <span class="text-bold text-capitalize"> {{'pickup5355' | translate}}
                            {{'schedule6210' | translate}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <div fxFlex="100%">
                            <mat-form-field class="form-field-input" appearance="outline">
                                <mat-label> {{'startDate7194' | translate}}</mat-label>
                                <input [min]="minDate" [max]="maxStartDate" matInput
                                    (dateChange)="startDateSelected($event)" placeholder="{{'date8215' | translate}}"
                                    [matDatepicker]="startDatePicker" id="start-date" name="startDate"
                                    #startDatePickerInput formControlName="startDate" [required]="true"
                                    (focus)="startDatePicker.open()">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker (closed)="startDatePickerInput.blur()">
                                </mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutGap="10px">
                        <div fxFlex="100%">
                            <mat-form-field class="form-field-input" appearance="outline">
                                <mat-label>{{'endDate8761' | translate}}</mat-label>
                                <input [min]="minEndDate" [max]="maxEndDate" matInput
                                    placeholder="{{'date8215' | translate}}" [matDatepicker]="endDatePicker"
                                    id="end-date" name="endDate" #endDatePickerInput formControlName="endDate"
                                    [required]="true" (focus)="endDatePicker.open()">
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker (closed)="endDatePickerInput.blur()"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxFlex="100%">
                    <mat-form-field class="form-field-input" appearance="outline">
                        <mat-label>{{'equipment6372' | translate}}</mat-label>
                        <input matInput formControlName="equipment" [matAutocomplete]="equipmentAuto"
                            class="form-field-input" required>
                        <mat-autocomplete #equipmentAuto="matAutocomplete" [displayWith]="getEquipmentDisplayValue"
                            autoActiveFirstOption>
                            <mat-option *ngFor="let equipment of equipments" [value]="equipment">
                                {{equipment.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <i class="icon-bell bell-notify"></i>
                    <div class="notify margin-bottom-15">
                        <span class="text-bold text-capitalize" id="get-notifications"
                            [translate]="'getnotifica3429'">Get Notifications By</span>
                    </div>
                </div>
                <div fxLayout="row" class="padding-10 notification-block" fxLayoutAlign="space-between center">
                    <div [translate]="'email8704'" fxFlex="100%">Email</div>
                    <div>
                        <section class="example-section">
                            <mat-slide-toggle class="example-margin" [checked]="email?true:false"
                                (change)="emailToggle($event)">
                            </mat-slide-toggle>
                        </section>
                    </div>
                </div>
                <div [hidden]="true">
                    <div fxLayout="row" class="padding-10 notification-block " fxLayoutAlign="space-between center">
                        <div [translate]="'sMS5598'" fxFlex="100%">SMS</div>
                        <div>
                            <section class="example-section">
                                <mat-slide-toggle class="example-margin">
                                </mat-slide-toggle>
                            </section>
                        </div>
                    </div>
                </div>

            </div>
        </mat-dialog-content>
    </form>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-divider fxFlex="1 0"></mat-divider>
    </div>
    <mat-dialog-actions align="end">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
            <button mat-raised-button class="secondary-btn" id="cancel-user-btn" (click)="onClear()">
                {{'clear3546' | translate}}
            </button>
            <button mat-raised-button color="accent" mat-raised-button class="mat-raised-button mat-accent"
                id="post-truck" (click)="postTruck()" [disabled]="!postTruckForm.valid">
                {{'post3541' | translate}}
            </button>
        </div>
    </mat-dialog-actions>
</section>
<ng-template #responseMsgBlock>
    <mat-dialog-content class="response-msg">
        <div align="end" class="dialog-close-icon">
            <i class="icon-close" (click)="closeDialog()"></i>
        </div>
        <div class="text-center response-msg-block" fxLayout="column" fxLayoutAlign="center center">
            <img src="assets/img/truck_posted.svg" alt="post-truck">
            <div class="message">
                <div class="text-bold margin-top-10 truck-posted-msg" [translate]="'yourtruckha402'">Your truck has been
                    posted.</div>
                <div class="margin-top-10" [translate]="'wellnotifyy2082'">
                    We'll notify you by email when loads match your criteria.
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>