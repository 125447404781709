import { Component, OnInit, Input } from '@angular/core';
import { Stop } from '../model/stop';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from '../xpo-constants';
import { DateTimePipe } from '@xpoc/ngx-common';
import { DateTimeService } from '../services/date-time.service';

@Component({
  selector: 'app-available-load-stops',
  templateUrl: './available-load-stops.component.html',
  styleUrls: [ './available-load-stops.component.scss' ]
})
export class AvailableLoadStopsComponent implements OnInit {

  public dateTimeFormat: string;
  public isNAUser: boolean;
  public dateDisplayFormat: string;
  public timeDisplayFormat: string;

  @Input() public stops: Stop[];

  constructor(
    private authService: AuthService,
    private datePipe: DateTimePipe,
    private dateTimeService: DateTimeService) {
    this.isNAUser = this.authService.checkNAUser();
  }

  ngOnInit() {
    if (this.isNAUser) {
      this.dateDisplayFormat = XPOConstants.dateFormat;
      this.timeDisplayFormat = XPOConstants.timeformat;
    } else {
      this.dateDisplayFormat = XPOConstants.euDateFormat;
      this.timeDisplayFormat = XPOConstants.euTimeFormat;
    }
  }

  public getScheduledArrivalLate(scheduledArrivalEarly, scheduledArrivalLate) {
    let arrivalLateFormatted = '';
    if (scheduledArrivalLate) {
      const isDateSameRange = this.dateTimeService.isSameDate(scheduledArrivalEarly, scheduledArrivalLate);
      const timeOnly = this.datePipe.transform(scheduledArrivalLate, this.timeDisplayFormat);
      const dateWithTime =
        `${this.datePipe.transform(scheduledArrivalLate, this.dateDisplayFormat)} • ${this.datePipe.transform(scheduledArrivalLate, this.timeDisplayFormat)}`;
      arrivalLateFormatted = isDateSameRange ?
        timeOnly :
        dateWithTime;
    }
    return arrivalLateFormatted;
  }

}

