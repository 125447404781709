import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { LoadDetails } from '../model/load-details';
import { LoadFilter } from '../model/load-filter';
import { AppSettings } from 'src/app/core/app-settings';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoadInfoUpdate } from '../model/load-infoupdate';
import { LoadResponse } from '../model';
import { XPOConstants } from '../xpo-constants';
import { DateTimePipe as DatePipe } from '@xpoc/ngx-common';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MyLoadsService {

  private baseUrl: string;
  public isFromLoadDetails: boolean;
  public loadFilterObject: LoadFilter;
  public isFileDownloading = false;
  private isNAUser: boolean;
  public searchedLoadNumber: string;
  public refreshMyLoadDetails = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private datePipe: DatePipe,
    private authService: AuthService) {
    this.baseUrl = this.appSettings.apiUrl;
    this.isNAUser = authService.checkNAUser();
  }

  refreshLoadDetails(shouldRefreshLoadDetails) {
    this.refreshMyLoadDetails.next(shouldRefreshLoadDetails);
  }

  getLoadDetails(loadNumber: string): Promise<LoadDetails> {
    const url = this.getUri('details');
    const reqObj = {
      id: loadNumber
    };
    return this.http
      .post(url, reqObj)
      .toPromise()
      .then((response) => {
        return response as LoadDetails;
      })
      .catch(error => {
        return null;
      });
  }

  // phoneNumberValidation(phoneNumber: string) {
  //   const url = this.getUri(`tracking/validate/phone/${phoneNumber}`);
  //   return this.http
  //     .post(url, { headers: this.getHeaders() })
  //     .pipe(map(response => {
  //       return response;
  //     }), catchError((error) => {
  //       return Promise.reject(error);
  //     }));
  // }

  getUpdateInfoCount(): Observable<number> {
    const url = this.getUri(`withnodispathinfo/count`);
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getTrackingmethods(): Observable<any> {
    const url = this.getUri(`trackingmethods?api_key=tracking`);
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getLoads(loadFilterObj: LoadFilter): Promise<LoadResponse> {
    const url = this.getUri(`search`);
    return this.http
      .post(url, loadFilterObj, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  search(loadFilterObj: LoadFilter): Promise<LoadResponse> {
    const url = this.getUri(`search`);
    return this.http
      .post(url, loadFilterObj, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  public getTrackingLink(tripCode) {
    const url = `${this.baseUrl}tracking/carrier?tripcode=${tripCode}`;
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  public shareTrackingLink(shareTrackingRequest): Observable<any> {
    const url = `${this.baseUrl}notifications/tracking`;
    return this.http
      .post(url, shareTrackingRequest, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  updateLoadInfo(loadInfo: LoadInfoUpdate): Promise<any> {
    const url = this.getUri('dispatcher');
    return this.http
      .post(url, loadInfo, { headers: this.getHeaders() })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
      });
  }

  updateLoadInfoToStaging(loadInfo: LoadInfoUpdate): Promise<any> {
    const url = this.getUri('dispatch/staging');
    return this.http
      .post(url, loadInfo, { headers: this.getHeaders() })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
      });
  }

  saveCheckCallDetails(checkCallRequest: any, checkCallNumber: number): Observable<boolean> {
    let checkCallIdentifier = '';
    switch (checkCallNumber) {
      case XPOConstants.checkCallNumbers.first:
        checkCallIdentifier = 'arrival';
        break;
      case XPOConstants.checkCallNumbers.second:
        checkCallIdentifier = 'pickupdeparture';
        break;
      case XPOConstants.checkCallNumbers.third:
        checkCallIdentifier = 'arrival';
        break;
      case XPOConstants.checkCallNumbers.fourth:
        checkCallIdentifier = 'dropoffdeparture';
        break;
    }
    const url = this.getUri(`checkcall/${checkCallIdentifier}`);
    return this.http
      .post(url, checkCallRequest, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  public getCheckCallWeightMandatoryStatus(tripId, stopNumber): Observable<any> {
    const url = this.getUri('checkcall/validateweight');
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tripId', tripId);
    queryParams = queryParams.append('stopNumber', stopNumber);
    return this.http
      .get(url, { headers: this.getHeaders(), params: queryParams })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getCheckCallKeyStoneEvent(checkCallNumber: number): string {
    let keyStoneEventName = '';
    switch (checkCallNumber) {
      case XPOConstants.checkCallNumbers.first:
        keyStoneEventName = XPOConstants.keystoneEventName.arrivedOrigin;
        break;
      case XPOConstants.checkCallNumbers.second:
        keyStoneEventName = XPOConstants.keystoneEventName.departedOrigin;
        break;
      case XPOConstants.checkCallNumbers.third:
        keyStoneEventName = XPOConstants.keystoneEventName.arrivedDestination;
        break;
      case XPOConstants.checkCallNumbers.fourth:
        keyStoneEventName = XPOConstants.keystoneEventName.departedDestination;
        break;
    }
    return keyStoneEventName;
  }

  getDriverList(search): Observable<any> {
    const url = this.baseUrl + 'drivers/basicinformation';
    let params = new HttpParams();
    params = params.append('searchText', search);
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getLoadData(orderNo, id): Observable<any> {
    const url = this.getUri(`${orderNo}/trip/${id}/dispatcher`);
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  dispatchMadateValidate(tripCode, dateRequest): Promise<any> {
    const url = this.getUri(`trips/${tripCode}/dispatchmandatecriteriamatch?partnerIdentifier=${this.authService.partnerIdentifier}`);
    return this.http
      .post(url, dateRequest, { headers: this.getHeaders().append('skipRedirect', 'true') })
      .toPromise();
  }


  getDriverStatus(statusRequest): Observable<any> {
    const url = this.getUri(`dispatch/validatedriverallocation`);
    return this.http
      .post(url, statusRequest, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  exportLoads(filterObject: LoadFilter, loadCount: number): Promise<any> {
    this.isFileDownloading = true;
    const url = this.getUri(`export/${loadCount}`);
    return this.http
      .post(url, filterObject, {
        responseType: 'blob'
      })
      .toPromise()
      .then(response => {
        this.downloadFile(response);
      })
      .catch(error => {
        this.isFileDownloading = false;
        this.handleError(error);
      });
  }

  downloadFile(data: Blob) {
    let preferredDateFormat = this.isNAUser ? XPOConstants.dateFormat : XPOConstants.euDateFormat;
    preferredDateFormat = preferredDateFormat.replace(/\//g, '-');
    const currentDate = this.datePipe.transform(new Date(), preferredDateFormat);
    const blob = data;
    const downloadLink = document.createElement('a');
    const downloadUrl = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      downloadLink.setAttribute('target', '_blank');
    }
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, `My Loads - ${currentDate}.xls`);
    } else {
      downloadLink.setAttribute('href', downloadUrl);
      downloadLink.setAttribute('download', `My Loads - ${currentDate}.xls`);
      downloadLink.style.visibility = 'hidden';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    this.isFileDownloading = false;
  }

  loadAvailable(body): Promise<any> {
    const url = this.getUri('availability');
    return this.http
      .post(url, body, this.getOptions())
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(this.handleError);
  }

  // Constructs the headers collection to use
  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getOptions() {
    const options = { headers: this.getHeaders(), withCredentials: true };
    return options;
  }

  // Constructrs the uri to use for the endpoint
  private getUri(endpoint?: string): string {
    return this.baseUrl + 'loads/' + endpoint;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
