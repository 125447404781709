import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppState {
  private data = new Subject<object>();
  private dataStream$ = this.data.asObservable();
  private subscriptions: Map<string, Array<(...param) => void>> = new Map<string, Array<(...param) => void>>();

  constructor() {
    this.dataStream$.subscribe((data) => this.onEvent(data));
  }

  notifyDataChanged(event, value) {
    const current = this.data[ event ];
    if (current !== value) {
      this.data[ event ] = value;
      this.data.next({
        event,
        data: this.data[ event ]
      });
    }
  }

  subscribe(event: string, callback: (...param) => void) {
    const subscribers = this.subscriptions.get(event) || [];
    subscribers.push(callback);
    this.subscriptions.set(event, subscribers);
  }

  onEvent(data: any) {
    const subscribers = this.subscriptions.get(data.event) || [];
    subscribers.forEach((callback) => {
      callback.call(null, data.data);
    });
  }
}
