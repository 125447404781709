<div class="cancel-confirmation">
    <div mat-dialog-title class="confirmation-header">
        <div fxLayout="row" fxLayoutAlign="end end">
            <span class="dialog-close-icon">
                <i class="icon-close" (click)="onClose()"></i>
            </span>
        </div>
    </div>
    <mat-dialog-content>
        <div fxLayout="column" fxLayoutAlign="center center" class="confirmation-content">
            <i class="icon-info1 error-icon"></i>
            <br>
            <b><span class="cancel-load">Cancel booking load # {{loadNumber}}?</span></b>
            <br>
            <span> Are you sure you want to cancel booking the load # {{loadNumber}}?</span>
            <br>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button (click)=" onClose()" class="text-uppercase secondary-action-btn"
            type="button">No</button>
        <button (click)="onDiscardChanges()" class="text-uppercase" mat-raised-button color="accent"
            type="button">Yes</button>
    </mat-dialog-actions>
</div>