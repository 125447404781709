<div fxLayoutAlign="end" class="close-btn">
    <i class="icon-close" (click)="onCancel()"></i>
</div>
<div>
    <app-filter-dropdown-mobile (filter)=onFilter($event) [filterList]="filterList" *ngIf="filterList"
        [filterLabel]="filterTxt" [initCheckedStatus]="initCheckedFilterStatus" [filterReset]="filterReset">
    </app-filter-dropdown-mobile>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center" class="status-button-block">
    <div fxFlex="40%">
        <button class="status-btns" mat-button (click)="onCancel()">{{'cancel7093' | translate}}</button>
    </div>
    <div fxFlex="60%" fxLayoutAlign="end center">
        <button class="status-btns" mat-button (click)="onClear()">{{'clearall6488' | translate}}</button>
        <button class="status-btns done-btn" mat-button (click)="onApply()">{{'done4198' | translate}}</button>
    </div>
</div>