import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  updateProfileInfo: User;

  constructor(private http: HttpClient,
              private appSettings: AppSettings) { }

  setUpdatedUserProfile(updateProfileInfo: User) {
    const url = this.getUri('userProfile');
    return this.http
      .put(url, updateProfileInfo, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise()
      .then((response) => {
        return response.status;
      })
      .catch(this.handleError);
  }

  getUserProfile(): Promise<any> {
    const url = this.getUri('');
    return this.http
      .get(url, { withCredentials: true })
      .toPromise()
      .then((response) => {
        if (response) {
          return response;
        }
        return null;
      })
      .catch(this.handleError);
  }


  // Constructrs the uri to use for the endpoint
  private getUri(endpoint?: string): string {
    return this.appSettings.apiUrl + 'userprofile';
  }

  // Constructs the headers collection to use
  private getHeaders(obj?: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
