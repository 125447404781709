import { KeyValue } from 'src/app/shared/model/key-value';

export class SelfRegistration {
  constructor(
    public firstName: string,
    public lastName: string,
    public phoneNumber: string,
    public emailAddress: string,
    public dotNumber: string,
    public extraDetails: KeyValue[],
    public recaptchaResponse: string,
    public roleCode: string,
    public sourceCode: string,
    public sourceComments: string,
    public mcNumber?: string,
    public isContracted?: boolean,
    public isActive?: boolean,
    public hasCargoInsurance?: boolean,
    public hasAutoInsurance?: boolean
  ) { }
}
