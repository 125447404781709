<div class="file-upload-dialog-container">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="text-bold-uppercase" mat-dialog-title>
    <ng-container *ngIf="isNAUser; else podUploadHeader;">
      <span id="alert-title" *ngIf="!order.isReplace; else replaceHeader" [translate]="'addDoc7110'">
        add document
      </span>
    </ng-container>
    <ng-template #replaceHeader>
      <span id="alert-title" class="alert-title">replace
        document</span>
    </ng-template>
    <ng-template #podUploadHeader>
      <span id="alert-title" class="alert-title" [translate]="'uploadPoD103'">
        Upload POD
      </span>
    </ng-template>
    <span class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </span>
  </div>
  <div *ngIf="!loadAssignedPermission" class="error-message" fxLayout="row" fxLayoutGap="5px">
    <div class="icon-exclaim"><i class="icon-exclaim_btn"></i></div>
    <div [translate]="'youdonothav6480'">You do not have permission to to upload POD.</div>
  </div>
  <form [formGroup]="paperworkUploadForm" *ngIf="!isUploadSuccess; else success_message">
    <div class="text-capitalize required-field">
      *<span>&nbsp;</span><span [translate]="'requir4016'">required fields</span>
    </div>
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="10px">
        <mat-form-field class="form-field-input" *ngIf="isNAUser; else stopPicker;">
          <input matInput placeholder="Order #" class="order-number-input" type="text" id="orderno_readonly"
            name="orderno_readonly" formControlName="orderNo" readonly required>
        </mat-form-field>
        <ng-template #stopPicker>
          <mat-form-field class="form-field-input">
            <mat-select placeholder="{{'delive8261' | translate | titlecase}}" required
              formControlName="deliveryLocation" (selectionChange)="onDeliveryLocationChange($event)">
              <mat-option *ngFor="let location of deliveryLocations; let i=index" [value]="location">
                {{location.locationName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="selectedDeliveryLocation">
            <span class="text-bold" [translate]="'address5096'">Address:</span>:
            <span>
              {{selectedDeliveryLocation.locationAddress1}},
              {{selectedDeliveryLocation.cityName}},
              {{selectedDeliveryLocation.countryName}},
              {{selectedDeliveryLocation.zipCode}}
            </span>
          </div>
        </ng-template>
        <h4 [translate]="'docume1119'" class="text-uppercase margin-bottom-10 margin-top-5">document</h4>
        <div *ngFor="let document of documents.controls; let i = index;">
          <mat-form-field class="form-field-input" *ngIf="isNAUser">
            <mat-select placeholder="{{'docume8079' | translate | titlecase}}" required formControlName="documentType"
              (selectionChange)="onSelectDocumentType($event)">
              <mat-option *ngFor="let docType of documentTypeList; let i=index" [value]="docType.code">
                {{docType.externalName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-container *ngIf="isNAUser">
          <mat-form-field class="form-field-input">
            <input type="text" matInput placeholder="{{'carrie6823' | translate}}" formControlName="carrierInvoiceNo"
              name="carrierInvoiceNo" id="carrier-invoice-no" [required]="isInvoiceDetailsMandatory">
            <mat-error
              *ngIf="paperworkUploadForm.controls.carrierInvoiceNo.errors && paperworkUploadForm.controls.carrierInvoiceNo.errors.required">
              <span [translate]="'carrie7316'">Carrier invoice
                no is required.</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-input">
            <input type="text" placeholder="{{'invoic7586' | translate}}" formControlName="invoiceAmount"
              name="invoice_amount" matInput id="invoice-amount"
              pattern="^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$" [required]="isInvoiceDetailsMandatory">
            <mat-error
              *ngIf="paperworkUploadForm.controls.invoiceAmount.errors && paperworkUploadForm.controls.invoiceAmount.errors.required">
              <span>Invoice amount is required.</span>
            </mat-error>
            <mat-error
              *ngIf="paperworkUploadForm.controls.invoiceAmount.errors && paperworkUploadForm.controls.invoiceAmount.errors.pattern">
              <span [translate]="'invali942'">Invalid
                invoice amount</span>
            </mat-error>
          </mat-form-field>
        </ng-container>
        <div formArrayName="documents">
          <div *ngFor="let document of documents.controls; let i = index;">
            <app-documents [paperworkUploadForm]="paperworkUploadForm" [formGroupName]="i" [docForm]="document"
              [uploadErrorFlag]="uploadErrorFlag" [uploadError]="uploadError"
              [supportedFileFormats]="permittedFileFormats"></app-documents>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
        <button id="cancel-btn" class="text-uppercase full-width secondary-btn" mat-raised-button (click)="onClose()">
          <span [translate]="'cancel7093'">CANCEL</span>
        </button>
        <button *ngIf="!order.isReplace; else replaceButton" id="upload-btn" color="accent"
          class="text-uppercase full-width" [class.btn-spinner]="isUploading" mat-raised-button (click)="uploadFile()"
          [disabled]="!paperworkUploadForm.valid || disableUploadBtn || !loadAssignedPermission">
          <span [translate]="'upload3780'">upload</span>
        </button>
        <ng-template #replaceButton>
          <button id="replace-btn" color="accent" class="text-uppercase full-width" mat-raised-button
            (click)="replaceFile()" [disabled]="!paperworkUploadForm.valid || disableUploadBtn">
            <span [translate]="'replac5857'">replace</span>
          </button>
        </ng-template>
      </div>
    </mat-dialog-actions>
  </form>
  <ng-template #success_message>
    <div *ngIf="!order.isReplace">
      <mat-dialog-content>
        <div class="margin-bottom-15" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="end center">
          <div class="text-bold-capitalize file-name">{{fileName}}</div>
          <div [translate]="'attach512'" class="attachment-msg">
            Attachment has been uploaded successfully.
          </div>
          <div [translate]="'wouldy5198'" class="doc-msg">
            Would you like to add another document?
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
          <button id="no-btn" class="text-uppercase full-width" mat-raised-button (click)="onClose()">
            <span [translate]="'no9227'">No</span>
          </button>
          <button id="yes-btn" class="text-uppercase full-width" color="accent" mat-raised-button
            (click)="addNewDocument()">
            <span [translate]="'yes7992'">Yes</span>
          </button>
        </div>
      </mat-dialog-actions>
    </div>
    <div *ngIf="order.isReplace">
      <mat-dialog-content>
        <div class="margin-bottom-10" [translate]="'yourdo5580'">
          Your document has been sucessfully replaced.
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button id="close-btn" class="text-uppercase full-width secondary-btn" mat-raised-button (click)="onClose()">
          <span [translate]="'close7039'">close</span>
        </button>
      </mat-dialog-actions>
    </div>
  </ng-template>
</div>