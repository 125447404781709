import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { GlobalService } from '../global.service';
import { LogEvent } from '../model';
import { AvailableLoadsService, KeystoneService } from '../services';
import { XPOConstants } from '../xpo-constants';

@Component({
  selector: 'app-reload-count-info',
  templateUrl: './reload-count-info.component.html',
  styleUrls: [ './reload-count-info.component.scss' ]
})
export class ReloadCountInfoComponent implements OnInit {

  @Input() reloadRequest: any;
  @Input() orderNumber: any;
  @Input() page: string;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  public isNAUser: boolean;
  public reloadsCount: any;
  public reloadLabel: any;

  constructor(
    private keystoneService: KeystoneService,
    private globalService: GlobalService,
    private availableLoadService: AvailableLoadsService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.isNAUser = this.authService.checkNAUser();
  }

  ngOnInit() {
    this.getReloadCount();
  }

  getReloadCount() {
    this.availableLoadService.getReloadsCount(this.reloadRequest).subscribe(count => {
      this.reloadsCount = count;
      this.reloadLabel = this.reloadsCount === 1 ? 'reload1424' : 'reloads1258';
    });
  }

  public navigateToReloads() {
    this.closeDialog.emit();
    const eventIdentifier = {
      orderNumber: this.orderNumber
    };
    this.logBidsKeyStone(XPOConstants.keystoneEventName.reloadAfterTransaction,
      XPOConstants.keystoneEventAction.click, eventIdentifier);
    const preferredLaneRadius = this.isNAUser ? XPOConstants.defaultLaneRadius : XPOConstants.defaultEuMileRadius;
    const laneOrigin = {
      name: this.reloadRequest.originLocations[ 0 ].location,
      latitude: this.reloadRequest.originLocations[ 0 ].latitude,
      longitude: this.reloadRequest.originLocations[ 0 ].longitude,
      laneRadius: preferredLaneRadius,
      destinationDeliveryDate: this.reloadRequest.pickupDate.min,
      destinationDeliveryDateLate: this.reloadRequest.pickupDate.max
    };
    this.globalService.planNextMoveForEquipment = this.reloadRequest.equipmentCategory ? this.reloadRequest.equipmentCategory[ 0 ] : '';
    this.availableLoadService.parentLoadNumber = this.orderNumber;
    this.globalService.setLaneOriginInfo(laneOrigin);
    this.globalService.setSearchLaneOriginInfo(laneOrigin.name);
    if (window.innerWidth < 1280) {
      this.availableLoadService.loadBoardFilterObj = null;
      this.availableLoadService.sortFilterObj = [];
    }
    if (window.location.pathname.endsWith('available-loads')) {
      if (this.availableLoadService.getCurrentTab() !== XPOConstants.availableLoadsTabCodes.all) {
        this.availableLoadService.triggerTabSwitch(XPOConstants.availableLoadsTabCodes.all);
      } else {
        this.globalService.setReloadAvailableLoad();
      }
    } else {
      this.availableLoadService.setTabBeforeNavigation(XPOConstants.availableLoadsTabCodes.all);
      this.router.navigate([ '/loads/available-loads' ]);
    }
  }

  logBidsKeyStone(eventName, eventAction, eventIdentifiers?) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction,
      this.page, null, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

}
