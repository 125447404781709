import { AuthService } from 'src/app/user-authentication/auth.service';
import { DateTimePipe } from '@xpoc/ngx-common';
import { Component, OnInit, NgZone, Input, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { MapConstants } from 'src/app/shared/model/';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { XpoGoogleMapApi } from 'src/app/google-map/services/google-map-api';
import { GoogleAnalyticsEventsService } from 'src/app/google-analytics/google-analytics-events.service';
import { WeatherAlertService, TrafficService } from 'src/app/shared/services';
import { LoadStop } from 'src/app/shared/model/load-stop';
import { Location } from 'src/app/shared/model/location copy';
import { AppSettings } from 'src/app/core/app-settings';


@Component({
  selector: 'app-load-detail-map',
  templateUrl: './load-detail-map.component.html',
  styleUrls: [ './load-detail-map.component.scss' ]
})

export class LoadDetailMapComponent extends XpoGoogleMapApi implements OnInit, OnChanges {

  @ViewChild('googleMap', { static: true }) mapDiv: ElementRef;
  @Input() loadStops: LoadStop[] = [];
  @Input() hideIntermediateStops: boolean;

  private mapOptions: any = {
    ...MapConstants.MAP_OPTIONS,
    center: MapConstants.NA_DEFAULT_LOCATION,
    traffic: false, // Enabling traffic control in the map
    weather: false, // Enabling weather control in the map
    zoomControl: false,
    mapTypeControl: false
  };
  private origin: Location;
  private destination: Location;
  private stops = [];
  private clutteredMarkers: any[] = [];
  private count = 0;
  public ismapCreated: boolean;


  constructor(
    private trafficServiceChild: TrafficService,
    private weatherAlertServiceChild: WeatherAlertService,
    private ngZoneChild: NgZone,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public datepipe: DateTimePipe,
    public titleCase: TitleCasePipe,
    public upperCase: UpperCasePipe,
    public appSettings: AppSettings,
    public authService: AuthService
  ) {
    super(trafficServiceChild, weatherAlertServiceChild, ngZoneChild, googleAnalyticsEventsService, authService,
      datepipe, titleCase, upperCase, appSettings);
  }

  private handleInput() {
    this.stops = [];
    let ind = 0;
    this.loadStops.forEach(stop => {
      if (ind === 0) {
        this.origin = new Location(
          stop.cityName,
          stop.latitude,
          stop.longitude
        );
      } else if (ind === this.loadStops.length - 1) {
        this.destination = new Location(
          stop.cityName,
          stop.latitude,
          stop.longitude
        );
      } else {
        let intermediate: Location;
        intermediate = new Location(
          stop.cityName,
          stop.latitude,
          stop.longitude,
          stop.type
        );
        this.stops.push(intermediate);
      }
      ind = ind + 1;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ismapCreated) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    this.hideIntermediateStops = this.hideIntermediateStops || false;
    this.handleInput();
    // Creating the map
    this.createMap(this.mapDiv.nativeElement, this.mapOptions).then(() => {
      this.ismapCreated = true;
      // Drawing the map route from origin to destination
      const origin = {
        lat: this.origin.latitude,
        lng: this.origin.longitude
      };
      const destination = {
        lat: this.destination.latitude,
        lng: this.destination.longitude
      };
      this.drawRoute(origin, destination, this.stops, this.hideIntermediateStops, true);
      this.map.addListener('zoom_changed', () => {
        this.mapZoomListener();
      });
    });
  }

  mapZoomListener() {
    const zoomLevel: number = this.map.getZoom();
    if (zoomLevel <= 2) {
      this.rescaleMarker(0.25);
    } else if (zoomLevel <= 3) {
      this.rescaleMarker(0.5);
    } else if (zoomLevel <= 4) {
      this.rescaleMarker(0.75);
    } else if (zoomLevel <= 5) {
      this.rescaleMarker(1);
    } else if (zoomLevel <= 10) {
      this.rescaleMarker(1.5);
    } else {
      this.rescaleMarker(2);
    }
  }

  // Method responsible for rescaling the markers
  rescaleMarker(scale: number) {
    for (const clutteredMarker of this.clutteredMarkers) {
      // Iterating the cluttered markers
      for (const markerName of Object.keys(clutteredMarker)) {
        const marker = clutteredMarker[ markerName ];
        const icon = marker.getIcon();
        // Rescaling the marker icon
        icon.scale = scale;
        marker.setIcon(icon);
      }
    }
    this.count += 1;
    if (this.count === 2 && this.origin.latitude === this.destination.latitude && this.origin.longitude === this.destination.longitude) {
      this.map.setZoom(11);
    }

  }
}
