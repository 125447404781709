import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from 'src/app/shared';
import { KeystoneService } from '../services';
import { LogEvent } from '../model';
@Component({
  selector: 'app-carrier-competition-terms-and-condition-dialog',
  templateUrl: './carrier-competition-terms-and-condition-dialog.component.html',
  styleUrls: [ './carrier-competition-terms-and-condition-dialog.component.scss' ]
})

export class CarrierCompetitionTermsAndConditionDialogComponent implements OnInit {
  @ViewChild('pdfViewer') public pdfViewer: PdfJsViewerComponent;
  public docViewer: PdfJsViewerComponent;
  public language: string;
  private pdfPath: string;

  constructor(
    private http: HttpClient,
    private dialogRef: MatDialogRef<CarrierCompetitionTermsAndConditionDialogComponent>,
    private authService: AuthService,
    private keystoneService: KeystoneService
  ) { }

  ngOnInit() {
    this.pdfPath = 'assets/pdf/carrier-contest-';
    if (this.authService.preferredLanguageCode) {
      this.language = this.authService.preferredLanguageCode;
      this.language = XPOConstants.euCarrierContestTermsLanguageCode.indexOf(this.language) > -1 ?
        this.language : XPOConstants.defaultLanguageCode;
      this.pdfPath = this.pdfPath.concat(this.language, '.pdf');
    }
    this.getpdf();
  }
  getpdf() {
    this.http.get(this.pdfPath, { responseType: 'blob' }).subscribe((blob: any) => {
      this.pdfViewer.pdfSrc = blob;
      this.pdfViewer.refresh();
    });

  }

  onClose() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.closetnc,
      XPOConstants.keystoneEventAction.cancel, XPOConstants.keystonePage.contesttnc);
    this.keystoneService.logEvent(logEvent);
    this.dialogRef.close();
  }
}

