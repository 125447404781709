import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { XPOConstants } from 'src/app/shared';
import { DispatchDashboardCount } from 'src/app/dashboard/models/dispatch-dashboard-count';
import { DispatchDashboardRequest } from 'src/app/dashboard/models/dispatch-dashboard-request';
import { GlobalService } from 'src/app/shared/global.service';
import { KeystoneService, AvailableLoadsService } from 'src/app/shared/services';
import { LogEvent } from 'src/app/shared/model';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../services/date-time.service';
import { DateTimePipe } from '@xpoc/ngx-common';

@Component({
  selector: 'app-dispatch-details-dialog',
  templateUrl: './dispatch-details-dialog.component.html',
  styleUrls: [ './dispatch-details-dialog.component.scss' ]
})
export class DispatchDetailsDialogComponent implements OnInit {
  public dispatchDetails;
  public mileRadius: number[];
  public selectedRadius: number;
  public isLoading: boolean;
  public countDetails: DispatchDashboardCount;
  public isNAUser: boolean;
  public permissions: any = [];
  public availLoadBidsPermission = false;
  public tenderPermission = false;
  public distanceUnitLabel: string;
  public distanceUnit: string;
  public pickupDateStart: any;
  public pickupDateEnd: any;

  constructor(
    private dialogRef: MatDialogRef<DispatchDetailsDialogComponent>,
    public appSettings: AppSettings,
    public authService: AuthService,
    public dashboardService: DashboardService,
    public router: Router,
    private globalService: GlobalService,
    private availableLoadsService: AvailableLoadsService,
    private keystoneService: KeystoneService,
    private translateService: TranslateService,
    private dateTimeService: DateTimeService,
    private datePipe: DateTimePipe) {
    this.dialogRef.disableClose = true;
    this.isNAUser = this.authService.checkNAUser();
    this.permissions = this.authService.permissions;
    this.availLoadBidsPermission = this.permissions.indexOf(XPOConstants.permissionTypes.availableLoads) !== -1;
    this.tenderPermission = this.permissions.indexOf(XPOConstants.permissionTypes.loadTenders) !== -1;
    this.dispatchDetails = this.dialogRef._containerInstance._config.data;
    this.mileRadius = XPOConstants.deadheads;
  }

  ngOnInit() {
    if (this.isNAUser) {
      this.distanceUnitLabel = this.translateService.instant('mileRa8028');
      this.selectedRadius = XPOConstants.defaultLaneRadius;
    } else {
      this.distanceUnitLabel = this.translateService.instant('radius5189');
      this.selectedRadius = XPOConstants.defaultEuMileRadius;
      this.distanceUnit = XPOConstants.euDefaultDistanceUnit.toLowerCase();
    }
    this.onMileRadiusChangeCount();
  }

  onClose() {
    this.dialogRef.close();
  }

  onMileRadiusChangeCount() {
    const isDeliveryDateInPast = this.dateTimeService.isPast(this.dispatchDetails.destinationScheduledArrivalEarly, 'date');
    this.dispatchDetails.destinationScheduledArrivalEarly = isDeliveryDateInPast ?
      this.dateTimeService.getCurrentMoment() : this.dispatchDetails.destinationScheduledArrivalEarly;
    this.dispatchDetails.destinationDeliveryDateLate =
      this.dateTimeService.addDaysTo(this.dispatchDetails.destinationScheduledArrivalEarly, 7);
    const laneDetails = new DispatchDashboardRequest();
    laneDetails.latitude = this.dispatchDetails.destinationLocation.latitude;
    laneDetails.longitude = this.dispatchDetails.destinationLocation.longitude;
    laneDetails.location = this.dispatchDetails.destinationLocation.name;
    laneDetails.radius = this.selectedRadius;
    laneDetails.unit = this.appSettings.getApplicationConstant('BaseDistanceUnit');
    this.pickupDateStart = this.datePipe.transform(this.dispatchDetails.destinationScheduledArrivalEarly, XPOConstants.dateFormat);
    if (this.dispatchDetails.destinationScheduledArrivalLate) {
      this.pickupDateStart = this.datePipe.transform(this.dispatchDetails.destinationScheduledArrivalLate, XPOConstants.dateFormat);
    }
    this.pickupDateEnd = this.datePipe.transform(new Date(this.pickupDateStart).setDate(new Date(this.pickupDateStart).getDate() + 2), XPOConstants.dateFormat);
    const requestObj = {
      inTransitDestination: laneDetails,
      partnerIdentifier: this.authService.partnerIdentifier,
      equipmentCategory: this.dispatchDetails.equipmentCategory ? [ this.dispatchDetails.equipmentCategory ] : null,
      pickupDate: { min: this.pickupDateStart, max: this.pickupDateEnd }
    };
    this.isLoading = true;
    this.dashboardService.getTrackCount(requestObj).subscribe(response => {
      this.countDetails = response;
      const eventIdentifiers = {
        planNextMoveFilterCriteria: {
          location: laneDetails.location,
          radius: laneDetails.radius
        },
        planNextMoveFilterResults: response
      };
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.planNextMoveFilterRecords,
        XPOConstants.keystoneEventAction.search, XPOConstants.keystonePage.planNextMoveDialog, null, eventIdentifiers);
      this.keystoneService.logEvent(logEvent);
      this.isLoading = false;
    }, error => {
      this.onClose();
    });
  }

  navigateToAvailableLoads() {
    if (this.availLoadBidsPermission) {
      const laneDestination = {
        name: this.dispatchDetails.destinationLocation.name,
        latitude: this.dispatchDetails.destinationLocation.latitude,
        longitude: this.dispatchDetails.destinationLocation.longitude,
        laneRadius: this.selectedRadius,
        destinationDeliveryDate: this.pickupDateStart,
        destinationDeliveryDateLate: this.pickupDateEnd
      };
      this.globalService.planNextMoveForEquipment = this.dispatchDetails.equipmentCategory;
      this.availableLoadsService.parentLoadNumber = this.dispatchDetails.orderNumber;
      if (this.isNAUser) {
        this.globalService.setLaneOriginInfo(laneDestination);
      } else if (this.countDetails.loadsCount > 0) {
        this.globalService.setLaneOriginInfo(laneDestination);
      } else {
        this.globalService.setLaneOriginInfo(null);
      }
      this.globalService.setSearchLaneOriginInfo(laneDestination.name);
      this.availableLoadsService.setTabBeforeNavigation(XPOConstants.availableLoadsTabCodes.all);
      this.router.navigate([ '/loads/available-loads' ]);
      this.logKeystone(XPOConstants.keystoneEventName.planNextMoveAvailableLoadsClick,
        XPOConstants.keystoneEventAction.click);
      this.dialogRef.close();
    }
  }

  navigateToTenders() {
    if (this.isNAUser && this.tenderPermission) {
      this.router.navigate([ '/loads/load-tenders' ]);
      this.logKeystone(XPOConstants.keystoneEventName.planNextMovePendingTenderClick,
        XPOConstants.keystoneEventAction.click);
      this.dialogRef.close();
    }
  }

  navigateToBids(filterStatus: string) {
    if (this.isNAUser && this.availLoadBidsPermission) {
      this.globalService.bidsStatusFilter = [];
      this.globalService.bidsStatusFilter.push(filterStatus);
      this.router.navigate([ '/loads/bids' ]);
      const isBidsClick = XPOConstants.bidsCheckedStatusNA[ 0 ] === filterStatus;
      this.logKeystone(XPOConstants.keystoneEventName[ isBidsClick ?
        'planNextMovePendingBidsClick' : 'planNextMovePendingCounterOfferClick' ], XPOConstants.keystoneEventAction.click);
      this.dialogRef.close();
    }
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName,
      keystoneEventAction, XPOConstants.keystonePage.planNextMoveDialog);
    this.keystoneService.logEvent(logEvent);
  }
}
