<ul class="load-details-list" fxShow fxHide.lt-sm>
  <li>
    <div fxLayout="row">
      <div id="tender-number-title" fxFlex="20%">
        <b [translate]="'loadNo3662'">Load #</b>
      </div>
      <div id="tender-equipment-title" fxFlex="20%">
        <b [translate]="'equipment6372'">Equipment</b>
      </div>
      <div id="tender-commodity-title" fxFlex="20%">
        <b [translate]="'commodity2969'">Commodity</b>
      </div>
      <div id="tender-weight-title" fxFlex="20%">
        <b [translate]="'weight678'">Weight</b>
      </div>
      <div id="tender-equipment-title" fxFlex="20%">
        <b [translate]="'dimensions5866'">Dimensions</b>
      </div>
      <div id="tender-temperature-title" fxFlex="20%" *ngIf="isTemperature">
        <b [translate]="'temperature4187'">Temperature</b>
      </div>
      <div id="tender-distance-title" fxFlex="20%">
        <b [translate]="'totalDistan8780'">Total Distance</b>
      </div>
    </div>
  </li>
  <li *ngFor="let loadDetail of loadDetails; let i=index;">
    <div fxLayout="row">
      <div id="tender-number" fxFlex="20%">
        <span>{{loadDetail.orderCode}}</span>
      </div>
      <div id="tender-equipment" fxFlex="20%">
        <span>{{loadDetail.equipmentType}}</span>
      </div>
      <div id="tender-commodity" fxFlex="20%">
        <span>{{loadDetail.name}}</span>
      </div>
      <div id="tender-weight" fxFlex="20%">
        <span>{{loadDetail.weight}} {{loadDetail.weightUnit}}</span>
      </div>
      <div id="tender-equipment" fxFlex="20%">
        <span>{{loadDetail.dimensions}}</span>
      </div>
      <div id="tender-temperature" fxFlex="20%" *ngIf="isTemperature">
        <span>{{loadDetail.temperatureMin}} to {{loadDetail.temperatureMax}} {{temperatureFormat}}</span>
      </div>
      <div id="tender-distance" fxFlex="20%">
        <span>{{loadDetail.totalDistance}} {{loadDetail.distanceUnit}}</span>
      </div>
    </div>
  </li>
</ul>
<ul class="load-details-list" fxShow.lt-sm fxHide>
  <li>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'loadNo3662'">Load #</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        {{loadDetail.orderCode}}
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'equipment6372'">Equipment</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        <span>{{loadDetail.equipmentType}}</span>
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'commodity2969'">Commodity</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        <span>{{loadDetail.name}}</span>
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'weight678'">Weight</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        <span>{{loadDetail.weight}} {{loadDetail.weightUnit}}</span>
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'dimensions5866'">Dimensions</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        <span>{{loadDetail.dimensions}}</span>
      </div>
    </div>
  </li>
  <li *ngIf="isTemperature">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'temperature4187'">Temperature</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        <span>{{loadDetail.temperatureMin}} to {{loadDetail.temperatureMax}} {{temperatureFormat}}</span>
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <b [translate]="'totalDistan8780'">Total Distance</b>
      </div>
      <div *ngFor="let loadDetail of loadDetails">
        <span>{{loadDetail.totalDistance}} {{loadDetail.distanceUnit}}</span>
      </div>
    </div>
  </li>
</ul>