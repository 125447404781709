import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, LOCALE_ID, Inject } from '@angular/core';
import { XPOConstants, TenantId } from '../shared/xpo-constants';
import { ApplicationConstants } from '../shared/model/application-constants';
import { User } from '../shared/model/user.model';
import { AuthService } from '../user-authentication/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AppSettings {
  public apiUrl: string;
  public mpApiUrl: string;
  public apiKey: string;
  public isUnAutheticatedPage = false;
  public http: HttpClient;
  public isUnauthorized: boolean;
  private messageConfig: any;
  public applicationconstantConfig: Array<ApplicationConstants>;
  private locale: string;

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private cookieService: CookieService,
    @Inject(LOCALE_ID) locale: string
  ) {
    this.locale = locale;
  }

  init() {
    const promise = new Promise((resolve, reject) => {
      this.http = this.injector.get(HttpClient);
      const currentURL = window.location.href;
      this.isUnAutheticatedPage = XPOConstants.unAuthPageConfigs.find((config) => currentURL.indexOf(config.url) > -1) ? true : false;
      // Fetching api url
      this.http.get('./assets/appSettings.json')
        .toPromise()
        .then((config: any) => {
          this.apiUrl = config.apiUrl;
          this.mpApiUrl = config.mpApiUrl;
          this.apiKey = config.apiKey;
          const isRegistrationPage = currentURL.indexOf('/registration') > -1;
          const isAuthCookieExist = this.cookieService.check(XPOConstants.xpoAuthCookie);
          if ((isRegistrationPage || !isAuthCookieExist) && this.isUnAutheticatedPage) {
            const consturl = this.apiUrl + 'lookups/applicationconstants';
            this.http
              .get<Array<ApplicationConstants>>(consturl, { withCredentials: true })
              .subscribe((applicationconstants) => {
                if (applicationconstants) {
                  this.applicationconstantConfig = applicationconstants;
                  this.setAPIKeys();
                  this.setUserTenant();
                  return resolve(true);
                } else {
                  return resolve(false);
                }
              });
          } else {
            return this.getUserProfile()
              .then(() => { resolve(true); })
              .catch(() => { reject(); });
          }
        }).catch(() => reject());

    });
    return promise;
  }

  fetchApplicationConstants() {
    const partnerIdentifier = this.authService.partnerIdentifier || '';
    const consturl = this.apiUrl + 'lookups/applicationconstants/' + partnerIdentifier;
    this.http
      .get<Array<ApplicationConstants>>(consturl, { withCredentials: true })
      .subscribe(applicationconstants => {
        this.applicationconstantConfig = applicationconstants;
        this.setAPIKeys();
        this.setUserTenant();
      });
  }

  getUserProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.apiUrl + 'userProfile';
      return this.http
        .get<User>(url, { withCredentials: true })
        .subscribe((userProfile) => {
          this.authService.setUserProperties(userProfile);
          const partnerIdentifier = userProfile.currentPartner.partnerIdentifier;
          this.setWalkMeData(userProfile);
          const consturl = this.apiUrl + 'lookups/applicationconstants/' + partnerIdentifier;
          return this.http
            .get<Array<ApplicationConstants>>(consturl, { withCredentials: true })
            .subscribe((applicationconstants) => {
              if (applicationconstants) {
                this.applicationconstantConfig = applicationconstants;
                this.setAPIKeys();
                this.setUserTenant();
                resolve(true);
              } else {
                resolve(false);
              }
            }, (error) => {
              resolve(false);
            });
        }, (error) => {
          if (error.status === XPOConstants.errorCode.unAuthorized) {
            console.log('Userprofile error  - Authorization denied. Please re-login.');
            sessionStorage.setItem('previousRoute', window.location.href);
            this.isUnauthorized = true;
            return resolve(false);
          } else {
            console.warn(error);
            return resolve(false);
          }
        });
    });
  }

  public loadMessage(appSettingsFileName?: string) {
    return new Promise((resolve, reject) => {
      if (appSettingsFileName.startsWith('messageSettings')) {
        /* default case en-GB, no change in appSettingsFileName else add registered language here */
        if (this.locale === XPOConstants.i18nFr) {
          appSettingsFileName += '_fr';
        } else if (this.locale === XPOConstants.i18nEs) {
          appSettingsFileName += '_es';
        } else if (this.locale === XPOConstants.i18nPl) {
          appSettingsFileName += '_pl';
        }
      }
      this.http = this.injector.get(HttpClient);
      const url = './assets/translations/' + appSettingsFileName + '.json';
      this.http.get(url)
        .pipe(response => response)
        .subscribe((data) => {
          if (this.messageConfig === null) {
            this.messageConfig = data;
          } else {
            const result = {};
            Object.assign(result, this.messageConfig, data);
            this.messageConfig = result;
          }
          resolve(true);
        }, (error) => {
          resolve(true);
        });
    });
  }

  setUserTenant() {
    const isNAUser = (parseInt(this.getApplicationConstant('TenantId'), 10) === TenantId.NA) ? true : false;
    this.authService.setIsNAUser(isNAUser);
    this.authService.tenantId = parseInt(this.getApplicationConstant('TenantId'), 10);
  }

  setAPIKeys() {
    if (this.applicationconstantConfig) {
      this.applicationconstantConfig.forEach((element, index) => {
        switch (element.key) {
          case XPOConstants.apiKeys.dimension1:
          case XPOConstants.apiKeys.dimension2:
          case XPOConstants.apiKeys.dimension3:
          case XPOConstants.apiKeys.dimension4:
          case XPOConstants.apiKeys.dimension5:
          case XPOConstants.apiKeys.dimension6:
          case XPOConstants.apiKeys.dimension7:
          case XPOConstants.apiKeys.dimension8:
          case XPOConstants.apiKeys.dimension10:
            this.applicationconstantConfig[ index ].value = element.value ? atob(element.value).slice(10, -3) : '';
            break;
          default:
            break;
        }
      });
    }
  }

  public getMessage(key: any) {
    return this.messageConfig[ key ] !== undefined ? this.messageConfig[ key ] : key;
  }

  public getApplicationConstant(key: any) {
    const applicationConstant = this.applicationconstantConfig &&
      this.applicationconstantConfig.filter(appConstant => appConstant.key === key);
    return applicationConstant && applicationConstant.length ? applicationConstant[ 0 ].value : null;
  }

  private setWalkMeData(userProfile: any) {
    const walkMeUserData = {
      username: userProfile[ `username` ],
      userRole: this.authService.partnerRole,
      businessUnit: userProfile[ `businessUnit` ],
      type: userProfile[ `entityName` ],
      regionCode: userProfile[ `countryCode` ],
      preferredLanguage: userProfile[ `preferredLanguageCode` ]
    };
    window[ 'walkMe_data' ] = walkMeUserData;
  }
}

export function configAppSettings(appSettings: AppSettings) {
  return () => appSettings.init();
}
