import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { KeystoneService } from '../services';
import { LogEvent } from '../model';
import { XPOConstants } from '../xpo-constants';
import { SharedService } from '../services/shared.service';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/core/app-settings';
import { QuickSignupDialogComponent } from '../quick-signup-dialog/quick-signup-dialog.component';

@Component({
  selector: 'app-signup-promo-dialog',
  templateUrl: './signup-promo-dialog.component.html',
  styleUrls: [ './signup-promo-dialog.component.scss' ]
})
export class SignupPromoDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<SignupPromoDialogComponent>,
    private keystoneService: KeystoneService,
    private sharedService: SharedService,
    private router: Router,
    private appSettings: AppSettings,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.dialogRef.disableClose = true;
  }

  signIn() {
    this.dialogRef.close();
    this.logKeystone(XPOConstants.keystoneEventName.signupPromoSignIn, XPOConstants.keystoneEventAction.click);
    this.sharedService.setPreviousRoute(this.data.prevUrl);
    window.location.href = this.appSettings.apiUrl.replace('api/', 'session/create');
  }

  signUp() {
    this.dialogRef.close();
    if (this.data && this.data.isQuickSignup && this.sharedService.partnerIdentifierUnAuth) {
      this.logKeystone(XPOConstants.keystoneEventName.quickSignupPromoSignUp, XPOConstants.keystoneEventAction.click);
      const config: MatDialogConfig = { autoFocus: false };
      this.dialog.open(QuickSignupDialogComponent, config);
    } else {
      this.logKeystone(XPOConstants.keystoneEventName.signupPromoSignUp, XPOConstants.keystoneEventAction.click);
      this.router.navigate([ '/registration' ], { queryParams: { source: this.router.url.split('?')[ 0 ].substr(1) } });
    }
  }

  onClose() {
    this.logKeystone(XPOConstants.keystoneEventName.signupPromoCancel, XPOConstants.keystoneEventAction.cancel);
    this.dialogRef.close();
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string, eventIdentifier?: any) {
    const page = this.router.url.split('?')[ 0 ].substr(1);
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction,
      page, XPOConstants.keystoneSubPage.signupPromo, eventIdentifier);
    this.keystoneService.logAnonymousEvent(logEvent);
  }

}
