<div class="stops-container">
  <div class="step" *ngFor="let stop of stops; first as isFirst; last as isLast; let i = index">
    <div class="v-stepper">
      <div class="circle">
        <i class="icon-circle-filled"
          [ngClass]="{'icon-circle-outlined pickup': stop.type === 'Pickup', 'delivery': stop.type === 'Delivery'}"></i>
      </div>
      <div class="line" *ngIf="!isLast"></div>
    </div>
    <div class="content">
      <div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column">
        <div fxFlex="45%">
          <div fxLayout="column" fxLayoutGap="10px">
            <div class="address-holder">
              <div class="text-bold" id="stop-locname-{{i}}">
                {{stop.locationName}}
              </div>
              <div id="stop-address-{{i}}">
                {{stop.locationAddress1}}, {{stop.cityName}}, {{ isNAUser?stop.stateCode:stop.countryName}},
                {{stop.zipCode}}
              </div>
            </div>
            <div class="schedule-block">
              <span class="text-bold" [translate]="'scheduled9690'">
                Scheduled
              </span>
              <span>: {{stop.scheduledArrivalEarly | xpocDateTime: dateDisplayFormat}} •
                {{stop.scheduledArrivalEarly | xpocDateTime: timeDisplayFormat}}</span>
              <span *ngIf="stop.scheduledArrivalLate"> -
                {{getScheduledArrivalLate(stop.scheduledArrivalEarly, stop.scheduledArrivalLate)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>