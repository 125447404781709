<section class="share-tracking-email-container">
    <form [formGroup]="shareEmailForm">
        <div class="share-email-container">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label [translate]="'languagefor5965'">Language for communication</mat-label>
                <mat-select id="language-selector" formControlName="language">
                    <mat-option *ngFor="let language of supportedLanguages" [value]="language.code">
                        {{language.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-chip-list #emailList>
                    <mat-chip *ngFor="let email of inviteeEmailList; let i = index;" [selectable]="true"
                        [removable]="true" (removed)="removeEmail(i)">
                        <span class="text-overflow-ellipsis">{{email.contact}}</span>
                        <i matChipRemove class="icon-cancel chip-icon margin-top-5"></i>
                    </mat-chip>
                    <input formControlName="email" placeholder="{{'pleasespeci8134' | translate}}"
                        [matChipInputFor]="emailList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addEmail($event)">
                </mat-chip-list>
                <mat-error *ngIf="email?.errors?.invalid">{{email.errors.invalid.error | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="margin-top-25 copy-checkbox">
            <mat-checkbox formControlName="shouldCopyMe">
                {{'sendtrackin1758' | translate}}
            </mat-checkbox>
        </div>
        <div fxLayout="row" class="full-width" fxLayoutAlign="end center">
            <div>
                <button type="button" mat-raised-button id="cancel-btn" class="text-bold-uppercase"
                    (click)="onCancel()">
                    <span [translate]="'cancel7093'">CANCEL</span>
                </button>
            </div>
            <div class="margin-left-10">
                <button type="button" mat-raised-button [class.btn-spinner]="isLoading"
                    [disabled]="(!isFormValid || isLoading)" id="send-email-btn" class="text-bold-uppercase"
                    color="accent" (click)="sendEmail()">
                    <span [translate]="'send3670'">SEND</span>
                </button>
            </div>
        </div>
    </form>
</section>