import { Component, OnInit, SimpleChanges, OnChanges, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: [ './search-box.component.scss' ]
})

export class SearchBoxComponent implements OnInit, OnChanges {
  @Input() public placeholder: string;
  @Input() searchBoxModel: string;
  @Input() public enableClose: boolean;
  @Input() public readOnly: boolean;
  @Output() searchBoxModelChange = new EventEmitter();
  public searchValue: string;

  constructor() { }

  ngOnInit() {
    this.searchValue = this.searchBoxModel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('searchBoxModel')) {
      this.searchValue = changes.searchBoxModel.currentValue;
    }
  }

  onChange(value) {
    if (this.searchValue !== this.searchBoxModel) {
      this.searchBoxModelChange.emit(this.searchBoxModel);
    }
    this.searchValue = this.searchBoxModel;
  }

  onClose(value) {
    this.searchBoxModel = '';
    if (this.searchValue !== this.searchBoxModel) {
      this.searchBoxModelChange.emit(this.searchBoxModel);
    }
    this.searchValue = this.searchBoxModel;
  }
}
