/// <reference types="@types/googlemaps" />
import { Injectable, NgZone } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { XpoGoogleMapConfig } from '../model/google-map-config';
import { Location } from 'src/app/shared/model/location';
import { GoogleAnalyticsEventsService } from 'src/app/google-analytics/google-analytics-events.service';
import { TrafficService } from 'src/app/shared/services/traffic.service';
import { Weather, Respons } from 'src/app/shared/model/weather';
import { WeatherAlertService } from 'src/app/shared/services/weather-alert.service';
import { WeatherAlert, TruckLocation } from 'src/app/shared/model/weather-alert';
import { XPOConstants, TenantId } from 'src/app/shared';
import { Resource, Traffic, TrafficAlertItem } from 'src/app/shared/model/traffic';
import * as globals from 'src/app/shared/globals';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { DateTimePipe as DatePipe } from '@xpoc/ngx-common';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { AppSettings } from 'src/app/core/app-settings';

declare const google;

/**
 * Class responisble for creating map, marker, infowindow using Google Map API
 */
@Injectable()
export class XpoGoogleMapApi {
  private static readonly AERIS_API_URL = 'https://maps.aerisapi.com';
  private static readonly AERIS_OVERLAY_FEATURE = 'radar';

  protected map: any;
  protected RIGHT_TOP: any;
  private trafficWeatherControl: HTMLElement;
  private trafficLayer: any;
  private weatherLayer: any;

  public trafficAlertAPICalled = false;
  public trafficData: Traffic;
  public trafficAlertItems: TrafficAlertItem[] = [];


  public weatherData: Weather;
  public weatherAlertResponses: Respons[] = [];
  public weatherAlertList: WeatherAlert[] = [];
  public truckLocations: TruckLocation[] = [];
  public weatherAlertAPICalled = false;
  private MAX_LIMIT = 25;
  public startIndex = 0;
  public endIndex = 0;
  private filterWeatherAlertsOptions = [ 'blizzard.png', 'blowingsnow.png', 'drizzle.png', 'drizzlef.png', 'fdrizzle.png',
    'flurries.png', 'flurriesw.png', 'fog.png', 'freezingrain.png', 'freezingrainn.png', 'rain.png', 'rainandsnow.png', 'raintosnow.png',
    'rainw.png', 'showers.png', 'showersw.png', 'sleet.png', 'sleetsnow.png', 'snow.png', 'snowshowers.png', 'snowshowersw.png',
    'snowtorain.png', 'snoww.png', 'tstorm.png', 'tstormsw.png', 'tstormw.png', 'wintrymix.png' ];

  public isTruckPopUpMobileShowing = false;
  public tenantIdStatus = true;
  public googleMapApiKey: string;
  public aerisWeatherApiKey: string;

  constructor(
    private trafficService: TrafficService,
    private weatherAlertService: WeatherAlertService,
    private ngZone: NgZone,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public authService: AuthService,
    public datepipe: DatePipe,
    public titleCase: TitleCasePipe,
    public upperCase: UpperCasePipe,
    public appSettings: AppSettings
  ) {
    if (this.authService) {
      const tenantId = this.authService.tenantId;
      if (tenantId === TenantId.EU) {
        this.tenantIdStatus = false;
      }
    }
    this.googleMapApiKey = appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension1);
    this.aerisWeatherApiKey = appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension2) + '_'
      + appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension3);
    if (!this.googleMapApiKey) {
      this.appSettings.fetchApplicationConstants();
    }
  }

  // TODO Create an interface that has all the map options
  createMap(htmlElement: HTMLElement, options: any): Promise<void> {
    const loader = new Loader({
      apiKey: this.googleMapApiKey,
      version: "weekly",
      libraries: [ 'geometry' ]
    });
    return loader.load().then(() => {
      if (!options.styles) {
        options.styles = XpoGoogleMapConfig.MAP_STYLE;
      }
      this.map = new google.maps.Map(htmlElement, options);
      this.RIGHT_TOP = google.maps.ControlPosition.RIGHT_TOP;
      // Adding traffic control only if the options have traffic property as true
      if (options.traffic) {
        if (!this.trafficWeatherControl) {
          // Creating the custom control div
          this.createCustomControl();
        }
        // Creating the traffic control
        this.createTrafficControl();
        this.trafficLayer = this.createTrafficLayer();
      }
      // Adding weather control, only if the options have weather property as true
      if (options.weather && this.tenantIdStatus) {
        if (!this.trafficWeatherControl) {
          // Creating the custom control div
          this.createCustomControl();
        }
        // Creating the weather control
        this.createWeatherControl();
        this.weatherLayer = this.createWeatherLayer(this.aerisWeatherApiKey);
      }
      return;
    });
  }

  // TODO Create an interface that has all the marker options
  createMarker(markerOptions: any, addToMap: boolean): any {
    if (addToMap) {
      markerOptions.map = this.map;
    }
    return new google.maps.Marker(markerOptions);
  }

  // Method responsible to create a google map point
  createPoint(x: number, y: number) {
    return new google.maps.Point(x, y);
  }

  // Method responsible to create a google map size object
  createSize(width: number, height: number) {
    return new google.maps.Size(width, height);
  }

  // Method responsible to find the orientation of the marker based on the origin and destination
  findHeading(position1: any, position2: any) {
    return google.maps.geometry.spherical.computeHeading(position1, position2);
  }

  // Method responsible to set the map bounds based on the markers
  setMapBoundsBasedOnMarkers(markers: any[]) {
    const bounds = new google.maps.LatLngBounds();
    for (const marker of markers) {
      bounds.extend(marker.getPosition());
    }
    if (markers.length === 1) {

      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(10);
    } else {
      this.map.fitBounds(bounds);
    }

  }

  // Method responsible for creating the google maps traffic layer
  createTrafficLayer() {
    return new google.maps.TrafficLayer();
  }

  // Method responsible for creating the aeris radar weather layer
  createWeatherLayer(aerisApiKey: string) {
    return new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        const weatherApiUrl = `${XpoGoogleMapApi.AERIS_API_URL}/${aerisApiKey}/${XpoGoogleMapApi.AERIS_OVERLAY_FEATURE}/`;
        return [ weatherApiUrl, zoom, '/', coord.x, '/', coord.y, '/current.png' ].join('');
      },
      tileSize: this.createSize(256, 256)
    });
  }

  // Method responsible for creating the custom control div for traffic and weather
  private createCustomControl() {
    this.trafficWeatherControl = document.createElement('div');
    this.trafficWeatherControl.style.marginRight = '50px'; // Custom Alignment to match the wireframe
    this.trafficWeatherControl.style.marginBottom = '-65px'; // Custom Alignment to match the wireframe
    this.trafficWeatherControl.style.padding = '5px 10px';
    this.trafficWeatherControl.style.border = '1px solid #C3C1BE';
    this.trafficWeatherControl.style.backgroundColor = XpoGoogleMapConfig.CONTROL_BACKGROUND_COLOR;

    // Positioning the custom control in the map
    this.map.controls[ google.maps.ControlPosition.RIGHT_BOTTOM ].push(this.trafficWeatherControl);
  }

  // Method responsible for creating the custom control div for traffic
  private createTrafficControl() {
    const trafficControl = document.createElement('div');
    trafficControl.style.cursor = 'pointer';
    trafficControl.style.display = 'inline-block';
    this.trafficWeatherControl.appendChild(trafficControl);
    if (trafficControl.previousSibling) {
      trafficControl.style.marginLeft = '10px';
    }

    // Traffic Control Icon
    const trafficControlIcon = document.createElement('div');
    trafficControlIcon.style.backgroundImage = 'url(assets/icons/traffic-map-disabled.svg)';
    trafficControlIcon.style.width = '14px';
    trafficControlIcon.style.height = '12px';
    trafficControlIcon.style.margin = 'auto';
    trafficControl.appendChild(trafficControlIcon);

    // Traffic Control Text
    const trafficControlText = document.createElement('div');
    trafficControlText.innerHTML = XpoGoogleMapConfig.CONTROL_TRAFFIC;
    trafficControlText.style.fontSize = '8px';
    trafficControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
    trafficControlText.style.marginTop = '3px';
    trafficControlText.style.userSelect = 'none';
    trafficControlText.style.webkitUserSelect = 'none';
    trafficControl.appendChild(trafficControlText);

    // Traffic Control click listener
    trafficControl.addEventListener('click', () => {
      // this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general,
      // XPOConstants.eventAction.traficLanes, 'Traffic Lanes', 10);
      this.enableOrDisableTrafficLayer(trafficControlIcon, trafficControlText);
    });
  }

  // Method called when the traffic toggle button is turned on/off
  private enableOrDisableTrafficLayer(trafficControlIcon: HTMLDivElement, trafficControlText: HTMLDivElement) {
    if (this.trafficLayer.getMap()) {
      trafficControlIcon.style.backgroundImage = 'url(assets/icons/traffic-map-disabled.svg)';
      trafficControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
      this.trafficLayer.setMap(null);
    } else {
      trafficControlIcon.style.backgroundImage = 'url(assets/icons/traffic-map-enabled.svg)';
      trafficControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_ENABLED;
      this.trafficLayer.setMap(this.map);
    }
  }

  // Method responsible for creating the custom control div for weather
  private createWeatherControl() {
    const weatherControl = document.createElement('div');
    weatherControl.style.cursor = 'pointer';
    weatherControl.style.display = 'inline-block';
    this.trafficWeatherControl.appendChild(weatherControl);
    if (weatherControl.previousSibling) {
      weatherControl.style.marginLeft = '10px';
    }

    // Weather Control Icon
    const weatherControlIcon = document.createElement('div');
    weatherControlIcon.style.backgroundImage = 'url(assets/icons/weather-map-disabled.svg)';
    weatherControlIcon.style.width = '16px';
    weatherControlIcon.style.height = '14px';
    weatherControlIcon.style.margin = 'auto';
    weatherControl.appendChild(weatherControlIcon);

    // Weather Control Text
    const weatherControlText = document.createElement('div');
    weatherControlText.innerHTML = XpoGoogleMapConfig.CONTROL_WEATHER;
    weatherControlText.style.fontSize = '8px';
    weatherControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
    weatherControlText.style.marginTop = '1px';
    weatherControlText.style.userSelect = 'none';
    weatherControlText.style.webkitUserSelect = 'none';
    weatherControl.appendChild(weatherControlText);

    // Weather Control click listener
    weatherControl.addEventListener('click', () => {
      // this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general,
      // XPOConstants.eventAction.weatherRadar, 'Weather Layer', 10);
      this.enableOrDisableWeatherLayer(weatherControlIcon, weatherControlText);
    });
  }

  // Method called when the weather toggle button is turned on/off
  private enableOrDisableWeatherLayer(weatherControlIcon: HTMLDivElement, weatherControlText: HTMLDivElement) {
    if (this.map.overlayMapTypes.length > 0) {
      weatherControlIcon.style.backgroundImage = 'url(assets/icons/weather-map-disabled.svg)';
      weatherControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_DISABLED;
      this.map.overlayMapTypes.pop();
    } else {
      weatherControlIcon.style.backgroundImage = 'url(assets/icons/weather-map-enabled.svg)';
      weatherControlText.style.color = XpoGoogleMapConfig.CONTROL_COLOR_ENABLED;
      this.map.overlayMapTypes.push(this.weatherLayer);
      // Setting the overlay opacity as 0.5
      this.map.overlayMapTypes.getAt(0).setOpacity(0.5);
    }
  }

  drawStopMarker(stop: any, seqNumber?: string, icon?: string): any {
    if (!icon) {
      icon = 'assets/img/uno-cluster1.png';
    }
    const imageMarker = {
      url: icon,
      scaledSize: new google.maps.Size(30, 40),
      labelOrigin: new google.maps.Point(15, 15)
    };
    return new google.maps.Marker({
      position: stop,
      map: this.map,
      optimized: false,
      icon: imageMarker,
      label: { color: '#ffffff', fontWeight: 'bold', fontSize: '18px', text: seqNumber },
    });
  }

  // Method responsible for drawing the route based on the origin and destination
  drawRoute(origin: any, destination: any, stops: any, hideStopMarker?: boolean, showLoadMarkers?: boolean, optimizeWaypoints: boolean = false): any {
    const directionsService = new google.maps.DirectionsService;
    const directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: XpoGoogleMapConfig.ROUTE_STROKE_COLOR,
        strokeWeight: XpoGoogleMapConfig.ROUTE_STROKE_WEIGHT
      },
      suppressMarkers: true
    });
    directionsDisplay.setMap(this.map);

    const imageDest = showLoadMarkers ? {
      url: 'assets/icons/destination_pin.svg',
      scaledSize: new google.maps.Size(45, 45),
    } : {
      url: 'assets/icons/destination_pin.svg',
      scaledSize: new google.maps.Size(35, 35),
    };

    const waypts = [];
    const intermediateStopImage = {
      url: 'assets/icons/intermediate-stop-pin.svg',
      scaledSize: new google.maps.Size(35, 35)
    }
    stops.forEach((element, index) => {
      waypts.push({
        location: { lat: element.latitude, lng: element.longitude },
        stopover: true
      });
      if (!hideStopMarker) {
        const stopNumber = index + 1;
        const stopMarker = new google.maps.Marker({ icon: intermediateStopImage });
        stopMarker.setLabel({ color: '#fff', fontSize: '12px', fontWeight: 'bold', text: String(stopNumber) });
        stopMarker.setMap(this.map);
        stopMarker.setPosition({ lat: element.latitude, lng: element.longitude });
      }
    });

    directionsService.route({
      origin,
      destination,
      waypoints: waypts,
      optimizeWaypoints,
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      } else {
        console.log('Directions request failed: ' + status);
      }
    });

    const imageOrigin = showLoadMarkers ? {
      url: 'assets/icons/origin_pin.svg',
      scaledSize: new google.maps.Size(45, 45),
    } : {
      url: 'assets/icons/origin_pin.svg',
      scaledSize: new google.maps.Size(35, 35),
    };

    const markerOrigin = new google.maps.Marker({
      position: origin,
      map: this.map,
      optimized: false,
      icon: imageOrigin
    });



    const markerDest = new google.maps.Marker({
      position: destination,
      map: this.map,
      optimized: false,
      icon: imageDest
    });

    return {
      markerOrigin: markerOrigin,
      markerDestination: markerDest,
      directionsDisplay: directionsDisplay
    };
  }

  // Method responsible for creating a custom infowindow
  createCustomInfoWindow() {
    return new (XpoCustomInfoWindow())();
  }

  createAvailableLoadInfoWindow() {
    return new (definePopupClass())();
  }

  createInfowWindow() {
    return new google.maps.InfoWindow;
  }
  async showTrafficAlert(takeFromMapBounds: boolean) {
    let northEastLat = 0;
    let northEastLng = 0;
    let southWestLat = 0;
    let southWestLng = 0;

    const bounds = this.map.getBounds();
    if (takeFromMapBounds) {
      northEastLat = bounds.getNorthEast().lat();
      northEastLng = bounds.getNorthEast().lng();
      southWestLat = bounds.getSouthWest().lat();
      southWestLng = bounds.getSouthWest().lng();
    } else {
      const detailsForTrafficAlerts: Location[] = this.getTruckLocationAndNextStop();
      northEastLat = detailsForTrafficAlerts[ 1 ].latitude;
      northEastLng = detailsForTrafficAlerts[ 0 ].longitude;
      southWestLat = detailsForTrafficAlerts[ 0 ].latitude;
      southWestLng = detailsForTrafficAlerts[ 1 ].longitude;
    }
    // this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general,
    // XPOConstants.eventAction.traffic, 'Fetch Traffic Alert', 10);
    await this.trafficService.getTrafficAlerts(northEastLat, northEastLng, southWestLat, southWestLng).then(
      data => {
        this.trafficData = data;
        this.trafficAlertAPICalled = false;
        this.handleTrafficResponse();
      }).catch(error => {
        this.trafficAlertAPICalled = false;
      });
  }

  handleTrafficResponse() {
    let trafficAlert: TrafficAlertItem;
    if (this.trafficData != null && this.trafficData.resourceSets != null &&
      this.trafficData.resourceSets.length > 0 && this.trafficData.resourceSets[ 0 ].resources != null &&
      this.trafficData.resourceSets[ 0 ].resources.length > 0) {
      this.trafficAlertItems = [];
      this.trafficData.resourceSets[ 0 ].resources.sort((a: Resource, b: Resource) => {
        if (a.lastModified > b.lastModified) {
          return -1;
        } else if (a.lastModified < b.lastModified) {
          return 1;
        } else {
          return 0;
        }
      });
      let index = 0;
      this.trafficData.resourceSets[ 0 ].resources.forEach((element) => {
        if (element.roadClosed && element.type) {
          trafficAlert = new TrafficAlertItem(element.description,
            element.roadClosed, this.getDisplayTrafficTime(element.lastModified), index);
          index = index + 1;
          this.ngZone.run(() => {
            this.trafficAlertItems.push(trafficAlert);
          });
        }
      });
    }
    this.pushWeatherTrafficAlertControl();
    globals.setNotificationsCount(this.trafficAlertItems.length);
    globals.setTrafficAlerts(this.trafficAlertItems);
    if (globals.notificationsCount) {
      globals.notificationElement.innerHTML = `<span class="notification-count">${globals.notificationsCount}</span>`;
      globals.notificationDotElement.className += ' active';
    }
  }

  onCloseTrafficAlert(trafficItem: TrafficAlertItem) {
    this.trafficAlertItems.splice(trafficItem.index, 1);
    this.trafficAlertItems.forEach((item, index) => {
      this.trafficAlertItems[ index ].index = index;
    });
  }

  getTruckLocationAndNextStop(): Location[] { return []; }

  showWeatherAlert() {
    if (this.tenantIdStatus) {
      let subtrucks: TruckLocation[] = [];
      globals.setNotificationDismissed(false);
      if (this.truckLocations.length < this.startIndex + this.MAX_LIMIT) {
        this.endIndex = this.truckLocations.length;
      } else {
        this.endIndex = this.startIndex + this.MAX_LIMIT;
      }
      subtrucks = this.truckLocations.slice(this.startIndex, this.endIndex);
      this.startIndex = this.startIndex + this.MAX_LIMIT;
      // this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general, XPOConstants.eventAction.weather,
      //   'Fetch Weather Alert', 10);
      this.weatherAlertService.fetchWeatherAlert(subtrucks).then(data => {
        this.weatherData = data;
        if (this.weatherAlertResponses.length > 0) {
          this.weatherData.response.responses.forEach(element => {
            this.weatherAlertResponses.push(element);
          });
        } else {
          this.weatherAlertResponses = this.weatherData.response.responses;
        }
        if (this.truckLocations.length <= this.endIndex) {
          this.setWeatherAlert();
        } else {
          this.showWeatherAlert();
        }
        this.weatherAlertAPICalled = false;
      }).catch(error => {
        this.weatherAlertAPICalled = false;
      });
    }
  }

  setWeatherAlert() {
    if (this.weatherAlertResponses.length > 0) {
      this.weatherAlertResponses.sort((a: any, b: any) => {
        if (a.response.ob.timestamp > b.response.ob.timestamp) {
          return -1;
        } else if (a.response.ob.timestamp < b.response.ob.timestamp) {
          return 1;
        } else {
          return 0;
        }
      });

      let index = 0;
      this.weatherAlertList = [];
      globals.setNotificationDismissed(false);
      this.weatherAlertResponses.forEach(element => {
        if ((this.filterWeatherAlertsOptions.indexOf(element.response.ob.icon) > -1) &&
          !this.isInWeatherAlertList(element.response.place.name)) {
          this.ngZone.run(() => {
            const weatherPlace = this.titleCase.transform(element.response.place.name) + ', '
              + this.upperCase.transform(element.response.place.state);
            this.weatherAlertList.push(
              new WeatherAlert(
                weatherPlace,
                element.response.ob.tempC,
                element.response.ob.tempF,
                element.response.ob.humidity,
                element.response.ob.weather,
                'https://www.aerisweather.com/img/docs/' + element.response.ob.icon,
                element.response.ob.isDay,
                this.getDisplayWeatherTime(element.response.ob.timestamp),
                index));
            index = index + 1;
          });
        }
      });
      this.pushWeatherTrafficAlertControl();
    }
    globals.setNotificationsCount(this.weatherAlertList.length);
    if (globals.notificationsCount) {
      globals.notificationElement.innerHTML = `<span class="notification-count">${globals.notificationsCount}</span>`;
      globals.notificationDotElement.className += ' active';
    }
    globals.setWeatherAlerts(this.weatherAlertList);
  }

  private isInWeatherAlertList(cityName: string): boolean {
    let isExist = false;
    this.weatherAlertList.forEach(element => {
      if (element.place === cityName) {
        isExist = true;
      }
    });
    return isExist;
  }

  pushWeatherTrafficAlertControl() {
    const trafficAlert = document.getElementById('traffic_weather_alert');
    this.map.controls[ this.RIGHT_TOP ].push(trafficAlert);
  }

  onCloseWeatherAlert(weatherItem: WeatherAlert) {
    this.weatherAlertList.splice(weatherItem.index, 1);
    this.weatherAlertList.forEach((item, index) => {
      this.weatherAlertList[ index ].index = index;
    });
  }

  getDisplayWeatherTime(lastModified: any): string {
    const date = new Date(lastModified);
    const dateTime = this.datepipe.transform(date, XPOConstants.timeFormat);
    return dateTime;
  }

  getDisplayTrafficTime(lastModified: any): string {
    const date = new Date(parseInt(lastModified.substr(6), 10));
    const dateTime = this.datepipe.transform(date, XPOConstants.timeFormat);
    return dateTime;
  }
}

/**
 * Function responsible for creating a custom info window by extending google.maps.OverlayView
 * Getting google not defined error when tried to create the below function as a class
 */
function XpoCustomInfoWindow() {
  function CustomInfoWindow() {
    this.outercontainer = document.createElement('div');
    this.outercontainer.classList.add('marker-popup-container');
    this.outercontainer.style.position = 'absolute';

    this.container = document.createElement('div');
    this.container.style.backgroundColor = '#FFFFFF';
    this.container.style.borderRadius = '4px';
    this.container.style.boxShadow = '0 2px 4px 0 rgba(0,0,0,0.2)';
    this.container.style.color = '#232323';
    this.container.style.overflow = 'hidden';
    this.container.style.position = 'absolute';
    this.container.style.padding = '10px 10px';

    this.closeBtn = document.createElement('div');
    this.closeBtn.style.backgroundImage = 'url(assets/icons/close.svg)';
    this.closeBtn.style.width = '12px';
    this.closeBtn.style.height = '12px';
    this.closeBtn.style.cssFloat = 'right';
    this.closeBtn.style.cursor = 'pointer';
    this.closeBtn.setAttribute('id', 'close-btn-popup');
    this.container.appendChild(this.closeBtn);

    this.content = document.createElement('div');
    this.container.appendChild(this.content);

    this.outercontainer.appendChild(this.container);

    this.layer = null;
    this.marker = null;
    this.position = null;
    this.popupOptions = { top: -225, left: -330 };
  }

  CustomInfoWindow.prototype = new google.maps.OverlayView();

  CustomInfoWindow.prototype.onAdd = function () {
    this.layer = this.getPanes().floatPane;
    this.layer.appendChild(this.outercontainer);
    this.closeBtn.addEventListener('click', function () {
      this.close();
    }.bind(this), false);
    // Ensuring the custom info window is visible fully in the map
    setTimeout(this.panToView.bind(this), 200);
  };

  // added top and left parameters to draw, so that custom position can be sett to the custom window.
  CustomInfoWindow.prototype.draw = function () {
    if (this.getProjection()) {
      this.position = this.getProjection().fromLatLngToDivPixel(this.marker.getPosition());
      this.outercontainer.style.top = this.position.y + (this.popupOptions.top) + 'px';
      this.outercontainer.style.left = this.position.x + (this.popupOptions.left) + 'px';
    }
  };

  CustomInfoWindow.prototype.panToView = function () {
    const map = this.getMap();
    const x = this.position.x;
    const y = this.position.y;
    const point = new google.maps.Point(x, y);
    if (this.getProjection()) {
      const latLng = this.getProjection().fromDivPixelToLatLng(point);
      this.map.panTo(latLng);
    }
  };

  CustomInfoWindow.prototype.onRemove = function () {
    this.layer.removeChild(this.outercontainer);
  };

  CustomInfoWindow.prototype.setContent = function (content) {
    this.content.innerHTML = content;
  };

  CustomInfoWindow.prototype.setOptions = function (options) {
    this.popupOptions = options;
  };

  CustomInfoWindow.prototype.open = function (map, marker) {
    this.marker = marker;
    this.setMap(map);
  };

  CustomInfoWindow.prototype.close = function () {
    this.setMap(null);
  };

  return CustomInfoWindow;
}

/** Defines the Popup class. */
function definePopupClass() {

  function Popup() {

    this.content = document.getElementById('content');
    this.content.classList.add('popup-bubble-content');

    const pixelOffset = document.createElement('div');
    pixelOffset.classList.add('popup-bubble-anchor');
    pixelOffset.appendChild(this.content);

    this.anchor = document.createElement('div');
    this.anchor.classList.add('popup-tip-anchor');
    this.anchor.appendChild(pixelOffset);

    this.layer = null;
    this.marker = null;
    this.position = null;
  }
  // NOTE: google.maps.OverlayView is only defined once the Maps API has
  // loaded. That is why Popup is defined inside initMap().
  Popup.prototype = Object.create(google.maps.OverlayView.prototype);

  /** Called when the popup is added to the map. */
  Popup.prototype.onAdd = function () {
    this.layer = this.getPanes().floatPane;
    this.layer.appendChild(this.anchor);
    // Ensuring the custom info window is visible fully in the map
    setTimeout(this.panToView.bind(this), 500);
  };

  /** Called when the popup is removed from the map. */
  Popup.prototype.onRemove = function () {
    if (this.anchor.parentElement) {
      this.anchor.parentElement.removeChild(this.anchor);
    }
  };

  Popup.prototype.draw = function () {
    if (this.getProjection()) {
      this.position = this.getProjection().fromLatLngToDivPixel(this.marker.getPosition());
      this.anchor.style.top = this.position.y + 'px';
      this.anchor.style.left = this.position.x + 'px';
      if (document.getElementById('close-popup')) {
        document.getElementById('close-popup').addEventListener('click', function () {
          this.close();
        }.bind(this), false);
      }
    }
  };

  Popup.prototype.panToView = function () {
    const map = this.getMap();
    const x = this.position.x;
    const y = this.position.y;
    const point = new google.maps.Point(x, y);
    if (this.getProjection()) {
      const latLng = this.getProjection().fromDivPixelToLatLng(point);
      this.map.panTo(latLng);
    }
  };

  Popup.prototype.setContent = function (content) {
    this.content.innerHTML = content;
  };

  /** Stops clicks/drags from bubbling up to the map. */
  Popup.prototype.stopEventPropagation = function () {
    const anchor = this.anchor;
    anchor.style.cursor = 'auto';

    [ 'click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart',
      'pointerdown' ]
      .forEach(function (event) {
        anchor.addEventListener(event, function (e) {
          e.stopPropagation();
        });
      });
  };

  Popup.prototype.open = function (map, marker) {
    this.marker = marker;
    this.setMap(map);
  };

  Popup.prototype.close = function () {
    this.setMap(null);
  };

  return Popup;
}

