import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  public searchlaneOriginInfo: any;
  public planNextMoveForEquipment = '';
  private laneOriginInfo: any;
  private laneOriginInfoSubject = new Subject<any>();
  public updateInfoCount = new Subject<any>();
  public refreshMap = new Subject<any>();
  public callDispatcher = new Subject<any>();
  public callDispatcherToStaging = new Subject<any>();
  public reviewAvailableLoad = new Subject<any>();
  public reloadAvailableLoad = new Subject<any>();
  public driverDetails: any;
  public orderDetails: any;
  public loadId: string;
  public bidsStatusFilter: string[] = [];
  public loadNumber: string;
  public origin: any;
  public opportunityWidgetFilter: any;

  constructor() { }

  setLaneOriginInfo(origin) {
    this.laneOriginInfo = origin;
    this.laneOriginInfoSubject.next(this.laneOriginInfo);
  }

  setSearchLaneOriginInfo(origin) {
    this.searchlaneOriginInfo = origin;
  }

  getLaneOriginInfoSubject() {
    return this.laneOriginInfoSubject.asObservable();
  }

  setRefreshMap() {
    this.refreshMap.next('refresh');
  }

  setReviewAvailableLoad() {
    this.reviewAvailableLoad.next('review');
  }

  setReloadAvailableLoad() {
    this.reloadAvailableLoad.next('reload');
  }

  getRefreshMap() {
    return this.refreshMap.asObservable();
  }

  getReviewAvailableLoad() {
    return this.reviewAvailableLoad.asObservable();
  }

  getReloadAvailableLoad() {
    return this.reloadAvailableLoad.asObservable();
  }

  getLaneOriginInfo() {
    return this.laneOriginInfo;
  }

  getNewUpdateInfoCount(): Observable<any> {
    return this.updateInfoCount.asObservable();
  }
}
