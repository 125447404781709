import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Validations } from '@xpoc/ngx-common';
import { XPOConstants } from 'src/app/shared';
import { MyLoadsService, KeystoneService } from 'src/app/shared/services';
import { Load } from 'src/app/shared/model/load';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { LogEvent } from 'src/app/shared/model';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-share-tracking-email',
  templateUrl: './share-tracking-email.component.html',
  styleUrls: [ './share-tracking-email.component.scss' ]
})
export class ShareTrackingEmailComponent implements OnInit {

  @Input()
  public loadDetails: Load;

  @Input()
  public linkToShare: string;

  @Input()
  public locationData: any;

  @Input()
  public supportedLanguages: any;

  @Output()
  private cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  private shareSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() private updateLink: EventEmitter<any> = new EventEmitter<any>();
  public shareEmailForm: FormGroup;
  public readonly maximumLimitOfInvitees = 10;
  public readonly separatorKeysCodes: number[] = [ ENTER, COMMA, SEMICOLON ];
  public readonly emailRegxp = XPOConstants.emailIDRegex;
  public isLoading = false;
  public isFormValid = false;
  public messageConfig;
  private languageCode: string;

  @ViewChild('emailList') emailList;

  constructor(
    private formBuilder: FormBuilder,
    private myLoadsService: MyLoadsService,
    private authService: AuthService,
    private keystoneService: KeystoneService
  ) { }

  ngOnInit() {
    if (this.authService.preferredLanguageCode) {
      const language = this.authService.preferredLanguageCode;
      this.languageCode = XPOConstants.shareTrackingLanguageCodes.indexOf(language) > -1 ? language : XPOConstants.euDefaultLanguageCode;
    }
    this.shareEmailForm = this.createForm();
    this.configureMessages();
    this.shareEmailForm.get('language').valueChanges.subscribe(valueChange => {
      this.updateLink.emit(valueChange);
    });
  }

  get email() {
    return this.shareEmailForm.controls.email;
  }

  get inviteeEmailList() {
    return this.shareEmailForm.get('recipients').value;
  }

  private configureMessages() {
    this.messageConfig = {
      success: 'trackinglin232', // 'Tracking link was successfully sent to the emails provided',
      partial: 'deliverytof9765	', // 'Delivery to following email id’s have failed. Please verify the details and try again.',
      failure: 'wehaveencou8795', // 'We have encountered a server error. Please verify email addresses and try again later.'
    };
  }

  private logShareTrackingKeyStone(eventName, eventAction, eventIdentifiers?) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction,
      this.loadDetails.pageInfo ? this.loadDetails.pageInfo : XPOConstants.keystonePage.loads,
      this.loadDetails.subPageInfo, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

  private createForm(): FormGroup {
    const newForm = this.formBuilder.group({
      email: [ '', Validations.required ],
      shouldCopyMe: [ false ],
      recipients: this.formBuilder.array([], Validations.required),
      language: [ this.languageCode ]
    }, {
      validator: this.setFormValidation.bind(this)
    });
    return newForm;
  }

  private setFormValidation(shareEmailForm: FormGroup) {
    const emailFormValues = shareEmailForm.value;
    if (emailFormValues.shouldCopyMe || emailFormValues.recipients.length > 0) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }
  public removeEmail(emailIndex: number) {
    const control = this.shareEmailForm.controls.recipients as FormArray;
    control.removeAt(emailIndex);
    if (control.length < this.maximumLimitOfInvitees) {
      this.shareEmailForm.get('email').enable();
    }
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingEmailActions, XPOConstants.keystoneEventAction.delete);
  }

  public addEmail(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();
    if (value && !this.emailRegxp.test(value)) {
      this.email.setErrors({ invalid: { error: 'entervalide8568' } });
      this.emailList.errorState = true;
      return false;
    }

    if (this.inviteeEmailList.some(email => email.contact.toLowerCase() === value.toLowerCase())) {
      this.email.setErrors({ invalid: { error: 'duplicateem5989' } });
      this.emailList.errorState = true;
      return false;
    }

    if ((value || '')) {
      const control = this.shareEmailForm.controls.recipients as FormArray;
      control.push(this.formBuilder.control({ contact: value }));
      this.emailList.errorState = false;
    }
    if (input) {
      input.value = '';
      this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingEmailActions, XPOConstants.keystoneEventAction.create);
      if (this.inviteeEmailList.length >= this.maximumLimitOfInvitees) {
        this.shareEmailForm.get('email').disable();
      }
    }
  }

  public onCancel(shouldClose = false) {
    this.cancel.emit(shouldClose);
    const eventName = shouldClose ? XPOConstants.keystoneEventAction.back : XPOConstants.keystoneEventAction.cancel;
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingEmailActions, eventName);
  }


  public sendEmail() {
    this.isLoading = true;
    const loadDetails = this.loadDetails;
    const shareEmailFormValues = this.shareEmailForm.value;
    const emailRequestPayLoad = {
      ...this.locationData,
      number: loadDetails.isTravelOrder ? loadDetails.alternateNumber : loadDetails.number,
      tripIdentifier: loadDetails.tripNumber,
      url: this.linkToShare + '?lang=' + shareEmailFormValues.language,
      recipients: shareEmailFormValues.recipients,
      copyMe: shareEmailFormValues.shouldCopyMe,
      userEmail: this.authService.userProfile.email,
      partnerName: this.authService.userProfile.currentPartner.partnerName,
      mode: 'EMAIL',
      language: shareEmailFormValues.language
    };
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingEmail, XPOConstants.keystoneEventAction.initiate);
    this.myLoadsService.shareTrackingLink(emailRequestPayLoad)
      .subscribe((response) => {
        this.isLoading = false;
        const responseDataToShow = {
          ...response,
          messages: this.messageConfig
        };
        this.shareSuccess.emit(responseDataToShow);
      }, (error) => {
        this.onCancel(true);
      });
  }
}
