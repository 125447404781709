<div *ngIf="!error && isNAUser">
  <div class="form-data" *ngIf="!formSubmitted">
    <div mat-dialog-title>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="text-bold-uppercase" [translate]="'dispatchInf40'">Dispatch Information</span>
        <span class="dialog-close-icon">
          <i class="icon-close" (click)="onCloseDispatcher()"></i>
        </span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="5px" *ngIf="isNAUser && isDispatchInfoOptionalOrMandatoryCheckRequired"
      class="dispatch-threshold margin-bottom-20">
      <i [class]="isFieldsMandatory ? 'icon-info1 mandatory-icon' : 'icon-warning_rounded non-mandatory-icon'"></i>
      <span *ngIf="isFieldsMandatory;else optionalMsg" class="warning-message padding-right-left-5 text-bold">Dispatch
        information is required when load pickup time is within {{dispatchThreshold}} hours.</span>
      <ng-template #optionalMsg>
        <span class="warning-message padding-right-left-5 text-bold">Skip for now if dispatch information is not yet
          available. Dispatch information is required prior to the day of pick up.</span>
      </ng-template>
    </div>
    <form [formGroup]="updateInfoForm">
      <div
        *ngIf="((!isDispatchInfoOptionalOrMandatoryCheckRequired)|| (isDispatchInfoOptionalOrMandatoryCheckRequired && isFieldsMandatory))"
        class="required-field">
        <span class="required">* </span>
        <span [translate]="'requir4016'">Required fields</span>
      </div>
      <mat-dialog-content>
        <div fxLayout="column" fxLayoutGap="20px">
          <div>
            <mat-form-field class="form-field-input" appearance="outline">
              <mat-label>{{'driverName8471' | translate}}</mat-label>
              <input matInput placeholder="{{'driverName8471' | translate}}" formControlName="driverSearchString"
                id="driver" [matAutocomplete]="driverAuto" class="text-capitalize" name="driver"
                (focusout)="clearDriver()"
                [required]="((!isDispatchInfoOptionalOrMandatoryCheckRequired) || (isDispatchInfoOptionalOrMandatoryCheckRequired && isFieldsMandatory))">
              <mat-autocomplete #driverAuto="matAutocomplete">
                <mat-option class="add-option-block" *ngIf="assetManagementPermission" (click)="createDriver()">
                  <button mat-button color="accent" class="add-new-driver-option-btn">
                    <i class="icon-plus icon-margin"></i>
                    <span class="text-uppercase" [translate]="'newdri7203'"> new driver</span>
                  </button>
                </mat-option>
                <mat-option *ngIf="isEmptyDriverList && isNoDriver" [translate]="'noDriv7746'">
                  No drivers are found
                </mat-option>
                <mat-option *ngIf="!isEmptyDriverList && isNoDriver" [translate]="'noDriv7746'">
                  No drivers are found
                </mat-option>
                <mat-option *ngFor="let driver of driverList" class="text-capitalize" [value]="driver.name"
                  (onSelectionChange)="onSelectDriver(driver)" fxLayoutAlign="space-between center">
                  <div fxLayoutAlign="space-between center">
                    <span [ngClass]="{'driverxpo-name':driver.isDriveXPO}">{{ driver.name }}</span>
                    <span class="drivexpo-logo-img" *ngIf="driver.isDriveXPO">
                      <img src="assets/img/DriveXPOlogo.png">
                    </span>
                  </div>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="(updateInfoForm.controls.driverSearchString.errors && updateInfoForm.controls.driverSearchString.errors.invalid) ||
                    (updateInfoForm.controls.driverName.errors && updateInfoForm.controls.driverName.errors.required)">
                <span [translate]="'enterV3331'">
                  Enter valid Driver
                </span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="driver-mobile" *ngIf="updateInfoForm && updateInfoForm.controls.driverName.value">
            <div class="dispatch-email" *ngIf="!isNAUser">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>{{'emailA2367' | translate}}</mat-label>
                <input matInput placeholder="{{'emailA2367' | translate}}" size="100" id="email" name="email"
                  formControlName="email">
                <mat-error
                  *ngIf="updateInfoForm.controls.email.errors && updateInfoForm.controls.email.errors.invalidEmail">
                  <span [translate]="'entervalide8568'">Enter valid email</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="driver-phone-number">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label [translate]="'driver7022'">Driver Mobile Phone #</mat-label>
                <mat-select formControlName="driverPhoneNumber" class="select-label" [required]="isNAUser">
                  <mat-option *ngFor="let phone of trackignPhoneNumberList" value="{{phone.phoneNumber}}">
                    {{phone.phoneNumber}}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="updateInfoForm.controls.driverPhoneNumber.errors && updateInfoForm.controls.driverPhoneNumber.errors.required"
                  [translate]="'driver2050'">
                  Driver Mobile Phone # is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="tracking-select">
            <mat-form-field class="form-field-input" appearance="outline">
              <mat-label [translate]="'tracki806'" class="text-capitalize">tracking method</mat-label>
              <mat-select id="trackingMethod" name="trackingMethod" formControlName="trackingMethod"
                [required]="((!isDispatchInfoOptionalOrMandatoryCheckRequired)||(isFieldsMandatory && isDispatchInfoOptionalOrMandatoryCheckRequired))">
                <mat-option *ngFor="let trackingMethod of trackingMethodType"
                  [value]="trackingMethod.trackingMethodCode"
                  (click)="setTrackingMethod(trackingMethod.trackingMethodCode)">
                  {{trackingMethod.trackingMethodName}}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="updateInfoForm.controls.trackingMethod.errors && updateInfoForm.controls.trackingMethod.errors.required"
                [translate]="'tracki5295'">
                Tracking Method is required.
              </mat-error>
              <mat-error
                *ngIf="updateInfoForm.controls.trackingMethod.errors && updateInfoForm.controls.trackingMethod.errors.invalid"
                [translate]="'nodriv5953'">
                No driver account found, Please use a different tracking method
              </mat-error>
            </mat-form-field>
          </div>

          <div [ngSwitch]=updateInfoForm.controls.trackingMethod.value *ngIf="isNAUser">
            <div *ngSwitchCase="trackingMethodTypeCode.truck">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>Truck #</mat-label>
                <input matInput placeholder="Truck #" formControlName="naTruckNumberString" id="truckNoNA"
                  [matAutocomplete]="truckAuto" name="truckNoNA" (focusout)="clearTruck()" [required]="isNAUser">
                <mat-error
                  *ngIf="updateInfoForm.controls.naTruckNumberString.errors && updateInfoForm.controls.naTruckNumberString.errors.invalid ">
                  <span [translate]="'enterV3316'">
                    Enter valid equipment #
                  </span>
                </mat-error>

                <mat-autocomplete #truckAuto="matAutocomplete">
                  <mat-option class="add-option-block" *ngIf="manageEquipment" (click)="createTruckTrailer('TRUCK')">
                    <button mat-button color="accent" class="add-new-driver-option-btn">
                      <i class="icon-plus icon-margin"></i>
                      <span class="text-uppercase" [translate]="'newtru4935'">new truck</span>
                    </button>
                  </mat-option>
                  <mat-option *ngIf="!truckList.length" [translate]="'noreco5231'">
                    No records found
                  </mat-option>
                  <mat-option *ngFor="let truck of truckList" [value]="truck.equipmentNumber"
                    (onSelectionChange)="onSelectTruck(truck)" fxLayoutAlign="space-between center">
                    <span>{{ truck.equipmentNumber }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div *ngSwitchCase="trackingMethodTypeCode.trailer">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>Trailer #</mat-label>
                <input matInput placeholder="Trailer #" formControlName="naTrailerNumberString" id="trailerNoNA"
                  [matAutocomplete]="trailerAuto" name="trailerNoNA" (focusout)="clearTrailer()" [required]="isNAUser">
                <mat-error
                  *ngIf="updateInfoForm.controls.naTrailerNumberString.errors && updateInfoForm.controls.naTrailerNumberString.errors.invalid">
                  <span [translate]="'enterV3316'">
                    Enter valid equipment #
                  </span>
                </mat-error>
                <mat-autocomplete #trailerAuto="matAutocomplete">
                  <mat-option class="add-option-block" *ngIf="manageEquipment" (click)="createTruckTrailer('TRAILER')">
                    <button mat-button color="accent" class="add-new-driver-option-btn">
                      <i class="icon-plus icon-margin"></i>
                      <span class="text-uppercase" [translate]="'newtra4910'">new trailer</span>
                    </button>
                  </mat-option>
                  <mat-option *ngIf="!trailerList.length" [translate]="'noreco5231'">
                    No records found
                  </mat-option>
                  <mat-option *ngFor="let trailer of trailerList" [value]="trailer.equipmentNumber"
                    (onSelectionChange)="onSelectTrailer(trailer)" fxLayoutAlign="space-between center">
                    <span>{{ trailer.equipmentNumber }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div *ngSwitchCase="trackingMethodTypeCode.mobile" class="tracking-phone-check">
              <div class="phone-checkbox-block" fxLayout="row" fxLayoutGap="10px">
                <mat-checkbox fxFlexAlign="start" id="driver-phone-tracking" labelPosition="after"
                  formControlName="isDriverPhoneTrackable" (change)="onTrackingPhoneNoChange()" class="text-capitalize">
                </mat-checkbox>
                <span [translate]="'usedri4991'">Use driver's phone number
                  {{updateInfoForm.controls.driverPhoneNumber.value}} for tracking </span>
              </div>
              <div class="trackable-phone">
                <mat-form-field class="form-field-input" *ngIf="!updateInfoForm.controls.isDriverPhoneTrackable.value"
                  appearance="outline">
                  <mat-label [translate]="'tracka7469'">Trackable Phone #</mat-label>
                  <mat-select formControlName="trackingPhoneNumber" class="select-label"
                    [required]="hasValidator('trackingPhoneNumber')">
                    <mat-option *ngFor="let phone of trackignPhoneNumberList" value="{{phone.phoneNumber}}">
                      {{phone.phoneNumber}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="updateInfoForm.controls.trackingPhoneNumber.errors && (updateInfoForm.controls.trackingPhoneNumber.errors.invalid ||
                     updateInfoForm.controls.trackingPhoneNumber.errors.required)">
                    Trackable phone # is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="isLoadScheduled">
            <div fxLayout="column" fxLayoutGap="20px">
              <div *ngIf="isNAUser">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span [translate]="'isyour5146'">
                    Is your truck empty?
                  </span>
                  <span>
                    <mat-radio-group fxLayout="row" fxLayoutGap="15px" formControlName="isTruckEmpty" id="truck-empty"
                       name="truck-empty" (change)="changeTruckEmpty()" [required]="hasValidator('isTruckEmpty')">
                      <mat-radio-button [value]="true">{{'yes7992' | translate}}</mat-radio-button>
                      <mat-radio-button [value]="false">{{'no9227' | translate}}</mat-radio-button>
                    </mat-radio-group>
                  </span>
                </div>
              </div>
              <div *ngIf="updateInfoForm && !updateInfoForm.controls.isTruckEmpty.value">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>Delivery Location (Current Load)</mat-label>
                  <input matInput placeholder="Delivery Location (Current Load)"
                    formControlName="deliveryLocationString" id="deliveryLocation"
                    [matAutocomplete]="driverLocationAuto" class="text-capitalize" name="deliveryLocation"
                    (focusout)="clearDeliveryLocation()" (keyup)="getDeliveryLocation()"
                    [required]="isFieldsMandatory && isDeliverLocationMandatory">
                  <mat-error
                    *ngIf="updateInfoForm.controls.deliveryLocationString.errors && updateInfoForm.controls.deliveryLocationString.errors.invalid">
                    <span [translate]="'invali7345'">
                      Invalid Delivery Location
                    </span>
                  </mat-error>
                  <mat-autocomplete #driverLocationAuto="matAutocomplete">
                    <mat-option *ngIf="!locations.length">
                      {{'noreco5231' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let location of locations" class="text-capitalize" [value]="location.value"
                      (onSelectionChange)="onSelectDeliveryLocation(location)" fxLayoutAlign="space-between center">
                      <span>{{ location.label }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div *ngIf="updateInfoForm && updateInfoForm.controls.isTruckEmpty.value; ">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>Truck's Current Location</mat-label>
                  <input matInput placeholder="Truck's Current Location" formControlName="truckCurrentLocationString"
                    id="currentLocation" [matAutocomplete]="trucksCurrentLocationAuto" class="text-capitalize"
                    name="currentLocation" (focusout)="cleartruckCurrentLocation()" (keyup)="getTruckCurrentLocation()"
                    [required]="isFieldsMandatory && !isDeliverLocationMandatory">
                  <mat-autocomplete #trucksCurrentLocationAuto="matAutocomplete">
                    <mat-option *ngIf="!currentLocations.length">
                      {{'noreco5231' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let location of currentLocations" class="text-capitalize"
                      [value]="location.value" (onSelectionChange)="onSelectCurrentTruckLocation(location)"
                      fxLayoutAlign="space-between center">
                      <span>{{ location.label }}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="updateInfoForm.controls.truckCurrentLocationString.errors && updateInfoForm.controls.truckCurrentLocationString.errors.invalid">
                    <span [translate]="'invali1689'">
                      Invalid Current Location
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutGap="15px"
                *ngIf="updateInfoForm && !updateInfoForm.controls.isTruckEmpty.value && isNAUser">
                <div fxFlex="45%" class="truck-empty-date">
                  <mat-form-field class="form-field-input" appearance="outline">
                    <mat-label>{{'truckE8962' | translate | titlecase}}</mat-label>
                    <input matInput [matDatepicker]="emptyDatePicker" id="empty-date" formControlName="truckEmptyDate"
                      placeholder="{{'truckE8962' | translate | titlecase}}"
                      [required]="isFieldsMandatory && isDeliverLocationMandatory">
                    <mat-error *ngIf="updateInfoForm.controls.truckEmptyDate.errors && (updateInfoForm.controls.truckEmptyDate.errors.invalid 
                      || updateInfoForm.controls.truckEmptyDate.errors.required)">
                      Date required
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="emptyDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #emptyDatePicker (selectedChanged)="emptyDateSelected($event)"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxFlex="55%">
                  <div class="time-picker">
                    <xpoc-mat-time-picker formControlName="truckEmptyTime" name="time" [label]="'Truck Emptying Time'"
                      [required]="isFieldsMandatory && isDeliverLocationMandatory" [appearance]="'outline'">
                    </xpoc-mat-time-picker>
                  </div>
                  <mat-error
                    *ngIf="updateInfoForm.controls.truckEmptyTime.errors && updateInfoForm.controls.truckEmptyTime.errors.invalid">
                    <span [translate]="'timere6835'">
                      Time required
                    </span>
                  </mat-error>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="15px" *ngIf="(updateInfoForm.controls.deliveryLocation.value ||
                  updateInfoForm.controls.truckCurrentLocation.value) && isNAUser">
                <div fxFlex="45%" class="truck-empty-date">
                  <mat-form-field class="form-field-input" appearance="outline">
                    <mat-label>{{'pickUp3862' | translate}}</mat-label>
                    <input matInput [matDatepicker]="etaDatePicker" id="eta-date" formControlName="pickUpETADate"
                      placeholder="{{'pickUp3862' | translate}}">
                    <mat-error *ngIf="updateInfoForm.controls.pickUpETADate.errors && (updateInfoForm.controls.pickUpETADate.errors.invalid
                      || updateInfoForm.controls.pickUpETADate.errors.required)">
                      <span [translate]="'datere9193'">
                        Date required
                      </span>
                    </mat-error>
                    <mat-datepicker-toggle matSuffix [for]="etaDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #etaDatePicker (selectedChanged)="etaDateSelected($event)"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxFlex="55%">
                  <div class="time-picker">
                    <xpoc-mat-time-picker formControlName="pickUpETATime" name="time" [label]="'Pick Up ETA Time'"
                      [appearance]="'outline'">
                    </xpoc-mat-time-picker>
                  </div>
                  <mat-error
                    *ngIf="updateInfoForm.controls.pickUpETATime.errors && updateInfoForm.controls.pickUpETATime.errors.invalid">
                    <span [translate]="'loadLa3896'">
                      Time required
                    </span>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button *ngIf="isDispatchInfoOptionalOrMandatoryCheckRequired && !isFieldsMandatory" mat-button
          class="text-bold-uppercase" color="accent" (click)="onClose(true)">skip for now</button>
        <button *ngIf="!isDispatchInfoOptionalOrMandatoryCheckRequired" mat-button (click)="onClose()"
          class="text-bold-uppercase" type="button" color="accent">{{'cancel7093' | translate}}</button>
        <button (click)="onSaveDispatcher()" id="save-profile-btn" color="accent" mat-raised-button
          class="text-bold-uppercase" [disabled]="!updateInfoForm.valid" type="button">
          {{'save5874' | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
<div *ngIf="!error && !isNAUser" fxLayout="column" fxLayoutGap="10px">
  <div *ngIf="!formSubmitted" fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
    <span class="text-uppercase text-bold" [translate]="dispatchHeading">update dispatch</span>
    <span class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </span>
  </div>
  <div *ngIf="!dispatchPermission" class="error-message" fxLayout="row" fxLayoutGap="5px">
    <div class="icon-exclaim"><i class="icon-exclaim_btn"></i></div>
    <div [translate]="'youdonothav5862'">You do not have permission to add/edit dispatch information.</div>
  </div>
  <div fxLayout="column" fxLayoutGap="10px" *ngIf="!formSubmitted" class="form-data">
    <div fxLayout="row" fxLayoutGap="10px" class="text-bold">
      <div>
        <span [translate]="'load2935'" class="text-bold">Load</span># &nbsp;{{load.isTravelOrder?load.alternateNumber :
        load.number}}
      </div>
      <div>
        <span [translate]="'trip3812'" class="text-bold divider">Trip</span>#&nbsp;{{load.tripNumber}}
      </div>
      <div>
        <span [translate]="'pickUp7068'" class="text-bold divider">Pick Up:</span>
        {{dispatchOrigin}}
      </div>

    </div>
    <div class="text-capitalize">
      <span class="required-field">*&nbsp;</span><span [translate]="'requir4016'">required fields</span>
    </div>
    <div fxLayout="column" fxLayoutGap=10px>
      <form [formGroup]="updateInfoForm">
        <mat-dialog-content>
          <!-- <div fxLayout="row"> -->
          <!-- <div class="icon-truck" fxFlex="5%">
            </div> -->
          <div fxLayout="column" fxLayoutGap=10px fxFlex="100%" class="truck-block">
            <div>
              <span><b [translate]="'equipment6372'">Equipment</b></span>
            </div>
            <div fxLayout="row" fxLayoutGap=10px fxFlex="100%">
              <div class="example-container" fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{'truckPlate1581' | translate}}</mat-label>
                  <input matInput placeholder="{{'truckPlate1581' | translate}}" id="truck-editable" name="truck"
                    formControlName="euTruckNumber" [required]="!isNAUser">
                </mat-form-field>
              </div>
              <div class="example-container" fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{'trailerPlat8723' | translate}}</mat-label>
                  <input matInput placeholder="{{'trailerPlat8723' | translate}}" id="trailer-editable" name="trailer"
                    formControlName="euTrailerNumber">
                </mat-form-field>
              </div>
            </div>
            <mat-error>
              <div *ngIf="updateInfoForm.controls.euTruckNumber.errors && updateInfoForm.controls.euTruckNumber.errors.required && updateInfoForm.controls.euTruckNumber.dirty;
                  else loadError" [translate]="'trucknumber3698'">
                Truck number is required.
              </div>
              <ng-template #loadError>
                <div *ngIf="((updateInfoForm.controls.euTrailerNumber.errors && updateInfoForm.controls.euTrailerNumber.invalid) || (updateInfoForm.controls.euTruckNumber.errors && updateInfoForm.controls.euTruckNumber.invalid && updateInfoForm.controls.euTruckNumber.dirty)) && 
                (updateInfoForm.controls.euTrailerNumber.touched || updateInfoForm.controls.euTruckNumber.touched)"
                  [translate]="'specialchar7105'">
                  Special characters and spaces are not allowed
                </div>
              </ng-template>
            </mat-error>
            <div fxLayout="column" fxLayoutGap="10px" class="margin-bottom-10">
              <div>
                <span><b class="text-capitalize" [translate]="'preferredtr7123'"> preferred tracking method</b></span>
              </div>

              <!-- <mat-form-field> -->
              <mat-radio-group formControlName="trackingMethod" fxLayout="row" fxLayoutAlign="start center"
                fxLayoutGap="10px" required>
                <mat-radio-button *ngFor="let trackingMethod of trackingMethodType"
                  [value]="trackingMethod.trackingMethodCode">
                  {{trackingMethod.trackingMethodName}}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button class="text-uppercase secondary-btn" (click)="onClose()"
            type="button">{{'cancel7093' | translate}}</button>
          <button (click)="onSaveDispatcher()" [class.btn-spinner]="isSaving" id="save-profile-btn"
            class="text-uppercase mat-raised-button mat-accent" mat-raised-button
            [disabled]="!updateInfoForm.valid || disableUploadBtn || !dispatchPermission" type="button"> {{'save5874' |
            translate}}</button>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</div>
<div class="form-data" *ngIf="formSubmitted && !isDispatchInfoOptionalOrMandatoryCheckRequired">
  <div *ngIf="isNAUser ; else dispatchSuccess">
    <div mat-dialog-title class="text-bold-uppercase">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span [translate]="'succes8946'">Success</span>
        <span class="dialog-close-icon">
          <i class="icon-close" (click)="onClose()"></i>
        </span>
      </div>
    </div>
    <mat-dialog-content>
      <p [translate]="'detailssave9465'">Details saved successfully.</p>
    </mat-dialog-content>
  </div>
  <ng-template #dispatchSuccess>

    <app-dispatch-info-success-dialog *ngIf="dispatchLoadTrackingInfo" [loadDetails]="dispatchLoadTrackingInfo"
      (CloseEvent)="onClose()">
    </app-dispatch-info-success-dialog>
  </ng-template>
</div>
<div *ngIf="error">
  <div mat-dialog-title class="text-bold-uppercase">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span [translate]="'dispatchInf40'">Dispatch Information Error</span>
      <span class="dialog-close-icon">
        <i class="icon-close" (click)="onClose()"></i>
      </span>
    </div>
  </div>
  <mat-dialog-content>
    <p [translate]="'sorryS2239'">Sorry Some Error Occurred</p>
  </mat-dialog-content>
</div>