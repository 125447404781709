import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Country } from '../model/country';
import { Equipment } from '../model/equipment';
import { State } from '../model/state';
import { Currency } from '../model/currency';
import { Role } from 'src/app/admin/model/role.model';
import { Permission } from '../model/permission.model';
import { Language } from '../model/language';
import { WeightUnit } from 'src/app/loadboard/model/weight-unit';
import { SelectedCommodity } from 'src/app/loadboard/model/selected-commodity';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService
  ) {
    this.baseUrl = appSettings.apiUrl;
  }

  getDocumentType(): Observable<any> {
    const url = this.getUri('lookups/documenttypes');
    return this.http
      .get(url, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  commodity(): Promise<SelectedCommodity[]> {
    const url = this.getUri('lookups/allcommodities');

    return this.http
      .request('get', url)
      .toPromise()
      .then(response => {
        return response as SelectedCommodity[]; // sort asc
      }).catch(this.handleError);
  }

  getEqpSize(categoryCode): Promise<string[]> {
    return this.get('lookups/equipmentType', categoryCode)
      .then((response) => {
        return this.sortBySortOrder(response, 'ASC');
      })
      .catch(this.handleError);

  }

  unitWeights(): Promise<WeightUnit[]> {
    return this.get('lookups/unitWeights')
      .then((response) => this.sortBySortOrder(response, 'ASC') as WeightUnit[])
      .catch(this.handleError);
  }

  // Role Management
  permissions(): Promise<Permission[]> {
    return this.get('permissions').then((response) => this.sortBySortOrder(response, 'ASC') as Permission[]);
  }

  roles(): Promise<Role[]> {
    return this.get('roles').then((response) => this.sortBySortOrder(response, 'ASC') as Role[]);
  }

  addRole(role: Role): Promise<Response> {
    return this.post('roles', role);
  }

  updateRole(role: Role): Promise<Response> {
    return this.put('roles', role);
  }

  deleteRole(role: Role): Promise<Response> {
    return this.delete('roles', role);
  }

  currencies(): Promise<Currency[]> {
    return this.get('lookups/currencies')
      .then((response) => this.sortBySortOrder(response, 'ASC') as Currency[])
      .catch(this.handleError);
  }

  languages(forAppless = false): Promise<Language[]> {
    const tenantId = this.authService.tenantId;
    const url = this.getUri('lookups/cultures');
    let httpParams = new HttpParams();
    httpParams = httpParams.append('tenantid', tenantId);
    httpParams = httpParams.append('isAppless', forAppless.toString());
    return this.http
      .request('get', url, { params: httpParams, headers: new HttpHeaders({ 'skipRedirect': 'true' }) })
      .toPromise()
      .then((response: any) => {
        return this.sortBySortOrder(response, 'ASC') as Language[];
      }).catch(this.handleError);
  }

  equipment(): Promise<Equipment[]> {
    return this.get('lookups/equipmentcategory')
      .then((response) => this.sortBySortOrder(response, 'ASC') as Equipment[])
      .catch(this.handleError);
  }


  getDriverRoles(): Observable<any> {
    const url = this.getUri('lookups/driverroles');
    return this.http
      .get(url, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  private sortBySortOrder(lookups: any[], order: string) {
    // By default return the lookups unsorted
    let retVal = lookups;
    // Verify all elements in list have SortOrder defined and have a value
    const isListSortable = lookups.every((element) => element.SortOrder != null);
    if (isListSortable && order) {
      switch (order.toUpperCase()) {
        case 'ASC':
          // Ascending order
          retVal = lookups.sort((a, b) => a.SortOrder - b.SortOrder);
          break;
        case 'DESC':
          // Descending order
          retVal = lookups.sort((a, b) => b.SortOrder - a.SortOrder);
          break;
        default:
          // return original unsorted list
          break;
      }
    }
    return retVal;
  }

  countries(): Promise<Country[]> {
    return this.get('lookups/countries')
      .then((response) => this.sortBySortOrder(response, 'ASC') as Country[])
      .catch(this.handleError);
  }

  getCountriesCallingCode(): Observable<any> {
    const url = this.getUri('lookups/countriesbyregion');
    return this.http.get(url, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  public getNotificationEnabledCountries(): Observable<any> {
    const url = this.getUri('lookups/notificationcountriesbyregion');
    return this.http.get(url, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  cities(cityName: string): Promise<any[]> {
    const url = this.getUri('lookups/cities');
    const params: HttpParams = new HttpParams().set('search', cityName);
    return this.http
      .request('get', url, { params })
      .toPromise()
      .then(response => {
        return response as any[]; // sort asc
      }).catch(this.handleError);
  }

  getCityFromPincode(pincode: string): Promise<any> {
    const url = this.appSettings.apiUrl + 'lookups/locations/' + pincode;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return response as any;
      })
      .catch(this.handleError);
  }

  getDriverList(driverName: string): Observable<any> {
    const url = this.getUri('lookups/drivers');
    let params: HttpParams = new HttpParams();
    params = params.append('partnerIdentifier', this.authService.partnerIdentifier);
    params = params.append('search', driverName);
    return this.http
      .request('get', url, { params })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  states(countryCode: string): Promise<State[]> {
    const url = this.getUri('lookups/states');
    const params: HttpParams = new HttpParams().set('countrycode', countryCode);
    return this.http
      .request('get', url, { params, headers: new HttpHeaders({ skipRedirect: 'true' }) })
      .toPromise()
      .then(response => {
        return response as State[];
      }).catch(this.handleError);
  }

  private get(type: string, values?: any): Promise<any> {
    let value = '';
    if (Array.isArray(values)) {
      value = values.join('');
    } else {
      value = values;
    }
    const url = this.getUri(type, value);
    return this.http
      .get(url)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError);
  }

  private put(type: string, obj: any): Promise<any> {
    const url = this.getUri(type);
    return this.http
      .put(url, obj, { headers: this.getHeaders() })
      .toPromise()
      .then(() => obj)
      .catch(this.handleError);
  }

  private post(type: string, obj: any): Promise<any> {
    const url = this.getUri(type);
    return this.http
      .post(url, obj, { headers: this.getHeaders() })
      .toPromise()
      .then(() => obj)
      .catch(this.handleError);
  }

  private delete(type: string, obj: any): Promise<Response> {
    const url = this.getUri(type);
    return this.http
      .delete(url, { headers: this.getHeaders() })
      .toPromise()
      .catch(this.handleError);
  }

  // Constructrs the uri to use for the endpoint
  private getUri(type, values?): string {
    if (Array.isArray(values)) {
      values.forEach((v) => type += '/' + v);
    } else {
      if (values) {
        type += '/' + values;
      }
    }
    return this.baseUrl + type;
  }

  // Constructs the headers collection to use
  private getHeaders(obj?: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
