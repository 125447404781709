<div mat-dialog-title>
    <div fxFlex="95%">
        <span class="text-uppercase text-bold" *ngIf="!showConfirmationMsg; else confirmationHeader"
            [translate]="'mailto148'">mail to xpoconnect
            support</span>
    </div>
    <div class="dialog-close-icon" fxFlex="5%">
        <i class="icon-close" (click)="onClose()"></i>
    </div>
    <ng-template #confirmationHeader>
        <span class="text-uppercase text-bold" [translate]="'confirmatio8018'">
            confirmation
        </span>
    </ng-template>
</div>


<div *ngIf="!showConfirmationMsg; else confirmationContent" fxLayoutGap="5px">
    <div class="required-block">
        <span class="required">*</span>
        <span [translate]="'requir4016'">Required fields</span>
    </div>
    <form [formGroup]="supportForm">
        <mat-dialog-content class="support-form-container" fxLayout="column" fxLayoutGap="5px">
            <mat-form-field appearance="outline">
                <mat-label>{{'firstName9858' | translate}}</mat-label>
                <input matInput placeholder="{{'firstName9858' | translate}}" id="firstName" name="firstName"
                    formControlName="firstName" required>
                <mat-error
                    *ngIf="supportForm.controls.firstName.errors &&
                     (supportForm.controls.firstName.errors.invalidName || supportForm.controls.firstName.errors.required)">
                    <span [translate]="'entervalidf5618'">Enter valid first name</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'lastName1947' | translate}}</mat-label>
                <input matInput placeholder="{{'lastName1947' | translate}}" id="lastName" name="lastName"
                    formControlName="lastName" required>
                <mat-error
                    *ngIf="supportForm.controls.lastName.errors &&
                       (supportForm.controls.lastName.errors.invalidName || supportForm.controls.lastName.errors.required)">
                    <span [translate]="'entervalidl6237'">Enter valid last name</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'emailA2367' | translate | titlecase}}</mat-label>
                <input matInput placeholder="{{'emailA2367' | translate | titlecase}}" id="email" name="email"
                    formControlName="email" required>
                <mat-error *ngIf="supportForm.controls.email.errors &&
                     (supportForm.controls.email.errors.invalidEmail || supportForm.controls.email.errors.required)">
                    <span [translate]="'entervalide8568'">Enter valid email</span>
                </mat-error>
            </mat-form-field>
            <label class="text-capitalize" [translate]="'mobile7555'">mobile</label>
            <div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field fxFlex="35%" class="phone-type select-required" appearance="outline">
                        <mat-label>{{'countr5418' | translate | titlecase}}</mat-label>
                        <mat-select formControlName="country" class="select-label" required>
                            <mat-option *ngFor="let country of countries" value={{country.code}}>
                                {{country.code}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="65%" appearance="outline">
                        <mat-label>{{'mobile7555' | translate | titlecase}}</mat-label>
                        <input matInput placeholder="{{'mobile7555' | translate | titlecase}}" id="mobile" name="mobile"
                            formControlName="phoneNumber" [maxlength]="phoneNoMaxLength" (keyup)="formatPhone()"
                            required>
                    </mat-form-field>
                    <mat-error
                        *ngIf="supportForm.controls.phoneNumber.errors && 
                  (supportForm.controls.phoneNumber.errors.invalidPhone || supportForm.controls.phoneNumber.errors.required)">
                        <span [translate]="'enterV5093'">Enter valid phone number</span>
                    </mat-error>
                </div>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>{{'typeof2413' | translate}}</mat-label>
                <mat-select id="type" name="supportType" formControlName="supportType" class="select-label"
                    (selectionChange)="onSelectingSupportType()" required>
                    <mat-option *ngFor="let supportType of typeOfSupport" value={{supportType.code}}>
                        {{supportType.code}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="showSupportRequired" appearance="outline">
                <mat-label>{{'suppor2587' | translate}}</mat-label>
                <textarea matInput placeholder="{{'suppor2587' | translate}}" type="text" name="supportRequired"
                    formControlName="supportRequired" maxlength="250" required></textarea>
                <mat-hint align="end">
                    {{ supportForm.controls.supportRequired.value ? supportForm.controls.supportRequired.value.length : 0 }}
                    / 250</mat-hint>
                <mat-error
                    *ngIf="supportForm.controls.supportRequired.errors && (supportForm.controls.supportRequired.errors.invalid || supportForm.controls.supportRequired.errors.required)">
                    <span [translate]="'enterv3816'">Enter valid support required
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'xPORel1525' | translate}}</mat-label>
                <input matInput placeholder="{{'xPORel1525' | translate}}" size="50" id="xRelationship"
                    name="xpoRelationship" formControlName="xpoRelationship">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'MC/DOT #' | translate}}</mat-label>
                <input matInput placeholder="MC / DOT #" id="mcOrDot" name="mcOrDot" formControlName="mcOrDotNo"
                    required>
                <mat-error
                    *ngIf="supportForm.controls.mcOrDotNo.errors &&supportForm.controls.mcOrDotNo.errors.required">
                    <span> Enter valid MC/DOT #</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'xpoRepresen4869' | translate}}</mat-label>
                <input matInput placeholder="{{'xpoRepresen4869' | translate}}" id="xpoRepresentative" maxlength="20"
                    name="xpoRepresentative" formControlName="xpoRepresentative" required>
                <mat-error
                    *ngIf="supportForm.controls.xpoRepresentative.errors &&supportForm.controls.xpoRepresentative.errors.required">
                    <span [translate]="'enterv7420'">Enter valid XPO representative
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{'notes7747' | translate}}</mat-label>
                <textarea matInput placeholder="{{'notes7747' | translate}}" type="text" name="notes"
                    formControlName="notes" maxlength="250" required></textarea>
                <mat-hint align="end">
                    {{ supportForm.controls.notes.value ? supportForm.controls.notes.value.length : 0 }}
                    / 250</mat-hint>
                <mat-error *ngIf="supportForm.controls.notes.errors &&supportForm.controls.notes.errors.required">
                    <span [translate]="'enterv3545'">Enter valid notes </span>
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions align="end" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
            <button mat-raised-button (click)="onClose()" class="text-uppercase secondary-btn"
                [disabled]="disableBtn">{{'cancel7093' | translate}}</button>
            <button mat-raised-button (click)="sentSupportMail()" class="text-uppercase mat-raised-button mat-accent"
                [disabled]="!supportForm.valid || disableBtn">
                {{'mail3877' | translate}}
            </button>
        </mat-dialog-actions>
    </form>
</div>
<ng-template #confirmationContent>
    <mat-dialog-content>
        <div class="popup-msg-title" [translate]="'thanky6704'">Thank you for contacting XPO</div>
        <div class="popup-msg" [translate]="'asuppo8438'">A support representative will get back to you in 24 hours.
        </div>
    </mat-dialog-content>
</ng-template>