import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadInfoUpdate } from '../model/load-infoupdate';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Load } from '../model/load';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Validations, PhoneFormattingPipe } from '@xpoc/ngx-common';
import { DateTimePipe as DatePipe } from '@xpoc/ngx-common';
import { Country } from '../model/country';
import { DriverDetails, DriverPhone } from '../model/driver-details';
import { Subscription } from 'rxjs';
import { XPOConstants } from '../xpo-constants';
import { AppSettings } from 'src/app/core/app-settings';
import { DriverStatusRequest } from '../model/driver-status-request';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { MyLoadsService } from '../services/my-loads.service';
import { LookupService } from '../services/lookup.service';
import { GoogleAnalyticsEventsService } from 'src/app/google-analytics/google-analytics-events.service';
import { TendersService } from '../services/tenders.service';
import { KeystoneService } from '../services/keystone.service';
import { AvailableLoadsService } from '../services/available-loads.service';
import { AssetManagementService } from '../services/asset-management.service';
import { DriverListRequest } from '../model/driver-list-request';
import { GlobalService } from '../global.service';
import { TruckTrailerDeatils } from '../model/truck-trailer-details';
import { LogEvent } from '../model/log-event';
import { EquipmentListRequest } from '../model/equipment-list-request';
import { DriverStatusDialogComponent } from '../driver-status-dialog/driver-status-dialog.component';
import { AddNewDriverComponent } from '../driver/add-new-driver/add-new-driver.component';
import { AddEquipmentFormComponent } from '../equipment/add-equipment-form/add-equipment-form.component';
import { CancelConfirmationDialogComponent } from '../cancel-confirmation-dialog/cancel-confirmation-dialog.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-dispatch-info-dialog',
  templateUrl: './dispatch-info-dialog.component.html',
  styleUrls: [ './dispatch-info-dialog.component.scss' ]
})
export class DispatchInfoDialogComponent implements OnInit {

  public loadInfo: LoadInfoUpdate;
  public formSubmitted = false;
  public isDispatchInfoOptionalOrMandatoryCheckRequired = false;
  public error: boolean;
  public updateInfoForm: FormGroup;
  public dispatchTrackingForm: FormGroup;
  public load: Load;
  public tripcode: string;
  public currentTruckNumber: string;
  public currentTrailer: string;
  public countries: Country[];
  public dialogConfig: MatDialogConfig;
  public emailSubject: string;
  public callWoodApi: boolean;
  public isNAUser: boolean;
  public driverList: DriverDetails[];
  public phoneNumberList: DriverPhone[];
  public trackignPhoneNumberList: DriverPhone[];
  public isEmptyDriverList: boolean;
  public isNoDriver: boolean;
  public pendingRequest: Subscription;
  private isDriverAssigned: boolean;
  private page: string;
  private subPage: string;
  public phoneNoMaxLength: number;
  public dispatchOrigin: string;
  public dispatchDest: string;
  public dispatchPickupDate: string;
  public driverStatus = XPOConstants.driverStatus;
  public userActions = XPOConstants.userActions;
  private driverStatusRequest: DriverStatusRequest;
  private isLoadScheduled: boolean;
  public isDriveXPODriver: boolean;
  public assetManagementPermission = false;
  public truckList = [];
  public trailerList = [];
  public locations = [];
  public currentLocations = [];
  public manageEquipment = false;
  public dateFormat: string;
  public trackingMethodType = [];
  public trackingMethodTypeCode: any;
  public defaultPhoneType: string;
  public isFieldsMandatory = false;
  public dispatchThreshold: string;
  public isDeliverLocationMandatory = false;
  public dispatchLoadTrackingInfo: any;
  public dispatchPermission: boolean;
  public isSaving: boolean;
  public disableUploadBtn: boolean;
  public dispatchHeading: string;
  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;

  constructor(
    public dialogRef: MatDialogRef<DispatchInfoDialogComponent>,
    private authService: AuthService,
    private appSettings: AppSettings,
    public loadService: MyLoadsService,
    private lookupService: LookupService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    public gaService: GoogleAnalyticsEventsService,
    private loadTendersService: TendersService,
    private assetManagementService: AssetManagementService,
    private keystoneService: KeystoneService,
    public customPhoneFormatting: PhoneFormattingPipe,
    private dialog: MatDialog,
    private loadBoardService: AvailableLoadsService,
    public datePipe: DatePipe,
    private dateAdapter: DateAdapter<any>,

  ) {
    this.dialogRef.disableClose = true;
    this.defaultPhoneType = XPOConstants.defaultPhoneType;
    this.trackingMethodTypeCode = XPOConstants.trackingMethodTypeCode;
    this.loadService.getTrackingmethods().subscribe(trackingMethods => {
      this.trackingMethodType = trackingMethods;
    });
    this.loadInfo = new LoadInfoUpdate();
    this.error = false;
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.width = '470px';
    this.isNAUser = this.authService.checkNAUser();
    this.phoneNoMaxLength = this.isNAUser ? XPOConstants.defaultPhoneNoLengthNA : XPOConstants.defaultPhoneNoLengthEU;
    this.dateFormat = XPOConstants.dateFormat;
    const permissions = this.authService.permissions;
    if (permissions.findIndex(permission => permission === XPOConstants.permissionTypes.assetManagement) > -1) {
      this.assetManagementPermission = true;
    }
    if (permissions.findIndex(permission => permission === XPOConstants.permissionTypes.manageEquipment) > -1) {
      this.manageEquipment = true;
    }
  }

  ngOnInit() {
    this.dispatchPermission = true;
    if (!this.isNAUser) {
      this.dispatchPermission = false;
      const permissions = this.authService.permissions;
      if (permissions.findIndex(permission => permission === XPOConstants.permissionTypes.loadAssignedAction) > -1) {
        this.dispatchPermission = true;
      }
    }
    this.disableUploadBtn = false;
    this.isSaving = false;
    this.load = this.dialogRef._containerInstance._config.data;
    this.dispatchHeading = this.load.isDispatchInfo ? 'editDi6734' : 'adddispatch281';
    this.dispatchLoadTrackingInfo = this.load;
    this.currentTruckNumber = this.load.truckNumber ? this.load.truckNumber : '';
    this.currentTrailer = this.load.trailerNumber ? this.load.trailerNumber : '';
    if (this.load && this.load.driverName) {
      this.load.driverName = this.load.driverName.trim();
    }
    this.emailSubject = this.dialogRef._containerInstance._config.data.emailSubject;
    this.dispatchOrigin = this.dialogRef._containerInstance._config.data.dispatchOrigin;
    this.dispatchDest = this.dialogRef._containerInstance._config.data.dispatchDest;
    this.dispatchPickupDate = this.dialogRef._containerInstance._config.data.dispatchPickupDate;
    this.callWoodApi = this.dialogRef._containerInstance._config.data.callWoodApi ?
      this.dialogRef._containerInstance._config.data.callWoodApi : false;
    this.page = this.dialogRef._containerInstance._config.data.page;
    this.subPage = this.dialogRef._containerInstance._config.data.subPage;
    this.driverStatusRequest = this.dialogRef._containerInstance._config.data.driverStatusRequest;
    this.isLoadScheduled = this.dialogRef._containerInstance._config.data.isLoadScheduled;
    if (this.dialogRef._containerInstance._config.data.isFieldsMandatory !== undefined) {
      this.isFieldsMandatory = this.dialogRef._containerInstance._config.data.isFieldsMandatory;
    }
    if (this.isNAUser) {
      this.dispatchThreshold =
        this.appSettings.getApplicationConstant(XPOConstants.dispatchConfigurationKeys.mandateDispatchUpdateThresholdInHours);
    }
    this.isDispatchInfoOptionalOrMandatoryCheckRequired =
      this.dialogRef._containerInstance._config.data.isDispatchInfoOptionalOrMandatoryCheckRequired !== undefined ?
        this.dialogRef._containerInstance._config.data.isDispatchInfoOptionalOrMandatoryCheckRequired : false;
    const driverListRequest = new DriverListRequest('', 1, XPOConstants.defaultPageSize);
    this.assetManagementService.getDriverList(driverListRequest).subscribe(response => {
      this.isEmptyDriverList = response && !response.totalCount ? true : false;
    });
    if (!this.isNAUser) {
      this.lookupService.countries().then(response => {
        this.countries = response;
      });
    }
    if (this.isDispatchInfoOptionalOrMandatoryCheckRequired) {
      this.updateInfoForm = this.createFormForBookNow(this.load);
    } else {
      this.updateInfoForm = this.createForm(this.load);
    }
    if (!this.isNAUser) {
      this.updateInfoForm.get('euTruckNumber').setValidators([ Validators.required, Validators.pattern('[A-Za-z0-9]*$') ]);
      this.updateInfoForm.get('euTrailerNumber').setValidators(Validators.pattern('[A-Za-z0-9]*$'));
    }
    this.tripcode = this.load.alternateNumber;
    if (this.load.driverName) {
      this.searchDriver(this.load.driverName, true, this.load.driverId);
    } else {
      this.searchDriver('');
    }
    if (this.page === XPOConstants.keystonePage.availableLoads && this.globalService.driverDetails
      && this.globalService.driverDetails.driverName) {
      const driverDetails = this.globalService.driverDetails;
      if (driverDetails.driverName && driverDetails.driverId) {
        this.searchDriver(driverDetails.driverName, true, driverDetails.driverId);
      } else {
        this.searchDriver('');
      }
    }
    if (this.load && this.load.truckNumber) {
      this.getTruckTrailer(this.load.truckNumber, XPOConstants.equipmentTypeCode.truck);
    } else {
      this.getTruckTrailer('', XPOConstants.equipmentTypeCode.truck);
    }
    if (this.load && this.load.trailerNumber) {
      this.getTruckTrailer(this.load.trailerNumber, XPOConstants.equipmentTypeCode.trailer);
    } else {
      this.getTruckTrailer('', XPOConstants.equipmentTypeCode.trailer);
    }
    this.updateInfoForm.get('driverSearchString').valueChanges.subscribe(search => {
      this.updateInfoForm.get('driverSearchString').setErrors(null);
      this.searchDriver(search);
    });
    this.updateInfoForm.get('truckEmptyTime').valueChanges.subscribe(truckEmptyTime => {
      const truckEmptyDate = this.updateInfoForm.get('truckEmptyDate').value;
      if (this.isDeliverLocationMandatory) {
        if (truckEmptyTime === '') {
          if (!truckEmptyDate) {
            this.updateInfoForm.get('truckEmptyDate').setErrors(this.isFieldsMandatory ? { invalid: true } : null);
          } else {
            this.updateInfoForm.get('truckEmptyTime').setErrors(this.isFieldsMandatory ? { invalid: true } : null);
          }
        } else {
          if (truckEmptyDate === '') {
            this.updateInfoForm.get('truckEmptyDate').setErrors(this.isFieldsMandatory ? { invalid: true } : null);
          }
        }
      }
    });

    this.updateInfoForm.get('pickUpETATime').valueChanges.subscribe(pickUpETATime => {
      const pickUpETADate = this.updateInfoForm.get('pickUpETADate').value;
      if (pickUpETATime !== '' && (pickUpETADate === '')) {
        this.updateInfoForm.get('pickUpETADate').setErrors({ invalid: true });
      }
    });

    this.updateInfoForm.get('naTruckNumberString').valueChanges.subscribe(search => {
      this.getTruckTrailer(search, XPOConstants.equipmentTypeCode.truck);
      this.updateInfoForm.get('naTruckNumberString').setErrors(null);
      if (!search) {
        if (this.isNAUser) {
          this.updateInfoForm.get('naTruckNumber').setValue(this.currentTruckNumber);
        } else {
          this.updateInfoForm.get('naTruckNumber').setValue('');
        }
      }
    });
    this.updateInfoForm.get('naTrailerNumberString').valueChanges.subscribe(search => {
      this.getTruckTrailer(search, XPOConstants.equipmentTypeCode.trailer);
      this.updateInfoForm.get('naTrailerNumberString').setErrors(null);
      if (!search) {
        if (this.isNAUser) {
          this.updateInfoForm.get('naTrailerNumber').setValue(this.currentTrailer);
        } else {
          this.updateInfoForm.get('naTrailerNumber').setValue('');
        }
      }
    });
    if (this.load.isTruckEmpty) {
      this.getTruckCurrentLocation();
    } else {
      this.getDeliveryLocation();
    }
    this.setTrackingMethod(this.load.trackingMethodCode);
    if (this.isNAUser) {
      this.dateAdapter.setLocale('en');
    }
    if ((this.isFieldsMandatory) && (this.isDispatchInfoOptionalOrMandatoryCheckRequired) &&
      (this.updateInfoForm.get('isTruckEmpty').value === false)) {
      this.isDeliverLocationMandatory = true;
    } else {
      this.isDeliverLocationMandatory = false;
    }
  }

  private createForm(load: Load) {
    const driverPhoneValidator = (this.isNAUser) ? [ Validations.phone, Validators.required ] : [ Validations.euPhone ];
    const group = this.formBuilder.group({
      driverName: [ this.load && this.load.driverName ? this.load.driverName : '', this.isNAUser ? [ Validators.required ] : '' ],
      driverSearchString: [ this.load && this.load.driverName ? this.load.driverName : '', this.isNAUser ? [ Validators.required ] : '' ],
      driverPhoneNumber: [ this.load && this.load.driverPhoneNumber ?
        this.customPhoneFormatting.transform(this.load.driverPhoneNumber, this.authService.tenantId) : '', driverPhoneValidator ],
      email: [ { value: '', disabled: true }, [ Validations.email ] ],
      isTruckEmpty: [ this.load && this.load.hasOwnProperty('isTruckEmpty') && this.isNAUser ? this.load.isTruckEmpty :
        (this.isNAUser ? false : true) ],
      trackingMethod: [ this.load && this.load.trackingMethodCode ?
        this.load.trackingMethodCode : this.trackingMethodTypeCode.dtm, [ Validators.required ] ],
      isDriverPhoneTrackable: [ this.load && this.load.hasOwnProperty('useDriverPhoneAsTracking') ?
        this.load.useDriverPhoneAsTracking : true ],
      trackingPhoneNumber: [ this.load && this.load.trackingPhoneNumber ? this.load.trackingPhoneNumber : '' ],
      euTruckNumber: [ this.load && this.load.truckNumber && !this.isNAUser ? this.load.truckNumber : '' ],
      naTruckNumber: [ this.load && this.load.truckNumber && this.isNAUser ? this.load.truckNumber : '', this.isNAUser ? [ Validators.required ] : '' ],
      naTruckNumberString: [ this.load && this.load.truckNumber && this.isNAUser ? this.load.truckNumber : '' ],
      truckLicenseCountry: [ this.load && this.load.truckLicenseCountry ? this.load.truckLicenseCountry : '' ],
      euTrailerNumber: [ this.load && this.load.trailerNumber && !this.isNAUser ? this.load.trailerNumber : '' ],
      naTrailerNumber: [ this.load && this.load.trailerNumber && this.isNAUser ? this.load.trailerNumber : '', this.isNAUser ? [ Validators.required ] : '' ],
      naTrailerNumberString: [ this.load && this.load.trailerNumber && this.isNAUser ? this.load.trailerNumber : '' ],
      trailerLicenseCountry: [ this.load && this.load.trailerLicenseCountry ? this.load.trailerLicenseCountry : '' ],
      deliveryLocation: [ this.load && this.load.deliveryLocation ? this.load.deliveryLocation : '' ],
      deliveryLocationString: [ this.load && this.load.deliveryLocation ? this.load.deliveryLocation : '' ],
      truckCurrentLocation: [ this.load && this.load.truckCurrentLocation ? this.load.truckCurrentLocation : '' ],
      truckCurrentLocationString: [ this.load && this.load.truckCurrentLocation ? this.load.truckCurrentLocation : '' ],
      truckEmptyTime: [ this.load && this.load.truckEmptyTime ? this.load.truckEmptyTime : '' ],
      truckEmptyDate: [ this.load && this.load.truckEmptyDate ? this.load.truckEmptyDate : '' ],
      pickUpETADate: [ this.load && this.load.etaToNextStopDate ? this.load.etaToNextStopDate : '' ],
      pickUpETATime: [ this.load && this.load.etaToNextStopTime ? this.load.etaToNextStopTime : '' ],
      driverId: [ null ],
      driverPartnerId: [ null ],
      driverPhoneType: [ XPOConstants.trackingPhoneNumber ]
    },
      { validator: this.validDispatchForm.bind(this) }
    );
    return group;
  }

  private createFormForBookNow(load: Load) {
    const mandatoryValidatorField = (this.isFieldsMandatory) ? [ Validators.required ] : [];
    const driverPhoneValidatorForBookNowDispatch = (this.isNAUser && this.isFieldsMandatory) ?
      [ Validations.phone, Validators.required ] : [];
    const group = this.formBuilder.group({
      driverName: [ this.load && this.load.driverName ? this.load.driverName : '', mandatoryValidatorField ],
      driverSearchString: [ this.load && this.load.driverName ? this.load.driverName : '', mandatoryValidatorField ],
      driverPhoneNumber: [ this.load && this.load.driverPhoneNumber ?
        this.customPhoneFormatting.transform(this.load.driverPhoneNumber, this.authService.tenantId) : '',
        driverPhoneValidatorForBookNowDispatch ],
      email: [ { value: '', disabled: true }, [ Validations.email ] ],
      isTruckEmpty: [ this.load && this.load.hasOwnProperty('isTruckEmpty') &&
        this.isNAUser ? this.load.isTruckEmpty : false, mandatoryValidatorField ],
      trackingMethod: [ this.load && this.load.trackingMethodCode ? this.load.trackingMethodCode :
        (this.isNAUser ? this.trackingMethodTypeCode.dtm : ''), mandatoryValidatorField ],
      isDriverPhoneTrackable: [ this.load && this.load.hasOwnProperty('useDriverPhoneAsTracking') ?
        this.load.useDriverPhoneAsTracking : true ],
      trackingPhoneNumber: [ this.load && this.load.trackingPhoneNumber ? this.load.trackingPhoneNumber : '' ],
      euTruckNumber: [ this.load && this.load.truckNumber && !this.isNAUser ? this.load.truckNumber : '' ],
      naTruckNumber: [ this.load && this.load.truckNumber && this.isNAUser ? this.load.truckNumber : '', [ Validators.required ] ],
      naTruckNumberString: [ this.load && this.load.truckNumber && this.isNAUser ? this.load.truckNumber : '' ],
      truckLicenseCountry: [ this.load && this.load.truckLicenseCountry ? this.load.truckLicenseCountry : '' ],
      euTrailerNumber: [ this.load && this.load.trailerNumber && !this.isNAUser ? this.load.trailerNumber : '' ],
      naTrailerNumber: [ this.load && this.load.trailerNumber && this.isNAUser ? this.load.trailerNumber : '', [ Validators.required ] ],
      naTrailerNumberString: [ this.load && this.load.trailerNumber && this.isNAUser ? this.load.trailerNumber : '' ],
      trailerLicenseCountry: [ this.load && this.load.trailerLicenseCountry ? this.load.trailerLicenseCountry : '' ],
      deliveryLocation: [ this.load && this.load.deliveryLocation ? this.load.deliveryLocation : '', mandatoryValidatorField ],
      deliveryLocationString: [ this.load && this.load.deliveryLocation ? this.load.deliveryLocation : '', mandatoryValidatorField ],
      truckCurrentLocation: [ this.load && this.load.truckCurrentLocation ? this.load.truckCurrentLocation : '', mandatoryValidatorField ],
      truckCurrentLocationString: [ this.load && this.load.truckCurrentLocation ?
        this.load.truckCurrentLocation : '', mandatoryValidatorField ],
      truckEmptyTime: [ this.load && this.load.truckEmptyTime ? this.load.truckEmptyTime : '',
      this.load && this.load.isTruckEmpty ? [] : mandatoryValidatorField ],
      truckEmptyDate: [ this.load && this.load.truckEmptyDate ? this.load.truckEmptyDate : '',
      this.load && this.load.isTruckEmpty ? [] : mandatoryValidatorField ],
      pickUpETADate: [ this.load && this.load.etaToNextStopDate ? this.load.etaToNextStopDate : '' ],
      pickUpETATime: [ this.load && this.load.etaToNextStopTime ? this.load.etaToNextStopTime : '' ],
      driverId: [ null ],
      driverPartnerId: [ null ],
      driverPhoneType: [ XPOConstants.trackingPhoneNumber ]
    }, { validator: this.validDispatchForm.bind(this) }
    );
    return group;
  }

  private validDispatchForm(control: FormGroup) {
    if (this.isFieldsMandatory) {
      if (control.get('isTruckEmpty').value) {
        control.get('truckCurrentLocation').setValidators(Validators.required);
        control.get('truckCurrentLocationString').setValidators(Validators.required);
        control.get('deliveryLocation').setValidators(null);
        control.get('deliveryLocationString').setValidators(null);
      } else {
        control.get('truckCurrentLocation').setValidators(null);
        control.get('truckCurrentLocationString').setValidators(null);
        control.get('deliveryLocation').setValidators(Validators.required);
        control.get('deliveryLocationString').setValidators(Validators.required);
      }
    }
    if (control.get('isDriverPhoneTrackable').value) {
      control.get('trackingPhoneNumber').setValidators(null);
    } else {
      control.get('trackingPhoneNumber').setValidators(Validators.required);
    }
  }

  hasValidator(controlName: string) {
    const control = this.updateInfoForm.get(controlName);
    const validators = control.validator ? control.validator(control) : null;
    return !!(validators && validators.hasOwnProperty('required'));
  }

  onTrackingPhoneNoChange() {
    if (this.updateInfoForm.get('isDriverPhoneTrackable').value) {
      this.updateInfoForm.get('trackingPhoneNumber').setValue('');
      this.updateInfoForm.get('trackingPhoneNumber').markAsUntouched();
    }
  }

  setTrackingMethod(trackingCode) {
    this.updateInfoForm.get('naTruckNumber').disable();
    this.updateInfoForm.get('naTrailerNumber').disable();
    this.updateInfoForm.get('trackingMethod').setErrors(null);
    switch (trackingCode) {
      case this.trackingMethodTypeCode.truck:
        this.updateInfoForm.get('naTruckNumber').enable();
        if (this.isNAUser) {
          this.updateInfoForm.get('naTrailerNumber').setValue(this.currentTrailer);
          this.updateInfoForm.get('naTrailerNumberString').setValue(this.currentTrailer);
        } else {
          this.updateInfoForm.get('naTrailerNumber').setValue('');
          this.updateInfoForm.get('naTrailerNumberString').setValue('');
        }
        this.updateInfoForm.get('isDriverPhoneTrackable').setValue(true);
        this.updateInfoForm.get('trackingPhoneNumber').setValue('');
        break;
      case this.trackingMethodTypeCode.trailer:
        this.updateInfoForm.get('naTrailerNumber').enable();
        if (this.isNAUser) {
          this.updateInfoForm.get('naTruckNumber').setValue(this.currentTruckNumber);
          this.updateInfoForm.get('naTruckNumberString').setValue(this.currentTruckNumber);
        } else {
          this.updateInfoForm.get('naTruckNumber').setValue('');
          this.updateInfoForm.get('naTruckNumberString').setValue('');
        }
        this.updateInfoForm.get('isDriverPhoneTrackable').setValue(true);
        this.updateInfoForm.get('trackingPhoneNumber').setValue('');
        break;
      case this.trackingMethodTypeCode.mobile:

        if (this.isNAUser) {
          this.updateInfoForm.get('naTruckNumber').setValue(this.currentTruckNumber);
          this.updateInfoForm.get('naTruckNumberString').setValue(this.currentTruckNumber);
          this.updateInfoForm.get('naTrailerNumber').setValue(this.currentTrailer);
          this.updateInfoForm.get('naTrailerNumberString').setValue(this.currentTrailer);
        } else {
          this.updateInfoForm.get('naTruckNumber').setValue('');
          this.updateInfoForm.get('naTruckNumberString').setValue('');
          this.updateInfoForm.get('naTrailerNumber').setValue('');
          this.updateInfoForm.get('naTrailerNumberString').setValue('');
        }
        break;
      case this.trackingMethodTypeCode.dtm:
        if (this.isNAUser) {
          this.updateInfoForm.get('naTruckNumber').setValue(this.currentTruckNumber);
          this.updateInfoForm.get('naTruckNumberString').setValue(this.currentTruckNumber);
          this.updateInfoForm.get('naTrailerNumber').setValue(this.currentTrailer);
          this.updateInfoForm.get('naTrailerNumberString').setValue(this.currentTrailer);
        } else {
          this.updateInfoForm.get('naTruckNumber').setValue('');
          this.updateInfoForm.get('naTruckNumberString').setValue('');
          this.updateInfoForm.get('naTrailerNumber').setValue('');
          this.updateInfoForm.get('naTrailerNumberString').setValue('');
        }
        this.updateInfoForm.get('isDriverPhoneTrackable').setValue(true);
        this.updateInfoForm.get('trackingPhoneNumber').setValue('');
        break;
      default:
        break;
    }
  }

  changeTruckEmpty() {
    if (this.isFieldsMandatory && this.isDispatchInfoOptionalOrMandatoryCheckRequired && !this.updateInfoForm.get('isTruckEmpty').value) {
      this.isDeliverLocationMandatory = true;
    } else {
      this.isDeliverLocationMandatory = false;
    }
    this.updateInfoForm.get('deliveryLocation').setValue('');
    this.updateInfoForm.get('deliveryLocationString').setValue('');
    this.updateInfoForm.get('truckCurrentLocation').setValue('');
    this.updateInfoForm.get('truckCurrentLocationString').setValue('');
    this.updateInfoForm.get('pickUpETADate').setValue('');
    this.updateInfoForm.get('pickUpETATime').setValue('');
    this.updateInfoForm.get('truckEmptyDate').setValue('');
    this.updateInfoForm.get('truckEmptyTime').setValue('');
    this.updateInfoForm.get('truckEmptyDate').setErrors(null);
    this.updateInfoForm.get('truckEmptyTime').setErrors(null);
  }

  getDeliveryLocation() {
    this.updateInfoForm.get('deliveryLocationString').setErrors(null);
    if (!this.updateInfoForm.get('deliveryLocationString').value) {
      this.locations = [];
      this.updateInfoForm.get('deliveryLocation').setValue('');
      this.updateInfoForm.get('pickUpETADate').setValue('');
      this.updateInfoForm.get('pickUpETATime').setValue('');
    } else {
      this.loadBoardService.getLocationAutoComplete(this.updateInfoForm.get('deliveryLocationString').value).then(response => {
        this.locations = response;
      });
    }
  }

  getTruckCurrentLocation() {
    this.updateInfoForm.get('truckCurrentLocationString').setErrors(null);
    if (!this.updateInfoForm.get('truckCurrentLocationString').value) {
      this.currentLocations = [];
      this.updateInfoForm.get('truckCurrentLocation').setValue('');
      this.updateInfoForm.get('pickUpETADate').setValue('');
      this.updateInfoForm.get('pickUpETATime').setValue('');
    } else {
      this.loadBoardService.getLocationAutoComplete(this.updateInfoForm.get('truckCurrentLocationString').value).then(response => {
        this.currentLocations = response;
      });
    }
  }

  emptyDateSelected(dateSelected) {
    this.updateInfoForm.get('truckEmptyDate').setValue(dateSelected);
    this.updateInfoForm.get('truckEmptyDate').setErrors((!this.updateInfoForm.get('truckEmptyDate').value &&
      this.updateInfoForm.get('truckEmptyTime').value) ? { invalid: true } : null);
    this.updateInfoForm.get('truckEmptyTime').setErrors((!this.updateInfoForm.get('truckEmptyTime').value &&
      this.updateInfoForm.get('truckEmptyDate').value) ?
      { invalid: true } : null);
  }

  etaDateSelected(dateSelected) {
    this.updateInfoForm.get('pickUpETADate').setValue(dateSelected);
    this.updateInfoForm.get('pickUpETADate').setErrors(!this.updateInfoForm.get('pickUpETADate').value &&
      this.updateInfoForm.get('pickUpETATime').value ? { invalid: true } : null);
    this.updateInfoForm.get('pickUpETATime').setErrors(!this.updateInfoForm.get('pickUpETATime').value &&
      this.updateInfoForm.get('pickUpETADate').value ? { invalid: true } : null);
  }

  onSaveDispatcher() {
    this.isSaving = true;
    this.disableUploadBtn = true;
    if (this.updateInfoForm.value.driverId && this.isLoadScheduled) {
      this.driverStatusRequest.driverId = this.updateInfoForm.value.driverId;
      this.loadService.getDriverStatus(this.driverStatusRequest).subscribe(response => {
        if (response === this.driverStatus.noOverlap) {
          this.logKeystone(XPOConstants.keystoneEventName.noOverlap, XPOConstants.keystoneEventAction.submit);
          this.submitDispatchData();
        } else {
          this.dialogConfig.data = response;
          this.dialog.open(DriverStatusDialogComponent, this.dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              switch (result) {
                case this.userActions.continue:
                  this.logKeystone(XPOConstants.keystoneEventName.loadOverlap, XPOConstants.keystoneEventAction.submit);
                  this.submitDispatchData();
                  break;
                case this.userActions.assignNewDriver:
                  this.clearDriver(true);
                  this.logKeystone(XPOConstants.keystoneEventName.transitOverlap, XPOConstants.keystoneEventAction.assignNewDriver);
                  break;
                case this.userActions.cancel:
                  this.clearDriver(true);
                  const eventName = response === this.driverStatus.loadOverlap ?
                    XPOConstants.keystoneEventName.loadOverlap : XPOConstants.keystoneEventName.transitOverlap;
                  this.logKeystone(eventName, XPOConstants.keystoneEventAction.assignDriver);
                  break;
              }
            });
        }
      });
    } else {
      this.submitDispatchData();
    }

  }

  submitDispatchData() {
    this.globalService.orderDetails = null;
    this.globalService.driverDetails = null;
    if (!this.isDispatchInfoOptionalOrMandatoryCheckRequired) {
      this.callDispatcher();
    } else {
      this.formSubmitted = true;
      this.callDispatcherToStaging();
      this.onClose();
    }
  }

  onCloseDispatcher() {
    if (this.isDispatchInfoOptionalOrMandatoryCheckRequired) {
      this.openCancelConfimation();
    } else {
      this.onClose();
    }
  }

  openCancelConfimation() {
    this.dialogConfig.data = { loadNumber: this.load.number };
    this.dialog
      .open(CancelConfirmationDialogComponent, this.dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result.isDiscardChanges) {
          this.onClose();
        }
      });
  }

  onClose(isSkipForNow?: boolean) {
    let isDispatchInfoIncomplete: boolean;
    if (isSkipForNow || (this.isDispatchInfoOptionalOrMandatoryCheckRequired && !this.updateInfoForm.get('driverName').value
      && !this.updateInfoForm.get('driverPhoneNumber').value)) {
      isDispatchInfoIncomplete = true;
    } else {
      isDispatchInfoIncomplete = false;
    }
    this.dialogRef.close({
      isDispatchSaved: this.formSubmitted,
      dispatchDetails: this.loadInfo,
      isDispatchInfoIncomplete,
      isSkipForNow
    });
  }

  callDispatcherToStaging() {
    this.createDispatchInfoSave();
    if (this.loadInfo.driverName ||
      this.loadInfo.trackingMethodCode ||
      this.loadInfo.deliveryLocation ||
      this.loadInfo.truckEmptyDate ||
      this.loadInfo.truckEmptyTime
    ) {
      this.loadService.updateLoadInfoToStaging(this.loadInfo).then(response => {
      }).catch(error => {
        this.error = true;
      });
    }
  }

  callDispatcher() {
    this.createDispatchInfoSave();
    this.gaService.emitEvent(XPOConstants.eventCategory.assigned, XPOConstants.eventAction.dispatch, 'Dispatch info updated', 10);
    if (this.isNAUser) {
      if (this.isDispatchInfoOptionalOrMandatoryCheckRequired) {
        this.saveTenderDispatch();
      } else {
        this.loadService.updateLoadInfo(this.loadInfo).then(response => {
          this.saveTenderDispatch();
        }).catch(error => {
          this.error = true;
        });
      }
    } else {
      this.loadService.updateLoadInfo(this.loadInfo).then(response => {
        this.saveTenderDispatch();
      }).catch(error => {
        this.error = true;
      });
    }
  }

  saveTenderDispatch() {
    this.dispatchLoadTrackingInfo.trackingMethodInfo = this.updateInfoForm.value.trackingMethod;
    this.dispatchLoadTrackingInfo.page = this.page;
    this.dispatchLoadTrackingInfo.subPage = this.subPage;
    this.isSaving = false;
    this.disableUploadBtn = false;
    this.formSubmitted = true;
    if (this.callWoodApi) {
      this.loadInfo.responseStatusId = 1;
      this.loadTendersService.saveTenderDispatch(this.load.tenderGuid, this.loadInfo).then(result => {
      });
    }
    if (this.isDriverAssigned) {
      this.logKeystone(XPOConstants.keystoneEventName.updateDispatch, XPOConstants.keystoneEventAction.assignDriver);
    }
  }
  createDispatchInfoSave() {
    const updateInfoFormValue = this.updateInfoForm.value;
    this.loadInfo = new LoadInfoUpdate();
    this.loadInfo.isTruckEmpty = updateInfoFormValue.isTruckEmpty;
    this.loadInfo.trackingMethodCode = updateInfoFormValue.trackingMethod;
    this.loadInfo.useDriverPhoneAsTracking = updateInfoFormValue.isDriverPhoneTrackable;
    this.loadInfo.trackingPhoneNumber = updateInfoFormValue.trackingPhoneNumber;
    this.loadInfo.truckCurrentLocation = updateInfoFormValue.truckCurrentLocation;
    this.loadInfo.tripCode = this.load.alternateNumber;
    this.loadInfo.deliveryLocation = updateInfoFormValue.deliveryLocation;
    this.loadInfo.truckEmptyDate = updateInfoFormValue.truckEmptyDate ?
      this.datePipe.transform(updateInfoFormValue.truckEmptyDate, XPOConstants.dbDateFormat) : '';
    this.loadInfo.truckEmptyTime = updateInfoFormValue.truckEmptyTime;
    this.loadInfo.etaToNextStopDate = updateInfoFormValue.pickUpETADate ?
      this.datePipe.transform(updateInfoFormValue.pickUpETADate, XPOConstants.dbDateFormat) : '';
    this.loadInfo.etaToNextStopTime = updateInfoFormValue.pickUpETATime;
    this.loadInfo.driverPhoneNumber = updateInfoFormValue.driverPhoneNumber;
    this.loadInfo.driverName = updateInfoFormValue.driverName;
    this.loadInfo.truckLicenseCountry = updateInfoFormValue.truckLicenseCountry;
    this.loadInfo.truck = new TruckTrailerDeatils();
    this.loadInfo.truck.number = this.isNAUser ? updateInfoFormValue.naTruckNumber : updateInfoFormValue.euTruckNumber;
    this.loadInfo.truck.plateNumber = updateInfoFormValue.truckLicensePlate;
    this.loadInfo.truck.plateCountry = updateInfoFormValue.truckLicenseCountry;
    this.loadInfo.trailer = new TruckTrailerDeatils();
    this.loadInfo.trailer.number = this.isNAUser ? updateInfoFormValue.naTrailerNumber : updateInfoFormValue.euTrailerNumber;
    this.loadInfo.trailer.plateNumber = updateInfoFormValue.trailerLicensePlate;
    this.loadInfo.trailer.plateCountry = updateInfoFormValue.trailerLicenseCountry;
    this.loadInfo.trailerLicenseCountry = updateInfoFormValue.trailerLicenseCountry;
    this.loadInfo.respondedBy = this.authService.userProfile == null ?
      '' : this.authService.userProfile.firstName + ' ' + this.authService.userProfile.lastName;
    this.loadInfo.carrierCode = this.authService.partnerCode ? this.authService.partnerCode : '';
    this.loadInfo.tripId = this.load.id;
    this.loadInfo.orderCode = this.load.number;
    this.loadInfo.isTracking = false;
    this.loadInfo.emailSubject = this.emailSubject;
    this.loadInfo.emailRecipients = this.authService.userProfile ? this.authService.userProfile.email : '';
    this.loadInfo.frequency = XPOConstants.dispatchFrequency;
    this.loadInfo.driverPartnerId = updateInfoFormValue.driverPartnerId;
    this.loadInfo.driverId = updateInfoFormValue.driverId;
    this.loadInfo.newDriverPhoneNumberFieldCode = updateInfoFormValue.driverPhoneType;
    this.loadInfo.origin = this.dispatchOrigin;
    this.loadInfo.destination = this.dispatchDest;
    this.loadInfo.loadDate = this.dispatchPickupDate;
    this.loadInfo.isDriveXPO = this.isDriveXPODriver;
    const truckNumber = this.load && this.load.truckNumber ? this.load.truckNumber : '';
    const trailerNumber = this.load && this.load.trailerNumber ? this.load.trailerNumber : '';
    const driverPhoneNumber = this.load && this.load.driverPhoneNumber ?
      this.customPhoneFormatting.transform(this.load.driverPhoneNumber, this.authService.tenantId) : '';
    const truckNumberFormValue = this.isNAUser ? updateInfoFormValue.naTruckNumber : updateInfoFormValue.euTruckNumber;
    const trailerNumberFormValue = this.isNAUser ? updateInfoFormValue.naTrailerNumber : updateInfoFormValue.naTrailerNumber;
    if (truckNumber !== truckNumberFormValue ||
      trailerNumber !== trailerNumberFormValue ||
      driverPhoneNumber !== updateInfoFormValue.driverPhoneNumber) {
      this.loadInfo.isTracking = true;
    }

    if (this.isNAUser && this.loadInfo.trackingMethodCode !== this.trackingMethodTypeCode.truck) {
      this.loadInfo.truck.number = this.currentTruckNumber;
    }
    if (this.isNAUser && this.loadInfo.trackingMethodCode !== this.trackingMethodTypeCode.trailer) {
      this.loadInfo.trailer.number = this.currentTrailer;
    }
  }

  searchDriver(name: string, populateData?: boolean, driverId?: number) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.loadService.getDriverList(name).subscribe(response => {
      this.driverList = response;
      this.isNoDriver = this.driverList.length ? false : true;
      if (populateData && this.driverList.length) {
        const filteredData = this.driverList.filter(driver => driver.driverId === driverId);
        if (filteredData.length) {
          this.onSelectDriver(filteredData[ 0 ]);
          this.updateInfoForm.get('driverSearchString').setValue(name);
          this.updateInfoForm.get('driverPhoneNumber').setValue(this.load.driverPhoneNumber ?
            this.customPhoneFormatting.transform(this.load.driverPhoneNumber, this.authService.tenantId) : '');
          this.isDriverAssigned = false;
        } else {
          this.driverList = [];
        }
      }
    });
  }

  populateDriver(name: string, driverId?: number) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.loadService.getDriverList(name).subscribe(response => {
      this.driverList = response;
      this.isNoDriver = this.driverList.length ? false : true;
      if (this.driverList.length) {
        const filteredData = this.driverList.filter(driver => driver.driverId === driverId);
        if (filteredData.length) {
          this.autoComplete.closePanel();
          this.updateInfoForm.get('driverSearchString').setValue(name);
          this.onSelectDriver(filteredData[ 0 ]);
        }
      }
    });
  }

  clearTruck() {
    if (!this.updateInfoForm.get('naTruckNumberString').value) {
      this.updateInfoForm.get('naTruckNumberString').setErrors({ invalid: true });
    } else if (this.updateInfoForm.get('naTruckNumber').value !== this.updateInfoForm.get('naTruckNumberString').value) {
      this.updateInfoForm.get('naTruckNumberString').setErrors({ invalid: true });
    }
    if (this.isNAUser && this.updateInfoForm.get('naTruckNumberString').value === this.currentTruckNumber) {
      this.updateInfoForm.get('naTruckNumberString').setErrors(null);
      this.updateInfoForm.get('naTruckNumber').setValue(this.currentTruckNumber);
    }
  }

  clearDeliveryLocation() {
    if (this.updateInfoForm.get('deliveryLocationString').value &&
      (this.updateInfoForm.get('deliveryLocation').value !== this.updateInfoForm.get('deliveryLocationString').value)) {
      this.updateInfoForm.get('deliveryLocationString').setErrors({ invalid: true });
    }
  }

  cleartruckCurrentLocation() {
    if (this.updateInfoForm.get('truckCurrentLocationString').value &&
      (this.updateInfoForm.get('truckCurrentLocation').value !== this.updateInfoForm.get('truckCurrentLocationString').value)) {
      this.updateInfoForm.get('truckCurrentLocationString').setErrors({ invalid: true });
    }
  }

  clearTrailer() {
    if (!this.updateInfoForm.get('naTrailerNumberString').value) {
      this.updateInfoForm.get('naTrailerNumberString').setErrors({ invalid: true });
    }
    if (this.updateInfoForm.get('naTrailerNumberString').value &&
      (this.updateInfoForm.get('naTrailerNumber').value !== this.updateInfoForm.get('naTrailerNumberString').value)) {
      this.updateInfoForm.get('naTrailerNumberString').setErrors({ invalid: true });
    }
    if (this.isNAUser && this.updateInfoForm.get('naTrailerNumberString').value === this.currentTrailer) {
      this.updateInfoForm.get('naTrailerNumberString').setErrors(null);
      this.updateInfoForm.get('naTrailerNumber').setValue(this.currentTrailer);
    }
  }

  clearDriver(clearData?: boolean) {
    if (!this.updateInfoForm.get('driverName').value) {
      if ((!this.isDispatchInfoOptionalOrMandatoryCheckRequired) ||
        (this.isDispatchInfoOptionalOrMandatoryCheckRequired && this.isFieldsMandatory)) {
        this.updateInfoForm.get('driverSearchString').setErrors({ invalid: true });
      }
    }
    if (this.updateInfoForm.get('driverName').value !== this.updateInfoForm.get('driverSearchString').value || clearData) {
      if ((!this.isDispatchInfoOptionalOrMandatoryCheckRequired) ||
        (this.isDispatchInfoOptionalOrMandatoryCheckRequired && this.isFieldsMandatory)) {
        this.updateInfoForm.get('driverSearchString').setErrors({ invalid: true });
      }
      this.updateInfoForm.get('driverName').setValue('');
      this.updateInfoForm.get('driverId').setValue(null);
      this.updateInfoForm.get('driverPartnerId').setValue(null);
      this.updateInfoForm.get('driverPhoneNumber').setValue(null);
      this.isDriverAssigned = false;
    }
    if (clearData) {
      this.updateInfoForm.get('driverSearchString').setValue('');
    }
    if (this.isDispatchInfoOptionalOrMandatoryCheckRequired &&
      !this.updateInfoForm.get('driverSearchString').value && !this.updateInfoForm.get('driverName').value) {
      this.updateInfoForm.get('driverPhoneNumber').setValue(null);
      this.updateInfoForm.get('driverPhoneNumber').clearValidators();
      this.updateInfoForm.get('driverPhoneNumber').updateValueAndValidity();
    }
  }

  createDriver() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.createDriverFromDispatch,
      XPOConstants.keystoneEventAction.initiate);
    this.keystoneService.logEvent(logEvent);
    this.dialogConfig.data = {
      action: XPOConstants.userActions.create,
      driver: ''
    };
    this.dialog
      .open(AddNewDriverComponent, this.dialogConfig).afterClosed().subscribe(driverData => {
        const name = driverData.firstName + ' ' + driverData.lastName;
        this.populateDriver(name, driverData.driverId);
      });
  }

  createTruckTrailer(equipmentTypeCode: string) {
    this.dialogConfig.data = {
      action: XPOConstants.userActions.create,
      equipmentDetails: '',
      equipmentTypeCode
    };
    this.dialog
      .open(AddEquipmentFormComponent, this.dialogConfig)
      .afterClosed()
      .subscribe((isEquipmentSaved) => {
        this.getTruckTrailer('', equipmentTypeCode);
      });
  }

  onSelectTruck(truckDetails) {
    this.updateInfoForm.get('naTruckNumber').setValue(truckDetails.equipmentNumber);
  }

  onSelectDeliveryLocation(location) {
    this.updateInfoForm.get('deliveryLocation').setValue(location.label);
  }

  onSelectCurrentTruckLocation(location) {
    this.updateInfoForm.get('truckCurrentLocation').setValue(location.label);
  }

  onSelectTrailer(trailerDetails) {
    this.updateInfoForm.get('naTrailerNumber').setValue(trailerDetails.equipmentNumber);
  }

  onSelectDriver(driver: DriverDetails) {
    this.isDriveXPODriver = driver.isDriveXPO;
    this.phoneNumberList = [];
    this.trackignPhoneNumberList = [];
    if (driver.phones) {
      for (const phone of driver.phones) {
        phone.phoneNumber = this.customPhoneFormatting.transform(
          (!this.isDriveXPODriver && !this.isNAUser) ? phone.nationalPhoneNumber : phone.phoneNumber, this.authService.tenantId);
      }
    }
    this.phoneNumberList = driver.phones;
    this.trackignPhoneNumberList = driver.phones.length ?
      (driver.phones.filter(phone => phone.phoneTypeCode === this.defaultPhoneType)) : [];

    if (!this.isNAUser || this.phoneNumberList.length === 1) {
      this.updateInfoForm.get('driverPhoneNumber').setValue(
        this.phoneNumberList[ 0 ] ? this.phoneNumberList[ 0 ].phoneNumber : '');
    }
    this.updateInfoForm.get('driverName').setValue(driver.name);
    this.updateInfoForm.get('driverId').setValue(driver.driverId);
    this.updateInfoForm.get('driverPartnerId').setValue(driver.driverPartnerId);
    this.updateInfoForm.get('email').setValue(driver.emailAddress);
    this.isDriverAssigned = true;
  }

  logKeystone(eventName, eventAction) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction, this.page);
    this.keystoneService.logEvent(logEvent);
  }

  formatDriverPhoneNo() {
    const updateInfoForm = this.updateInfoForm.value;
    this.updateInfoForm.get('driverPhoneNumber')
      .setValue(this.customPhoneFormatting.transform(updateInfoForm.driverPhoneNumber, this.authService.tenantId));
  }

  getTruckTrailer(searchText: string, equipmentTypeCode: string) {
    const equipmentListRequest = new EquipmentListRequest(searchText, equipmentTypeCode, 1, 10);
    this.assetManagementService.getequipmentList(equipmentListRequest).subscribe(response => {
      if (equipmentTypeCode === XPOConstants.equipmentTypeCode.truck) {
        this.truckList = response.equipments;
      } else {
        this.trailerList = response.equipments;
      }
    });
  }

}
