<div mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="text-bold-uppercase" [translate]="'delete1989'"> delete equipment
    </div>
    <div class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </div>
  </div>
</div>
<div>
  <div *ngIf="!isEquipmentDeleted; else deleteConfirm">
    <mat-dialog-content>
      <p>
        <span [translate]="'select2961'">
          You have selected to delete
        </span>
        <b class="text-capitalize"> {{equipmentDetails.equipmentNumber}}.</b>
      </p>
      <p>
        <span [translate]="'delete5030'">
          Are you sure you want to delete this
        </span>
        <span>&nbsp;{{'equipment6372' | translate}}?</span>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="padding-bottom-20">
        <button mat-raised-button class="text-uppercase secondary-btn" (click)="onClose()">
          {{'no9227' | translate}}
        </button>
        <button mat-raised-button class="text-uppercase" color="accent" (click)="deleteEquipment()">
          {{'yes7992' | translate}}
        </button>
      </div>
    </mat-dialog-actions>
  </div>
  <ng-template #deleteConfirm>
    <mat-dialog-content>
      <b class="xpo-capitalize">{{equipmentDetails.equipmentNumber}}&nbsp;</b>
      <span [translate]="'hasBee7458'">has been deleted!</span>
    </mat-dialog-content>
  </ng-template>
</div>