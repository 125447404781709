import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HomeLocation, TruckLoad } from 'src/app/shared/model';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { DashboardData } from '../models/dashboard-data';
import { CarrierDetails } from 'src/app/shared/model/carrier-details';
import { SupportRequest } from 'src/app/shared/model/support-request';
import { LoadsWidgetCount } from '../models/loads-widget-count';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUrl: string;
  public showPickUpDatePickerPopover: boolean;
  public showDeliveryDatePickerPopover: boolean;
  public selectedPickUpDateFeild: string;
  public selectedDeliveryDateFeild: string;
  public isFromDashboard: boolean;
  public partnerIdentifier: string;
  public request = {
    deliveryDate: {
      min: '',
      max: ''
    },
    pickupDate: {
      min: '',
      max: ''
    },
    q: '',
    status: [],
    page: null,
    pageSize: null
  };

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService) {
    this.baseUrl = appSettings.apiUrl;
    this.partnerIdentifier = authService.partnerIdentifier;
  }

  public findDriver(search): Observable<any> {
    const url = this.baseUrl + 'drivers/basicinformation';
    let params = new HttpParams();
    params = params.append('searchText', search);
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getPickupData(request): Promise<DashboardData> {
    const url = this.getUri('scheduledloads');
    return this.http.post<DashboardData>(url, request, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  getDeliveryData(request): Promise<DashboardData> {
    const url = this.getUri('inprogressloads');
    return this.http.post<DashboardData>(url, request, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  getTruckLoads(request): Observable<Array<TruckLoad>> {
    const url = this.getUri('activeloads');
    return this.http.post<Array<TruckLoad>>(url, request, this.getOptions())
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  getAvailableLoadsCount(availLoadCountRequest): Promise<Array<any>> {
    const url = `${this.baseUrl}availableloads/loadaggregation`;
    return this.http.post<Array<any>>(url, availLoadCountRequest,
      { headers: this.getHeaders().append('skipRedirect', 'true'), withCredentials: true })
      .toPromise()
      .then(response => {
        return response;
      });
  }

  getHomeLocation(): Promise<CarrierDetails> {
    const url = this.getUri(this.partnerIdentifier + '/details');
    return this.http.get<HomeLocation>(url, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  getCarrierDetails(partnerId?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('skipRedirect', 'true');
    const partnerIdentifier = partnerId || this.partnerIdentifier;
    const url = this.getUri('profile?partnerIdentifier=' + partnerIdentifier);
    return this.http.get(url, { headers })
      .pipe(response => response,
        catchError(this.handleError)
      );
  }

  sendSupportMail(supportRequestObj: SupportRequest): Observable<number> {
    const url = this.getUri('support');
    return this.http
      .post(url, supportRequestObj, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getLoadsWidgetCount(): Promise<LoadsWidgetCount> {
    const url = this.baseUrl + 'loads/count';
    return this.http
      .get(url)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(this.handleError);
  }

  sendCarrierFeedback(request): Promise<any> {
    const url = this.getUri('feedback');
    return this.http.post(url, request, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);
  }

  getBidsTenderCount(): Observable<any> {
    const url = this.getUri(`tenders/active/count`);
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getKpiFilterType(): Observable<any> {
    const url = `${this.baseUrl}lookups/carrier/kpifiltertype`;
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getKpiDetails(selecteFilterType: string): Observable<any> {
    const url = this.getUri(`kpidetails`);
    let params = new HttpParams();
    params = params.append('range', selecteFilterType);
    return this.http
      .get(url, this.getOptions(params))
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getLoadList(loadFilter): Observable<any> {
    const url = this.baseUrl + `loads/active/summary`;
    return this.http.post<Array<any>>(url, loadFilter, this.getOptions())
      .pipe((response) => {
        response = response;
        return response;
      }, catchError(this.handleError));
  }

  getTrackCount(dispatchCountReq): Observable<any> {
    const url = this.baseUrl + `availableloads/offers/count`;
    return this.http.post<Array<any>>(url, dispatchCountReq, this.getOptions())
      .pipe((response) => {
        response = response;
        return response;
      }, catchError(this.handleError));
  }

  getCarrierRatings(selelectedPeriod): Observable<any> {
    const url = this.getUri(`carrierratings`);
    let params = new HttpParams();
    params = params.append('range', selelectedPeriod);
    return this.http
      .get(url, this.getOptions(params))
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getRewards(): Observable<any> {
    const url = this.getUri(`${this.partnerIdentifier}/loyalty`);
    return this.http.get(url, this.getOptions())
      .pipe(response => response,
        catchError(this.handleError)
      );
  }


  private getHeaders(obj?: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getOptions(params?: HttpParams) {
    const options = { headers: this.getHeaders(), params, withCredentials: true };
    return options;
  }

  // Constructrs the uri to use for the endpoint
  private getUri(endpoint?: string): string {
    return this.baseUrl + 'carrierdashboard/' + endpoint;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
