import { Component, OnInit, OnDestroy } from '@angular/core';
import { XPOConstants } from '../../xpo-constants';
import { LogEvent } from '../../model/log-event';
import { DriverData } from '../../model/driver';
import { KeystoneService } from '../../services/keystone.service';
import { AppSettings } from 'src/app/core/app-settings';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: [ './create-driver.component.scss' ]
})
export class CreateDriverComponent implements OnInit, OnDestroy {
  public dialogAction: string;
  public createMethodList: any;
  public selectedCreateMethod: string;
  public createFormFlag = true;
  public driverDetails: DriverData;
  public savedDataAction: string;
  public userActions: any;
  public intervalTimer: any;

  constructor(
    private dialogRef: MatDialogRef<CreateDriverComponent>,
    private appsettings: AppSettings,
    private keystoneService: KeystoneService,
    private translateService: TranslateService) {
    this.dialogRef.disableClose = true;
    this.translateService.get('enterM2825').subscribe((value) => {
      this.createMethodList = [ {
        name: this.translateService.instant('enterM2825'),
        code: '1'
      },
      {
        name: this.translateService.instant('sendEm4788'),
        code: '2'
      },
      {
        name: this.translateService.instant('upload7120'),
        code: '3'
      }
      ];
      this.selectedCreateMethod = this.createMethodList[ 0 ].code;
    });
    this.dialogAction = this.dialogRef._containerInstance._config.data.action;
    this.driverDetails = this.dialogRef._containerInstance._config.data.driver ?
      this.dialogRef._containerInstance._config.data.driver : null;
    this.userActions = XPOConstants.userActions;
  }

  ngOnInit() {
  }

  selectMethod() {
    switch (this.selectedCreateMethod) {
      case '1': this.createFormFlag = true;
    }
  }

  onClose() {
    const keystoneEventName = (this.dialogAction === this.userActions.edit) ? XPOConstants.keystoneEventName.editDriver
      : XPOConstants.keystoneEventName.createDriver;
    const keystoneEventAction = (this.savedDataAction === this.userActions.confirm) ? XPOConstants.keystoneEventAction.submit
      : XPOConstants.keystoneEventAction.cancel;
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName,
      keystoneEventAction, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.driver);
    this.keystoneService.logEvent(logEvent);
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.dialogRef.close(this.savedDataAction);
  }

  onCancel(savedDataAction) {
    this.savedDataAction = savedDataAction;
    if (savedDataAction !== this.userActions.confirm) {
      this.onClose();
    }
    this.intervalTimer = setTimeout(() => {
      this.onClose();
    }, XPOConstants.confirmationTimeoutInterval);
  }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  }
}
