import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout/layout.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AppSettings, configAppSettings } from './app-settings';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SupportFormComponent } from './support-form/support-form.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { CustomLiveChatWidgetDirective } from './custom-live-chat-widget/custom-live-chat-widget.directive';
import { AdminNotificationsComponent } from './admin-notifications/admin-notifications.component';
import { MainMenuComponent } from './navbar/main-menu/main-menu.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { EuSupportFormComponent } from './eu-support-form/eu-support-form.component';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { SwitchCarrierComponent } from './my-account/switch-carrier/switch-carrier.component';

@NgModule({
  declarations: [
    LayoutComponent,
    NavbarComponent,
    ContactUsComponent,
    SupportFormComponent,
    MyAccountComponent,
    FeedbackFormComponent,
    CustomLiveChatWidgetDirective,
    AdminNotificationsComponent,
    MainMenuComponent,
    BreadCrumbComponent,
    EuSupportFormComponent,
    SwitchCarrierComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CustomMaterialModule,
    SharedModule,
    HttpClientModule,
    RouterModule
  ],
  exports: [
    LayoutComponent
  ],
  entryComponents: [
    SupportFormComponent,
    FeedbackFormComponent,
    AdminNotificationsComponent,
    EuSupportFormComponent,
    SwitchCarrierComponent
  ],
  providers: [
    AppSettings,
    {
      provide: APP_INITIALIZER,
      useFactory: configAppSettings,
      deps: [ AppSettings ],
      multi: true
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
