import { Component, OnInit } from '@angular/core';
import { LogEvent } from 'src/app/shared/model';
import { XPOConstants } from 'src/app/shared';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KeystoneService } from 'src/app/shared/services';
import { LoyaltyService } from 'src/app/shared/services/loyalty.service';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-upgrade-notification',
  templateUrl: './upgrade-notification.component.html',
  styleUrls: [ './upgrade-notification.component.scss' ]
})
export class UpgradeNotificationComponent implements OnInit {
  public color: any = 'bronze';
  public upgradeDetail: any;
  public lastMonthLoads: any;
  public minimumScore: any;
  public isLoading: boolean;
  public username: string;
  public tenandId: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private keystoneService: KeystoneService,
    private dialogRef: MatDialogRef<UpgradeNotificationComponent>,
    private loyaltyService: LoyaltyService) {
    this.dialogRef.disableClose = true;
    this.tenandId = this.authService.tenantId;
    this.isLoading = true;
    const userProfile = this.authService.userProfile || {};
    this.username = userProfile.username;
    this.getUpgradeDetails();
  }

  ngOnInit() {
  }

  navigatetoLoyalty() {
    this.upgradeInfoUpdate();
    this.router.navigate([ '/rewards-program' ]);
    this.logKeystone(XPOConstants.keystoneEventName.viewDetails, XPOConstants.keystoneEventAction.navToRewards);
    this.dialogRef.close();
  }

  onClose() {
    this.upgradeInfoUpdate();
    this.logKeystone(XPOConstants.keystoneEventName.close, XPOConstants.keystoneEventAction.upgradeNotificationClosed);
    this.dialogRef.close();

  }

  getUpgradeDetails() {
    this.loyaltyService.getUpgradeDetails().subscribe(response => {
      this.isLoading = false;
      this.upgradeDetail = response ? response : '';
      this.color = this.upgradeDetail.loyaltyLevelName ? this.upgradeDetail.loyaltyLevelName.toLowerCase() : '';
    });
  }

  upgradeInfoUpdate() {
    const upgradeInfo = {
      loyaltyAlertTypeCode: 'UN',
      username: this.username,
    };
    this.loyaltyService.upgradeInfoUpdate(upgradeInfo).subscribe();
  }
  logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const logEvent = new LogEvent(
      XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction, XPOConstants.keystonePage.upgradeNotification);
    this.keystoneService.logEvent(logEvent);
  }

}
