export let weatherAlerts: any;
export let trafficAlertItems: any;
export let notificationsCount = 0;
export let notificationElement: any;
export let notificationDotElement: any;
export let laneDrillLocInfo: any;
export let laneDrillDate: any;
export let isNotificationsDismissed: boolean;

export function setWeatherAlerts(weather: any) {
    weatherAlerts = weather;
}

export function setTrafficAlerts(traffic: any) {
    trafficAlertItems = traffic;
}

export function setNotificationsCount(num: number) {
    notificationsCount = num;
}

export function addNotificationsCount(num: number) {
    notificationsCount += num;
}

export function setNotificationElement(element: any) {
    notificationElement = element;
}

export function setNotificationDotElement(element: any) {
    notificationDotElement = element;
}

export function setLaneDrillLocInfo(obj: any) {
    laneDrillLocInfo = obj;
}

export function setLaneDrillDate(obj: any) {
    laneDrillDate = obj;
}

export function setNotificationDismissed(value: boolean) {
    isNotificationsDismissed = value;
}
