<div *ngIf="onProcessing; else placebidMsg">
  <app-page-loader></app-page-loader>
</div>
<ng-template #placebidMsg>
  <div class="bid-dialog-container">
    <form #bidForm="ngForm">
      <div mat-dialog-title>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>
            <span *ngIf="getBidAmount; else status_message" class="text-bold-uppercase"
              [translate]="'placeBid8521'">place
              bid</span>
            <ng-template #status_message>
              <span *ngIf="(!onProcessing && isSubmitted && isError); else confirmation_header"
                class="text-bold-uppercase" [translate]="'bidunderrev2512'">bid
                under review</span>
              <ng-template #confirmation_header>
                <div *ngIf="!onProcessing" [ngSwitch]="currentStatus">
                  <span *ngSwitchCase="bidStatuses.accepted" class="text-bold-uppercase"
                    [translate]="'bidaccepted6522'">bid
                    accepted</span>
                  <span *ngSwitchCase="bidStatuses.rejected" class="text-bold-uppercase"
                    [translate]="'bidunderrev2512'">bid
                    under review</span>
                  <div *ngSwitchCase="bidStatuses.noStatus">
                    <span *ngIf="errorType === 1" class="text-bold-uppercase" [translate]="'loadunavail227'">
                      load unavailable</span>
                    <span *ngIf="errorType !== 1" class="text-bold-uppercase" [translate]="'unable1385'">unable
                      to place the bid</span>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </span>
          <span *ngIf="!onProcessing" class="dialog-close-icon">
            <i class="icon-close" (click)="onClose()"></i>
          </span>
        </div>
        <div class="buy-head text-bold" *ngIf="getBidAmount && isNAUser && !load.isBidOnly">
          <span [translate]="'buyitNowfor5023'">Buy it Now for: </span>
          {{buyPrice | xpocCurrency: currency: true : tenandId}}
        </div>
        <div class="buy-head text-bold" *ngIf="!isNAUser && getBidAmount && false">
          <span [translate]="'xPOind3510'">XPO Indicative Price</span>
          <span>:</span>
          {{buyPrice | xpocCurrency: priceCurrency: true : tenandId}}
        </div>
      </div>
      <mat-dialog-content>
        <div *ngIf="getBidAmount; else status_content" class="content-block">
          <div class="form-content">
            <div fxLayout="row" fxLayoutGap="15px">
              <div fxFlex="50%">
                <div class="label" [translate]="'amount7002'">Amount</div>
                <mat-form-field class="form-field-input">
                  <input matInput [(ngModel)]="bidAmount" class="txtField" id="bid-amount" (keyup)="convertAmount()"
                    name="bidAmount" #bidControl="ngModel" required [pattern]="currencyPattern"
                    (blur)="onBidAmountChange(bidAmount)">
                  <mat-error *ngIf="bidControl.errors && bidControl.errors.required">
                    <span [translate]="'amountisreq8810'">Amount is
                      required</span>
                  </mat-error>
                  <mat-error *ngIf="bidControl.errors && bidControl.errors.pattern">
                    <span [translate]="'amountisinv3626'">Amount is
                      invalid</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="50%" class="currency">
                <div class="currency-label label" [translate]="'currency1783'">
                  Currency
                </div>
                <mat-form-field class="form-field-input">
                  <mat-select name="bidCurrency" [(ngModel)]="currency" disabled>
                    <mat-option *ngFor="let c of currencies" value={{c.code}} selected>
                      {{c.symbol}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" *ngIf="isBidRestricted && isNAUser">
              <span class="error-message">
                {{validationMsg}}
              </span>
            </div>
            <div class="converted-block" *ngIf="convertedBidAmount && preferredCurrency != defaultCurrency">
              <div fxLayout="row" fxLayoutGap="15px" matTooltip="{{currencyMsg}}" [matTooltipPosition]="'below'">
                <mat-form-field fxFlex="50%" class="pair-child converted-data form-field-input">
                  <span class="label" [translate]="'estima5514'">Estimated
                    Conversion</span>
                  <input matInput [(ngModel)]="convertedBidAmount" name="estimatedCurrency" class="estimated-input"
                    id="converted-bid-amount" readonly />
                </mat-form-field>
                <mat-form-field fxFlex="50%" class="pair-child currency converted-data form-field-input">
                  <span class="label" [translate]="'prefer9503'">Preferred
                    Currency</span>
                  <input matInput [(ngModel)]="preferredCurrencySymbol" name="preferredCurrencySymbol"
                    class="estimated-input" id="converted-bid-symbol" readonly />
                </mat-form-field>
              </div>
            </div>
          </div>
          <app-load-requirements [load]="load" [isValidationRequired]=true [isUnAuthenticated]="isUnauthenticated"
            [showRedeemOption]="showRedeemOption" [checkboxValidationMsg]="checkboxMsg"
            (confirm)="confirmAction($event)">
          </app-load-requirements>
          <div class="content"></div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end" *ngIf="getBidAmount" class="dialog-btn">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
          <button type="button" mat-raised-button class="text-uppercase secondary-btn"
            (click)="onClose()">{{'cancel7093' |
            translate}}</button>
          <button type="button" mat-raised-button class="text-uppercase" color="accent" (click)="placeBid()"
            [disabled]="!bidForm.valid || onProcessing || actionConfirmed || isBidRestricted">
            {{'submitbid5200' | translate}}
          </button>
        </div>
      </mat-dialog-actions>
    </form>
    <ng-template #status_content>
      <div *ngIf="!onProcessing">
        <div class="form-data" *ngIf="isSubmitted && !isError">
          <p *ngIf="currentStatus === bidStatuses.accepted; else rejectedBid">
            <span *ngIf="isCreditToRedeem || hasAlreadyRedeemed" [translate]="'reward5436'">
              Rewards credit redeemed! </span><span *ngIf="isCreditToRedeem || hasAlreadyRedeemed">! </span>
            <span>{{'thankyoufor1913' | translate}}
            </span><span> </span>
            <b>
              <span [translate]="'loadNo3662'">Load # </span>
              <span> {{loadNumber}}</span>
            </b>.
            <span [translate]="'yourbi1977'"> Your bid is
              accepted.</span>
          </p>
          <ng-template #rejectedBid>
            <div *ngIf="currentStatus === bidStatuses.rejected; else noStatus">
              <span>{{'thankyoufor1913' | translate}}
              </span><span> </span>
              <b>
                <span [translate]="'loadNo3662'">Load # </span><span> {{loadNumber}}</span></b>.
              <p>
                <span *ngIf="isCreditToRedeem || hasAlreadyRedeemed" [translate]="'reward7009'"> Rewards credit will be
                  redeeemed if you are
                  assigned the load.</span>
                <span> </span>
                <span [translate]="'yourbi969'"> Your bid is currently
                  under review. Please check the "Bids" screen or your email for an update soon.</span>
              </p>
            </div>
            <ng-template #noStatus>
              <div *ngIf="errorType === 1">
                <span [translate]="'weapologize2732'">We
                  apologize, </span>
                <b>
                  <span [translate]="'loadNo3662'">Load # </span>
                  <span> {{loadNumber}} </span></b>
                <span [translate]="'isnolongera3530'"> is no
                  longer available</span>
              </div>
              <div *ngIf="errorType === 2">
                <span [translate]="'abidha3535'">A bid has already
                  been placed on this load and XPO Logistics has provided a counteroffer.</span>
              </div>
              <div *ngIf="errorType === 6">
                <span [translate]="'abidha3806'">A bid has already been placed on this
                  load.</span>
              </div>
              <div *ngIf="errorType === 7">
                <span>Sorry, you did not match the requirements
                  and hence were unassigned earlier from this load {{loadNumber}}.</span>
              </div>
            </ng-template>
          </ng-template>
        </div>
        <div class="form-data" *ngIf="isSubmitted && isError">
          <p>
            <span [translate]="'thankyoufor1913'">Thank you for placing a bid on
            </span>
            <b>
              <span [translate]="'loadNo3662'">Load # </span>
              {{loadNumber}}</b>.
            <span> </span>
            <span [translate]="'yourbi969'"> Your bid is
              currently under review. Please check the "Bids" screen or your email for an update soon.</span>
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>