import { Component, OnInit, Input } from '@angular/core';
import { XPOConstants } from '../../xpo-constants';
import { Stop } from '../../model';

@Component({
  selector: 'app-stops-tab',
  templateUrl: './stops-tab.component.html',
  styleUrls: [ './stops-tab.component.scss' ]
})
export class StopsTabComponent implements OnInit {

  @Input()
  public stops: Stop[];
  public dateTimeFormat: string;

  constructor() {
    this.stops = [];
  }

  ngOnInit() {
    this.dateTimeFormat = XPOConstants.dateTime12HrFormat;
  }

}
