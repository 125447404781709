<article *ngIf="contactInfo?.contacts">
  <div class="margin-top-bottom-20 message">
    {{contactInfo.message | translate}}
  </div>
  <div class="full-width">
    <mat-chip-list fxLayout="row" fxLayoutAlign="center center">
      <mat-chip color="{{contactInfo.chipColor}}"
        *ngFor="let item of contactInfo.contacts | slice: 0 : contactInfo.minimumCount">
        <span *ngIf="item.countryCode">{{item.countryCode }}</span>&nbsp;<span class="text-overflow-ellipsis">
          {{item.contact}}</span>
      </mat-chip>
      <button *ngIf="contactInfo.minimumCount && (contactInfo.contacts.length > minimumCountofItems)" mat-icon-button
        color="accent" id="show-more-btn" (click)="contactInfo.minimumCount = undefined">
        +{{contactInfo.contacts.length-contactInfo.minimumCount}}
      </button>
    </mat-chip-list>
  </div>
</article>