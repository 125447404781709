<div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="!isLoading; else loader" fxLayoutGap="10px">
  <mat-dialog-content class="upgrade-content">
    <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px">
      <img src="assets/img/upgrade-status.jpg" class="img" />
      <div fxFlex="20%" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="text-capitalize" [translate]="'congratulat7797'">congratulations!</h2>
        <div class="text-bold" [translate]="'youhav3618'">You've been upgraded to <span
            class="{{color | lowercase }}-color">&nbsp;{{upgradeDetail.loyaltyLevelName}}!</span> </div>
      </div>
      <div fxFlex="25%" fxLayout="column" fxLayoutGap="5px">
        <div class="text-bold-capitalize" [translate]="'requir9140'">requirements</div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-background'" class="rating-data">
          <div class="text-bold-capitalize color-white">average monthly loads</div>
          <div class="text-bold-uppercase color-white" *ngIf="upgradeDetail.criterias.lastMonthLoads; else noValue">
            {{upgradeDetail.criterias.lastMonthLoads}}</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-background'" class="rating-data">
          <div class="text-bold-capitalize color-white" [translate]="'minimu1722'">minimum carrier score</div>
          <div class="text-bold-uppercase color-white" *ngIf="upgradeDetail.criterias.minimumScore; else noValue">
            {{upgradeDetail.criterias.minimumScore}}</div>
        </div>
      </div>
      <div fxFlex="25%" fxLayout="column" fxLayoutGap="5px">
        <div class="text-bold-capitalize" [translate]="'benefi5440'">benefits</div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-light-background'"
          class="rating-data">
          <div class="text-bold-capitalize" [translate]="'status7330'">status bonus</div>
          <div class="text-bold-uppercase" *ngIf="upgradeDetail.benefits.statusBonus.totalBonus!==0; else noValue">
            {{upgradeDetail.benefits.statusBonus.totalBonus | xpocCurrency:
            upgradeDetail.benefits.statusBonus.measureCode : true : tenandId: '1.0-0'}}
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-light-background'"
          class="rating-data">
          <div class="text-bold-capitalize">rewards tier bonus</div>
          <div class="text-bold-uppercase" *ngIf="upgradeDetail.benefits.quarterlyBonus.totalBonus!=='0'; else noValue">
            {{upgradeDetail.benefits.quarterlyBonus.totalBonus | xpocCurrency:
            upgradeDetail.benefits.quarterlyBonus.measureCode : true : tenandId: '1.0-0'}}
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-light-background'"
          class="rating-data">
          <div class="text-bold-capitalize" [translate]="'extraF244'">Extra Fuel Savings</div>
          <div class="text-bold-uppercase" *ngIf="upgradeDetail.benefits.fuelSaving.benefitValue!==0; else noValue">
            {{upgradeDetail.benefits.fuelSaving.benefitValue}}¢/gal</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-light-background'"
          class="rating-data">
          <div class="text-bold-capitalize">Maintenance Savings</div>
          <div class="text-bold" *ngIf="upgradeDetail.loyaltyLevelName !== 'BRONZE'; else noValue">
            $5 - $30
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="color+'-light-background'"
          class="rating-data">
          <div class="text-bold-capitalize">Tire Savings</div>
          <div class="text-bold" *ngIf="upgradeDetail.loyaltyLevelName !== 'BRONZE'; else noValue">
            Avg: $35/tire
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <button mat-raised-button (click)="onClose()" class="text-uppercase secondary-btn">{{'close7039' |
        translate}}</button>
      <button mat-raised-button (click)="navigatetoLoyalty()" color="accent" class="text-uppercase"> view
        {{'details6541' | translate}}</button>
    </div>
  </mat-dialog-actions>
  <ng-template #noValue>
    <div class="text-bold-uppercase">-</div>
  </ng-template>
</div>
<ng-template #loader>
  <div class="loader">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>