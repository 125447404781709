<div class="container" *ngIf="reloadsCount">
  <div fxLayout="row" fxlayoutgap="20px" fxLayoutAlign="start center">
    <div fxLayout="column" class="reload-icon">
      <img src="assets/icons/icon_reloads_black.svg" />
    </div>
    <div class="text-capitalize reloads-link text-bold cursor-pointer full-width" (click)="navigateToReloads()"
      fxLayout="row" fxLayoutAlign="space-between center">
      {{'view3359'|translate|uppercase}} {{reloadsCount}} {{reloadLabel|translate|uppercase}}
      <i class="padding-5 icon-chevron-right" fxFlexAlign="end"></i>
    </div>
  </div>
</div>