import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { XPOConstants } from '../xpo-constants';

@Component({
  selector: 'app-share-tracking-success',
  templateUrl: './share-tracking-success.component.html',
  styleUrls: [ './share-tracking-success.component.scss' ]
})
export class ShareTrackingSuccessComponent implements OnChanges, OnInit {

  @Input()
  public successData;

  @Input()
  public loadDetails;

  @Output()
  public successAction: EventEmitter<string> = new EventEmitter<string>();

  public readonly minimumCountofItems = 3;
  public loadNumber;
  public successfullySent;
  public partiallySent;
  public fullyFailed;
  public successTitle: string;
  public rateConfirmationPage = XPOConstants.keystonePage.rateConfirmation;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('successData')) {
      this.processResponseData();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  private processResponseData() {
    if (this.successData) {
      this.loadNumber = this.successData.number;
      if (this.successData.status) {
        this.successfullySent = {
          message: this.successData.messages.success,
          chipColor: '',
          contacts: this.successData.successRecipients,
          minimumCount: this.minimumCountofItems
        };
        this.successTitle = 'trackingLin1442'; // Tracking Link Shared
      } else {
        this.fullyFailed = {
          message: this.successData.messages.failure,
          chipColor: 'warn',
          contacts: this.successData.failedRecipients,
          minimumCount: this.minimumCountofItems
        };
        this.successTitle = 'trackinglin4877'; // Tracking link share failed
        return;
      }

      if (this.successData.hasFailures) {
        this.partiallySent = {
          message: this.successData.messages.partial,
          chipColor: 'warn',
          contacts: this.successData.failedRecipients,
          minimumCount: this.minimumCountofItems
        };
        this.successTitle = 'trackingLin8105'; // Tracking link partially shared
      }
    }
  }

  public triggerSuccessAction(action: string) {
    this.successAction.next(action);
  }

}
