import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserAuthentication } from './user-authentication/user-authentication';
import { XPOConstants } from './shared';
import { LandingPageGuard } from './user-authentication/landing-page-guard';
import { NotificationsComponent } from './shared/notifications/notifications.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ LandingPageGuard ],
    redirectTo: '',
    pathMatch: 'full',
    data: { isDefaultRoute: true }
  },
  {
    path: 'registration',
    loadChildren: () => import('./self-registration/self-registration.module').then(mod => mod.SelfRegistrationModule),
    canActivate: [ UserAuthentication ],
    data: { isPublic: true }
  },
  {
    path: 'dashboard/overview',
    loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: { permissionType: XPOConstants.permissionTypes.dashboard }
  },
  {
    path: 'loads/lanes',
    loadChildren: () => import('./recurring-lane/recurring-lane.module').then(mod => mod.RecurringLaneModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      permissionType: XPOConstants.permissionTypes.dedicatedLanesView,
      isPublic: true
    }
  },
  {
    path: 'tracking',
    loadChildren: () => import('./tracking/tracking.module').then(mod => mod.TrackingModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      permissionType: XPOConstants.permissionTypes.tracking,
      isPublic: false
    }
  },
  {
    path: 'administration/finance-management',
    loadChildren: () => import('./finance/finance.module').then(mod => mod.FinanceModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      permissionType: XPOConstants.permissionTypes.financialManagement,
      isPublic: false
    }
  },
  {
    path: 'loads/bids',
    loadChildren: () => import('./bids/bids.module').then(mod => mod.BidsModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      isPublic: true
    }
  },
  {
    path: 'asset-management',
    loadChildren: () => import('./asset-management/asset-management.module').then(mod => mod.AssetManagementModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      isPublic: true
    }
  },
  {
    path: 'loads/load-tenders',
    loadChildren: () => import('./tenders/tenders.module').then(mod => mod.TendersModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      isPublic: true
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(mod => mod.UserProfileModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ]
  },
  {
    path: 'loads/my-loads',
    loadChildren: () => import('./my-loads/my-loads.module').then(mod => mod.MyLoadsModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: { permissionType: XPOConstants.permissionTypes.assignedLoads }
  },
  {
    path: 'loads/available-loads',
    loadChildren: () => import('./loadboard/loadboard.module').then(mod => mod.LoadboardModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: { isPublic: true }
  },
  {
    path: 'dashboard/analytics',
    loadChildren: () => import('./analytics/analytics.module').then(mod => mod.AnalyticsModule),
    data: {
      isPublic: false,
      permissionType: XPOConstants.permissionTypes.analyticsView
    }
  },
  {
    path: 'carrier-contest',
    loadChildren: () => import('./carrier-contest/carrier-contest.module').then(mod => mod.CarrierContestModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: {
      isPublic: false,
    }
  },
  {
    path: 'rewards-program',
    loadChildren: () => import('./carrier-rewards/carrier-rewards.module').then(mod => mod.CarrierRewardsModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: { isPublic: false, permissionType: XPOConstants.permissionTypes.rewardsView }
  },
  {
    path: 'email-unsubscribe',
    loadChildren: () => import('./email-subscription/email-subscription.module').then(mod => mod.EmailSubscriptionModule),
    canActivate: [ UserAuthentication ],
    canActivateChild: [ UserAuthentication ],
    data: { isPublic: true }
  },
  {
    path: 'notification',
    component: NotificationsComponent
  },
  {
    path: 'error-message',
    loadChildren: () => import('./error-message/error-message.module').then(mod => mod.ErrorMessageModule)
  },
  {
    path: 'unsupported-browser',
    loadChildren: () => import('./unsupported-browser/unsupported-browser.module').then(mod => mod.UnsupportedBrowserModule)
  },
  {
    path: 'rate-confirmation',
    loadChildren: () => import('./rate-confirmation/rate-confirmation.module').then(mod => mod.RateConfirmationModule)
  },
  {
    path: 'rate-con',
    loadChildren: () => import('./rate-confirmation/rate-confirmation.module').then(mod => mod.RateConfirmationModule)
  },
  {
    path: '**',
    redirectTo: '/dashboard/overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
  }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
