import { Note } from './note';
import { PaperWork } from './paper-work';
import { ReferenceNumber } from './reference-number';
import { LoadStop } from './load-stop';
import { Accessorial } from './accessorial';
import { TruckStops } from './truck-stops';

export class LoadDetails {
  constructor(
    public creationDate: string,
    public customerReferenceNumber: string,
    public charges: Accessorial[],
    public commodity: string,
    public containerNumber: string,
    public destination: LoadStop,
    public equipmentType: string,
    public equipmentCategory: string,
    public id: string,
    public notes: Note[],
    public number: string,
    public origin: LoadStop,
    public packaging: string,
    public paperworks: PaperWork[],
    public partners: string[],
    public pieces: number,
    public temperature: string,
    public isTemperatureInRange: boolean,
    public progressEntries: any[],
    public referenceNumbers: ReferenceNumber[],
    public orginStopReferenceNumbers: ReferenceNumber[],
    public intermediateStopsReferenceNumbers: ReferenceNumber[],
    public destinationStopReferenceNumbers: ReferenceNumber[],
    public shipmentId: string,
    public source: number,
    public system: number,
    public status: any,
    public statusString: string,
    public stops: LoadStop[],
    public totalCharges: number,
    public tractorNumber: string,
    public trainNumber: string,
    public weight: number,
    public weightFormatted: string,
    public weightUnit: string,
    public proNumber: string,
    public driverName: string,
    public driverPhoneNumber: string,
    public truckNumber: string,
    public trailerNumber: string,
    public truckLicensePlate: string,
    public truckLicenseState: string,
    public trailerLicensePlate: string,
    public trailerLicenseState: string,
    public carrierRepEmail: string,
    public carrierRepName: string,
    public carrierRepPhone: string,
    public events: any[],
    public currentLocation: any,
    public nextStop: any,
    public truckStops: TruckStops[],
    public driverId?: number,
    public tripCode?: string,
    public tripNumber?: string,
    public isTravelOrder?: boolean,
    public alternateNumber?: any,
    public salesRep?: any
  ) { }
}
