import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { AssetManagementService } from '../../services/asset-management.service';
import { LookupService } from '../../services/lookup.service';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PhoneFormattingPipe, Validations, DateTimePipe as DatePipe } from '@xpoc/ngx-common';
import { KeystoneService } from '../../services/keystone.service';
import { DriverData } from '../../model/driver';
import { LogEvent } from '../../model/log-event';
import { XPOConstants } from '../../xpo-constants';
import { MatSelectChange } from '@angular/material/select';
import { AppSettings } from 'src/app/core/app-settings';
import { DeleteDriverComponent } from '../delete-driver/delete-driver.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-new-driver',
  templateUrl: './add-new-driver.component.html',
  styleUrls: [ './add-new-driver.component.scss' ]
})
export class AddNewDriverComponent implements OnInit, OnDestroy {

  public driverForm: FormGroup;
  public isNAUser: boolean;
  public countryList = [];
  public driverRoles = [];
  public disableBtns = false;
  public isUserExist = false;
  public showSucessMsg = false;
  public intervalTimer: any;
  public driverRole: string;
  public submitBtn: string = XPOConstants.addDriverMsgs.addBtn;
  public successMessage: string = XPOConstants.addDriverMsgs.addBtnSuccess;
  public userActions = XPOConstants.userActions;
  public emailAddressPlaceholder: string;
  public dialogAction: string;
  public driverDetails: DriverData;
  private dialogConfig: MatDialogConfig;
  public assetAction: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public assetManagementService: AssetManagementService,
    public lookupService: LookupService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddNewDriverComponent>,
    public customPhoneFormatting: PhoneFormattingPipe,
    public keystoneService: KeystoneService,
    private appSettings: AppSettings,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.lookupService.getDriverRoles().subscribe(roles => {
      this.driverRoles = roles;
    });
    this.isNAUser = this.authService.checkNAUser();
    this.dialogAction = this.dialogRef._containerInstance._config.data ?
      this.dialogRef._containerInstance._config.data.action : this.userActions.create;
    this.driverDetails = this.dialogRef._containerInstance._config.data ? this.dialogRef._containerInstance._config.data.driver : null;
    this.driverForm = this.createForm();
    this.emailAddressPlaceholder = this.translateService.instant('email8704');
    if (this.dialogAction === this.userActions.edit && this.driverDetails) {
      this.emailAddressPlaceholder = this.translateService.instant('userNa2996');
      this.assetManagementService.getDriverRole(this.driverDetails).subscribe((driverRoleCode) => {
        this.driverDetails.roleCode = driverRoleCode;
        if (driverRoleCode) {
          this.driverRoles = this.enableNonActiveRoles(driverRoleCode);
        }
        this.populateDriverDetails();
      });
    } else if (!this.isNAUser && this.dialogAction === this.userActions.create) {
      this.submitBtn = XPOConstants.addDriverMsgs.inviteBtn;
    }
    this.fetchCountryList();
    this.assetAction = true;
    if (!this.isNAUser) {
      this.assetAction = false;
      const permissions = this.authService.permissions;
      if (permissions.findIndex(permission => permission === XPOConstants.permissionTypes.assetAction) > -1) {
        this.assetAction = true;
      }
    }
  }

  private enableNonActiveRoles(roleCode) {
    const driverRoles = this.driverRoles;
    driverRoles.forEach((role) => {
      if (role.code === roleCode && !role.isActive) {
        role.isActive = true;
        return false;
      }
    });
    return driverRoles;
  }

  private fetchCountryList() {
    this.lookupService.getCountriesCallingCode().subscribe(response => {
      this.countryList = response;
    });
  }

  private populateDriverDetails() {
    const countryCode = this.driverDetails.countryCode || '';
    this.driverForm.setValue({
      role: this.driverDetails.roleCode,
      firstName: this.driverDetails.firstName,
      lastName: this.driverDetails.lastName,
      emailAddress: this.driverDetails.userName,
      countryCode,
      phoneNumber: this.driverDetails.mobilePhoneNumber
    });
  }

  public updateDriver() {
    const driverData = this.formDriverData();
    driverData.driverId = this.driverDetails.driverId;
    driverData.isDriveXPO = this.driverDetails.isDriveXPO;
    driverData.address1 = this.driverDetails.address1;
    driverData.emailAddress = this.driverDetails.emailAddress;
    driverData.emailNotification = this.driverDetails.emailNotification;
    driverData.alternatePhoneNumber = this.driverDetails.alternatePhoneNumber;

    driverData.city = this.driverDetails.city;
    driverData.comments = this.driverDetails.comments;
    driverData.countryCode = this.driverDetails.countryCode;
    driverData.licenseCountryCode = this.driverDetails.licenseCountryCode;
    driverData.licenseNumber = this.driverDetails.licenseNumber;
    driverData.licenseStateCode = this.driverDetails.licenseStateCode;
    driverData.mobileNumberNotification = this.driverDetails.mobileNumberNotification;
    driverData.postalCode = this.driverDetails.postalCode;
    driverData.stateCode = this.driverDetails.stateCode;

    driverData.trackingPhoneNumber = this.driverDetails.trackingPhoneNumber;
    driverData.userName = this.driverDetails.userName;

    driverData.dateOfBirth = '';
    driverData.hireDate = (this.driverDetails && this.driverDetails.hireDate) ?
      this.driverDetails.hireDate : this.datePipe.transform(new Date(), XPOConstants.dbDateFormat);
    driverData.licenseExpirationDate = '';

    this.assetManagementService.editDriver(driverData).subscribe(response => {
      if (response === 0) {
        this.driverForm.controls.userName.setErrors({ invalid: { error: this.appSettings.getMessage('userNameExists') } });
      } else {
        this.dialogRef.close(this.userActions.confirm);
      }
    });
  }

  private formDriverData(): DriverData {
    const driverData = new DriverData();
    const driverForm = this.driverForm.value;
    driverData.roleCode = driverForm.role;
    driverData.firstName = driverForm.firstName;
    driverData.lastName = driverForm.lastName;
    driverData.userName = driverForm.emailAddress;
    driverData.emailAddress = driverForm.emailAddress;
    driverForm.phoneNumber = driverForm.phoneNumber.toString().replace('+', '');
    driverData.mobilePhoneNumber = this.isNAUser ? driverForm.phoneNumber : driverForm.countryCode + '-' + driverForm.phoneNumber;
    driverData.mobilePhoneTypeCode = XPOConstants.defaultPhoneType;
    return driverData;
  }

  public cancelDriverEdit() {
    this.dialogRef.close(this.userActions.cancel);
  }

  public deleteDriver() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.deleteDriver,
      XPOConstants.keystoneEventAction.initiate, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.driver);
    this.keystoneService.logEvent(logEvent);
    this.dialogConfig = {
      data: {
        driver: this.driverDetails
      }
    };
    this.dialog
      .open(DeleteDriverComponent, this.dialogConfig)
      .afterClosed()
      .subscribe((isDriverDeleted) => {
        if (isDriverDeleted) {
          this.showSucessMsg = true;
          this.dialogRef.close(this.userActions.refresh);
        }
      });
  }

  onClose() {
    if (!this.showSucessMsg) {
      this.logKeystone(XPOConstants.keystoneEventAction.cancel);
    }
    this.dialogRef.close(this.showSucessMsg);
  }

  private euFirstNameValidation(control: FormControl) {
    const NAME_REGEXP = /^[a-z]+((.|'|-)?[ a-z])+$/i;
    return (NAME_REGEXP.test(control.value) || !control.value) ? null : { invalidName: true };
  }

  private euLastNameValidation(control: FormControl) {
    const NAME_REGEXP = /^(([a-z]+((.|'|-)?[ a-z]))|[a-z])+$/i;
    return (NAME_REGEXP.test(control.value) || !control.value) ? null : { invalidName: true };
  }

  private createForm() {
    let validatePhoneNumber = Validations.phone;
    let firstNameValidator = Validations.firstName;
    let lastNameValidator = Validations.lastName;
    const countryCodeRequiredValidator = [];
    let defaultDriverRole = XPOConstants.defaultDriverRole;
    if (!this.isNAUser) {
      validatePhoneNumber = Validations.euPhone;
      firstNameValidator = this.euFirstNameValidation;
      lastNameValidator = this.euLastNameValidation;
      defaultDriverRole = XPOConstants.defaultEuDriverRole;
      countryCodeRequiredValidator.push(Validators.required);
    }
    const group = this.formBuilder.group({
      role: [ defaultDriverRole, [ Validations.required ] ],
      firstName: [ '', [ firstNameValidator, Validators.required ] ],
      lastName: [ '', [ lastNameValidator, Validators.required ] ],
      emailAddress: [ { value: '', disabled: this.dialogAction === this.userActions.edit },
      [ Validations.email, Validators.required ] ],
      countryCode: [ '', countryCodeRequiredValidator ],
      phoneNumber: [ '', [ validatePhoneNumber, Validators.required ] ]
    });
    return group;
  }

  onClear() {
    this.driverForm = this.createForm();
  }

  formatPhone() {
    const driverForm = this.driverForm.value;
    this.driverForm.get('phoneNumber').setValue(this.customPhoneFormatting.transform(driverForm.phoneNumber, this.authService.tenantId));
  }

  createNewDriver() {
    this.isUserExist = false;
    this.disableBtns = true;
    const driverData = new DriverData();
    const driverForm = this.driverForm.value;
    driverData.roleCode = driverForm.role;
    driverData.firstName = driverForm.firstName;
    driverData.lastName = driverForm.lastName;
    driverData.userName = driverForm.emailAddress;
    driverData.emailAddress = driverForm.emailAddress;
    driverData.mobilePhoneNumber = this.isNAUser ? driverForm.phoneNumber : driverForm.countryCode + '-' + driverForm.phoneNumber;
    driverData.mobilePhoneTypeCode = XPOConstants.defaultPhoneType;
    this.assetManagementService.addDriver(driverData).subscribe(response => {
      this.disableBtns = false;
      this.isUserExist = response.isUserExistingInDXPO;
      if (!response.isUserExistingInDXPO) {
        const eventIdentifiers = {
          driverDeatils: driverData
        };
        this.logKeystone(XPOConstants.keystoneEventAction.submit, eventIdentifiers);
        this.showSucessMsg = true;
        this.intervalTimer = setTimeout(() => {
          driverData.driverId = response.driverId;
          this.onCloseWithDriver(driverData);
        }, XPOConstants.confirmationTimeoutInterval);
      }
    });
  }

  onCloseWithDriver(driverData) {
    this.dialogRef.close(driverData);
  }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  }

  logKeystone(keystoneEventAction: string, eventIdentifiers?: any) {
    const logEvent = new LogEvent(
      XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.createDriver, keystoneEventAction, null, null, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

  onSelectRole(role: MatSelectChange) {
    this.driverRole = role.value;
    if (this.driverRole !== XPOConstants.defaultDriverRole) {
      this.submitBtn = XPOConstants.addDriverMsgs.inviteBtn;
      this.successMessage = XPOConstants.addDriverMsgs.inviteBtnSuccess;
    } else {
      this.submitBtn = XPOConstants.addDriverMsgs.addBtn;
      this.successMessage = XPOConstants.addDriverMsgs.addBtnSuccess;
    }
  }
}
