import { Traffic } from '../model/traffic';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';

@Injectable()
export class TrafficService {
  public BING_API_BASE = 'https://dev.virtualearth.net/REST/v1/Traffic/Incidents/';
  public bingApiKey: string;

  constructor(private http: HttpClient, public appSettings: AppSettings) {
    // this.bingApiKey = appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension4);
  }

  getTrafficAlerts(northLat: number, eastLng: number, southLat: number, westLng: number): Promise<any> {
    // South Latitude, West Longitude, North Latitude, East Longitude
    const apiUrl = `${this.BING_API_BASE}${southLat},${westLng},${northLat},${eastLng}?s=3,4&key=${this.bingApiKey}&jsonp=JSONP_CALLBACK`;

    return new Promise((resolve, reject) => {
      this.http.jsonp(apiUrl, 'callback')
        .toPromise().then(response => {
          const data = response;
          resolve(response as Traffic);
        }).catch(error => {
          const errorData = error;
          reject(error);
        });
    });
  }
}
