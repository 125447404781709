import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { LogEvent } from '../model/log-event';
import { SystemId, PartnerType } from '../xpo-constants';

@Injectable({
  providedIn: 'root'
})
export class KeystoneService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings) {
    this.baseUrl = this.appSettings.apiUrl;
  }

  logEvent(logEvents: LogEvent) {
    const url = this.getUri('keystone');
    return this.http
      .post(url, logEvents, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  logEmailEvent(logEvent: LogEvent, partnerIdentifier: string) {
    const url = this.baseUrl + 'logs/anonymous/events/keystone?partnerIdentifier=' + partnerIdentifier;
    return this.http
      .post(url, logEvent, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  logAnonymousEvent(logEvent: LogEvent) {
    const url = this.baseUrl + 'logs/anonymous/events/keystone?systemId='
      + SystemId.FreightOptimizer + '&partnerType=' + PartnerType.vendor;
    return this.http
      .post(url, logEvent, { headers: this.getHeaders() })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('skipRedirect', 'true');
    return headers;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + 'logs/events/' + endpoint;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
