import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../core/app-settings';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings) {
    this.baseUrl = this.appSettings.apiUrl;
  }

  userAlertNotification(request: any): Observable<any> {
    const url = `${this.baseUrl}notifications/alerts`;
    return this.http
      .post(url, request, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  private getOptions(params?: HttpParams) {
    const options = { headers: this.getHeaders(), params, withCredentials: true };
    return options;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  private getHeaders(obj?: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }
}
