<h1 mat-dialog-title class="text-uppercase" *ngIf="title">{{title | translate}}</h1>
<mat-dialog-content class="response-msg" fxLayoutAlign="center center">
    <div class="text-center">
        <i class="event-icon icon-Yes success-icon" *ngIf="isSuccess"></i>
        <i class="event-icon icon-info1 error-icon" *ngIf="!isSuccess && isError"></i>
        <br><br>
        <div class="message">
            {{message | translate}}
        </div>
        <button id="action-btn" mat-raised-button color="accent" (click)="onClose()"
            class="margin-10 text-uppercase">{{actionText | translate }}</button>
    </div>
</mat-dialog-content>