import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DateTimePipe, Validations } from '@xpoc/ngx-common';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from '..';
import { MyLoadsService } from '../services';
import { ShareTrackingDialogComponent } from '../share-tracking-dialog/share-tracking-dialog.component';
import { KeystoneService } from '../services/keystone.service';
import { LogEvent } from '../model/log-event';

@Component({
  selector: 'app-dispatch-info-success-dialog',
  templateUrl: './dispatch-info-success-dialog.component.html',
  styleUrls: [ './dispatch-info-success-dialog.component.scss' ]
})


export class DispatchInfoSuccessDialogComponent implements OnInit {

  @Input() loadDetails: any;
  @Output() CloseEvent = new EventEmitter<any>();
  public shouldShowBackButton = false;
  public shouldShowCopyButton = true;
  public shouldShowPrimaryActions = true;
  public shouldShowHelpText = true;
  public linkToShare = '';
  public copyLink = '';
  public isLinkCopied = false;
  public copyButtonText = 'copy1101'; // 'Copy'
  public sharingMedium = '';
  public dispatchDetails: any;
  public languageCode: string;
  public isTrackingLink: boolean;
  public dispatchTrackingForm: any;
  public dispatchLoadTrackingInfo: any;
  public locationData: any;
  public trackingSuccess = false;
  private shareTrackingDialogConfig: MatDialogConfig;
  private orderInfo: any;
  private dispatchTrackingRq: any;
  public countryList: [];
  public carrierCountryCode: any;
  public successMessage: string;
  public recipientInfo: any;
  public isLoading = true;
  public shareTrackingDisabled: boolean;
  public isSaving: boolean;

  constructor(
    public dialogRef: MatDialogRef<DispatchInfoSuccessDialogComponent>,
    private translateService: TranslateService,
    private myLoadsService: MyLoadsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public datePipe: DateTimePipe,
    private dialog: MatDialog,
    private loadService: MyLoadsService,
    private lookupService: LookupService,
    private keystoneService: KeystoneService) {
    this.shareTrackingDialogConfig = new MatDialogConfig();
    this.dispatchTrackingForm = this.createDispatchTrackingForm();
  }


  ngOnInit() {
    this.shareTrackingDisabled = false;
    this.isSaving = false;
    this.languageCode = XPOConstants.euDefaultLanguageCode;
    this.dispatchLoadTrackingInfo = this.loadDetails;
    this.isTrackingLink = false;
    if (this.authService.preferredLanguageCode) {
      const language = this.authService.preferredLanguageCode;
      this.languageCode = XPOConstants.shareTrackingLanguageCodes.indexOf(language) > -1 ? language : XPOConstants.euDefaultLanguageCode;
    }
    this.fetchTrackingLink();
    this.fetchCountryList();
    this.frameLocationData();
    this.setCarrierCountryCode();
    if (this.loadDetails.trackingMethodInfo !== 'DTM') {
      this.logKeystone(XPOConstants.keystoneEventName.shareTracking, XPOConstants.keystoneEventAction.show);
    }
  }

  private fetchTrackingLink() {
    this.isLoading = true;
    this.myLoadsService.getTrackingLink(this.dispatchLoadTrackingInfo.alternateNumber)
      .subscribe((response) => {
        if (response.isAuthourized && response.trackingUrl) {
          this.copyLink = response.trackingUrl + '?lang=' + this.languageCode;
          this.linkToShare = response.trackingUrl;
          this.isTrackingLink = true;
          this.isLoading = false;
        } else {
          this.isTrackingLink = false;
          this.isLoading = false;
        }
      });
  }
  onClose() {
    if (this.loadDetails.trackingMethodInfo !== 'DTM') {
      this.logKeystone(XPOConstants.keystoneEventName.shareTracking, XPOConstants.keystoneEventAction.cancel);
    }
    this.CloseEvent.emit(true);
  }

  private fetchCountryList() {
    this.lookupService.getNotificationEnabledCountries().subscribe(response => {
      this.countryList = response;
    });
  }

  createDispatchTrackingForm() {
    const dispatchGroup = this.formBuilder.group({
      mode: [ 'EMAIL' ],
      email: [ '', [ Validations.email ] ],
      countryCode: [ '' ],
      phoneNumber: [ '', [ Validations.euPhone ] ]
    });
    return dispatchGroup;
  }
  private frameLocationData() {
    const stopsDetails = this.dispatchLoadTrackingInfo.stops;
    const noOfStops = stopsDetails.length;
    const firstStop = stopsDetails[ 0 ];
    const lastStop = stopsDetails[ noOfStops - 1 ];
    this.locationData = {
      pickUp: `${firstStop.cityName}, ${firstStop.countryName ? firstStop.countryName : firstStop.countryCode} ${firstStop.zipCode}`,
      pickUpAt: this.datePipe.transform(this.dispatchLoadTrackingInfo.originPickupDate, XPOConstants.euMonthDateTimeFormat),
      drop: `${lastStop.cityName}, ${lastStop.countryName ? lastStop.countryName : lastStop.countryCode} ${lastStop.zipCode}`
    };
  }

  public onCopy(inputLink: string) {
    const selBox = document.createElement('textarea');
    selBox.value = inputLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isLinkCopied = true;
    this.copyButtonText = this.translateService.instant('copied3657') + '!';
    this.logKeystone(XPOConstants.keystoneEventName.shareTracking, XPOConstants.keystoneEventAction.copy);
  }

  shareMore() {
    this.logKeystone(XPOConstants.keystoneEventName.shareTrackingMore, XPOConstants.keystoneEventAction.show);
    this.orderInfo = {
      originPickupDate: this.dispatchLoadTrackingInfo.stops[ 0 ].scheduledArrivalEarly,
      stops: this.dispatchLoadTrackingInfo.stops,
      alternateNumber: this.dispatchLoadTrackingInfo.alternateNumber,
      originStop: {
        scheduledArrivalEarly: this.dispatchLoadTrackingInfo.originStop.scheduledArrivalEarly,
        scheduledArrivalLate: this.dispatchLoadTrackingInfo.originStop.scheduledArrivalLate
      },
      number: this.dispatchLoadTrackingInfo.number,
      id: this.dispatchLoadTrackingInfo.id,
      status: this.dispatchLoadTrackingInfo.status,
      tripNumber: this.dispatchLoadTrackingInfo.tripNumber,
      pageInfo: this.dispatchLoadTrackingInfo.page,
      subPageInfo: this.dispatchLoadTrackingInfo.subPage,
      tripCode: this.dispatchLoadTrackingInfo.alternateNumber,
      isTravelOrder: this.dispatchLoadTrackingInfo.isTravelOrder,
      showUpdateDispatch: true
    };
    this.shareTrackingDialogConfig.width = '450px';
    this.shareTrackingDialogConfig.data = { loadDetails: this.orderInfo };
    this.dialog
      .open(ShareTrackingDialogComponent, this.shareTrackingDialogConfig)
      .afterClosed()
      .subscribe((shareActions) => {
        this.CloseEvent.emit(true);
      });
    this.CloseEvent.emit(true);
  }

  private setCarrierCountryCode() {
    let userCountryCode = this.authService.userProfile.countryCode;
    userCountryCode = userCountryCode.indexOf('+') === -1 ? `+${userCountryCode}` : userCountryCode;
    this.carrierCountryCode = userCountryCode;
  }

  dispatcherTracking() {
    this.shareTrackingDisabled = true;
    this.isSaving = true;
    if (this.dispatchTrackingForm.value.mode === 'EMAIL') {
      this.successMessage = 'trackinginf4082'; // 'Tracking information has been shared with the below email id.';
      this.recipientInfo = this.dispatchTrackingForm.value.email;
      this.dispatchTrackingRq = {
        ...this.locationData,
        number: this.dispatchLoadTrackingInfo.isTravelOrder ? this.dispatchLoadTrackingInfo.alternateNumber :
          this.dispatchLoadTrackingInfo.number,
        tripIdentifier: this.dispatchLoadTrackingInfo.tripNumber,
        url: this.copyLink,
        recipients: [ {
          contact: this.dispatchTrackingForm.value.email
        } ],
        copyMe: false,
        userEmail: this.authService.userProfile.email,
        partnerName: this.authService.userProfile.currentPartner.partnerName,
        mode: this.dispatchTrackingForm.value.mode,
        language: this.languageCode
      };
      this.logKeystone(XPOConstants.keystoneEventName.shareTrackingEmail, XPOConstants.keystoneEventAction.click);
    } else if (this.dispatchTrackingForm.value.mode === 'SMS') {
      this.successMessage = 'trackinginf7083'; // 'Tracking information has been shared by sms to the below phone number.';
      this.recipientInfo = this.dispatchTrackingForm.value.countryCode + ' ' + this.dispatchTrackingForm.value.phoneNumber;
      this.dispatchTrackingRq = {
        ...this.locationData,
        number: this.dispatchLoadTrackingInfo.isTravelOrder ? this.dispatchLoadTrackingInfo.alternateNumber :
          this.dispatchLoadTrackingInfo.number,
        tripIdentifier: this.dispatchLoadTrackingInfo.tripNumber,
        url: this.copyLink,
        recipients: [ {
          contact: this.dispatchTrackingForm.value.phoneNumber,
          countryCode: this.dispatchTrackingForm.value.countryCode
        } ],
        copyMe: false,
        userMobileNumber: this.authService.userProfile.phoneNumber.replace(this.carrierCountryCode, ''),
        userMobileCountryCode: this.carrierCountryCode,
        mode: this.dispatchTrackingForm.value.mode,
        language: this.languageCode,
        partnerName: this.authService.userProfile.currentPartner.partnerName
      };
      this.logKeystone(XPOConstants.keystoneEventName.shareTrackingText, XPOConstants.keystoneEventAction.click);
    } else {
      this.successMessage = 'trackinginf2320'; // 'Tracking information has been shared by whatsapp to the below phone number.';
      this.recipientInfo = this.dispatchTrackingForm.getRawValue().countryCode + this.dispatchTrackingForm.value.phoneNumber;
      this.dispatchTrackingRq = {
        ...this.locationData,
        number: this.dispatchLoadTrackingInfo.isTravelOrder ? this.dispatchLoadTrackingInfo.alternateNumber :
          this.dispatchLoadTrackingInfo.number,
        tripIdentifier: this.dispatchLoadTrackingInfo.tripNumber,
        url: this.copyLink,
        recipients: [ {
          contact: this.dispatchTrackingForm.value.phoneNumber,
          countryCode: this.dispatchTrackingForm.getRawValue().countryCode
        } ],
        copyMe: false,
        userMobileNumber: this.authService.userProfile.phoneNumber.replace(this.carrierCountryCode, ''),
        userMobileCountryCode: this.carrierCountryCode,
        mode: this.dispatchTrackingForm.value.mode,
        language: this.languageCode,
        partnerName: this.authService.userProfile.currentPartner.partnerName
      };
      this.logKeystone(XPOConstants.keystoneEventName.shareTrackingWhatsapp, XPOConstants.keystoneEventAction.click);
    }
    this.loadService.shareTrackingLink(this.dispatchTrackingRq)
      .subscribe((response) => {
        this.trackingSuccess = true;
      });
  }

  logKeystone(eventName, eventAction) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction, this.loadDetails.page);
    this.keystoneService.logEvent(logEvent);
  }
  modeChange(mode) {
    switch (mode) {

      case 'EMAIL':
        this.dispatchTrackingForm.get('email').setValidators(Validations.email);
        this.dispatchTrackingForm.get('countryCode').setValidators(null);
        this.dispatchTrackingForm.get('phoneNumber').setValidators(null);
        this.dispatchTrackingForm.get('countryCode').setErrors(null);
        this.dispatchTrackingForm.get('phoneNumber').setErrors(null);
        break;

      case 'SMS':
        this.dispatchTrackingForm.get('countryCode').setValidators([ Validators.required ]);
        this.dispatchTrackingForm.get('phoneNumber').setValidators(Validations.euPhone);
        this.dispatchTrackingForm.get('countryCode').setValue(false);
        this.dispatchTrackingForm.get('countryCode').enable();
        this.dispatchTrackingForm.get('email').setValidators(null);
        this.dispatchTrackingForm.get('email').setErrors(null);
        break;

      case 'WHATSAPP':
        this.dispatchTrackingForm.get('countryCode').setValue('+34');
        this.dispatchTrackingForm.get('countryCode').disable();
        this.dispatchTrackingForm.get('countryCode').setValidators(Validators.required);
        this.dispatchTrackingForm.get('phoneNumber').setValidators(Validations.euPhone);
        this.dispatchTrackingForm.get('email').setValidators(null);
        this.dispatchTrackingForm.get('email').setErrors(null);
        break;
    }
  }
}
