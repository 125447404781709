<ul>
  <li *ngFor="let stop of stops; first as isFirst; last as isLast; let i = index">
    <div class="stops-data">

      <div id="first-stop" *ngIf="isFirst">
        <div class="load-stops">
          <div>
            <span>
              <i class="icon-circle-outlined origin-stop-marker"></i>
            </span>
          </div>
          <div fxLayout="row" class="xpo-text-padding full-width" fxShow fxHide.lt-md>
            <p>
              <b>{{stop.city}}, {{ stop.stateCode}} {{stop.zipCode}}</b>
            </p>
            <p class="xpo-align-right">
              <b [translate]="'scheduled9690'">Scheduled:</b><span>: </span>
              {{stop.scheduledArrivalTime | xpocDateTime: dateTimeFormat}}</p>
          </div>
          <div fxHide fxShow.lt-md>
            <div fxLayout="row">
              <div fxLayout="row" class="data-left-padding ">
                <b>{{stop.city}}, {{ stop.stateCode}} {{stop.zipCode}}</b>
              </div>
            </div>
            <div fxLayout="row" class="data-left-padding">
              <b [translate]="'scheduled9690'">Scheduled:</b><span>: </span>
              {{stop.scheduledArrivalTime | xpocDateTime: dateTimeFormat}}</div>
          </div>
        </div>

        <div class="load-status-delay" fxShow fxHide.lt-md></div>
        <div class="load-status-delay-mob" fxHide fxShow.lt-md></div>
      </div>

      <div id="intermediate-stop-{{i}}" *ngIf="!isFirst && !isLast">
        <div class="load-status-delay" fxShow fxHide.lt-md></div>
        <div class="load-status-delay-mob" fxHide fxShow.lt-md></div>
        <div class="load-stops">
          <div>
            <span>
              <i class="icon-circle-outlined secondary-stop-marker"></i>
            </span>
          </div>

          <div fxLayout="row" class="xpo-text-padding full-width" fxShow fxHide.lt-md>
            <p class="stops-city">
              <b>{{stop.city}}, {{ stop.stateCode}} {{stop.zipCode}}</b>
            </p>
            <p class="xpo-align-right">
              <b [translate]="'scheduled9690'">Scheduled:</b><span>: </span>
              {{stop.scheduledArrivalTime | xpocDateTime: dateTimeFormat}}</p>
          </div>
          <div fxHide fxShow.lt-md>
            <div fxLayout="row">
              <div fxLayout="row" class="data-left-padding ">
                <b>{{stop.city}}, {{ stop.stateCode}} {{stop.zipCode}}</b>
              </div>
            </div>
            <div fxLayout="row" class="data-left-padding">
              <b [translate]="'scheduled9690'">Scheduled:</b><span>: </span>
              {{stop.scheduledArrivalTime | xpocDateTime: dateTimeFormat}}</div>
          </div>

        </div>
        <div class="load-status-delay" fxShow fxHide.lt-md></div>
        <div class="load-status-delay-mob" fxHide fxShow.lt-md></div>
      </div>

      <div id="last-stop" *ngIf="isLast">
        <div class="load-status-delay" fxShow fxHide.lt-md></div>
        <div class="load-status-delay-mob" fxHide fxShow.lt-md></div>
        <div class="load-stops">
          <div>
            <span>
              <i class="icon-circle-filled destination-stop-marker"></i>
            </span>
          </div>
          <div fxLayout="row" class="xpo-text-padding full-width" fxShow fxHide.lt-md>
            <p>
              <b>{{stop.city}}, {{ stop.stateCode}} {{stop.zipCode}}</b>
            </p>
            <p class="xpo-align-right">
              <b [translate]="'scheduled9690'">Scheduled:</b><span>: </span>
              {{stop.scheduledArrivalTime | xpocDateTime: dateTimeFormat}}</p>
          </div>

          <div fxHide fxShow.lt-md>
            <div fxLayout="row">
              <div fxLayout="row" class="data-left-padding ">
                <b>{{stop.city}}, {{ stop.stateCode}} {{stop.zipCode}}</b>
              </div>
            </div>
            <div fxLayout="row" class="data-left-padding">
              <b [translate]="'scheduled9690'">Scheduled:</b><span>: </span>
              {{stop.scheduledArrivalTime | xpocDateTime: dateTimeFormat}}</div>
          </div>
        </div>
      </div>

    </div>
  </li>
</ul>