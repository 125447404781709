import { Directive, Renderer2, Inject, Input, OnInit } from '@angular/core';
import { LiveChatService } from './live-chat.service';
import { ChatHelperService } from './chat-helper.service';
import { DOCUMENT } from '@angular/common';
declare const window: any;

@Directive({
  selector: 'appCustomLiveChatWidget'
})

export class CustomLiveChatWidgetDirective implements OnInit {

  @Input() liveChatParameter: any = {};

  public scriptLoaded = false;
  public pageName: string;
  public params: any;

  constructor(
    private renderer2: Renderer2,
    private liveChatService: LiveChatService,
    @Inject(DOCUMENT) private document,
    private chatHelperService: ChatHelperService) { }

  ngOnInit() {
    this.chatHelperService.pagesName.subscribe(page => {
      if (page) {
        this.pageName = page;
      }
    });

    if (this.liveChatParameter.businessUnit === this.liveChatParameter.enabledBusinessUnit) {
      this.liveChatService.notifier.subscribe(makeVisible => {
        if (makeVisible) {
          this.initScript();
        }
      });
    }

    this.liveChatService.notifier.subscribe(makeVisible => {
      if (!this.scriptLoaded) {
        return;
      }
      if (!makeVisible) {
        window.LC_API.hide_chat_window();
      } else {
        const isMaximized = window.LC_API.chat_window_maximized();
        if (isMaximized) {
          window.LC_API.minimize_chat_window();
        }
      }
      window.LC_API.set_custom_variables(this.getParams());
    });


  }

  private initScript() {
    window.__lc = window.__lc || {};
    window.__lc.license = this.liveChatParameter.licenseId;
    window.__lc.group = this.liveChatParameter.group;
    window.__lc.params = this.getParams();

    window.__lc.visitor = {
      name: this.liveChatParameter.name,
      email: this.liveChatParameter.userEmailId,
      partnerName: this.liveChatParameter.partnerName
    };

    const lc = this.renderer2.createElement('script');
    lc.type = 'text/javascript';
    lc.async = true;
    lc.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
    this.renderer2.appendChild(this.document.body, lc);
    lc.addEventListener('load', this.chatLoaded.bind(this));
  }

  chatLoaded() {
    if (window.LC_API) {
      window.LC_API.on_after_load = () => {
        this.scriptLoaded = true;
      };
    }
  }

  public getParams() {
    return this.params = [
      { name: 'Login', value: 'loggedIn' },
      { name: 'name', value: this.liveChatParameter.name },
      { name: 'page', value: this.pageName },
      { name: 'subPage', value: this.pageName },
      { name: 'partnerIdentifier', value: this.liveChatParameter.partnerIdentifier },
      { name: 'id', value: 'logged' },
      { name: 'BU', value: this.liveChatParameter.businessUnit },
      { name: 'layer', value: 'EF' },
      { name: 'EmailId', value: this.liveChatParameter.userEmailId },
      { name: 'partnerName', value: this.liveChatParameter.partnerName }
    ];
  }

}
