export class Location {
  constructor(
    public name: string,
    public latitude: number,
    public longitude: number
  ) { }
}

export class HomeLocation {
  constructor(
    public radius: number,
    public carrierCode: string,
    public address1: string,
    public countryCode: string,
    public city: string,
    public postalCode: string,
    public stateCode: string,
    public latitude: number,
    public longitude: number
  ) {
  }
}
