<section class="share-tracking-text-container">
    <form [formGroup]="shareTextForm">
        <div fxLayout="column" fxLayoutGap="10px" class="margin-bottom-15">
            <div [translate]="'sharedetail5422'">
                Share details via
            </div>
            <div>
                <mat-radio-group formControlName="mode" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-radio-button value="SMS" (change)="switchMessagingMode($event)">
                        SMS
                    </mat-radio-button>
                    <mat-radio-button value="WHATSAPP" (change)="switchMessagingMode($event)">
                        WhatsApp (Spain only)
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label [translate]="'languagefor5965'">Language for communication</mat-label>
            <mat-select id="language-selector" formControlName="language">
                <mat-option *ngFor="let language of supportedLanguages" [value]="language.code">
                    {{language.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="text-fields-container">
            <div fxLayout="row" formArrayName="recipients"
                *ngFor="let recipient of shareTextForm.get('recipients')['controls']; let i = index;"
                fxLayoutAlign="space-between center" fxLayoutGap="15px">
                <mat-form-field appearance="outline" fxFlex="45%" [formGroupName]="i">
                    <mat-label [translate]="'countryCode5026'" class="text-capitalize">country code</mat-label>
                    <mat-select id="country-code-{{i}}" formControlName="countryCode">
                        <mat-option *ngFor="let country of countryList" value="{{country.callingCode}}">
                            {{country.description}} {{country.callingCode}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="phone-no-error" *ngIf="(recipient.controls['countryCode']?.errors)">
                        {{recipient.controls['countryCode']?.errors?.invalid?.error}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="40%" [formGroupName]="i">
                    <mat-label class="text-capitalize">{{'phoneNumber9259' | translate}} {{i+1}}
                    </mat-label>
                    <input matInput size="100" type="text" formControlName="contact" id="phone-number-{{i}}"
                        maxlength="15" name="phoneNumber">
                    <mat-error *ngIf="(recipient.controls['contact']?.errors)">
                        {{recipient.controls['contact']?.errors?.invalid?.error | translate}}
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxFlex="15%" fxLayoutAlign="center center">
                    <div class="cursor-pointer margin-bottom-15">
                        <span class="icon-close" (click)="removePhone(i)"
                            *ngIf="(totalNumberOfContacts>1 && !isLoading)"></span>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
            <button id="btn-add-commodity" mat-button color="accent" class="text-bold-capitalize" (click)="addPhone()"
                [disabled]="totalNumberOfContacts<1 || totalNumberOfContacts === maximumLimitOfInvitees || shareTextForm.invalid || isLoading">
                <i class="icon-plus"></i>
                <span class="margin-left-5" [translate]="'addmore9264'">add more</span>
            </button>
        </div>
        <div class="margin-top-25 copy-checkbox">
            <mat-checkbox formControlName="shouldCopyMe" matTooltip="{{'whatsappopt268' | translate}}"
                [matTooltipDisabled]="shareTextForm.controls['shouldCopyMe'].status!=='DISABLED'">
                {{'sendtrackin340' | translate}}
            </mat-checkbox>
        </div>
        <div fxLayout="row" class="full-width" fxLayoutAlign="end center">
            <div>
                <button type="button" mat-raised-button id="cancel-btn" class="text-bold-uppercase"
                    (click)="onCancel()">
                    <span [translate]="'cancel7093'">CANCEL</span>
                </button>
            </div>
            <div class="margin-left-10">
                <button type="button" mat-raised-button [disabled]="shareTextForm.invalid" id="send-text-btn"
                    [class.btn-spinner]="isLoading" class="text-bold-uppercase" color="accent" (click)="sendText()">
                    <span [translate]="'send3670'">SEND</span>
                </button>
            </div>
        </div>
    </form>
</section>