import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionMessageComponent } from './session-message/session-message.component';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    CustomMaterialModule,
    TranslateModule
  ],
  declarations: [
    SessionMessageComponent
  ],
  entryComponents: [
    SessionMessageComponent
  ]
})
export class UserAuthenticationModule { }
