<nav class="mat-elevation-z1 navbar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div *ngIf="!isLoggedIn" class="hamburger-menu" fxHide fxShow.lt-lg fxFlex="30px" fxLayoutAlign="center center">
    <i class="icon-mobile_hamburger_thin" (click)="sideMenuTrigger()"></i>
  </div>
  <div class="connect-logo" (click)="onLogoClick()">
    <img [ngClass]="{'cursor-pointer': !isUnsupportedBrowser}" src="assets/img/xpo-connect-logo.png"
      class="logo-block padding-right-left-10">
  </div>
  <div fxFlex="none" fxLayoutAlign="start center" fxShow fxHide.lt-lg>
    <app-main-menu *ngIf="(isLoggedIn || (!isLoggedIn && isUnAuthMenu)) && source.length" [source]="source"
      [isUnAuthMenu]="isUnAuthMenu" [isQuickSignup]="isQuickSignup"></app-main-menu>
  </div>
  <div fxFlex="noshrink" class="right-shortcuts" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <ng-container *ngIf="!isUnsupportedBrowser">
      <div *ngIf="isLoggedIn && isNAUser;else promoLiveChat" fxLayout="column" fxLayoutAlign="center center">
        <div attr.data-id="{{livechatDataId}}" class="livechat_button livechat-icon">
          <a href={{livechatURL}}>chat</a>
        </div>
        <span class="text-capitalize" fxShow fxHide.lt-lg>live chat</span>
      </div>
    </ng-container>
    <ng-template #promoLiveChat>
      <div *ngIf="isNAUser" fxLayout="column" fxLayoutAlign="center center" (click)="openSignupPromo()"
        class="cursor-pointer">
        <i class="icon-chat right-sec-icons"></i>
        <span class="text-capitalize" fxShow fxHide.lt-lg>live chat</span>
      </div>
    </ng-template>
    <app-contact-us [isQuickSignup]="isQuickSignup"></app-contact-us>
    <div *ngIf="!isLoggedIn" fxLayout="column" fxLayoutAlign="center center" (click)="signIn()" class="cursor-pointer"
      fxHide.lt-sm fxShow>
      <i class="icon-account-circle right-sec-icons"></i>
      <span class="text-capitalize" fxShow fxHide.lt-lg>sign in</span>
    </div>
    <div *ngIf="!isLoggedIn" [matMenuTriggerFor]="menu" fxLayout="column" fxLayoutAlign="center center"
      class="cursor-pointer" fxHide fxShow.lt-sm>
      <i class="icon-account-circle right-sec-icons"></i>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item class="text-bold" (click)="signIn()">Sign In</button>
      <button mat-menu-item class="text-bold" (click)="signUp()">Sign Up for Free</button>
    </mat-menu>
  </div>
  <app-my-account *ngIf="!isUnsupportedBrowser && isLoggedIn;else unauth_carrier_details"></app-my-account>
</nav>
<ng-template #unauth_carrier_details>
  <div fxLayout="row" fxLayoutGap="10px" *ngIf="carrierDetails" class="carrier-details cursor-pointer" fxHide.lt-sm
    fxShow (click)="signUp()">
    <div fxLayout="row" fxLayoutAlign="center center" class="tier-icon tier-icon-platinum">
      <i class="icon-star"></i>
    </div>
    <div fxLayout="column" fxLayoutGap="5px" class="carrier-name">
      <span #carrier_name class="line-ellipsis" [matTooltip]="carrierDetails.carrierName"
        [matTooltipDisabled]="!sharedService.isEllipsisActive(carrier_name)" [matTooltipPosition]="'below'">
        {{carrierDetails.carrierName}}
      </span>
      <span class="claim-text-platinum line-ellipsis" #claim_text [matTooltip]="claim_text.textContent"
        [matTooltipDisabled]="!sharedService.isEllipsisActive(claim_text)" [matTooltipPosition]="'below'">
        Claim your rewards now</span>
    </div>
  </div>
</ng-template>