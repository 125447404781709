import { SortFilter } from './sort-filter';
import { TripLength } from './trip-length';

class DateField {
  constructor(
    public min: string,
    public max: string
  ) { }
}
class Location {
  constructor(
    public latitude: number,
    public longitude: number,
    public location: string,
    public radius: number,
    public unit: string,
    public type?: string
  ) { }
}
class WeightLimit {
  constructor(
    public min: number,
    public max: number
  ) { }
}

export class AvailableLoadsRequest {
  constructor(
    public deliveryDate?: DateField,
    public originLocations?: Location[],
    public destinationLocations?: Location[],
    public laneSearch?: any,
    public pickupDate?: DateField,
    public q?: string,
    public status?: string[],
    public equipmentCategory?: string[],
    public additionalStops?: string[],
    public availableDays?: string[],
    public isExcludeCommodity?: boolean,
    public orderCommodity?: string[],
    public equipmentSize?: string[],
    public additionalAvailability?: string[],
    public additionalRequirement?: string[],
    public pickUpTime?: string[],
    public deliveryTime?: string[],
    public totalItemCount?: number,
    public weight?: number,
    public weightUnit?: string,
    public isNoWeekendLoads?: boolean,
    public isOnlyWeekendLoads?: boolean,
    public isCPAT?: boolean,
    public isSmartway?: boolean,
    public isTSA?: boolean,
    public isPIP?: boolean,
    public isFAST?: boolean,
    public isCSA?: boolean,
    public isCustomsBroker?: boolean,
    public isTWICcard?: boolean,
    public isUIIAcard?: boolean,
    public isDropAndHook?: boolean,
    public isHazmat?: boolean,
    public isTemperatureControlled?: boolean,
    public page?: number,
    public pageSize?: number,
    public driverName?: string,
    public driverId?: number,
    public selectedDriverLocation?: string,
    public sortExpressions?: SortFilter[],
    public distance?: TripLength,
    public ratePerDistance?: TripLength,
    public isHotDeal?: number,
    public alternateNumber?: string,
    public tripNumber?: string,
    public originSearch?: any[],
    public destinationSearch?: any[],
    public isDeadheadSort?: boolean,
    public weightRange?: WeightLimit
  ) { }
}
