<form [formGroup]="quickSignupForm">
    <div mat-dialog-title class="quicksignup-title" fxLayout="row" fxLayoutAlign="space-between start"
        fxLayoutGap="10px" [ngClass]="{'margin-0' : isSubmitted || isError}">
        <span *ngIf="!isSubmitted && !isError">Sign Up for Free</span>
        <span class=" dialog-close-icon close-btn" fxFlexAlign="start">
            <i class="icon-close" (click)="onClose()"></i>
        </span>
    </div>

    <ng-container *ngIf="!isLoading;else loader">
        <ng-container *ngIf="!isError;else error_msg">
            <ng-container *ngIf="!isSubmitted;else status_msg">
                <mat-dialog-content>
                    <div class="quicksignup-form" fxLayout="column" fxLayoutGap="15px">
                        <div class="padding-bottom-20">Already have an account?
                            <span class="btn-link" (click)="signIn()">Sign In</span>
                        </div>
                        <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="15px">
                            <mat-form-field appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="firstName" name="firstName" id="profile-firstName"
                                    required>
                                <mat-error
                                    *ngIf="quickSignupForm.controls.firstName.touched && quickSignupForm.controls.firstName.errors && 
                    (quickSignupForm.controls.firstName.errors.invalidName || quickSignupForm.controls.firstName.errors.required)">
                                    Enter valid first name
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="lastName" name="lastName" id="profile-lastName"
                                    required>
                                <mat-error
                                    *ngIf="quickSignupForm.controls.lastName.touched && quickSignupForm.controls.lastName.errors && 
                    (quickSignupForm.controls.lastName.errors.invalidName || quickSignupForm.controls.lastName.errors.required)">
                                    Enter valid last name
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="15px">
                            <mat-form-field appearance="outline" ngClass.gt-xs="full-width">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" name="email" id="email" required>
                                <mat-error
                                    *ngIf="quickSignupForm.controls.email.touched &&quickSignupForm.controls.email.errors &&
                (quickSignupForm.controls.email.errors.invalidEmail || quickSignupForm.controls.email.errors.required)">
                                    Enter valid email
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="15px">
                            <mat-form-field appearance="outline">
                                <mat-label>Country Code</mat-label>
                                <mat-select id="country-code" name="countryCode" formControlName="countryCode"
                                    (selectionChange)="onSelectCountryCode($event)" required>
                                    <mat-option *ngFor="let code of phoneCountryCodes" [value]="code.value">
                                        {{code.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="quickSignupForm.controls.countryCode.touched && quickSignupForm.controls.countryCode.errors && quickSignupForm.controls.countryCode.errors.required">
                                    Select valid country code
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Phone Number</mat-label>
                                <input matInput formControlName="userPhone" name="userPhone" id="user_phone"
                                    [value]="quickSignupForm['controls'].userPhone.value | xpocPhoneFormatting : tenandId"
                                    maxlength="12" placeholder="XXX-XXX-XXXX" required>
                                <mat-error
                                    *ngIf="quickSignupForm.controls.userPhone.touched && quickSignupForm.controls.userPhone.errors &&
                (quickSignupForm.controls.userPhone.errors.invalidPhone || quickSignupForm.controls.userPhone.errors.required)">
                                    Enter valid phone #
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="10px" class="padding-bottom-20">
                            <mat-checkbox formControlName="termsAndConditions" (change)="termsAndConditions($event)"
                                required>
                            </mat-checkbox>
                            <div>
                                I have read and accept the
                                <span class="btn-link" (click)="openTermsConditions()">Terms &amp; Conditions</span> and
                                <span class="btn-link" (click)="openPrivacyPolicy()">Privacy Policy</span>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="5px"
                            [ngClass]="{'recaptcha-disable':(quickSignupForm.invalid || !isTermsAndConditions)}"
                            class="padding-bottom-20">
                            <ngx-recaptcha2 #captchaElem (success)="resolved($event)"
                                formControlName="recaptchaReactive" siteKey="{{ xpoSiteKey }}">
                            </ngx-recaptcha2>
                            <mat-error
                                *ngIf="quickSignupForm.controls.recaptchaReactive.errors && quickSignupForm.controls.recaptchaReactive.errors.invalidCaptcha">
                                Invalid Captcha. Please retry.
                            </mat-error>
                        </div>
                        <button mat-raised-button class="create-acc-btn" type="button" fxFlexAlign="start"
                            [class.btn-spinner]="isLoading"
                            [disabled]="quickSignupForm.invalid || !isTermsAndConditions || isSubmitted || !captchaCallbackVal"
                            (click)="submitRegistration()">Create Account</button>
                    </div>
                </mat-dialog-content>
            </ng-container>
            <ng-template #status_msg>
                <ng-container *ngIf="regResponse.isSuccess;else error_msg">
                    <ng-container
                        *ngIf="regResponse.isSetupAndActive || regResponse.needsManualSetup || regResponse.isUserExisting || regResponse.isRequestWithinSLA ; else invaildDotNo">
                        <ng-container *ngIf="regResponse.isUserExisting">
                            <mat-dialog-content class="status-msg">
                                <img class="margin-bottom-20" src="assets/img/reg_msg.svg" />
                                <div class="response-title">This email is already in use. </div>
                                <div class="response-msg">Please <b>login</b> with your current user name and password.
                                    If you forgot your password,please click on <b>Reset Password</b>
                                </div>
                            </mat-dialog-content>
                            <mat-dialog-actions align="end">
                                <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
                                    <button mat-raised-button (click)="reset()" class="text-uppercase">Reset
                                        Password</button>
                                    <button mat-raised-button color="accent" (click)="login()"
                                        class="text-uppercase">Login</button>
                                </div>
                            </mat-dialog-actions>
                        </ng-container>
                        <ng-container *ngIf="regResponse.needsManualSetup && regResponse.carrierStatusCode==='ND'">
                            <mat-dialog-content class="status-msg">
                                <img class="margin-bottom-20" src="assets/img/reg_success.svg" />
                                <div class="response-title"> Thank You!</div>
                                <div class="response-msg"> Your Sign up request has been received. If you've moved
                                    freight with XPO, you will receive an email to set a password within 48 hours.
                                    <P>For any queries, email us at <b>{{supportContact.email}}</b></P>
                                </div>
                            </mat-dialog-content>
                        </ng-container>
                        <ng-container *ngIf="regResponse.isSetupAndActive">
                            <mat-dialog-content class="status-msg">
                                <img class="margin-bottom-20" src="assets/img/reg_success.svg" />
                                <div class="response-title">Your Sign up request has been received</div>
                                <div class="response-msg">Please check your email to <b>create password</b> and complete
                                    registration
                                </div>
                            </mat-dialog-content>
                        </ng-container>
                        <ng-container
                            *ngIf="(regResponse.registrationStatusCode === statusCodes.underSupportReview && regResponse.carrierStatusCode!=='ND') || (regResponse.isAccountingOffice && regResponse.needsManualsetup)">
                            <mat-dialog-content class="status-msg">
                                <img class="margin-bottom-20" src="assets/img/reg_success.svg" />
                                <div class="response-title"> Thank You!</div>
                                <div class="response-msg">Your Sign up request has been received. If you do not receive
                                    an update on your request within 48 hours, you may reach out to XPO Support at
                                    <b>{{supportContact.email}}</b>
                                </div>
                            </mat-dialog-content>
                        </ng-container>
                        <ng-container *ngIf="regResponse.registrationStatusCode === statusCodes.underAdminReview">
                            <mat-dialog-content class="status-msg">
                                <img class="margin-bottom-20" src="assets/img/reg_success.svg" />
                                <div class="response-title"> Thank You!</div>
                                <div class="response-msg">Your access request has been sent to your carrier’s Admin.
                                    Please contact your Admin if you have any questions about your request.
                                </div>
                            </mat-dialog-content>
                        </ng-container>
                        <ng-container
                            *ngIf="regResponse.isRequestWithinSLA && (regResponse.registrationStatusCode !== statusCodes.underSupportReview && regResponse.registrationStatusCode !== statusCodes.underAdminReview)">
                            <mat-dialog-content class="status-msg">
                                <img class="margin-bottom-20" src="assets/img/reg_success.svg" />
                                <div class="response-title"> Thank You!</div>
                                <div class="response-msg">Your Sign up request has been received and is being reviewed.
                                    Please allow 48 hours for us to review your request.
                                    <p>If you have not received an update after 48
                                        hours, please feel free to reach out to us at <b>{{supportContact.email}}</b>
                                    </p>
                                </div>
                            </mat-dialog-content>
                        </ng-container>
                    </ng-container>
                    <ng-template #invaildDotNo>
                        <mat-dialog-content class="status-msg">
                            <img class="margin-bottom-20" src="assets/img/reg_success.svg" />
                            <div class="response-title"> Thank You!</div>
                            <div class="response-msg"> Please check your email to continue with the registration process
                            </div>
                        </mat-dialog-content>
                    </ng-template>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-template #error_msg>
            <mat-dialog-content class="status-msg">
                <img class="margin-bottom-20" src="assets/img/reg_error.svg" />
                <div class="response-title">We were unable to create your account at this time.</div>
                <div class="response-msg">Please contact us so we can help.</div>
                <ng-container *ngTemplateOutlet="support"></ng-container>
            </mat-dialog-content>
        </ng-template>
    </ng-container>
    <ng-template #loader class="loader">
        <mat-dialog-content>
            <app-page-loader></app-page-loader>
        </mat-dialog-content>
    </ng-template>
    <ng-template #support>
        <div class="response-msg">
            <i class="icon-mail support-icon"></i>
            <a class="support-link" href="mailto:{{supportContact.email}}?subject={{supportContact.emailSubject}}">
                {{supportContact.email}}</a>
        </div>
        <div class="response-msg">
            <i class="icon-phone support-icon"></i>
            <a class="support-link" href="tel:{{supportContact.phone}}">{{supportContact.phone}}</a>
        </div>
    </ng-template>
</form>