import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { FomFilter } from '../../model/fom-filter';

@Component({
  selector: 'app-filter-dropdown-mobile',
  templateUrl: './filter-dropdown-mobile.component.html',
  styleUrls: [ './filter-dropdown-mobile.component.scss' ]
})
export class FilterDropdownMobileComponent implements OnInit {

  @Input() filterList: FomFilter[];
  @Input() filterLabel: string;
  @Input() initCheckedStatus: string[];
  @Input() filterReset: Subject<boolean>;
  @Output() filter = new EventEmitter();
  public selectedFilterList: string[];
  public selectedFilterCodeList: string[];
  public checkedFilter: boolean[];
  public selectAllChecked: boolean;
  private PreviousCheckedList: boolean[];
  private PreviousSelectedList: string[];
  private PreviousSelectedCodeList: string[];

  constructor() { }

  ngOnInit() {
    this.initializeData();
    if (this.filterReset) {
      this.filterReset.subscribe(val => {
        if (val) {
          this.clear();
        }
      });
    }
  }

  initializeData() {
    this.selectedFilterList = [];
    this.selectedFilterCodeList = [];
    this.checkedFilter = [];
    this.PreviousCheckedList = [];
    this.PreviousSelectedList = [];
    this.PreviousSelectedCodeList = [];
    this.filterList.forEach(element => {
      if (this.initCheckedStatus && this.initCheckedStatus.length > 0) {
        this.checkedFilter.push(this.initCheckedStatus.indexOf(element.code.toString()) !== -1);
        this.PreviousCheckedList.push(this.initCheckedStatus.indexOf(element.code.toString()) !== -1);
        if (this.initCheckedStatus.indexOf(element.code.toString()) !== -1) {
          this.selectedFilterList.push(element.name);
          this.PreviousSelectedList.push(element.name);
          this.PreviousSelectedCodeList.push(element.code.toString());
        }
      } else {
        this.checkedFilter.push(false);
        this.PreviousCheckedList.push(false);
      }
    });
    if (this.initCheckedStatus && (this.initCheckedStatus.length === this.filterList.length)) {
      this.selectAllChecked = true;
    }
  }

  filterChange(event, index) {
    this.checkedFilter[ index ] = !this.checkedFilter[ index ];
    if (!event.checked && this.selectAllChecked) {
      this.selectAllChecked = !this.selectAllChecked;
    } else {
      if (this.checkedFilter.indexOf(false) === -1) {     // check all filters are checked
        this.selectAllChecked = true;
      }
    }
    this.submit();
  }

  selectAllFilter(event) {
    this.selectAllChecked = true;
    if (event.checked) {
      this.checkedFilter = Array(this.filterList.length).fill(true);
    } else {
      this.checkedFilter = Array(this.filterList.length).fill(false);
    }
    this.submit();
  }

  clear() {
    this.selectAllChecked = false;
    this.checkedFilter = Array(this.filterList.length).fill(false);
  }

  submit() {
    this.selectedFilterList = [];
    this.selectedFilterCodeList = [];
    for (let i = 0; i < this.checkedFilter.length; i++) {
      if (this.checkedFilter[ i ]) {
        this.selectedFilterList.push(this.filterList[ i ].name);
        this.selectedFilterCodeList = this.selectedFilterCodeList.concat(this.filterList[ i ].code);
      }
    }
    this.filter.emit(this.selectedFilterCodeList);
    this.PreviousSelectedList = this.selectedFilterList.slice();
    this.PreviousSelectedCodeList = this.selectedFilterCodeList.slice();
    this.PreviousCheckedList = this.checkedFilter.slice();
  }

}
