<div class="main-menubar">
    <ul>
        <li *ngFor="let item of source; let i=index;" class="text-capitalize">
            <div *ngIf="item.subItem.length > 0 ; else singleMenu">
                <button id={{item.subItem[0].code}} mat-flat-button class="text-capitalize"
                    *ngIf="!isUnAuthMenu;else unAuthMenu" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{ exact: false }" [routerLink]="[item.link]" [matMenuTriggerFor]="menu"
                    (mouseenter)="openMenu(i)" (click)="menuClick(item)">{{ item.name | translate }}
                    <i class="icon-chevron-down"></i>
                </button>
                <ng-template #unAuthMenu>
                    <button id={{item.subItem[0].code}} mat-flat-button class="text-capitalize"
                        [matMenuTriggerFor]="menu" (mouseenter)="openMenu(i)" (click)="menuClick(item)">{{ item.name |
                        translate }}
                        <i class="icon-chevron-down"></i>
                    </button>
                </ng-template>
                <mat-menu #menu="matMenu" [hasBackdrop]="false" class="main-menu">
                    <span (mouseleave)="closeMenu(i)">
                        <ul class="sub-menu-item">
                            <li class="text-capitalize" *ngFor="let s of item.subItem; let i=index;">
                                <button id={{s.code}} mat-flat-button class="text-capitalize"
                                    *ngIf="!isUnAuthMenu;else unAuthMenuItem" routerLinkActive="active-link"
                                    [routerLink]="[s.link]" [routerLinkActiveOptions]="{ exact: false }">
                                    {{ s.name | translate }}
                                    <span *ngIf="s.isNewItem" class="new-menu">NEW</span>
                                </button>
                                <ng-template #unAuthMenuItem>
                                    <button id={{s.code}} mat-flat-button class="text-capitalize"
                                        (click)="menuClick(s)">{{ s.name | translate }}
                                        <span *ngIf="s.isNewItem" class="new-menu">NEW</span>
                                    </button>
                                </ng-template>
                            </li>
                        </ul>
                    </span>
                </mat-menu>
            </div>
            <ng-template #singleMenu>
                <button id={{item.code}} mat-flat-button routerLinkActive="active-link"
                    *ngIf="!isUnAuthMenu;else unAuthSingleMenu" [routerLinkActiveOptions]="{ exact: false }"
                    [routerLink]="[item.link]">{{ item.name | translate}}
                </button>
                <ng-template #unAuthSingleMenu>
                    <button id={{item.code}} mat-flat-button (click)="menuClick(item)">
                        {{ item.name | translate}}
                    </button>
                </ng-template>
            </ng-template>
        </li>
    </ul>
</div>