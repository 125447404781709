import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './user-authentication/auth.service';
import { Subscription } from 'rxjs';

declare const gtag;
declare const newrelic;

@Component({
  selector: 'app-root',
  template: `<app-layout></app-layout>`
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    public authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', { page_path: event.urlAfterRedirects });
        gtag('set', { dimension1: this.authService.username }); // Setting up username as dimension for reports
        gtag('set', { dimension2: this.authService.partnerIdentifier }); // Setting up partner code for user differentiation
        gtag('set', { dimension3: this.authService.businessUnit }); // Setting up partner code for user differentiation
        gtag('event', 'page_view', {
          userName: this.authService.username,
          partnerIdentifier: this.authService.partnerIdentifier,
          businessUnit: this.authService.businessUnit
        });
        newrelic.setCustomAttribute('userName', this.authService.username);
        newrelic.setCustomAttribute('partnerIdentifier', this.authService.partnerIdentifier);
        newrelic.setCustomAttribute('businessUnit', this.authService.businessUnit);
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
