export class MapOptions {
  constructor(
    public showRoute?: boolean,
    public showHomeLocation?: boolean,
    public hideAvailableLoads?: boolean,
    public centerHomeLocation?: boolean,
    public showTruckClusterDialog?: boolean,
    public showAvailLoadMarkerLevelZoom?: boolean
  ) { }
}
