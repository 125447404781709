
export class SavedSearchResponse {
  constructor(
    public currentPage: number,
    public items: any,
    public pageSize: number,
    public totalItemCount: number,
    public totalPagesCount: number,
    public shouldEnableNotificationToggle: boolean
  ) { }
}
