import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-map-error',
  templateUrl: './google-map-error.component.html',
  styleUrls: ['./google-map-error.component.scss']
})
export class GoogleMapErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
