import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoadFilter } from 'src/app/shared/model/load-filter';
import { LoadResponse } from 'src/app/shared/model/load-response';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  private baseUrl: string;
  public orderId: any;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService
  ) {
    this.baseUrl = appSettings.apiUrl;
  }

  getDocuments(tripCode, pdfOnly = false) {
    const url = this.getUri('documents/trips');
    let params = new HttpParams().set('tripCode', tripCode);
    if (pdfOnly) {
      params = params.set('allowedFileExtensionsForRateCon', '.pdf');
    }
    return this.http
      .request('get', url, { params })
      .toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);
  }

  getRateConGuid(tripCode) {
    const url = this.getUri('loads/ratecon/metadata');
    const params = new HttpParams().set('carrierCode', this.authService.partnerCode).set('tripCode', tripCode);
    return this.http
      .request('get', url, { params })
      .toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);
  }

  getDocumentsEu(tripCode, stops) {
    let deliveryOrderNumbers = [];
    if (stops && stops.length) {
      stops.forEach((stop) => {
        if (stop.type === 'Delivery') {
          deliveryOrderNumbers.push(stop.customerOrderNumber);
        }
      });
    }
    const url = this.getUri('documents/carrierdocumentlist');
    const requestPayload = {
      orderNumber: deliveryOrderNumbers,
      tripCode: tripCode
    };
    return this.http
      .post(url, requestPayload, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);
  }

  getInvoices(tripCode, orderCode) {
    let url = this.getUri('invoices/orders/');
    url = url + orderCode + '/trips/' + tripCode;
    const params = new HttpParams()
      .set('partnerIdentifier', this.authService.partnerIdentifier);
    return this.http
      .request('get', url, { params })
      .toPromise()
      .then(response => {
        return response;
      }).catch(this.handleError);
  }

  getOrders(orderFilterObj: LoadFilter): Observable<LoadResponse> {
    const url = this.getUri(`loads/search`);
    return this.http
      .post(url, orderFilterObj, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  uploadDoc(formData: FormData): Observable<any> {
    const url = this.getUri(`documents/carrierfinancial/upload`);
    return this.http
      .post(url, formData, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getDownloadUrl(fileId, partnerIdentifier?: string) {
    const partnerId = partnerIdentifier || this.authService.partnerIdentifier;
    return this.getUri('partners/' + btoa(partnerId) + '/document/' + fileId);
  }

  getDocViewUrl(fileId, partnerIdentifier?: string) {
    const partnerId = partnerIdentifier || this.authService.partnerIdentifier;
    return this.baseUrl.replace('api/', '') + btoa(partnerId) + '/document/' + fileId;
  }

  getDocAsBlob(fileId, partnerIdentifier: string): Observable<any> {
    const url = this.getDocViewUrl(fileId, partnerIdentifier);
    return this.http.get(url, { responseType: 'blob' });
  }

  deleteDoc(refID): Observable<any> {
    const url = this.getUri(`documents/carrierfinancial/${refID}`);
    return this.http
      .delete(url, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));

  }

  replaceDoc(refID, fileData): Observable<any> {
    const url = this.getUri(`documents/carrierfinancial/${refID}`);
    return this.http
      .put(url, fileData, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));

  }

  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getOptions() {
    const options = { headers: this.getHeaders(), withCredentials: true };
    return options;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + endpoint;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

}
