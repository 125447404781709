import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ]
})
export class ConfirmDialogComponent implements OnInit {

  public title: string;
  public message: string;
  public acceptButtonLabel: string;
  public rejectButtonLabel: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = this.data.message;
    this.title = this.data.title;
    this.acceptButtonLabel = typeof this.data.acceptButtonLabel !== 'undefined' ? this.data.acceptButtonLabel : 'ok757';
    this.rejectButtonLabel = typeof this.data.rejectButtonLabel !== 'undefined' ? this.data.rejectButtonLabel : 'cancel7093';
  }

  ngOnInit() {
  }

  public onClose(isAccepted: boolean) {
    this.dialogRef.close(isAccepted);
  }

}
