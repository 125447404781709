import { AvailableLoad } from './available-load';

export class AvailableLoadsResponse {
  constructor(
    public currentPage: number,
    public items: AvailableLoad[],
    public pageSize: number,
    public totalItemCount: number,
    public totalPagesCount: number,
    public loadNumber?: number,
    public loadStatus?: number,
    public id?: number,
    public extendedJsonFilter?: any,
    public isMoreSimilarLoads?: boolean
  ) { }
}
