<div class="notifications-container">
  <div class="notifications-header">
    <h3>{{getNotificationCount()}} notifications</h3>
  </div>
  <div class="no-notifications"
    *ngIf="(!trafficAlertItems || trafficAlertItems.length == 0)  && (!weatherAlertList || weatherAlertList.length == 0); else notifications">
    <i class="icon-exclaim_btn record-icon"></i>
    <p [translate]="'nonoti309'">No notifications were found</p>
  </div>
  <ng-template #notifications>
    <div class="dismiss-notifications">
      <p (click)="onDismissNotification()" [translate]="'dismis3471'">dismiss all notifications</p>
    </div>
    <div class="traffic-div" id="traffic_weather_alert">
      <mat-card class="traffic-card" *ngFor="let trafficItem of (trafficAlertItems ? trafficAlertItems.slice(0,2): [])">
        <mat-card-header class="traffic-header">
          <mat-card-title>{{'traffi3754' | translate}}</mat-card-title>

          <button class="close-button" (click)="onCloseTrafficAlert(trafficItem)" mat-button>
            <i class="icon-close"></i>
          </button>
        </mat-card-header>
        <mat-card-content class="traffic-content">
          <span>{{trafficItem.lastModified}}</span>
          <br />
          <span>{{trafficItem.description | titlecase}}</span>
        </mat-card-content>
      </mat-card>
      <mat-card class="traffic-card" *ngFor="let weatherItem of (weatherAlertList ? weatherAlertList.slice(0,1): [])">
        <mat-card-header class="traffic-header">
          <mat-card-title>{{'weathe1664' | translate}}</mat-card-title>
          <button class="close-button" (click)="onCloseWeatherAlert(weatherItem)" mat-button>
            <i class="icon-close"></i>
          </button>
        </mat-card-header>
        <mat-card-content class="traffic-content">
          <div class="weather-content-div">
            <img src="assets/icons/weather-alert-red.svg" class="red-icon" />
            <img src="{{weatherItem.icon}}" class="weather-icon" />
            <div class="weather-message">
              <span>{{weatherItem.lastModified}}</span>
              <br />
              <span>{{weatherItem.weather}} in </span>
              <br />
              <span>{{weatherItem.place}}</span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-template>
</div>