<u class="cursor-pointer" (click)="showStops = !showStops">Show All</u>
<div *ngIf="showStops" class="inter-stops stop-card" ngClass.lt-sm="mobile-card">
  <div fxLayout="row" class="padding-15" fxLayoutGap="15px">
    <i class="icon-plus-box-filled"></i>
    <div>{{stops.length}} STOPS</div>
    <i class="icon-close margin-left-auto cursor-pointer" (click)="onCloseClick()"></i>
  </div>
  <div class="stops padding-right-left-15 padding-bottom-15">
    <div class="margin-top-15" fxLayout="row" fxLayoutGap="15px" *ngFor="let stop of stops; index as i">
      <i class="icon-square-round-edges square-icon"></i>
      <div class="stop-count" fxLayout="column">
        <div>STOP {{i+1}} •
          <span class="text-uppercase" [translate]="'pickUp3015'"
            *ngIf="stop.type === stopTypes.pickUp; else destinationLabel;">Pick Up </span>
          <ng-template #destinationLabel>
            <span class="text-uppercase" [translate]="'delivery8808'">Delivery </span>
          </ng-template>
        </div>
        <div class="margin-top-bottom-3 font-weight-normal">{{stop.cityName}},
          {{isNAUser? (stop.stateCode || stop.province) : (stop.countryName || stop.countryCode)}} {{stop.zipCode || stop.postalCode}}
        </div>
        <div class="time font-weight-normal">
          {{stop.scheduledArrivalEarly | xpocDateTime: monthDateTimeFormat}}
          <span *ngIf="!stop.scheduledArrivalLate">{{stop.timeZoneCode || stop.timeZoneAbbreviation}}</span>
          <span class="time" *ngIf="stop.scheduledArrivalLate"> - {{getScheduledArrivalLate(stop.scheduledArrivalEarly,
            stop.scheduledArrivalLate)}}
            {{stop.timeZoneCode || stop.timeZoneAbbreviation}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>