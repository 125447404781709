import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-confirmation-dialog',
  templateUrl: './cancel-confirmation-dialog.component.html',
  styleUrls: [ './cancel-confirmation-dialog.component.scss' ]
})
export class CancelConfirmationDialogComponent implements OnInit {
  public isDiscardChanges = false;
  public loadNumber: string;
  constructor(
    public dialogRef: MatDialogRef<CancelConfirmationDialogComponent>
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.loadNumber = this.dialogRef._containerInstance._config.data.loadNumber;
  }

  onClose() {
    this.dialogRef.close({
      isDiscardChanges: this.isDiscardChanges
    });
  }
  onDiscardChanges() {
    this.isDiscardChanges = true;
    this.onClose();
  }
}
