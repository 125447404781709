import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selection-tile',
  templateUrl: './selection-tile.component.html',
  styleUrls: [ './selection-tile.component.scss' ]
})
export class SelectionTileComponent implements OnInit {

  // Flag for enabling multiple selection
  @Input() multiple = true;
  @Input() items: any[];
  @Input() selectedItems: any[];
  @Input() withoutGap = false;
  @Output() selected = new EventEmitter<any[]>();

  constructor() {
  }

  ngOnInit() {
  }

  onSelect(selectedItem: any) {
    if (this.selectedItems && this.selectedItems.includes(selectedItem.code)) {
      this.multiple ? this.selectedItems.splice(this.selectedItems.indexOf(selectedItem.code), 1) : this.selectedItems = [];
    } else {
      this.multiple ? this.selectedItems.push(selectedItem.code) : this.selectedItems = [ selectedItem.code ];
    }
    this.selected.emit(this.multiple ? this.selectedItems : this.selectedItems[ 0 ]);
  }
}
