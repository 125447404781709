import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { AppSettings } from '../app-settings';
import { GlobalService } from 'src/app/shared/global.service';
import { AvailableLoadsService, KeystoneService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { XPOConstants } from 'src/app/shared';
import { SideMenuSource } from 'src/app/shared/model/side-menu-source';
import { SharedService } from 'src/app/shared/services/shared.service';
import { LogEvent } from 'src/app/shared/model';
import { SignupPromoDialogComponent } from 'src/app/shared/signup-promo-dialog/signup-promo-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuickSignupDialogComponent } from 'src/app/shared/quick-signup-dialog/quick-signup-dialog.component';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() source: SideMenuSource[];
  @Input() public isUnAuthMenu: boolean;
  @Input() public isQuickSignup: boolean;
  @Output() public sideNavTrigger: EventEmitter<any> = new EventEmitter();
  public isUnauthenticatedPage = false;
  public isUnsupportedBrowser = false;
  public isLoggedIn = false;
  public permissions: any = [];
  public livechatURL: string;
  public livechatDataId: any;
  public isNAUser: boolean;
  public showMenu: boolean;
  public carrierDetails: any;
  private unAuthPartnerIdSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private appSettings: AppSettings,
    public sharedService: SharedService,
    public globalService: GlobalService,
    public availableLoadsService: AvailableLoadsService,
    public router: Router,
    private keystoneService: KeystoneService,
    private dialog: MatDialog,
    private dashboardService: DashboardService
  ) {
    this.isUnauthenticatedPage = appSettings.isUnAutheticatedPage;
    this.isLoggedIn = this.authService.isLoggedIn;
    this.isUnsupportedBrowser = this.sharedService.isUnSupportedBrowser();
    this.isNAUser = this.authService.checkNAUser();
  }

  ngOnInit() {
    this.permissions = (this.authService && this.authService.permissions) ? this.authService.permissions : [];
    this.livechatDataId = this.appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension10);
    this.livechatURL = XPOConstants.livechatKeys.customLivechatUrl +
      this.appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension8);
    this.unAuthPartnerIdSubscription = this.sharedService.getPartnerIdUnAuth().subscribe({
      next: (partnerIdentifier) => {
        if (this.isUnauthenticatedPage && !this.isLoggedIn && partnerIdentifier) {
          this.getProfile(partnerIdentifier);
        }
      }
    });
  }

  onLogoClick() {
    if (!this.isUnsupportedBrowser) {
      this.isLoggedIn ? this.router.navigate([ this.authService.homeLocation ]) : this.openSignupPromo();
    }
  }

  signIn() {
    this.logKeystone(XPOConstants.keystoneEventName.navBarSignIn, XPOConstants.keystoneEventAction.click);
    this.sharedService.setPreviousRoute();
    window.location.href = this.appSettings.apiUrl.replace('api/', 'session/create');
  }

  signUp() {
    if (this.isQuickSignup && this.sharedService.partnerIdentifierUnAuth) {
      this.logKeystone(XPOConstants.keystoneEventName.navBarQuickSignUp, XPOConstants.keystoneEventAction.click);
      const config: MatDialogConfig = { autoFocus: false };
      this.dialog.open(QuickSignupDialogComponent, config);
    } else {
      this.logKeystone(XPOConstants.keystoneEventName.navBarSignUp, XPOConstants.keystoneEventAction.click);
      this.router.navigate([ '/registration' ], { queryParams: { source: this.router.url.split('?')[ 0 ].substr(1) } });
    }
  }

  openSignupPromo(urlPath?: string) {
    const config: MatDialogConfig = { autoFocus: false, data: { isQuickSignup: this.isQuickSignup } };
    if (urlPath) {
      const prevUrl = this.appSettings.apiUrl.replace('api/', 'carrier' + urlPath);
      config.data.prevUrl = prevUrl;
    }
    this.dialog.open(SignupPromoDialogComponent, config);
  }

  sideMenuTrigger() {
    this.sideNavTrigger.emit();
  }

  private getProfile(partnerIdentifier: string) {
    if (partnerIdentifier) {
      this.dashboardService.getCarrierDetails(partnerIdentifier).subscribe({
        next: (response) => { this.carrierDetails = response; },
        error: (error) => { this.carrierDetails = null; }
      });
    }
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const page = this.router.url.split('?')[ 0 ].substr(1);
    const logEvent = new LogEvent(
      XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction, page, XPOConstants.keystoneSubPage.menu);
    this.keystoneService.logAnonymousEvent(logEvent);
  }

  ngOnDestroy() {
    if (this.unAuthPartnerIdSubscription) {
      this.unAuthPartnerIdSubscription.unsubscribe();
    }
  }
}
