import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PhoneFormattingPipe, Validations } from '@xpoc/ngx-common';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';

import { XPOConstants } from 'src/app/shared';
import { User } from 'src/app/shared/model/user.model';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { AppSettings } from '../app-settings';
import { KeystoneService } from 'src/app/shared/services';
import { LogEvent } from 'src/app/shared/model';
@Component({
  selector: 'app-eu-support-form',
  templateUrl: './eu-support-form.component.html',
  styleUrls: [ './eu-support-form.component.scss' ]
})
export class EuSupportFormComponent implements OnInit {
  public euSupportTypes: any[];
  public euSupportForm: FormGroup;
  public emailAddress: string;
  public countryList: [];
  public showConfirmationMsg: boolean;

  constructor(
    private dialogRef: MatDialogRef<EuSupportFormComponent>,
    private formBuilder: FormBuilder,
    private appSettings: AppSettings,
    private lookupService: LookupService,
    public customPhoneFormatting: PhoneFormattingPipe,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private dashboardService: DashboardService,
    private keystoneService: KeystoneService

  ) {
    this.fetchCountryList();
  }

  ngOnInit() {
    this.showConfirmationMsg = true;
    this.emailAddress = this.appSettings.getApplicationConstant('XpoConnectCarrierSupportEmailAddress');
    this.euSupportTypes = XPOConstants.euSupportType;
    this.euSupportForm = this.createForm();
    this.populateUserDetails();


  }
  private createForm() {
    const group = this.formBuilder.group({
      supportType: [ '', [ Validators.required ] ],
      tripId: [ '' ],
      note: [ '', [ Validators.required ] ],
      xpoRepresentative: [ '' ],
      firstName: [ '', [ Validators.required ] ],
      lastName: [ '', [ Validators.required ] ],
      countryCode: [ '', Validators.required ],
      phoneNumber: [ '', [ Validations.euPhone, Validators.required ] ],
      email: [ '', [ Validations.email, Validators.required ] ],
    });
    return group;
  }
  private populateUserDetails() {
    this.userProfileService.getUserProfile().then(user => {

      if (user.countryCode) {
        user.countryCode = user.countryCode.indexOf('+') !== -1 ? user.countryCode :
          '+' + user.countryCode;
      }
      this.euSupportForm.setValue({
        supportType: '',
        tripId: '',
        note: '',
        xpoRepresentative: '',
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        countryCode: user.countryCode,
        phoneNumber: user.nationalPhone
      });
    });
  }
  formatPhone() {
    const driverForm = this.euSupportForm.value;
    this.euSupportForm.get('phoneNumber').setValue(this.customPhoneFormatting.transform(driverForm.phoneNumber, this.authService.tenantId));
  }
  private fetchCountryList() {
    this.lookupService.getCountriesCallingCode().subscribe(response => {
      this.countryList = response;
    });
  }
  onClose() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.supportRequest,
      XPOConstants.keystoneEventAction.cancel, XPOConstants.keystonePage.support);
    this.keystoneService.logEvent(logEvent);
    this.dialogRef.close();
  }
  contactSupport() {
    const contactSupportReq = {
      carrierIdentifierNumber: '',
      firstName: this.euSupportForm.value.firstName,
      lastName: this.euSupportForm.value.lastName,
      emailAddress: this.euSupportForm.value.email,
      phoneNumber: this.euSupportForm.value.countryCode + '-' + this.euSupportForm.value.phoneNumber,
      supportType: this.euSupportForm.value.supportType,
      otherSupportType: '',
      representative: this.euSupportForm.value.xpoRepresentative,
      comments: this.euSupportForm.value.note,
      orderNumber: this.euSupportForm.value.tripId
    };
    this.dashboardService.sendSupportMail(contactSupportReq).subscribe(response => {
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.supportRequest,
        XPOConstants.keystoneEventAction.submit, XPOConstants.keystonePage.support);
      this.keystoneService.logEvent(logEvent);
      this.showConfirmationMsg = false;
    });
  }
}
