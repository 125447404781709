<section class="confirm-dialog">
    <header class="confirm-header">
        <span class="close-icon cursor-pointer">
            <i class="icon-close" (click)="onClose(false)"></i>
        </span>
        <span class="text-bold-uppercase" *ngIf="title">{{title | translate}}</span>
    </header>
    <article>
        <p>{{message | translate}}</p>
    </article>
    <footer class="padding-top-15 confirm-footer">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
            <button class="action-btn text-uppercase cursor-pointer" (click)="onClose(false)" fxFlex="50%"
                mat-raised-button>
                {{rejectButtonLabel | translate}}
            </button>
            <button class="action-btn text-uppercase cursor-pointer" (click)="onClose(true)" fxFlex="50%"
                mat-raised-button color="accent">
                {{acceptButtonLabel | translate}}
            </button>
        </div>
    </footer>
</section>