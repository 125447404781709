<div class="mobile-filter-dropdown">
    <div class="filter-list-title">{{filterLabel}}</div>
    <ul>
        <li>
            <div class="checkbox-block">
                <mat-checkbox labelPosition="after" [checked]="selectAllChecked" class="checkbox-label"
                    (change)="selectAllFilter($event)">
                    <b class="text-capitalize" [translate]="'selectAll6020'">select all</b>
                </mat-checkbox>
                <div class="checkbox-item" *ngFor="let filter of filterList; let i = index">
                    <mat-checkbox labelPosition="after" [checked]="checkedFilter[i]"
                        class="checkbox-label text-capitalize" (change)="filterChange($event, i)">
                        {{filter.name | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </li>
    </ul>
</div>