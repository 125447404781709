import { Component, OnInit, Input } from '@angular/core';
import { Representative } from '../../model';

@Component({
  selector: 'app-representative-tab',
  templateUrl: './representative-tab.component.html',
  styleUrls: [ './representative-tab.component.scss' ]
})
export class RepresentativeTabComponent implements OnInit {

  @Input()
  public representatives: Representative;

  constructor() { }

  ngOnInit() {
  }

}
