export class EquipmentRequest {
  constructor(
    public equipmentNumber?: string,
    public equipmentDescription?: string,
    public equipmentTypeCode?: string,
    public licenseCode?: string,
    public licenseStateCode?: string,
    public licenseCountryCode?: string,
    public make?: string,
    public model?: string,
    public size?: string,
    public year?: number,
    public trackingDevice?: string,
    public trackingDeviceNumber?: string,
    public equipmentIdentifier?: string,
    public equipmentCategoryCode?: string,
    public equipmentCategoryTypeCode?: string,
    public driverName?: string,
    public driverId?: number,
    public isValidatedAlready?: boolean

  ) { }
}
