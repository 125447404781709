import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { CurrencyFormatPipe as CurrencyPipe } from '@xpoc/ngx-common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { XpocLibrary, DateTimePipe, DistanceUnitConverterPipe } from '@xpoc/ngx-common';
import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { TrafficService, WeatherAlertService } from './services';
import {
  FilterDropdownMobileDialogComponent
} from './filter-dropdowns/filter-dropdown-mobile-dialog/filter-dropdown-mobile-dialog.component';
import { FilterDropdownComponent } from './filter-dropdowns/filter-dropdown/filter-dropdown.component';
import { FilterDropdownMobileComponent } from './filter-dropdowns/filter-dropdown-mobile/filter-dropdown-mobile.component';
import { CarrierRequirementsComponent } from './carrier-requirements/carrier-requirements.component';
import { LoadRequirementsComponent } from './load-requirements/load-requirements.component';
import { DispatchInfoDialogComponent } from './dispatch-info-dialog/dispatch-info-dialog.component';
import { DriverStatusDialogComponent } from './driver-status-dialog/driver-status-dialog.component';
import { AddNewDriverComponent } from './driver/add-new-driver/add-new-driver.component';
import { AddEquipmentFormComponent } from './equipment/add-equipment-form/add-equipment-form.component';
import { DeleteEquipmentComponent } from './equipment/delete-equipment/delete-equipment.component';
import { AvailableLoadStopsComponent } from './available-load-stops/available-load-stops.component';
import { BuyItNowComponent } from './buy-it-now/buy-it-now.component';
import { PlaceBidComponent } from './place-bid/place-bid.component';
import { DeleteDriverComponent } from './driver/delete-driver/delete-driver.component';
import { CreateDriverComponent } from './driver/create-driver/create-driver.component';
import { CreateDriverFormComponent } from './driver/create-driver-form/create-driver-form.component';
import { DetailsTabComponent } from './common-tab-components/details-tab/details-tab.component';
import { StopsTabComponent } from './common-tab-components/stops-tab/stops-tab.component';
import { RepresentativeTabComponent } from './common-tab-components/representative-tab/representative-tab.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ErrorBlockComponent } from './error-block/error-block.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AvailableLoadNotesComponent } from './available-load-notes/available-load-notes.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AcceptTermsAndConditionsComponent } from './accept-terms-and-conditions/accept-terms-and-conditions.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { CircleProgressComponent } from './circle-progress/circle-progress.component';
import { UpgradeNotificationComponent } from './upgrade-notification/upgrade-notification.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule } from '@ngx-translate/core';
import { OnlineLoadReqComponent } from './online-load-req/online-load-req.component';
import { SelectionTileComponent } from './selection-tile/selection-tile.component';
import { GoogleMapErrorComponent } from './google-map-error/google-map-error.component';
import { TermsAndConditionsLoyaltyComponent } from './terms-and-conditions-loyalty/terms-and-conditions-loyalty.component';
import { DispatchDetailsDialogComponent } from './dispatch-details-dialog/dispatch-details-dialog.component';
import { CancelConfirmationDialogComponent } from './cancel-confirmation-dialog/cancel-confirmation-dialog.component';
import { SuccessErrorDialogComponent } from './success-error-dialog/success-error-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DocumentsComponent } from './documents/documents.component';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { SignupBannerComponent } from './signup-banner/signup-banner.component';
import { SignupPromoDialogComponent } from './signup-promo-dialog/signup-promo-dialog.component';
import { QuickSignupDialogComponent } from './quick-signup-dialog/quick-signup-dialog.component';
import { ShareTrackingDialogComponent } from './share-tracking-dialog/share-tracking-dialog.component';
import { ShareTrackingEmailComponent } from './share-tracking-email/share-tracking-email.component';
import { ShareTrackingTextComponent } from './share-tracking-text/share-tracking-text.component';
import { ShareTrackingSuccessComponent } from './share-tracking-success/share-tracking-success.component';
import { AdvertisementBannerComponent } from './advertisement-banner/advertisement-banner.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import {
  CarrierCompetitionTermsAndConditionDialogComponent
} from './carrier-competition-terms-and-condition-dialog/carrier-competition-terms-and-condition-dialog.component';
import { ReloadCountInfoComponent } from './reload-count-info/reload-count-info.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DispatchInfoSuccessDialogComponent } from './dispatch-info-success-dialog/dispatch-info-success-dialog.component';
import { ShareTrackingContactsListComponent } from './share-tracking-contacts-list/share-tracking-contacts-list.component';
import { PostTruckDialogComponent } from './post-a-truck/post-truck-dialog.component';
import { LoadDetailMapPopupComponent } from './load-detail-map-popup/load-detail-map-popup.component';
import { LoadDetailMapComponent } from './load-detail-map/load-detail-map.component';
import { IntermediateStopsComponent } from './intermediate-stops/intermediate-stops.component';
@NgModule({
  declarations: [
    PageLoaderComponent,
    NotificationsComponent,
    FilterDropdownComponent,
    FilterDropdownMobileComponent,
    FilterDropdownMobileDialogComponent,
    CarrierRequirementsComponent,
    LoadRequirementsComponent,
    DispatchInfoDialogComponent,
    DriverStatusDialogComponent,
    AddNewDriverComponent,
    AddEquipmentFormComponent,
    DeleteEquipmentComponent,
    AvailableLoadStopsComponent,
    BuyItNowComponent,
    PlaceBidComponent,
    DeleteDriverComponent,
    CreateDriverComponent,
    CreateDriverFormComponent,
    DetailsTabComponent,
    StopsTabComponent,
    RepresentativeTabComponent,
    SearchBoxComponent,
    ErrorBlockComponent,
    TermsAndConditionsComponent,
    AvailableLoadNotesComponent,
    NotificationComponent,
    ProgressBarComponent,
    StarRatingComponent,
    AcceptTermsAndConditionsComponent,
    CircleProgressComponent,
    UpgradeNotificationComponent,
    CookieBannerComponent,
    OnlineLoadReqComponent,
    SelectionTileComponent,
    GoogleMapErrorComponent,
    DispatchDetailsDialogComponent,
    TermsAndConditionsLoyaltyComponent,
    CancelConfirmationDialogComponent,
    SuccessErrorDialogComponent,
    ConfirmDialogComponent,
    DocumentsComponent,
    FileUploadDialogComponent,
    SignupBannerComponent,
    SignupPromoDialogComponent,
    QuickSignupDialogComponent,
    ShareTrackingDialogComponent,
    ShareTrackingEmailComponent,
    ShareTrackingTextComponent,
    ShareTrackingSuccessComponent,
    AdvertisementBannerComponent,
    CarrierCompetitionTermsAndConditionDialogComponent,
    ReloadCountInfoComponent,
    AlertDialogComponent,
    DispatchInfoSuccessDialogComponent,
    ShareTrackingContactsListComponent,
    PostTruckDialogComponent,
    LoadDetailMapComponent,
    LoadDetailMapPopupComponent,
    IntermediateStopsComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    XpocLibrary,
    CustomMaterialModule,
    TranslateModule,
    NgxCaptchaModule,
    PdfJsViewerModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    XpocLibrary,
    TranslateModule,
    PageLoaderComponent,
    FilterDropdownComponent,
    FilterDropdownMobileComponent,
    FilterDropdownMobileDialogComponent,
    CarrierRequirementsComponent,
    LoadRequirementsComponent,
    DispatchInfoDialogComponent,
    CancelConfirmationDialogComponent,
    BuyItNowComponent,
    PlaceBidComponent,
    AvailableLoadStopsComponent,
    DeleteDriverComponent,
    CreateDriverComponent,
    DetailsTabComponent,
    StopsTabComponent,
    RepresentativeTabComponent,
    SearchBoxComponent,
    ErrorBlockComponent,
    TermsAndConditionsComponent,
    AvailableLoadNotesComponent,
    NotificationComponent,
    ProgressBarComponent,
    StarRatingComponent,
    CircleProgressComponent,
    UpgradeNotificationComponent,
    CookieBannerComponent,
    OnlineLoadReqComponent,
    SelectionTileComponent,
    GoogleMapErrorComponent,
    DocumentsComponent,
    SignupBannerComponent,
    SignupPromoDialogComponent,
    QuickSignupDialogComponent,
    ShareTrackingDialogComponent,
    ShareTrackingEmailComponent,
    ShareTrackingTextComponent,
    ShareTrackingSuccessComponent,
    AdvertisementBannerComponent,
    ReloadCountInfoComponent,
    AlertDialogComponent,
    DispatchInfoSuccessDialogComponent,
    PostTruckDialogComponent,
    LoadDetailMapComponent,
    LoadDetailMapPopupComponent,
    IntermediateStopsComponent
  ],
  providers: [
    TrafficService,
    WeatherAlertService,
    DatePipe,
    TitleCasePipe,
    UpperCasePipe,
    CurrencyPipe,
    DateTimePipe,
    DistanceUnitConverterPipe,
    CookieService
  ],
  entryComponents: [
    DispatchInfoDialogComponent,
    CancelConfirmationDialogComponent,
    FilterDropdownMobileDialogComponent,
    AddEquipmentFormComponent,
    DeleteEquipmentComponent,
    AddNewDriverComponent,
    BuyItNowComponent,
    PlaceBidComponent,
    DeleteDriverComponent,
    CreateDriverComponent,
    TermsAndConditionsComponent,
    AcceptTermsAndConditionsComponent,
    UpgradeNotificationComponent,
    DriverStatusDialogComponent,
    TermsAndConditionsLoyaltyComponent,
    DispatchDetailsDialogComponent,
    SuccessErrorDialogComponent,
    ConfirmDialogComponent,
    FileUploadDialogComponent,
    SignupPromoDialogComponent,
    QuickSignupDialogComponent,
    ShareTrackingDialogComponent,
    CarrierCompetitionTermsAndConditionDialogComponent,
    ReloadCountInfoComponent,
    AlertDialogComponent,
    PostTruckDialogComponent,
    LoadDetailMapPopupComponent
  ]
})
export class SharedModule { }
