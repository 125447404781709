import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { KeystoneService } from 'src/app/shared/services/keystone.service';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { AppSettings } from 'src/app/core/app-settings';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model/log-event';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { FinanceService } from 'src/app/finance/services/finance.service';
import { SuccessErrorDialogComponent } from '../success-error-dialog/success-error-dialog.component';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: [ './file-upload-dialog.component.scss' ]
})
export class FileUploadDialogComponent implements OnInit {
  public paperworkUploadForm: FormGroup;
  public order: any;
  public isUploadSuccess = false;
  public disableUploadBtn = false;
  public uploadError: string;
  public uploadErrorFlag = false;
  public uploadErrorMessages: any;
  public fileName: string;
  public documentTypeList: any;
  public isInvoiceDetailsMandatory = false;
  public isNAUser: boolean;
  public permittedFileFormats = '.tiff, .tif, .pdf';
  public isUploading = false;
  public deliveryLocations = [];
  public selectedDeliveryLocation: any;
  public loadAssignedPermission: boolean;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private formBuilder: FormBuilder,
    private financeManagementService: FinanceService,
    private authService: AuthService,
    private appSettings: AppSettings,
    private keystoneService: KeystoneService,
    private lookupService: LookupService,
    private dialog: MatDialog) {
    this.isNAUser = this.authService.checkNAUser();
    this.dialogRef.disableClose = true;
    this.uploadErrorMessages = {
      fileNameAlreadyExists: 'afilew2202', // A file with the same file name exists. please choose a different file name.
      fileInSufficient: 'thedocument8868', // 'The document quality is not good. Please try to upload an image with a higher resolution.',
      fileNotADocument: 'invaliddocu321', // 'Invalid document. Please upload the proof of delivery document.'
      genericError: 'weapologize9965' // We apologize for the inconvenience. We are working to correct this issue
    };
  }

  ngOnInit() {
    this.loadAssignedPermission = true;
    if (!this.isNAUser) {
      this.loadAssignedPermission = false;
      const permissions = this.authService.permissions;
      if (permissions.findIndex(permission => permission === XPOConstants.permissionTypes.loadAssignedAction) > -1) {
        this.loadAssignedPermission = true;
      }
      this.permittedFileFormats = '.jpeg, .jpg, .pdf';
    }
    this.order = this.dialogRef._containerInstance._config.data;
    this.deliveryLocations = this.getDeliveryLocations();
    this.paperworkUploadForm = this.createForm();
    this.setDefaultDeliveryLocation();
    this.lookupService.getDocumentType().subscribe(response => {
      this.documentTypeList = response;
      if (!this.isNAUser) {
        const docTypeFormElement = this.paperworkUploadForm.get('documentType');
        docTypeFormElement.setValue('BILLOFLADING');
        docTypeFormElement.disable();
      }
    });

    this.defaultDoc(1);
  }

  private getDeliveryLocations() {
    let deliveryLocations = [];
    if (this.order.stops && this.order.stops.length) {
      deliveryLocations = this.order.stops.filter((stop) => stop.type === 'Delivery');
    }
    return deliveryLocations;
  }

  private setDefaultDeliveryLocation() {
    if (this.deliveryLocations.length && !this.isNAUser) {
      const defaultLocation = {
        value: this.deliveryLocations[ 0 ]
      };
      this.paperworkUploadForm.get('deliveryLocation').setValue(this.deliveryLocations[ 0 ]);
      this.onDeliveryLocationChange(defaultLocation);
    }
  }

  private createForm() {
    const invoiceValidatorsList = this.isNAUser ? [ Validators.required ] : [];
    const deliveryLocationValidatorsList = !this.isNAUser && this.deliveryLocations.length ? [ Validators.required ] : [];
    const group = this.formBuilder.group({
      orderNo: [ this.order.number, Validators.required ],
      deliveryLocation: [ '', deliveryLocationValidatorsList ],
      carrierInvoiceNo: [ this.order.document ? this.order.document.invoiceNumber : '', invoiceValidatorsList ],
      documentType: [ this.order.document ? this.order.document.documentCategoryCode : '', Validators.required ],
      invoiceAmount: [ this.order.document ? this.order.document.invoiceAmount : '', invoiceValidatorsList ],
      documents: this.formBuilder.array([])
    });
    return group;
  }

  private initDocument() {
    const group = this.formBuilder.group({
      uploadedDoc: [ '', Validators.required ],
    });
    return group;
  }

  public onSelectDocumentType(event) {
    if (event.value === 'CARRIERRATEINVOICE') {
      this.isInvoiceDetailsMandatory = true;
      this.paperworkUploadForm.get('carrierInvoiceNo').setValidators(Validators.required);
      this.paperworkUploadForm.get('invoiceAmount').setValidators(Validators.required);
    } else {
      this.isInvoiceDetailsMandatory = false;
      this.paperworkUploadForm.get('carrierInvoiceNo').setValidators(null);
      this.paperworkUploadForm.get('invoiceAmount').setValidators(null);
      this.paperworkUploadForm.get('carrierInvoiceNo').setErrors(null);
      this.paperworkUploadForm.get('invoiceAmount').setErrors(null);
    }
    this.paperworkUploadForm.get('documents').setValidators(null);
  }

  get documents(): FormArray {
    return this.paperworkUploadForm.get('documents') as FormArray;
  }

  onClose() {
    if (!this.fileName) {
      const keystoneEventName = this.order.isReplace ? XPOConstants.keystoneEventName.replaceDocument
        : XPOConstants.keystoneEventName.addDocument;
      this.logKeystone(keystoneEventName, XPOConstants.keystoneEventAction.cancel);
    }
    this.dialogRef.close(this.fileName ? XPOConstants.dialogActions.saved : XPOConstants.dialogActions.cancelled);
  }

  defaultDoc(noDoc: number) {
    const control = this.paperworkUploadForm.controls.documents as FormArray;
    if (control.length === 0) {
      for (let i = 0; i < noDoc; i++) {
        control.push(this.initDocument());
      }
    }
  }

  uploadFile() {
    const formData = this.createUploadData();
    this.uploadError = '';
    this.uploadErrorFlag = false;
    this.isUploading = true;
    this.financeManagementService.uploadDoc(formData).subscribe(response => {
      if (response.isError) {
        this.uploadError = response.errorCode ? this.uploadErrorMessages[ response.errorCode ] : this.uploadErrorMessages.genericError;
        this.uploadErrorFlag = true;
        this.paperworkUploadForm.controls.documents[ 'controls' ][ 0 ].setErrors({ invalid: { error: this.uploadError } });
      } else {
        this.logKeystone(XPOConstants.keystoneEventName.addDocument, XPOConstants.keystoneEventAction.upload);
        this.fileName = this.fileName.substring(0, this.fileName.lastIndexOf('.'));
        this.isUploadSuccess = this.isNAUser ? true : this.analyzePODResult(response);
      }
      this.disableUploadBtn = false;
      this.isUploading = false;
    }, error => {
      this.onClose();
    });
  }

  private analyzePODResult(dataToAnalyze) {
    let isUploadSuccess = true;
    if (dataToAnalyze && dataToAnalyze.imageRatingResult) {
      const imageResults = dataToAnalyze.imageRatingResult;
      if (imageResults.isDefinitionSufficient && imageResults.isDocument && !imageResults.isChocking) {
        isUploadSuccess = false;
        const dataForDialog = {
          message: 'attach512', // Attachment has been uploaded successfully.
          isSuccess: true,
          isAutoClosingEnabled: true
        };
        this.onClose();
        this.openSuccessOrErrorDialog(dataForDialog);
      } else if (!imageResults.isDefinitionSufficient) {
        isUploadSuccess = false;
        this.uploadErrorFlag = true;
        this.uploadError = this.uploadErrorMessages.fileInSufficient;
      } else if (!imageResults.isDocument || imageResults.isChocking) {
        isUploadSuccess = false;
        this.uploadErrorFlag = true;
        this.uploadError = this.uploadErrorMessages.fileNotADocument;
      }
    } else {
      isUploadSuccess = false;
      const dataForDialog = {
        message: 'attach512', // Attachment has been uploaded successfully.
        isSuccess: true,
        isAutoClosingEnabled: true
      };
      this.onClose();
      this.openSuccessOrErrorDialog(dataForDialog);
    }
    return isUploadSuccess;
  }

  private openSuccessOrErrorDialog(dataForDialog) {
    this.dialog.open(SuccessErrorDialogComponent, {
      data: dataForDialog,
      width: '450px'
    });
  }

  replaceFile() {
    const formData = this.createUploadData();
    this.uploadError = '';
    this.uploadErrorFlag = false;
    this.financeManagementService.replaceDoc(this.order.document.referenceId, formData).subscribe(response => {
      if (response.isError) {
        this.uploadError = this.uploadErrorMessages[ response.errorCode ];
        this.uploadErrorFlag = true;
        this.paperworkUploadForm.controls.documents[ 'controls' ][ 0 ].setErrors({ invalid: { error: this.uploadError } });
      } else {
        this.fileName = this.fileName.substring(0, this.fileName.lastIndexOf('.'));
        this.logKeystone(XPOConstants.keystoneEventName.replaceDocument, XPOConstants.keystoneEventAction.upload);
        this.isUploadSuccess = true;
      }
      this.disableUploadBtn = false;
    }, error => {
      this.onClose();
    });
  }

  createUploadData() {
    const formData = new FormData();
    this.disableUploadBtn = true;
    const uploadFileDetails = this.paperworkUploadForm.value;
    uploadFileDetails.documentType = this.isNAUser ? uploadFileDetails.documentType : 'BILLOFLADING';
    this.fileName = uploadFileDetails.documents[ 0 ].uploadedDoc.name;
    formData.append('UploadFile', uploadFileDetails.documents[ 0 ].uploadedDoc);
    const metaData = `{
      "orderCode": "${this.order.number}",
      "tripCode": "${this.order.alternateNumber}",
      "isIberianTrip": "${typeof (this.order.isIberianTrip) !== 'undefined' ? this.order.isIberianTrip : false}",
      "invoiceNumber": "${uploadFileDetails.carrierInvoiceNo}",
      "invoiceAmount": "${uploadFileDetails.invoiceAmount}",
      "documentTypeCode":"${uploadFileDetails.documentType}",
      "carrierCode": "${this.authService.partnerCode}"
    }`;
    formData.append('MetaData', metaData.toString());
    return formData;

  }

  addNewDocument() {
    this.paperworkUploadForm.reset();
    this.paperworkUploadForm.get('orderNo').setValue(this.order.number);
    this.isUploadSuccess = false;
    this.logKeystone(XPOConstants.keystoneEventName.multipleDocumentUpload, XPOConstants.keystoneEventAction.upload);
  }

  logKeystone(keystoneEventName, keystoneEventAction) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName,
      keystoneEventAction, XPOConstants.keystonePage.financeManagement);
    this.keystoneService.logEvent(logEvent);
  }

  public onDeliveryLocationChange(event) {
    this.selectedDeliveryLocation = event.value;
    this.paperworkUploadForm.get('orderNo').setValue(this.selectedDeliveryLocation.customerOrderNumber);
    this.order.number = this.selectedDeliveryLocation.customerOrderNumber;
  }

}

