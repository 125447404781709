<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="text-bold-uppercase" [translate]="'driver6801'">
            driver status
        </span>
        <span class="dialog-close-icon">
            <i class="icon-close" (click)="submit('cancel')"></i>
        </span>
    </div>
</div>
<div class="dialog-content">
    <div [ngSwitch]="status">
        <div *ngSwitchCase="driverStatus.loadOverlap">
            <mat-dialog-content>{{'nextlo8363' | translate}}
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                    <button fxFlex="50%" (click)="submit('cancel')" id="cancel-btn" class="text-uppercase secondary-btn"
                        mat-raised-button>{{'cancel7093' | translate}}</button>
                    <button fxFlex="50%" (click)=" submit('continue')" id="continue-btn" mat-raised-button
                        class="text-uppercase" color="accent">{{'continue8568' | translate}}</button>
                </div>
            </mat-dialog-actions>
        </div>

        <div *ngSwitchCase="driverStatus.transitOverlap">
            <mat-dialog-content>
                {{'unable6661' | translate}} </mat-dialog-content>
            <mat-dialog-actions align="end">
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                    <button fxFlex="100%" (click)="submit('assign new driver')" id="assign-btn" mat-raised-button
                        class="text-uppercase" color="accent">{{'assign5492' | translate}}</button>
                </div>
            </mat-dialog-actions>
        </div>
    </div>
</div>