import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { LoadTenders } from '../model/load-tenders';
import { LoadTenderResults } from '../model/load-tender-results';
import { LoadTenderDetails } from '../model/load-tender-details';
import { TenderFilter } from '../model/tender-filter';
import { TenderRejectReason } from '../model/tender-reject-reason';
import { TenderResponse } from '../model/tender-response';
import { LoadInfoUpdate } from '../model/load-infoupdate';

@Injectable({
  providedIn: 'root'
})
export class TendersService {

  private baseUrl: string;
  public tenderObject: LoadTenders;
  public isFileDownloading = false;

  constructor(private http: HttpClient,
              private appSettings: AppSettings) {
    this.baseUrl = this.appSettings.apiUrl;
  }

  getLoadTenders(body): Promise<LoadTenderResults> {
    const url = this.getAuthUri(`search`);
    return this.http
      .post<LoadTenderResults>(url, body, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  getLoadTenderDetails(body): Promise<LoadTenderDetails> {
    const url = this.getAuthUri(`details`);
    return this.http.post<LoadTenderDetails>(url, body, this.getOptions())
      .toPromise()
      .then(resp => {
        return resp;
      }).catch(error => {
        return null;
      });
  }

  search(tenderFilterObj: TenderFilter): Promise<LoadTenderResults> {
    const url = this.getAuthUri(`search`);
    return this.http
      .post<LoadTenderResults>(url, tenderFilterObj, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.handleError);
  }

  getTenderRejectReasons(): Promise<Array<TenderRejectReason>> {
    const url = this.getUri('rejectreasons');
    return this.http
      .get<TenderRejectReason[]>(url)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(this.handleError);
  }

  getAuthTenderRejectReasons(): Promise<Array<TenderRejectReason>> {
    const url = this.getAuthUri('rejectreasons');
    return this.http
      .get<TenderRejectReason[]>(url)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch(this.handleError);
  }


  rejectTender(body): Promise<TenderResponse> {
    const url = this.getUri('response/reject');
    return this.http
      .post<TenderResponse>(url, body)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.status.toString();
      });
  }

  rejectAuthTender(body): Promise<TenderResponse> {
    const url = this.getAuthUri(body.TenderGuid + '/reject');
    return this.http
      .post<TenderResponse>(url, body)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.status.toString();
      });
  }

  acceptTender(body): Promise<TenderResponse> {
    const url = this.getUri('dispatch');
    return this.http
      .post<TenderResponse>(url, body)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.status.toString();
      });
  }

  acceptAuthTender(tenderGuid: string): Promise<TenderResponse> {
    const url = this.getAuthUri(tenderGuid + '/accept');
    return this.http
      .post<TenderResponse>(url, tenderGuid)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.status.toString();
      });
  }

  saveTenderDispatch(tenderGuid: string, dispatchDetails: LoadInfoUpdate): Promise<TenderResponse> {
    const url = this.getAuthUri(tenderGuid + '/dispatch');
    return this.http
      .post<TenderResponse>(url, dispatchDetails)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.status.toString();
      });
  }

  exportTenders(filterObject: TenderFilter, tenderCount: number): Promise<any> {
    this.isFileDownloading = true;
    const url = `${this.baseUrl}authtenders/export/${tenderCount}`;
    return this.http
      .post(url, filterObject, {
        responseType: 'blob'
      })
      .toPromise()
      .then(response => {
        this.downloadFile(response);
      })
      .catch(error => {
        this.isFileDownloading = false;
        this.handleError(error);
      });
  }

  downloadFile(data: Blob) {
    const blob = data;
    const downloadLink = document.createElement('a');
    const downloadUrl = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      downloadLink.setAttribute('target', '_blank');
    }
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, 'TendersData.xls');
    } else {
      downloadLink.setAttribute('href', downloadUrl);
      downloadLink.setAttribute('download', 'TendersData.xls');
      downloadLink.style.visibility = 'hidden';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    this.isFileDownloading = false;
  }

  private getOptions() {
    const options = { headers: this.getHeaders(), withCredentials: true };
    return options;
  }


  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + 'tenders/' + endpoint;
  }

  private getAuthUri(endpoint?: string): string {
    return this.baseUrl + 'authtenders/' + endpoint;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
