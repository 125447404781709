import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LiveChatService } from './live-chat.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatHelperService {
  public notify = false;
  public pages = new Subject<string>();

  constructor(private router: Router, private liveChatService: LiveChatService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.toggetLiveChatVisibility(event.url);
      }
    });
  }

  public get pagesName(): Observable<string> {
    return this.pages.asObservable();
  }

  private toggetLiveChatVisibility(url: string) {
    this.pages.next(url);
    this.liveChatService.showChat();
  }

}
