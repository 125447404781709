<div class="dispatch-dialog-container">
  <div *ngIf="isLoading; else details_block">
    <app-page-loader></app-page-loader>
  </div>
  <ng-template #details_block>
    <div mat-dialog-title>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 class="text-uppercase" [translate]="'plannextMov7017'">
          Plan Next Move
        </h4>
        <span class="dialog-close-icon">
          <i class="icon-close" (click)="onClose()"></i>
        </span>
      </div>
    </div>
    <mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="start center" class="padding-top-bottom-10" fxLayoutGap="15px">
        <span class="text-capitalize text-bold" fxFlex="50%" [translate]="'driver419'">Driver</span>
        <span fxFlex="50%">{{dispatchDetails.driverName}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="padding-top-bottom-10" fxLayoutGap="15px"
        *ngIf="dispatchDetails.equipmentCategory">
        <span class="text-capitalize text-bold" fxFlex="50%" [translate]="'equipment6372'">Equipment</span>
        <span fxFlex="50%">{{dispatchDetails.equipmentCategory}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="padding-top-bottom-10" fxLayoutGap="15px">
        <div class="text-capitalize text-bold" fxFlex="50%" [translate]="distanceUnitLabel">mile radius</div>
        <div fxFlex="50%">
          <div [ngClass]="{'radius-container': !isNAUser, 'radius-container-na': isNAUser}">
            <mat-select id="mile-radius" [(ngModel)]="selectedRadius" (selectionChange)="onMileRadiusChangeCount()"
              class="radius-select">
              <mat-option *ngFor="let radius of mileRadius " [value]="radius">{{radius}}<span *ngIf="distanceUnit"
                  class="text-lowercase"> {{distanceUnit}}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="padding-top-bottom-10" fxLayoutGap="15px">
        <div class="text-capitalize text-bold" fxFlex="50%" [translate]="'destination2571'">Destination</div>
        <div fxFlex="50%">{{dispatchDetails.destination}}</div>
      </div>
      <ng-template #euCountList>
        <div class="count-margin">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div fxFlex="50%" class="text-capitalize text-bold" [translate]="'nearAv7776'">available loads near
              destination</div>
            <div fxFlex="50%" class="load-count"
              [ngClass]="{'cursor-pointer': availLoadBidsPermission && countDetails.loadsCount> 0}"
              fxLayoutAlign="start center" (click)="navigateToAvailableLoads()">
              <span [ngClass]="{'zero-count': !countDetails.loadsCount}">{{countDetails.loadsCount}}</span>
              <span class="cursor-pointer text-center" *ngIf="availLoadBidsPermission && countDetails.loadsCount== 0">
                &nbsp;<a>{{'goToAvailab4468' | translate}}</a>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="count-margin" *ngIf="isNAUser;else euCountList;">
        <div fxLayout="row" fxLayoutAlign="start center" class="grid-border-first">
          <div fxFlex="60%" class="text-capitalize text-bold" [translate]="'nearAv7776'">available loads near
            destination</div>
          <div fxFlex="40%" class="load-count" [ngClass]="{'cursor-pointer': availLoadBidsPermission}"
            fxLayoutAlign="center center" (click)="navigateToAvailableLoads()">
            {{countDetails.loadsCount}}
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="grid-border" fxLayoutGap="15px">
          <div fxFlex="60%" class="text-capitalize text-bold" [translate]="'bidsPe148'">bids pending</div>
          <div fxFlex="40%" class="load-count" [ngClass]="{'cursor-pointer': availLoadBidsPermission}"
            fxLayoutAlign="center center" (click)="navigateToBids('BIDREVIEW')">{{countDetails.bidsCount}}</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="grid-border" fxLayoutGap="15px">
          <div fxFlex="60%" class="text-capitalize text-bold" [translate]="'counte1976'">counteroffers pending</div>
          <div fxFlex="40%" class="load-count" [ngClass]="{'cursor-pointer': availLoadBidsPermission}"
            fxLayoutAlign="center center" (click)="navigateToBids('OPENCO')">{{countDetails.counterOffersCount}}</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="grid-border" fxLayoutGap="15px">
          <div fxFlex="60%" class="text-capitalize text-bold" [translate]="'tender4154'">tenders pending</div>
          <div fxFlex="40%" class="load-count" [ngClass]="{'cursor-pointer': tenderPermission}"
            fxLayoutAlign="center center" (click)="navigateToTenders()">
            {{countDetails.tendersCount}}</div>
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>
</div>