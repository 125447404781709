export class LoadBid {
    public carrierRepContactEmail?: string;
    public phoneNumber?: string;
    public isIberianTrip?: boolean;
    constructor(
        public alternateNumber: string,
        public amount: number,
        public currencyCode: string,
        public comments?: string,
        public loadId?: string,
        public suggestionId?: string,
        public carrierCode?: string,
        public contactName?: string,
        public loadSystemId?: string,
        public carrierRepUserName?: string,
        public buyPrice?: number,
        public tenderStatus?: string,
        public responseStatus?: string,
        public transactionFlag?: string,
        public priceSourceCode?: string,
        public wtpEntityId?: string,
        public isManuallyAccepted?: boolean
    ) {
    }
}
