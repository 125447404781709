import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupName } from '@angular/forms';
import { XPOConstants } from 'src/app/shared';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { KeystoneService } from '../services';
import { LogEvent } from '../model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: [ './documents.component.scss' ]
})
export class DocumentsComponent implements OnInit {

  @Input() public paperworkUploadForm: FormGroup;
  @Input() public docForm: FormGroup;
  @Input() public formGroupName: FormGroupName;
  @Input() uploadError: string;
  @Input() uploadErrorFlag: boolean;
  @Input() public supportedFileFormats: string;

  public uploadedFileName = 'chooseFile1935'; // 'Choose File *'
  public isFileAdded = false;
  public isNAUser: boolean;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private keystoneService: KeystoneService) { }
  ngOnInit() {
    this.isNAUser = this.authService.checkNAUser();
  }

  fileChanged($event) {
    const fileFormats = this.isNAUser ? XPOConstants.fileFormats : XPOConstants.euPODFormats;
    this.uploadErrorFlag = false;
    if (!this.isFileAdded) {
      this.docForm.get('uploadedDoc').setValue(null);
    }
    const target = $event.target || $event.srcElement;
    const file = $event.dataTransfer ? $event.dataTransfer.files[ 0 ] : $event.target.files[ 0 ];
    if (file && file.name) {
      const fileFormat = file.name.split('.');
      if (fileFormats.indexOf(fileFormat[ fileFormat.length - 1 ].toLowerCase()) !== -1) {
        const fileSize: number = (file.size) / (1024 * 1024);
        if (fileSize > 15) {
          this.uploadError = 'attachDocum6758'; // 'Attach Document (15 MB Limit)'
          this.uploadErrorFlag = true;
          this.uploadedFileName = 'chooseFile1935'; // 'Choose File *'
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd,
            XPOConstants.keystoneEventName.fileSizeExceeded,
            XPOConstants.keystoneEventAction.rejected,
            XPOConstants.keystonePage.documentDialog, null, null);
          this.keystoneService.logEvent(logEvent);
        } else {
          this.uploadedFileName = file.name;
          this.docForm.get('uploadedDoc').setValue(file);
          this.isFileAdded = true;
        }
      } else {
        this.uploadError = this.translateService.instant('onlyTI7846', { supportedFileFormats: this.supportedFileFormats });
        this.uploadErrorFlag = true;
        this.uploadedFileName = file.name;
        this.docForm.get('uploadedDoc').setValue(null);
      }
    }
  }

}
