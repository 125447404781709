import { Component, OnInit } from '@angular/core';
import { DriverDetails } from '../../model/driver-details';
import { XPOConstants } from '../../xpo-constants';
import { LogEvent } from '../../model/log-event';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DriverListRequest } from '../../model/driver-list-request';
import { EquipmentRequest } from '../../model/equipment-request';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { LookupService } from '../../services/lookup.service';
import { AssetManagementService } from '../../services/asset-management.service';
import { MyLoadsService } from '../../services/my-loads.service';
import { KeystoneService } from '../../services/keystone.service';
import { Subscription } from 'rxjs';
import { Equipment } from '../../model/equipment';
import { State } from '../../model/state';
import { Country } from '../../model/country';
import { EquipmentDetatils } from '../../model/equipment-details';
import { DeleteEquipmentComponent } from '../delete-equipment/delete-equipment.component';

@Component({
  selector: 'app-add-equipment-form',
  templateUrl: './add-equipment-form.component.html',
  styleUrls: [ './add-equipment-form.component.scss' ]
})
export class AddEquipmentFormComponent implements OnInit {
  public dialogAction: string;
  public userActions = XPOConstants.userActions;
  public equipmentDetails: EquipmentDetatils;
  public savedDataAction: string;
  public equipmentForm: FormGroup;
  public countries: Country[];
  public states: State[];
  public truckYears: any[] = [];
  public isNAUser: boolean;
  public showSucessMsg = false;
  private dialogConfig: MatDialogConfig;
  public showtTrackingFields: boolean;
  public equipmentCategories: Equipment[] = [];
  public equipmentCategoryType = [];
  public equipmentTypeCode: any;
  public pendingRequest: Subscription;
  public driverList: DriverDetails[];
  public isNoDriver: boolean;
  public isDriveXPODriver: boolean;
  public isEmptyDriverList: boolean;
  public driverAssociationDetails: any;
  public isSuccess = false;
  public equipmentDataObj: EquipmentRequest;

  constructor(
    private dialogRef: MatDialogRef<AddEquipmentFormComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private keystoneService: KeystoneService,
    public lookupService: LookupService,
    public loadService: MyLoadsService,
    private assetManagementService: AssetManagementService) {
    this.dialogRef.disableClose = true;
    this.lookupService.equipment().then(response => {
      this.equipmentCategories = response;
    });
    this.dialogConfig = new MatDialogConfig();
    let yearEnd = new Date().getFullYear();
    const startingYear = XPOConstants.startingModelYear;
    while (startingYear <= yearEnd) {
      this.truckYears.push(yearEnd);
      yearEnd = yearEnd - 1;
    }
    this.equipmentDataObj = new EquipmentRequest();
    this.isNAUser = this.authService.checkNAUser();
    this.dialogAction = this.dialogRef._containerInstance._config.data.action;
    this.equipmentDetails = this.dialogRef._containerInstance._config.data.equipmentDetails ?
      this.dialogRef._containerInstance._config.data.equipmentDetails : null;
    this.equipmentTypeCode = this.dialogRef._containerInstance._config.data.equipmentTypeCode ?
      this.dialogRef._containerInstance._config.data.equipmentTypeCode : null;
    if (this.equipmentDetails && this.equipmentDetails.licenseCountryCode) {
      this.onSelectingCountry(this.equipmentDetails.licenseCountryCode, false);
    }
    this.showtTrackingFields = (this.equipmentDetails &&
      (this.equipmentDetails.equipmentTypeCode !== XPOConstants.equipmentTypeCode.truck) ||
      this.equipmentTypeCode && (this.equipmentTypeCode !== XPOConstants.equipmentTypeCode.truck)) ?
      true : false;
    if (this.isNAUser) {
      const driverListRequest = new DriverListRequest('', 1, XPOConstants.defaultPageSize);
      this.assetManagementService.getDriverList(driverListRequest).subscribe(response => {
        this.isEmptyDriverList = response && !response.totalCount ? true : false;
      });
      this.countries = XPOConstants.countryListNA;
    } else {
      this.lookupService.countries().then(response => {
        this.countries = response;
      });
    }
  }

  ngOnInit() {
    this.equipmentForm = this.createForm();
    if (this.equipmentDetails && this.equipmentDetails.equipmentCategoryCode) {
      this.onSelectingEquipmentCategory(this.equipmentDetails.equipmentCategoryCode, false);
    }
    if (this.isNAUser) {
      if (this.equipmentDetails && this.equipmentDetails.driverName) {
        this.searchDriver(this.equipmentDetails.driverName, true, this.equipmentDetails.driverId);
      } else {
        this.searchDriver('');
      }
    }
    this.equipmentForm.get('driverSearchString').valueChanges.subscribe(search => {
      this.equipmentForm.get('driverSearchString').setErrors(null);
      this.searchDriver(search);
    });


  }

  private createForm() {
    const group = this.formBuilder.group({
      equipmentNumber: [ {
        value: (this.equipmentDetails && this.equipmentDetails.equipmentNumber) ? this.equipmentDetails.equipmentNumber : '',
        disabled: this.dialogAction !== this.userActions.create ? true : false
      }, [ Validators.required ] ],
      equipmentDescription: [ this.equipmentDetails ? this.equipmentDetails.equipmentDescription : '' ],
      equipmentTypeCode: [ this.equipmentDetails ? this.equipmentDetails.equipmentTypeCode :
        (this.equipmentTypeCode ? this.equipmentTypeCode : XPOConstants.equipmentTypeCode.truck), Validators.required ],
      licenseCode: [ this.equipmentDetails ? this.equipmentDetails.licenseCode : '' ],
      licenseStateCode: [ this.equipmentDetails ? this.equipmentDetails.licenseStateCode : '' ],
      licenseCountryCode: [ this.equipmentDetails ? this.equipmentDetails.licenseCountryCode : '' ],
      make: [ this.equipmentDetails ? this.equipmentDetails.make : '' ],
      model: [ this.equipmentDetails ? this.equipmentDetails.model : '' ],
      year: [ this.equipmentDetails && this.equipmentDetails.year ? this.equipmentDetails.year.toString() : '' ],
      trackingDevice: [ this.equipmentDetails ? this.equipmentDetails.trackingDevice : XPOConstants.defaultTruckTrackingDevice ],
      trackingDeviceNumber: [ this.equipmentDetails ? this.equipmentDetails.trackingDeviceNumber : '' ],
      equipmentCategory: [ this.equipmentDetails ? this.equipmentDetails.equipmentCategoryCode : '' ],
      equipmentCategoryType: [ this.equipmentDetails ? this.equipmentDetails.equipmentCategoryTypeCode : '' ],
      driverName: [ this.equipmentDetails && this.equipmentDetails.driverName && this.isNAUser ? this.equipmentDetails.driverName : '' ],
      driverSearchString: [ this.equipmentDetails && this.equipmentDetails.driverName &&
        this.isNAUser ? this.equipmentDetails.driverName : '' ],
      driverId: [ '' ]
    },
      {
        validator: this.validateEquipmentForm.bind(this)
      });
    return group;
  }

  validateEquipmentForm(equipmentForm: FormGroup) {
    if (equipmentForm.get('equipmentTypeCode').value !== XPOConstants.equipmentTypeCode.truck) {
      equipmentForm.get('trackingDevice').setErrors(equipmentForm.get('trackingDevice').value ?
        null : { invalid: { error: 'Tracking device required' } });
      equipmentForm.get('trackingDeviceNumber').setErrors(equipmentForm.get('trackingDeviceNumber')
        .value ? null : { invalid: { error: 'Tracking device number required' } });
    } else {
      equipmentForm.get('trackingDevice').setErrors(null);
      equipmentForm.get('trackingDeviceNumber').setErrors(null);
    }
  }

  onSelectingEquipmentCategory(equipmentCategoryCode: string, resetType: boolean) {
    if (resetType) {
      this.equipmentForm.get('equipmentCategoryType').setValue('');
    }
    this.lookupService.getEqpSize(equipmentCategoryCode).then(type => {
      this.equipmentCategoryType = type;
    });
  }

  onClose() {
    if (!this.showSucessMsg) {
      const keystoneEventName = (this.dialogAction === this.userActions.create) ? XPOConstants.keystoneEventName.createEquipment
        : XPOConstants.keystoneEventName.editEquipment;
      this.equipmentKeystone(XPOConstants.keystoneEventAction.cancel, keystoneEventName);
    }
    this.dialogRef.close(this.showSucessMsg);
  }

  onProceed() {
    this.isSuccess = true;
    this.equipmentDataObj.isValidatedAlready = true;
    this.addEditEquipment();
  }

  onCancel() {
    this.driverAssociationDetails = '';
  }

  saveEquipment() {
    const equipmentFormData = this.equipmentForm.value;
    this.equipmentDataObj.equipmentNumber = (this.dialogAction !== this.userActions.create) ?
      this.equipmentDetails.equipmentNumber : equipmentFormData.equipmentNumber;
    this.equipmentDataObj.equipmentDescription = equipmentFormData.equipmentDescription;
    this.equipmentDataObj.equipmentTypeCode = equipmentFormData.equipmentTypeCode;
    this.equipmentDataObj.licenseCode = equipmentFormData.licenseCode;
    this.equipmentDataObj.licenseCountryCode = equipmentFormData.licenseCountryCode;
    this.equipmentDataObj.licenseStateCode = equipmentFormData.licenseStateCode;
    this.equipmentDataObj.make = equipmentFormData.make;
    this.equipmentDataObj.model = equipmentFormData.model;
    this.equipmentDataObj.trackingDevice = equipmentFormData.trackingDevice;
    this.equipmentDataObj.trackingDeviceNumber = equipmentFormData.trackingDeviceNumber;
    this.equipmentDataObj.year = parseInt(equipmentFormData.year, 10);
    this.equipmentDataObj.equipmentCategoryCode = equipmentFormData.equipmentCategory;
    this.equipmentDataObj.equipmentCategoryTypeCode = equipmentFormData.equipmentCategoryType;
    this.equipmentDataObj.driverName = this.isNAUser ? equipmentFormData.driverName : '';
    this.equipmentDataObj.driverId = this.isNAUser ? equipmentFormData.driverId : '';
    this.equipmentDataObj.isValidatedAlready = false;
    this.addEditEquipment();
  }

  addEditEquipment() {
    this.assetManagementService.addEquipment(this.equipmentDataObj).subscribe(response => {
      if (response.isSuccess) {
        const keystoneEventName = (this.dialogAction === this.userActions.create) ? XPOConstants.keystoneEventName.createEquipment
          : XPOConstants.keystoneEventName.editEquipment;
        const eventIdentifiers = {
          equipmentDetails: this.equipmentDataObj
        };
        this.equipmentKeystone(XPOConstants.keystoneEventAction.submit, keystoneEventName, eventIdentifiers);
        this.showSucessMsg = true;
      } else {
        const driverAssociationDetails = response.existingDriverAssociationDetails;
        this.driverAssociationDetails = `Driver ${driverAssociationDetails.driverName} is already
          associated with ${(driverAssociationDetails.equipmentType === XPOConstants.equipmentTypeCode.truck) ?
            'Truck' : 'Trailer'} ${driverAssociationDetails.equipmentNumber}. Do you
          want to proceed with the new association?`;
      }
    });
  }

  equipmentKeystone(keystoneEventAction: string, keystoneEventName: string, eventIdentifiers?: any) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName,
      keystoneEventAction, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.equipment);
    if (eventIdentifiers) {
      logEvent.eventIdentifiers = eventIdentifiers;
    }
    this.keystoneService.logEvent(logEvent);
  }

  onSelectingCountry(countryId: string, isCountryCodeChng: boolean) {
    this.lookupService.states(countryId).then(response => {
      this.states = response;
      if (isCountryCodeChng) {
        this.equipmentForm.get('licenseStateCode').setValue(''); /*Reset state*/
      }
    }).catch();
  }

  deleteEquipment() {
    this.equipmentKeystone(XPOConstants.keystoneEventAction.initiate, XPOConstants.keystoneEventName.deleteEquipment);
    this.dialogConfig.data = {
      equipmentDetails: this.equipmentDetails
    };
    this.dialog
      .open(DeleteEquipmentComponent, this.dialogConfig)
      .afterClosed()
      .subscribe((isEquipmentDeleted) => {
        if (isEquipmentDeleted) {
          this.dialogRef.close(true);
        }
      });
  }

  onEquipmentTypeCodeChange(equipmentTypeCode: string) {
    this.equipmentForm.get('trackingDevice').setErrors(null);
    this.equipmentForm.get('trackingDeviceNumber').setErrors(null);
    this.equipmentForm.get('trackingDevice').setValue('');
    this.equipmentForm.get('trackingDeviceNumber').setValue('');
    if (equipmentTypeCode !== XPOConstants.equipmentTypeCode.truck) {
      this.equipmentForm.get('trackingDevice').setErrors({ invalid: { error: 'Tracking device required' } });
      this.equipmentForm.get('trackingDeviceNumber').setErrors({ invalid: { error: 'Tracking device number required' } });
      this.showtTrackingFields = true;
    } else {
      this.equipmentForm.get('trackingDevice').setValue(XPOConstants.defaultTruckTrackingDevice);
      this.showtTrackingFields = false;
    }
  }

  searchDriver(name: string, populateData?: boolean, driverId?: number) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.loadService.getDriverList(name).subscribe(response => {
      this.driverList = response;
      this.isNoDriver = this.driverList.length ? false : true;
      if (populateData && this.driverList.length) {
        const filteredData = this.driverList.filter(driver => driver.driverId === driverId);
        if (filteredData.length) {
          this.onSelectDriver(filteredData[ 0 ]);
          this.equipmentForm.get('driverSearchString').setValue(name);
        } else {
          this.driverList = [];
        }
      }
    });
  }

  onSelectDriver(driver: DriverDetails) {
    this.isDriveXPODriver = driver.isDriveXPO;
    this.equipmentForm.get('driverName').setValue(driver.name);
    this.equipmentForm.get('driverId').setValue(driver.driverId);
  }

  clearDriver() {
    if (!this.equipmentForm.get('driverSearchString').value) {
      this.equipmentForm.get('driverName').setValue('');
      this.equipmentForm.get('driverId').setValue('');
    } else if (this.equipmentForm.get('driverName').value !== this.equipmentForm.get('driverSearchString').value) {
      this.equipmentForm.get('driverSearchString').setErrors({ invalid: true });
    }
  }

}

