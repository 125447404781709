import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: [ './notification.component.scss' ]
})
export class NotificationComponent implements OnInit {
  @Input() message: string;
  @Output() closed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.closed.emit(false);
  }
}
