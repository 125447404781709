import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-tracking-contacts-list',
  templateUrl: './share-tracking-contacts-list.component.html',
  styleUrls: [ './share-tracking-contacts-list.component.scss' ]
})
export class ShareTrackingContactsListComponent implements OnInit {

  @Input()
  contactInfo: any;

  @Input()
  minimumCountofItems: number;

  constructor() { }

  ngOnInit() {
  }

}
