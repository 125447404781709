import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() { }

  public getTimeDifference(fromTime, toTime): number {
    let timeDifference = 0;
    if (fromTime && toTime) {
      const FROM_TIME = moment(fromTime);
      const TO_TIME = moment(toTime);
      timeDifference = FROM_TIME.diff(TO_TIME, 'minutes');
    }
    return timeDifference;
  }

  public getDaysDifference(toDate): number {
    let daysDifference = 0;
    const FROM_DATE = moment().startOf('day');
    const TO_DATE = moment(toDate).startOf('day');
    if (TO_DATE) {
      daysDifference = TO_DATE.diff(FROM_DATE, 'days', true);
    }
    return daysDifference;
  }

  public addMinutesTo(dateTimeToManipulate, minutesToAdd = 1) {
    return moment(dateTimeToManipulate).add(minutesToAdd, 'minutes');
  }

  public addDaysTo(dateTimeToManipulate, daysToAdd) {
    return moment(dateTimeToManipulate).add(daysToAdd, 'days');
  }

  public getPastTime(minutesToReduce: number, timeToManipulate?) {
    timeToManipulate = timeToManipulate ? moment(timeToManipulate) : moment();
    return timeToManipulate.subtract(minutesToReduce, 'minutes');
  }

  public getPreviousBusinessDay(currentDateTime?) {
    const today = currentDateTime ? moment(currentDateTime) : moment();
    switch (today.day()) {
      case 1:
        return today.subtract(6, 'days').day(5);
      case 0:
      case 6:
        return today.subtract(6, 'days').day(5).startOf('day');
      default:
        return today.day(today.day() - 1);
    }
  }

  public getYesterDayDate() {
    return moment().subtract(1, 'days').startOf('day');
  }

  public isSameDate(dateToValidate, dateToCompare) {
    return moment(dateToValidate).isSame(moment(dateToCompare), 'date');
  }

  public isPresentOrPast(timeToCheck: any, timeToCompare?: any) {
    timeToCompare = timeToCompare ? moment(timeToCompare) : moment();
    return moment(timeToCheck).isSameOrBefore(timeToCompare);
  }

  public isPast(timeToCheck: any, comparisonGranularity?: any) {
    let isPast = moment(timeToCheck).isBefore(moment());
    if (comparisonGranularity && comparisonGranularity === 'date') {
      isPast = moment(timeToCheck).isBefore(moment(), 'date');
    }
    return isPast;
  }

  public isInTimeRange(timeToValidate, rangeStart, rangeEnd): boolean {
    const isCheckCallTimeValid = moment(timeToValidate).isBetween(rangeStart, rangeEnd, 'minutes', '[]');
    return isCheckCallTimeValid;
  }

  public mergeDateAndTime(dateToMerge, timeToMerge, inUTCFormat = false) {
    const splittedTime = timeToMerge.split(':');
    const hours = String(splittedTime[ 0 ]).padStart(2, '0');
    const minutes = splittedTime[ 1 ];
    timeToMerge = `${hours}:${minutes}`;
    const formattedDate = moment(dateToMerge).format('YYYY-MM-DD') + 'T' + timeToMerge;
    let mergedDateAndTime = '';
    if (moment(formattedDate).isValid()) {
      mergedDateAndTime = inUTCFormat ? moment(formattedDate).utc().format() :
        moment(formattedDate).format('YYYY-MM-DDTHH:mm:ss');
    }
    return mergedDateAndTime;
  }

  public getDateSplit(dateToSplit): any {
    const dateToSplitMoment = moment(dateToSplit);
    const dateSplitted = {
      year: dateToSplitMoment.get('year'),
      month: dateToSplitMoment.get('month') + 1,
      day: dateToSplitMoment.get('date')
    };
    return dateSplitted;
  }

  public splitAndFormDate(dateToSplit, format, delimiter = '/') {
    const dateSplitted = dateToSplit.split(delimiter);
    const formatSplitted = format.split(delimiter);
    const dateObjectFormatted = dateSplitted.reduce((key, value, index) => {
      key[ formatSplitted[ index ] ] = value;
      return key;
    }, {});
    const momentDate = moment([ dateObjectFormatted.YYYY,
    dateObjectFormatted.MM,
    dateObjectFormatted.DD ]).subtract(1, 'month');
    return momentDate;
  }

  public getCurrentMoment(format?: string) {
    let currentMoment: any = moment();
    if (format) {
      currentMoment = moment().format(format);
    }
    return currentMoment;
  }

  public getDateFormatted(date, format: string) {
    return moment(date).format(format);
  }

  public convertTimeToSpecificTimezone(dateTimeToConvert, toTimezone) {
    let convertedTime = moment(dateTimeToConvert);
    if (dateTimeToConvert && toTimezone) {
      convertedTime = momentTZ(dateTimeToConvert).tz(toTimezone);
    }
    return convertedTime.format('YYYY-MM-DDTHH:mm:ss');
  }

  public assignTimezoneWithUTC(dateTimeToConvert, toTimezone) {
    let convertedTime = moment(dateTimeToConvert);
    if (dateTimeToConvert && toTimezone) {
      convertedTime = momentTZ.tz(dateTimeToConvert, toTimezone);
    }
    return convertedTime.utc().format();
  }

  public getTimezoneAbbreviation(ianaTimeZone) {
    let zoneAbbreviation = '';
    if (ianaTimeZone) {
      zoneAbbreviation = momentTZ.tz(ianaTimeZone).zoneAbbr();
    }
    return zoneAbbreviation;
  }
}
