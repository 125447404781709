<div mat-dialog-title>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="text-bold-uppercase">
    <div>
      <div [ngSwitch]="dialogAction" *ngIf="(savedDataAction != userActions.confirm); else confimMsg">
        <span *ngSwitchCase=userActions.create [translate]="'create8840'">
          create driver
        </span>
        <span *ngSwitchCase=userActions.edit [translate]="'update6341'">
          update driver
        </span>
      </div>
      <ng-template #confimMsg>
        <span [translate]="'confirmatio8018'"> Confirmation
        </span>
      </ng-template>
    </div>
    <div class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </div>
  </div>
</div>
<div>
  <div *ngIf="(savedDataAction != userActions.confirm); else confimMsgContent">
    <app-create-driver-form [dialogAction]="dialogAction" [driverDetails]="driverDetails" (cancel)="onCancel($event)">
    </app-create-driver-form>
  </div>
  <ng-template #confimMsgContent>
    <mat-dialog-content>
      <span [translate]="'driver1922'">
        Driver details saved successfully
      </span>.
    </mat-dialog-content>
  </ng-template>
</div>