<div>
  <div class="triangle"></div>
  <div fxLayout="row" fxLayoutAlign="start center" class="filter-bar" id="filter-dropdown-open"
    (click)="openFilterList()">
    <i [ngClass]="iconClass" class="icon-margin"></i>
    <span class="filter-label">
      <span id="filter-dropdown-label">{{filterLabel | translate}}
      </span>
      <span *ngIf="isHidden && selectedFilterList && selectedFilterList.length">({{selectedFilterList.length}})
      </span>
    </span>
    <div class="rotate-icon"
      [ngClass]="{'icon-padding': !isHidden || (isHidden && selectedFilterList && !selectedFilterList.length)}">
      <i class="icon-carat_up_filled "></i>
    </div>
  </div>
</div>
<div [ngClass]="{'show':!isHidden}" class="filter-dropdown">
  <div class="checkbox-block">
    <mat-checkbox labelPosition="after" [checked]="selectAllChecked" class="text-capitalize text-bold"
      (change)="selectAllFilter($event)">
      {{'selectAll6020' | translate}}
    </mat-checkbox>
    <div *ngFor="let filter of filterList; let i = index" class="margin-top-5">
      <mat-checkbox labelPosition="after" [checked]="checkedFilter[i]" class="text-capitalize"
        (change)="filterChange($event, i)">{{filter.name | translate}}
      </mat-checkbox>
    </div>
  </div>
  <div class="filter-buttons" fxLayoutAlign="end center" fxLayoutGap="15px">
    <button class="text-bold" mat-button (click)="clear()" id="filter-dropdown-clear">{{'clear3546' |
      translate}}</button>
    <button class="text-bold" mat-button color="accent" (click)="submit()" id="filter-dropdown-done">{{'done4198' |
      translate}}</button>
  </div>
</div>