import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateTimePipe } from '@xpoc/ngx-common';
import { XPOConstants } from 'src/app/shared';
import { LoadStop } from 'src/app/shared/model/load-stop';
import { DateTimeService } from 'src/app/shared/services/date-time.service';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-intermediate-stops',
  templateUrl: './intermediate-stops.component.html',
  styleUrls: [ './intermediate-stops.component.scss' ]
})
export class IntermediateStopsComponent implements OnChanges {

  @Input() loadStops: LoadStop[] = [];
  public stops = [];
  public stopTypes = XPOConstants.stopTypes;
  public monthDateTimeFormat: string;
  private timeFormat: string;
  public isNAUser: boolean;
  public showStops: boolean = false;


  constructor(
    private elementRef: ElementRef,
    public datePipe: DateTimePipe,
    private authService: AuthService,
    private dateTimeService: DateTimeService
  ) {
    this.isNAUser = this.authService.checkNAUser();
    if (this.isNAUser) {
      this.timeFormat = XPOConstants.timeformat;
      this.monthDateTimeFormat = XPOConstants.monthDateTimeFormat;
    } else {
      this.timeFormat = XPOConstants.euTimeFormat;
      this.monthDateTimeFormat = XPOConstants.euMonthDateTimeFormat;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleInput();
  }

  private handleInput() {
    this.stops = [];
    this.stops = this.loadStops.slice(1, -1);
  }

  getScheduledArrivalLate(scheduledArrivalEarly, scheduledArrivalLate) {
    let arrivalLateFormatted = '';
    if (scheduledArrivalLate) {
      const isDateSameRange = this.dateTimeService.isSameDate(scheduledArrivalEarly, scheduledArrivalLate);
      arrivalLateFormatted = isDateSameRange ?
        this.datePipe.transform(scheduledArrivalLate, this.timeFormat) :
        this.datePipe.transform(scheduledArrivalLate, this.monthDateTimeFormat);
    }
    return arrivalLateFormatted;
  }

  onCloseClick() {
    this.showStops = false;
  }

  @HostListener('document:click', [ '$event.target' ])
  onOutsideClick(targetElement) {
    const clickedInsideSupport = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInsideSupport) {
      this.showStops = false;
    }
  }
}
