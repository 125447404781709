import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'src/app/core/app-settings';
import { GlobalSystemNotification } from '../model/global-system-notification';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  public baseUrl: string;

  constructor(public http: HttpClient, public appSettings: AppSettings) {
    this.baseUrl = appSettings.apiUrl;
  }


  getNotificationSettings(pageNo: number, pageSize: number): Observable<any> {
    const url = `${this.baseUrl}globalsystemnotifications?pageNumber=${pageNo}&pageSize=${pageSize}`;
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getPopupNotification(notificationReqObj: any): Observable<any> {
    const url = `${this.baseUrl}globalsystemnotifications/search`;
    return this.http
      .post(url, notificationReqObj, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  deleteNotification(notificationId: number): Observable<any> {
    const url = `${this.baseUrl}globalsystemnotifications/${notificationId}`;
    return this.http
      .delete(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  editNotification(globalSystemNotification: GlobalSystemNotification): Observable<any> {
    const url = `${this.baseUrl}globalsystemnotifications/${globalSystemNotification.globalSystemNotificationId}`;
    return this.http
      .patch(url, globalSystemNotification, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  enableNotification(globalSystemNotification: GlobalSystemNotification): Observable<any> {
    const url = `${this.baseUrl}globalsystemnotifications/
    ${globalSystemNotification.globalSystemNotificationId}/enable/${globalSystemNotification.isEnabled}`;
    return this.http
      .patch(url, globalSystemNotification, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  addNotification(globalSystemNotification: GlobalSystemNotification): Observable<any> {
    const url = `${this.baseUrl}globalsystemnotifications`;
    return this.http
      .post(url, globalSystemNotification, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getDashboardSettings(): Observable<any> {
    const url = `${this.baseUrl}usersettings`;
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getDashboardSettingsPreferences(): Observable<any> {
    const url = `${this.baseUrl}notificationuserpreferences`;
    return this.http
      .get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  updateDashboardSettingsPreferences(settingsPreferences) {
    const url = `${this.baseUrl}notificationuserpreferences/upsert`;
    return this.http
      .post(url, settingsPreferences, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }


  updateDashboardSettings(settingsObject) {
    const url = `${this.baseUrl}usersettings/upsert`;
    return this.http
      .put(url, settingsObject, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  private getOptions() {
    const options = { headers: this.getHeaders(), withCredentials: true };
    return options;
  }

  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + endpoint;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
