import { Component, OnInit, Input, ViewChildren, QueryList, HostListener, ElementRef } from '@angular/core';
import { SideMenuSource } from 'src/app/shared/model/side-menu-source';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AvailableLoadsService } from 'src/app/shared/services';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { SignupPromoDialogComponent } from 'src/app/shared/signup-promo-dialog/signup-promo-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppSettings } from '../../app-settings';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: [ './main-menu.component.scss' ]
})
export class MainMenuComponent implements OnInit {

  @Input() source: SideMenuSource[];
  @Input() isUnAuthMenu: boolean;
  @Input() isQuickSignup: boolean;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  constructor(
    private elementRef: ElementRef,
    public router: Router,
    private availableLoadsService: AvailableLoadsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private appSettings: AppSettings
  ) { }

  ngOnInit() {
  }

  @HostListener('document:click', [ '$event.target' ])
  public supportClick(targetElement) {
    const clickedInsideSupport = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInsideSupport) {
      this.trigger.toArray().forEach((trigger, index) => {
        trigger.closeMenu();
      });
    }
  }

  openMenu(indexOfMenu) {
    this.availableLoadsService.opportunityWidgetFilter = '';
    const hasTrackingMenu: number = this.isUnAuthMenu ? 1 : this.authService.trackingPermission;
    indexOfMenu = indexOfMenu < 2 ? indexOfMenu : indexOfMenu - hasTrackingMenu;
    this.trigger.toArray().forEach((trigger, index) => {
      if (indexOfMenu !== index) {
        trigger.closeMenu();
      }
    });
    this.trigger.toArray()[ indexOfMenu ].openMenu();
  }

  menuClick(menuItem) {
    const urlPath = menuItem.subItem && menuItem.subItem.length > 0 ? menuItem.subItem[ 0 ].link :
      menuItem.link;
    if (!this.isUnAuthMenu) {
      this.availableLoadsService.opportunityWidgetFilter = '';
      this.router.navigate([ urlPath ]);
    } else {
      this.showSignupPromo(urlPath);
    }
  }

  closeMenu(indexOfMenu) {
    const hasTrackingMenu: number = this.isUnAuthMenu ? 1 : this.authService.trackingPermission;
    indexOfMenu = indexOfMenu < 2 ? indexOfMenu : indexOfMenu - hasTrackingMenu;
    this.trigger.toArray()[ indexOfMenu ].closeMenu();
  }

  showSignupPromo(urlPath: any) {
    const prevUrl = this.appSettings.apiUrl.replace('api/', 'carrier' + urlPath);
    const config: MatDialogConfig = {
      autoFocus: false,
      data: {
        isQuickSignup: this.isQuickSignup,
        prevUrl
      }
    };
    this.dialog.open(SignupPromoDialogComponent, config);
  }
}
