import { TruckLocation } from '../model/weather-alert';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MapConstants } from 'src/app/shared/model/map-constants';

@Injectable()
export class WeatherAlertService {

  constructor(private http: HttpClient) { }

  fetchWeatherAlert(truckLocations: TruckLocation[]): Promise<any> {
    let params: string;
    truckLocations.forEach(element => {
      if (params == null || params.length === 0) {
        params = '/observations?p=' + element.lat + ',' + element.long;
      } else {
        params = params + ',/observations?p=' + element.lat + ',' + element.long;
      }
    });

    const apiUrl = `${MapConstants.AERIS_WEATHER_PI_URL}client_id=${MapConstants.AERIS_CLIENT_ID}
    &client_secret=${MapConstants.AERIS_CLIENT_SECRET}&bcsi_scan_64741547a7fe261b=0&requests=${params}`;

    return new Promise((resolve, reject) => {
      this.http
        .get(apiUrl, { headers: new HttpHeaders({ skipRedirect: 'true' }) })
        .toPromise().then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
    });
  }

}
