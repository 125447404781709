<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{isNAUser ? load.number : load.isTravelOrder ? load.alternateNumber : load.number}}</span>
    <button mat-icon-button (click)="onClose()"><i class="icon-close"></i></button>
</div>
<mat-dialog-content class="content">
    <div fxLayout="row-reverse" fxLayoutGap="25px" fxLayout.lt-md="column" class="container">
        <app-load-detail-map fxFlex="grow" [loadStops]="data.load?.stops"></app-load-detail-map>
        <div fxFlex="none" fxLayout="column" fxLayoutGap="10px" class="stop-list">
            <ng-container *ngFor="let stop of load.stops; last as isLast">
                <div fxLayout="row" fxLayoutGap="10px" class="stop-item">
                    <div fxLayout="column" fxLayoutAlign="start center">
                        <ng-container [ngSwitch]="stop.number ?  stop.number : stop.stopSequenceNumber">
                            <i *ngSwitchCase="1" class="icon-circle-outlined origin"></i>
                            <i *ngSwitchCase="load.stops.length" class="icon-circle-outlined destination"></i>
                            <i *ngSwitchDefault class="icon-square-round-edges"></i>
                        </ng-container>
                        <div *ngIf="!isLast" class="line"></div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="5px" class="stop-details">
                        <div class="stop-type">
                            <ng-container [ngSwitch]="stop.number ?  stop.number : stop.stopSequenceNumber">
                                <span *ngSwitchCase="1">origin</span>
                                <span *ngSwitchCase="load.stops.length">destination</span>
                                <span *ngSwitchDefault>stop
                                    {{stop.number ? stop.number - 1 : stop.stopSequenceNumber - 1}}</span>
                            </ng-container>
                            <span> • </span>
                            <span *ngIf="stop.type === stopTypes.pickUp;else delivery">pickup</span>
                            <ng-template #delivery><span>delivery</span></ng-template>
                        </div>
                        <div>{{stop.cityName}}, {{isNAUser? (stop.stateCode || stop.province) : (stop.countryName || stop.countryCode)}} {{stop.zipCode || stop.postalCode}}</div>
                        <div>{{stop.scheduledArrivalEarly | xpocDateTime: monthDateTimeFormat}}
                            <span *ngIf="!stop.scheduledArrivalLate"> {{stop.timeZoneCode || stop.timeZoneAbbreviation}}</span>
                            <span *ngIf="stop.scheduledArrivalLate">&nbsp;-&nbsp;</span>
                            <span *ngIf="stop.scheduledArrivalLate">
                                {{getScheduledArrivalLate(stop.scheduledArrivalEarly,stop.scheduledArrivalLate)}}
                                {{stop.timeZoneCode || stop.timeZoneAbbreviation}}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>