import { Component, OnInit } from '@angular/core';
import { XPOConstants } from 'src/app/shared';
import { Observable, timer } from 'rxjs';
import { GoogleAnalyticsEventsService } from 'src/app/google-analytics/google-analytics-events.service';
import { MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-session-message',
  templateUrl: './session-message.component.html',
  styleUrls: [ './session-message.component.scss' ]
})
export class SessionMessageComponent implements OnInit {
  public idleState: string;
  public timedOut: boolean;
  public countDown;
  public count;
  private minuteString: string;
  private minutesString: string;
  private secondsString: string;

  constructor(
    private dialogRef: MatDialogRef<SessionMessageComponent>,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {

    this.dialogRef.disableClose = true;
    this.idleState = this.dialogRef._containerInstance._config.data.idleState;
    this.timedOut = this.dialogRef._containerInstance._config.data.timedOut;
    this.count = this.dialogRef._containerInstance._config.data.timedOutInterval;
    this.minutesString = this.dialogRef._containerInstance._config.data.minutes;
    this.minuteString = this.dialogRef._containerInstance._config.data.minute;
    this.secondsString = this.dialogRef._containerInstance._config.data.seconds;
  }

  ngOnInit() {
    this.countDown = timer(0, 1000)
      .pipe(take(this.count))
      .pipe(map(() => --this.count));
    const subscribe = this.countDown.subscribe(val => this.secondsTohhmmss(val));
  }

  onClose() {
    this.dialogRef.close('canceled');
  }

  public reset() {
    this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general,
      XPOConstants.eventAction.refresh, 'UNO Session Refresh', 10);
    this.onClose();
  }

  public login() {
    this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general,
      XPOConstants.eventAction.login, 'UNO User Re-Login', 10);
    this.onClose();
  }

  public logout() {
    this.googleAnalyticsEventsService.emitEvent(XPOConstants.eventCategory.general,
      XPOConstants.eventAction.logout, 'UNO User Logged out session', 5);
    this.onClose();
    sessionStorage.clear();
  }

  public secondsTohhmmss(totalSeconds) {

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    const countdown: any = { hours, minutes, seconds };

    if (countdown.minutes > 1) {
      this.idleState = countdown.minutes + this.minutesString + countdown.seconds + this.secondsString + '!';
    } else if (countdown.minutes === 1) {
      this.idleState = countdown.minutes + this.minuteString + countdown.seconds + this.secondsString + '!';
    } else {
      this.idleState = countdown.seconds + this.secondsString + '!';
    }
    return this.idleState;
  }

}
