

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTimePipe } from '@xpoc/ngx-common';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model';
import { KeystoneService } from 'src/app/shared/services';
import { DateTimeService } from 'src/app/shared/services/date-time.service';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-load-detail-map-popup',
  templateUrl: './load-detail-map-popup.component.html',
  styleUrls: [ './load-detail-map-popup.component.scss' ]
})
export class LoadDetailMapPopupComponent implements OnInit {

  public isNAUser: boolean
  public load: any;
  public monthDateTimeFormat: string;
  public timeFormat: string;
  public stopTypes = XPOConstants.stopTypes;
  private isLoggedIn: boolean;
  private isFromRateCon: boolean;

  constructor(
    private dialogRef: MatDialogRef<LoadDetailMapPopupComponent>,
    private keystoneService: KeystoneService,
    private authService: AuthService,
    private dateTimeService: DateTimeService,
    public datePipe: DateTimePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;
    this.load = this.data.load;
    this.isFromRateCon = this.data.isFromRateCon;
    this.isNAUser = this.authService.checkNAUser();
    this.monthDateTimeFormat = this.isNAUser ? XPOConstants.monthDateTimeFormat : XPOConstants.euMonthDateTimeFormat;
    this.timeFormat = this.isNAUser ? XPOConstants.timeformat : XPOConstants.euTimeFormat;
    this.isLoggedIn = this.authService.isLoggedIn;
  }

  ngOnInit(): void {
    this.logKeystone(this.isFromRateCon ? XPOConstants.keystoneEventName.rateConMapExpandView : XPOConstants.keystoneEventName.availableloadMapExpandView,
      XPOConstants.keystoneEventName.viewDetails, { orderNumber: this.load?.number });
  }

  getScheduledArrivalLate(scheduledArrivalEarly, scheduledArrivalLate) {
    let arrivalLateFormatted = '';
    if (scheduledArrivalLate) {
      const isDateSameRange = this.dateTimeService.isSameDate(scheduledArrivalEarly, scheduledArrivalLate);
      arrivalLateFormatted = isDateSameRange ?
        this.datePipe.transform(scheduledArrivalLate, this.timeFormat) :
        this.datePipe.transform(scheduledArrivalLate, this.monthDateTimeFormat);
    }
    return arrivalLateFormatted;
  }

  onClose() {
    this.dialogRef.close();
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string, eventIdentifier?: any) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction,
      this.isFromRateCon ? XPOConstants.keystonePage.rateConfirmation : XPOConstants.keystonePage.availableLoads,
      XPOConstants.keystoneSubPage.loadsmappopup, eventIdentifier);
    this.isLoggedIn ? this.keystoneService.logEvent(logEvent) : this.keystoneService.logAnonymousEvent(logEvent);
  }

}
