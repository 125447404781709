export class DriverData {
  constructor(
    public userName?: string,
    public driverId?: number,
    public firstName?: string,
    public lastName?: string,
    public dateOfBirth?: string,
    public licenseNumber?: string,
    public licenseStateCode?: string,
    public licenseCountryCode?: string,
    public licenseExpirationDate?: string,
    public hireDate?: string,
    public address1?: string,
    public city?: string,
    public stateCode?: string,
    public postalCode?: string,
    public countryCode?: string,
    public mobilePhoneNumber?: string,
    public alternatePhoneNumber?: string,
    public trackingPhoneNumber?: string,
    public mobilePhoneTypeCode?: string,
    public alternatePhoneTypeCode?: string,
    public trackingPhoneTypeCode?: string,
    public emailAddress?: string,
    public comments?: string,
    public photo?: string,
    public driverIdpublic?: number,
    public isActivepublic?: boolean,
    public mobileNumberNotification?: boolean,
    public alternateNumberNotification?: boolean,
    public trackingNumberNotification?: boolean,
    public emailNotification?: boolean,
    public isDriveXPO?: boolean,
    public roleCode?: string
  ) { }
}
