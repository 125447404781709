import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { CreditRedemption } from '../model/credit-redemption';
import { CreditRedemptionReversal } from '../model/credit-redemption-reversal';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {
  private baseUrl: string;
  public partnerIdentifier: string;
  public username: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService
  ) {
    this.baseUrl = this.appSettings.apiUrl;
    this.partnerIdentifier = authService.partnerIdentifier;
    this.username = this.authService.userProfile ? this.authService.userProfile.username : '';
  }

  getLoyaltyDetails(): Observable<any> {
    const url = this.getUri(`${this.partnerIdentifier}`);
    return this.http.get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getUpgradeDetails(): Observable<any> {
    let params = new HttpParams();
    params = params.append('username', this.username);
    params = params.append('partnerIdentifier', this.partnerIdentifier);
    const url = this.getUri(`notifications`);
    return this.http.get(url, this.getOptions(params))
      .pipe(response => { return response; },
        catchError(this.handleError));
  }

  getCredits(tripCode?: string): Observable<any> {
    const url = this.getUri(`${this.partnerIdentifier}/credits${tripCode ? `?tripCode=${tripCode}` : ``}`);
    return this.http
      .get(url, this.getOptions())
      .pipe(response => { return response; },
        catchError(this.handleError));
  }

  creditRedemption(request: CreditRedemption): Observable<any> {
    const url = this.getUri(`${this.partnerIdentifier}/credits/redemption`);
    return this.http
      .post(url, request, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  creditRedemptionReversal(request: CreditRedemptionReversal): Observable<any> {
    const url = this.getUri(`${this.partnerIdentifier}/credits/reversal`);
    return this.http
      .post(url, request, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  upgradeInfoUpdate(request: any): Observable<any> {
    const url = this.getUri(`notifications/${this.partnerIdentifier}`);
    return this.http
      .post(url, request, { headers: this.getHeaders() })
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  getLoyaltySummary(): Observable<any> {
    const url = this.getUri(`${this.partnerIdentifier}/summary`);
    return this.http
      .get(url, this.getOptions())
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  private getHeaders(obj?: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getOptions(params?: HttpParams) {
    const options = { headers: this.getHeaders(), params, withCredentials: true };
    return options;
  }

  // Constructrs the uri to use for the endpoint
  private getUri(endpoint?: string): string {
    return `${this.baseUrl}loyalty/${endpoint}`;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
