import { DateFilter } from './date-filter';
import { LaneFilter } from './lane-filter';
import { OptionFilter } from './option-filter';
import { SavedFilter } from './saved-filter';
import { MultiSelectFilter } from './multi-select-filter';

export class LoadboardFilter {
  constructor(
    public searchString: string,
    public page?: number,
    public pageSize?: number,
    public dateFilter?: DateFilter,
    public originLocations?: LaneFilter[],
    public originRadius?: number,
    public destinationLocations?: LaneFilter[],
    public destinationRadius?: number,
    public equipmentFilter?: OptionFilter,
    public stopFilter?: OptionFilter,
    public dayFilter?: OptionFilter,
    public statusFilter?: OptionFilter,
    public orderCommodity?: string[],
    public additionalAvailabilityFilter?: OptionFilter,
    public additionalRequirementFilter?: OptionFilter,
    public pickUpTimeFilter?: OptionFilter,
    public deliveryTimeFilter?: OptionFilter,
    public weight?: number,
    public weightUnit?: string,
    public optionFilter?: OptionFilter,
    public savedFilter?: SavedFilter,
    public equipmentSizeFilter?: string[],
    public commodityMultiselect?: MultiSelectFilter[],
    public equipmentMultiselect?: MultiSelectFilter[],
    public isExcludeCommodity?: boolean,
    public isNoWeekendLoads?: boolean,
    public isOnlyWeekendLoads?: boolean,
    public isCPAT?: boolean,
    public isSmartway?: boolean,
    public isTSA?: boolean,
    public isPIP?: boolean,
    public isFAST?: boolean,
    public isCSA?: boolean,
    public isCustomsBroker?: boolean,
    public isTWICcard?: boolean,
    public isUIIAcard?: boolean,
    public isDropAndHook?: boolean,
    public isHazmat?: boolean,
    public isTemperatureControlled?: boolean,
    public edit?: boolean,
    public filterId?: number,
    public savedSearchName?: string,
    public driverName?: string,
    public driverId?: number,
    public selectedDriverLocation?: string,
    public tripLengthMax?: number,
    public tripLengthMin?: number,
    public isBtnEnabled = true,
    public laneFilter?: any
  ) { }
}
