<section class="share-tracking-success-container">
  <div class="status-header text-align-center">
    <div class="text-capitalize margin-top-5 sub-header">
      <span>{{successTitle | translate}}</span>
    </div>
    <div class="margin-top-10">
      <i class="icon" [ngClass]="{'icon-check-circle-filled success-icon': (successData.status && !successData.hasFailures),
        'icon-warning_rounded warning-icon': (successData.status && successData.hasFailures),
        'icon-info1 error-icon': !successData.status
    }"></i>
    </div>
    <div class="text-bold-capitalize load-info margin-top-20">
      <span [translate]="'load4639'">Load#</span>
      <span>: {{loadNumber}}</span>
    </div>
  </div>
  <div class="contact-list-container margin-top-bottom-15 text-align-center">
    <app-share-tracking-contacts-list *ngIf="partiallySent" [contactInfo]="partiallySent"
      [minimumCountofItems]="minimumCountofItems">
    </app-share-tracking-contacts-list>
    <app-share-tracking-contacts-list *ngIf="fullyFailed" [contactInfo]="fullyFailed"
      [minimumCountofItems]="minimumCountofItems">
    </app-share-tracking-contacts-list>
    <app-share-tracking-contacts-list *ngIf="successfullySent" [contactInfo]="successfullySent"
      [minimumCountofItems]="minimumCountofItems">
    </app-share-tracking-contacts-list>
  </div>
  <div class="status-footer margin-top-40" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center"
    *ngIf="successData.status">
    <button type="button" mat-raised-button class="text-uppercase" id="btn-share-more"
      (click)="triggerSuccessAction('shareMore')">
      <span [translate]="'shareMore6403'">Share More</span>
    </button>
    <button *ngIf="successData?.pageInfo !== rateConfirmationPage  && !loadDetails.showUpdateDispatch" color="accent"
      type="button" mat-raised-button class="text-uppercase padding-5 wrapped-btn" id="btn-update-dispatch"
      (click)="triggerSuccessAction('updateDispatch')">
      <span [translate]="'update3385'">Update Dispatch</span>
    </button>
  </div>
</section>