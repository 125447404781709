import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: [ './bread-crumb.component.scss' ]
})
export class BreadCrumbComponent implements OnInit {

  @Input()
  menuItems: any;

  @Output()
  subMenuItemTrigger: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public triggerSubItemAction() {
    if (this.menuItems && this.menuItems.enableSubMenuAction) {
      this.subMenuItemTrigger.next();
    }
  }
}
