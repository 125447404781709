<div class="load-requirements-container" fxLayout="column" fxLayoutGap="10px">
  <div class="requirement-div" *ngIf="isNAUser && !isUnAuthenticated && ratePerDistance && !load.isBidOnly">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="rateInformationToggle = !rateInformationToggle">
      <span class="text-bold text-uppercase" [translate]="'rateinforma7519'">
        rate information
      </span>
      <span>
        <i [ngClass]="{'icon-chevron-up':rateInformationToggle, 'icon-chevron-down' :!rateInformationToggle}"></i>
      </span>
    </div>
    <div class="load-details-block" *ngIf="rateInformationToggle">
      <div fxLayout="row" fxLayoutGap="15px" *ngIf="ratePerDistance">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'ratepermile1495'">
          rate per mile
        </div>
        <div fxFlex="50%" *ngIf="!isMultipleCommodities; else multiple">{{ratePerDistance| xpocCurrency:
          currency: true : tenandId}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" *ngIf="ratePerDistanceWithDeadhead">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'ratepe7820'">
          rate per mile with deadhead
        </div>
        <div fxFlex="50%" *ngIf="!isMultipleCommodities; else multiple">{{ratePerDistanceWithDeadhead| xpocCurrency:
          currency: true: tenandId}}</div>
      </div>
    </div>
  </div>

  <div class="requirement-div">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="loadDetailsToggle = !loadDetailsToggle">
      <div class="text-bold text-uppercase" [translate]="'loaddetails4634'">
        load details
      </div>
      <i [ngClass]="{'icon-chevron-up':loadDetailsToggle, 'icon-chevron-down' :!loadDetailsToggle}"></i>
    </div>
    <div class="load-details-block" *ngIf="loadDetailsToggle" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'equipmentTy3635'">
          Equipment Type
        </div>
        <div fxFlex="50%">{{load.equipmentType}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'commod2326'">
          commodities
        </div>
        <div fxFlex="50%" *ngIf="!isMultipleCommodities; else multiple">{{commodity}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'weight678'">
          Weight
        </div>
        <div fxFlex="50%" *ngIf="load.weight">{{load.weight}} {{weightUnit}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'dimensions5866'">
          Dimensions
        </div>
        <div fxFlex="50%" *ngIf="!isMultipleCommodities; else multiple">{{dimensions}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="15px">
        <div fxFlex="50%" class="text-capitalize label" [translate]="'temper4957'">
          temperature range
        </div>
        <div fxFlex="50%" *ngIf="!isMultipleCommodities; else multiple">{{temperatureRange}}</div>
      </div>
    </div>
  </div>

  <div class="requirement-div">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="loadStopDetailsToggle = !loadStopDetailsToggle">
      <div class="text-bold-uppercase head-text" [translate]="'lanean6076'">
        lane and stops
      </div>
      <i [ngClass]="{'icon-chevron-up':loadStopDetailsToggle, 'icon-chevron-down' :!loadStopDetailsToggle}"></i>
    </div>
    <div *ngIf="loadStopDetailsToggle">
      <div class="compare-lane-content">
        <ul>
          <li *ngFor="let stop of load.stops; first as isFirst; last as isLast; let i = index">
            <div class="stops-data">
              <div id="first-stop" fxLayout="column">
                <div class="load-stops" *ngIf="isFirst">
                  <span>
                    <i class="icon-circle-filled origin-stop-marker"></i>
                  </span>
                  <div fxLayout="column" class="stop-data">
                    <p>
                      {{stop.cityName}}, {{ isNAUser?stop.stateCode:stop.countryName}} {{stop.zipCode}}
                    </p>
                    <p *ngIf="!stop.scheduledArrivalLate">
                      <b [translate]="'scheduled9690'">Scheduled</b><span>:</span>
                      {{stop.scheduledArrivalEarly | xpocDateTime: dateTimeFormat}}
                    </p>
                    <p *ngIf="stop.scheduledArrivalLate">
                      <b [translate]="'scheduledFr6286'">Scheduled From</b><span>:</span>
                      {{stop.scheduledArrivalEarly | xpocDateTime: dateTimeFormat}}
                    </p>
                    <p *ngIf="stop.scheduledArrivalLate">
                      <b [translate]="'scheduledTo3675'">Scheduled To</b><span>:</span>
                      {{stop.scheduledArrivalLate | xpocDateTime: dateTimeFormat}}
                    </p>
                  </div>
                </div>
              </div>
              <div id="intermediate-stop" *ngIf="!isFirst && !isLast">
                <div class="load-stops">
                  <span class="intermediate-stop-marker">
                    <i class="icon-circle-filled secondary-stop-marker"></i>
                  </span>
                  <div *ngIf=!stop class="xpo-capitalize" [translate]="'nostop2846'"> no stop</div>
                  <div class="stop-data" fxLayout="column" *ngIf=stop>
                    <p>
                      {{stop.cityName}}, {{ isNAUser?stop.stateCode:stop.countryName}} {{stop.zipCode}}
                    </p>
                    <p *ngIf="!stop.scheduledArrivalLate">
                      <b [translate]="'scheduled9690'">Scheduled</b><span>:</span>
                      {{stop.scheduledArrivalEarly | xpocDateTime: dateTimeFormat}}
                    </p>
                    <p *ngIf="stop.scheduledArrivalLate">
                      <b [translate]="'scheduledFr6286'">Scheduled From</b><span>:</span>
                      {{stop.scheduledArrivalEarly | xpocDateTime: dateTimeFormat}}
                    </p>
                    <p *ngIf="stop.scheduledArrivalLate">
                      <b [translate]="'scheduledTo3675'">Scheduled To</b><span>:</span>
                      {{stop.scheduledArrivalLate | xpocDateTime: dateTimeFormat}}
                    </p>
                  </div>
                </div>
              </div>
              <div id="last-stop" *ngIf="isLast">
                <div class="load-stops">
                  <span>
                    <i class="icon-circle-filled destination-stop-marker"></i>
                  </span>
                  <div class="stop-data" fxLayout="column">
                    <p>
                      {{stop.cityName}}, {{ isNAUser?stop.stateCode:stop.countryName}} {{stop.zipCode}}
                    </p>
                    <p *ngIf="!stop.scheduledArrivalLate">
                      <b [translate]="'scheduled9690'"> Scheduled</b><span>:</span>
                      {{stop.scheduledArrivalEarly | xpocDateTime: dateTimeFormat}}
                    </p>
                    <p *ngIf="stop.scheduledArrivalLate">
                      <b [translate]="'scheduledFr6286'"> Scheduled From</b><span>:</span>
                      {{stop.scheduledArrivalEarly | xpocDateTime: dateTimeFormat}}
                    </p>
                    <p *ngIf="stop.scheduledArrivalLate">
                      <b [translate]="'scheduledTo3675'">Scheduled To</b><span>:</span>
                      {{stop.scheduledArrivalLate | xpocDateTime: dateTimeFormat}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="requirement-div">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="loadRequirementsToggle = !loadRequirementsToggle">
      <div class="text-bold-uppercase head-text">
        <span *ngIf="!isNAUser;else endorsements" [translate]="'loadre2664'">load requirements</span>
        <ng-template #endorsements>Endorsements</ng-template>
      </div>
      <i [ngClass]="{'icon-chevron-up':loadRequirementsToggle, 'icon-chevron-down' :!loadRequirementsToggle}"></i>
    </div>
    <div *ngIf="loadRequirementsToggle" class="carrier-requirements-block">
      <app-carrier-requirements [load]="load"></app-carrier-requirements>
    </div>
  </div>
  <div class="requirement-div">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center" (click)="notesToggle = !notesToggle">
      <div class="text-bold-uppercase">
        <span *ngIf="!isNAUser;else notesReq" [translate]="'notes7747'">Notes</span>
        <ng-template #notesReq>Notes/Requirements</ng-template>
      </div>
      <i [ngClass]="{'icon-chevron-up':notesToggle, 'icon-chevron-down' :!notesToggle}"></i>
    </div>
    <div class="notes-container" *ngIf="notesToggle">
      <app-online-load-req [loadDetails]="load" [notesWordLength]="notesWordLength">
      </app-online-load-req>
    </div>
    <ng-template #multiple>
      <div fxFlex="50%" class="text-capitalize" [translate]="'multiple889'">
        Multiple
      </div>
    </ng-template>
  </div>

  <div class="redeem-block" fxLayout="row" *ngIf="showRedeemOption && isNAUser">
    <div fxFlex="10%" fxLayoutAlign="start center">
      <mat-checkbox [disabled]="isCreditDisabled || remainingCredits == 0 || hasAlreadyRedeemed" labelPosition="right"
        (change)="addCredit($event)" [checked]="hasAlreadyRedeemed">
      </mat-checkbox>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="75%">
      <div class="text-capitalize text-bold" [translate]="'redeem7946'"> redeem rewards credit</div>
      <div class="remaining-credit">{{'remain1390' | translate}}: ${{remainingCredits}}</div>
    </div>
    <div fxFlex="15%" fxLayoutAlign="start center" class="text-bold redeem-amount">
      <div>${{creditToRedeem}}</div>
    </div>
  </div>

  <div class="agreement-block margin-bottom-15" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="15px">
      <mat-checkbox (change)="confirmAction($event)">
      </mat-checkbox>
      <span [translate]="'iagree7197'">
        I agree to XPO's load tracking requirements and I confirm that I understand and will meet the carrier
        requirements for this load.
      </span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="15px" class="padding-top-5"
      *ngIf="load.isValidationRequired && !this.isUnAuthenticated && isValidationRequired">
      <mat-checkbox (change)="isValidationConfirmAction($event)"></mat-checkbox>
      <span>{{checkboxValidationMsg}}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="15px" class="padding-top-5"
      *ngIf="!load.isAppointmentConfirmed && !this.isUnAuthenticated && isNAUser">
      <mat-checkbox (change)="isAppointmentConfirmAction($event)"></mat-checkbox>
      <span>Must call XPO representative to get valid appointment time! The appointment time shown is a
        temporary value. (Contact Digital Freight Match +1 (866) 431-0752).</span>
    </div>
  </div>
</div>