<div mat-dialog-title class="text-bold text-uppercase" fxLayout="row" fxLayoutAlign="space-between center">
    <span>XPO Carrier Rewards Terms & Conditions</span>
    <span class="dialog-close-icon">
        <i class="icon-close" (click)="closeDialog()"></i>
    </span>
</div>

<mat-dialog-content>
    <p>
        These Terms and Conditions (the “Terms”) govern participation in the XPO Carrier Rewards program (the
        “Program”). Please read these Terms and Conditions (the “Terms”) carefully before agreeing to participate in the
        Program. Your eligibility to participate in the Program is conditioned on your acceptance of, and compliance
        with, these Terms. These Terms are effective as of the date of your acceptance of the Terms. Registration for,
        acceptance of and redemption of any rewards under the Program each constitute acceptance of these Terms.
    </p>
    <p>
        The Program is provided for the mutual benefit of XPO Logistics (“XPO”) and carrier companies that have executed
        a Motor Carrier Transportation Agreement (the “Agreement”) with XPO to deliver freight on behalf of XPO and its
        customers (“Carriers”). Entities that do not have an active Agreement with XPO are not eligible to participate
        in the Program. If there is a conflict between the terms of the Agreement and these Terms, the Agreement will
        control. These Terms do not modify the requirements set forth in any load tender or shipper instructions. These
        Terms do not modify any provisions of the Agreement.
    </p>
    <p class="heading">General</p>
    <p>
        Program participation and its benefits (“the Benefits”) are offered to Carrier participants (“Participants”) at
        the sole discretion of XPO and its affiliated companies. Any interpretation of Program Terms shall be at the
        sole discretion of XPO.
    </p>
    <p>
        Participation in the Program is void if prohibited by law in any jurisdiction of the Carrier. Participation in
        and Benefits under the Program are available to Carriers only and not individual drivers employed by or
        contracted with Carriers. To the extent Carrier is an individual, such individual must be of majority age to be
        a Participant in the Program and to redeem any Benefits provided through the Program. Specific Program benefits
        may not be available to Participants if prohibited by law in the Participant’s jurisdiction.
    </p>
    <p>
        XPO reserves the right to add, modify and/or delete any of the provisions of these Terms or any accompanying
        rules, Benefits or other aspects of the Program in its sole discretion at any time and for any reason and with
        or without notifying Participants. Such additions, modifications and/or deletions may be applied retroactively
        and can change or eliminate the value of any Benefits previously made available to Participants. Participant’s
        continued participation in the Program is conditioned on Participant’s acceptance of any change in these Terms
        or related rules, benefits or other aspects of the Program.
    </p>
    <p>
        XPO reserves the right to unilaterally terminate the Program at any time and with or without notice. If XPO
        terminates the Program, XPO may in its sole discretion determine whether to allow or to not allow any grace
        period for Participants to redeem any Benefit value previously made available to Participants through
        participation in the Program. All Benefits would terminate at the end of any such grace period.
    </p>
    <p>
        XPO reserves the right to terminate the participation of any Participant who XPO believes, in its sole
        discretion, is using the Program in a manner inconsistent with the Terms or Program’s intent or, without
        limitation, any of the following:
    </p>
    <ol>
        <li> Engaged in any misconduct, wrongdoing, fraudulent or dishonest behavior, or theft;</li>
        <li> Violated any applicable laws, regulations or ordinances;</li>
        <li> Violated any of the Terms;</li>
        <li> Becomes non-compliant with any XPO policy in the Agreement or any other agreement with XPO or its
            affiliates;</li>
        <li> Bankruptcy or insolvency of Participant Carrier; or</li>
        <li> Does not contract to carry an XPO load for a period of twelve (12) months.</li>
    </ol>
    <p>
        Termination of the participation of a Participant in the Program will also terminate any Benefits previously
        made available to the Participant. If XPO later allows Participant to again participate in the Program after any
        termination, no Benefits provided under the Program prior to termination will be restored to Participant.
    </p>
    <p class="heading">Benefits and Access</p>
    <p>
        The Program may include one or more Benefits to Participant. Such Benefits do not constitute property of the
        Participant. No Benefit or any other thing of value offered or provided under the Program creates any property
        interest to the Participant or any other party in such Benefit or other thing of value. Participant agrees that
        XPO will have NO liability of any kind or amount as a result of any Benefit not being credited to Participant
        under the Terms of the Program. In the event that liability may be considered due for failure to credit a
        Benefit, such liability will be limited to the value of the Benefit as determined by XPO. Participant agrees
        that Benefits provided under the program are non-transferrable and Participant agrees to not attempt to sell,
        trade, auction, or barter the value of such Benefits.
    </p>
    <p>
        Benefits may have an expiration date and expire if not redeemed by Participant. Participant is responsible for
        knowing what Benefits are available to Participant and the terms including expiration dates of such Benefits
        whether or not XPO provides any information of such Benefits to Participant.
    </p>
    <p>
        Some Benefits, such as Status Bonuses or Rewards Tier Bonuses as described below, can only be redeemed by the
        Participant by contracting to carry a load for XPO using the XPO online applications XPO Connect or Drive XPO.
    </p>
    <p>
        Benefits may be provided based on the volume of loads that the Participant contracts to deliver for XPO,
        Participant’s rating on the quality of performance provided for XPO on such loads, and/or other factors. The
        calculation of such Benefit criteria is determined at the sole discretion of XPO and may be revised or updated
        by XPO at any time. Participant agrees that it will communicate any dispute regarding such criteria calculations
        to XPO immediately and accept XPOs decision on any resolution provided.
    </p>
    <p class="heading">Program Structure</p>
    <p>
        Benefits may vary based on the Program tier for which the Participant qualifies. The Program initially includes
        four (4) tiers, Bronze, Silver, Gold and Platinum. Initial eligibility requirements for each tier is summarized
        in the table below. For each tier, Participant must maintain both the monthly load volume and minimum carrier
        score. Eligibility requirements and Benefits under each tier may be revised at the sole discretion of XPO at any
        time.
    </p>
    <div class="requirements-heading"> requirements</div>
    <mat-table [dataSource]="rewardsDetails" class="rewards-details">
        <ng-container matColumnDef="tier">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-bold-uppercase"> {{row.tier}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="monthlyLoadVolume">
            <mat-header-cell *matHeaderCellDef> Monthly Load Volume </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.monthlyLoadVolume}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="minimumCarrierScore">
            <mat-header-cell *matHeaderCellDef> Minimum Carrier Score </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.minimumCarrierScore}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['tier', 'monthlyLoadVolume', 'minimumCarrierScore']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['tier', 'monthlyLoadVolume', 'minimumCarrierScore']"></mat-row>
    </mat-table>

    <p>
        As an example, based on the above chart and provided for the avoidance of doubt, a Participant with a monthly
        load volume of three loads per month and carrier score of 98 would be eligible for the Silver tier. As a second
        example, a Participant with a monthly load volume of 11 and a carrier score of 89 would be eligible for the Gold
        tier.
    </p>
    <p>
        A Status Bonus may be credited to a Participant that becomes eligible for a higher Program tier. Such Status
        Bonus is only credited the first time a Participant becomes eligible for a higher Program tier. If Participant
        loses eligibility for the higher tier and later becomes eligible again for the higher tier, no additional Status
        Bonuses are credited. Rewards Tier Bonuses are credited once per quarter to a Participant.
    </p>
    <p>
        XPO strives to provide accurate information, however XPO is not responsible for incorrect or inaccurate
        information or calculations or any other technical or non-technical error.
    </p>
    <p>
        Participant is responsible for maintaining the security of any login information provided to Participant to
        access Program information and redeem Benefits, including but not limited to, access to the XPO apps XPO Connect
        and Drive XPO. Participant agrees to notify XPO immediately of any unauthorized access or fraudulent activity.
    </p>
    <p class="heading">Marketing Partners</p>
    <p>
        XPO may include third party partners (“Partners”) in its Program to enhance Program Benefits or for various
        other reasons. Nothing in the Terms is intended to convey or shall be construed as establishing any agency,
        partnership or joint venture relationship between XPO and such Partners.
    </p>
    <p>
        Fuel discount and tire and maintenance discount benefits are currently provided through the Program using a
        Partner. Participant must complete a credit application that must be reviewed and approved by Partner to be able
        to participate in the fuel discount and/or tire and maintenance benefit. If Partner does not approve the
        Participant to participate in the fuel discount and/or tire and maintenance benefit, Participant may still be
        able to participate in the Program and be provided access to other Benefits. XPO disclaims any and all liability
        or other claims of any type related to the provision of benefits by Partners including the gathering, use or
        disclosure of any Participant information provided to Partner. Participant is solely responsible for acceptance,
        understanding and compliance with any terms and conditions associated with any Benefits provided by or through
        Partners.
    </p>
    <p>
        Although XPO endeavors to ensure that all Program information is updated and accurate, XPO does rely at least in
        part on third parties and third-party information and services and XPO cannot guarantee the accuracy of such
        information and services. It is up to Participant to ensure any information is accurate. XPO accepts no
        liability for any loss, direct or indirect, Participant experiences as a result of relying wholly on the Program
        information provided by XPO.
    </p>
    <p class="heading">Permission to use Information</p>
    <p>
        Participant consents to XPO use of any Program information for marketing and other uses without any compensation
        or other value being provided to Participant. XPO agrees to not provide Participant information to third parties
        except in the course of administering the Program and providing Program Benefits
    </p>
    <p class="heading">Limitation of Liability.</p>
    <p>
        XPO provides the Program “As-is” and to the fullest extent permitted by applicable law expressly disclaims any
        warranties of any kind, express or implied, including but not limited to, warranties of merchantability, fitness
        for a particular purpose, accuracy, completeness or availability of the Program or Benefits. Participant agrees
        that XPO does not represent, warrant or guaranty that the Program will be uninterrupted, without omissions or
        error free, or that defects will be corrected, or changes implemented. Under no circumstances including, but not
        limited to, negligence will XPO be liable for any direct, indirect, incidental, special or consequential damages
        arising out of the Program or related products and services, even if an XPO representative has been advised of
        the possibility of such damages.
    </p>
    <p>
        To the extent not prohibited by law, in no event shall XPO be liable for personal injury or any incidental,
        special, indirect, or consequential damages whatsoever, including, without limitation, damages for loss of
        profits, loss of data, business interruption, or any other commercial damages or losses, arising out of or
        related to your use or inability to use the Program, however caused, regardless of the theory of liability
        (contract, tort, or otherwise) and even if XPO has been advised of the possibility of such damages. Some
        jurisdictions do not allow the limitation of liability for personal injury, or of incidental or consequential
        damages, so this limitation may not apply to you. In no event shall XPO’s total liability to Participant, or any
        other party, for all damages (other than as may be required by applicable law in cases involving personal
        injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the
        above-stated remedy fails of its essential purpose.
    </p>
    <p>
        Participant is solely responsible for the determination of any tax liabilities that may be incurred as a result
        of participation in the Program.
    </p>
</mat-dialog-content>