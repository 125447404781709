import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppSettings } from 'src/app/core/app-settings';
import { KeystoneService } from '../services';
import { SharedService } from '../services/shared.service';
import { LogEvent } from 'src/app/shared/model';
import { XPOConstants } from 'src/app/shared';
import { Router } from '@angular/router';
@Component({
  selector: 'app-advertisement-banner',
  templateUrl: './advertisement-banner.component.html',
  styleUrls: [ './advertisement-banner.component.scss' ]
})
export class AdvertisementBannerComponent implements OnInit {
  public isBannerClosed: boolean;
  public cookiePolicyUrl: string;
  @Input() bannerData: any;
  constructor(
    private cookieService: CookieService,
    private appSettings: AppSettings,
    private sharedService: SharedService,
    private keystoneService: KeystoneService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCookieValue();
    this.cookiePolicyUrl = this.appSettings.apiUrl.replace('api/', 'policy/cookie');
  }

  getCookieValue() {
    this.isBannerClosed = this.cookieService.check('carrierCompetitionBanner');
    this.sharedService.setCarrierCompetitionBanner({ showBanner: !this.isBannerClosed });
    this.sharedService.setCarrierCompetitionBanner({
      showBanner: !this.isBannerClosed, page: this.bannerData.page,
      eventName: this.bannerData.eventName
    });
  }
  private logUsersKeystoneEvents(eventName, eventAction, eventIdentifiers?) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction,
      XPOConstants.keystonePage.adminUsers, null, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

  closeBanner() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.contestDismissiveBanner,
      XPOConstants.keystoneEventAction.cancel,
      this.bannerData.page, null, '');
    this.keystoneService.logEvent(logEvent);
    this.cookieService.set('carrierCompetitionBanner', 'true', 14);
    this.isBannerClosed = true;
    this.sharedService.setCarrierCompetitionBanner({
      showBanner: !this.isBannerClosed, page: this.bannerData.page,
      eventName: this.bannerData.eventName
    });
  }
  openContestDetails() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.carrierCompetitionDetails,
      XPOConstants.keystoneEventAction.view,
      this.bannerData.page, null, '');
    this.keystoneService.logEvent(logEvent);
    this.router.navigateByUrl('/carrier-contest');
  }

}
