import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SelfRegistration } from '../model/self-registration';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/core/app-settings';
import { SelfRegistrationRequest } from '../model/self-registration-request';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings
  ) {
    this.baseUrl = this.appSettings.apiUrl;
  }

  userRegistration(userDetails: SelfRegistration | SelfRegistrationRequest): Observable<any> {
    const url = this.getUri('carrier');
    return this.http
      .post(url, userDetails, { headers: new HttpHeaders({ skipRedirect: 'true' }) })
      .pipe(response => {
        return response;
      });
  }
  passwordRest(email: any) {
    const url = this.baseUrl + 'passwordreset/';
    return this.http
      .post(url, email, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));

  }

  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + 'registrations/' + endpoint;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  public getSignupRoles(): Observable<any> {
    const roleURI = this.getUri('roles');
    return this.http
      .get(roleURI, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      });
  }

  public getInfoSourceList(): Observable<any> {
    const infoSourceURI = this.getUri('sources');
    return this.http
      .get(infoSourceURI, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      });
  }

  public checkForExistingUser(emailToCheck) {
    const existingUserCheckURI = this.getUri('validateuser');
    let queryParams = new HttpParams();
    queryParams = queryParams.append('userName', emailToCheck);
    return this.http
      .get(existingUserCheckURI, { headers: this.getHeaders(), params: queryParams })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

}
