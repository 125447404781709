<div class="progress-bar">
  <div *ngIf="showValue" class="progress-label" id="Progress-id">
    <span *ngIf="value.val !== undefined && value.val!==0; else no_value">
      <b>{{value.val}} </b>of {{value.maxVal}}</span>
  </div>
  <progress max={{value.maxVal}} value={{value.val}} class="progress-main" aria-labelledby="Progress-id">
    <div class="progress-bar" role="presentation">
      <span class="progress-value">&nbsp;</span>
    </div>
  </progress>
  <ng-template #no_value>
    <span class="text-bold">N/A</span>
  </ng-template>
</div>
