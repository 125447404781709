<section class="ad-banner-container">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">

        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center center">
            <div fxFlex="95%">
                <span class="ad-text">Win 1000 € Apple Voucher</span>
                <button class="view-more cursor-pointer" (click)="openContestDetails()">Know More</button>
            </div>
            <div>
                <i class="icon-close icon" (click)="closeBanner()"></i>
            </div>
        </div>

    </div>

</section>
<!-- TBD: Change the text and add a CTA -->