<div class="create-driver-form">
  <div class="required-field">
    <span class="required">* </span>
    <span [translate]="'requir4016'">Required fields</span>
  </div>
  <form [formGroup]="driverForm">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
          <mat-label>{{'userNa2996' | translate | titlecase}}</mat-label>
          <input matInput size="100" id="userName" name="userName" formControlName="userName">
          <mat-error *ngIf="driverForm.controls.userName.errors && driverForm.controls.userName.errors.invalid">
            {{driverForm.controls.userName.errors.invalid.error}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'firstName9858' | translate | titlecase}}</mat-label>
          <input matInput size="100" id="firstName" name="firstName" formControlName="firstName" required>
          <mat-error *ngIf="driverForm.controls.firstName.errors && 
                (driverForm.controls.firstName.errors.invalidName || driverForm.controls.firstName.errors.required)">
            <span [translate]="'entervalidf5618'"> Enter valid first name
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'lastName1947' | translate | titlecase}}</mat-label>
          <input matInput size="100" id="lastName" name="lastName" formControlName="lastName" required>
          <mat-error *ngIf="driverForm.controls.lastName.errors && 
              (driverForm.controls.lastName.errors.invalidName || driverForm.controls.lastName.errors.required)">
            <span [translate]="'entervalidl6237'">Enter valid last name</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'street231' | translate | titlecase}}</mat-label>
          <input matInput size="100" id="streetAddress" name="streetAddress" formControlName="streetAddress">
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'city5383' | translate | titlecase}}, {{'state8720' | translate | titlecase}},
              {{'countr5418' | translate | titlecase}}</mat-label>
            <input matInput id="location" [matAutocomplete]="originAuto" name="driverCityStateCountry"
              formControlName="cityStateCountry" (keyup)="onSelectCity()">
            <mat-error
              *ngIf="driverForm.controls.cityStateCountry.errors && driverForm.controls.cityStateCountry.errors.invalid">
              {{driverForm.controls.cityStateCountry.errors.invalid.error}}
            </mat-error>
          </mat-form-field>
          <mat-autocomplete #originAuto="matAutocomplete" appearance="outline">
            <mat-option *ngFor="let location of cityList" [value]="location.label"
              (onSelectionChange)="onSelectCity(location)">
              {{ location.label }}
            </mat-option>
          </mat-autocomplete>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'zipcod2866' | translate | titlecase}}</mat-label>
            <input type="text" matInput name="driverPostalcode" formControlName="zipCode" id="zipCode"
              (keyup)="getCity()">
            <mat-error *ngIf="driverForm.controls.zipCode.errors && driverForm.controls.zipCode.errors.invalid">
              {{driverForm.controls.zipCode.errors.invalid.error}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="text-bold phone-wrap" [translate]="'addAnd2656'">
          Please add and select which phone numbers you would like to receive SMS messages
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-checkbox class="margin-bottom-20" id="mobile-no-notify" formControlName="mobileNumberNotification"
            fxFlex="7%">
          </mat-checkbox>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'phone5880' | translate | titlecase}} 1</mat-label>
            <input matInput size="100" id="phoneNoOne" name="phoneNoOne" formControlName="phoneNoOne"
              [maxlength]="phoneNoMaxLength" (keyup)="formatPhone(1)" [required]="isNAUser">
            <mat-error
              *ngIf="driverForm.controls.phoneNoOne.errors &&
                   (driverForm.controls.phoneNoOne.errors.invalidPhone || driverForm.controls.phoneNoOne.errors.required)">
              <span [translate]="'enterV5093'">Enter valid phone number</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label [translate]="'phoneT4569'">Phone Type</mat-label>
            <mat-select id="phoneNoTypeOne" name="phoneNoTypeOne" formControlName="phoneNoTypeOne"
              [required]="isNAUser">
              <mat-option *ngFor="let type of phoneTypeList" id="type1" value="{{type.code}}">
                {{type.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" *ngIf="!driverDetails.isDriveXPO">
          <mat-checkbox class="margin-bottom-20" id="alternate-no-notify" formControlName="alternateNumberNotification"
            fxFlex="7%">
          </mat-checkbox>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'phone5880' | translate | titlecase}} 2</mat-label>
            <input matInput size="100" id="phoneNoTwo" name="phoneNoTwo" formControlName="phoneNoTwo"
              [maxlength]="phoneNoMaxLength" (keyup)="formatPhone(2)">
            <mat-error
              *ngIf="driverForm.controls.phoneNoTwo.errors && driverForm.controls.phoneNoTwo.errors.invalidPhone">
              <span [translate]="'enterV5093'">Enter valid phone number</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label [translate]="'phoneT4569'">Phone Type</mat-label>
            <mat-select id="phoneNoTypeTwo" name="phoneNoTypeTwo" formControlName="phoneNoTypeTwo">
              <mat-option *ngFor="let type of phoneTypeList" id="type2" value="{{type.code}}">
                {{type.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-checkbox class="margin-bottom-20" id="tracking-no-notify" formControlName="trackingNumberNotification"
            fxFlex="7%">
          </mat-checkbox>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'tracki163' | translate | titlecase}} #</mat-label>
            <input matInput size="100" id="trackingPhoneNo" name="trackingPhoneNo" [maxlength]="phoneNoMaxLength"
              formControlName="trackingPhoneNo" (keyup)="formatPhone(3)">
            <mat-error
              *ngIf="driverForm.controls.trackingPhoneNo.errors && driverForm.controls.trackingPhoneNo.errors.invalidPhone">
              <span [translate]="'enterV5093'">Enter valid phone number</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label [translate]="'tracki8702'">Tracking Phone Type</mat-label>
            <mat-select id="trackingPhoneNoType" name="trackingPhoneNoType" formControlName="trackingPhoneNoType">
              <mat-option *ngFor="let type of phoneTypeList" id="type" value="{{type.code}}">{{type.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="text-bold phone-wrap" [translate]="'emailA5744'">
          Would you like to receive updates at the below email address ?
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-checkbox class="margin-bottom-20" id="emailNotification" formControlName="emailNotification"
            fxFlex="10%">
          </mat-checkbox>
          <mat-form-field fxFlex="90%" fxLayoutGap="10px" appearance="outline">
            <mat-label>{{'email8704' | translate | titlecase}}</mat-label>
            <input matInput size="100" id="email" name="email" formControlName="email" [required]="!isNAUser">
            <mat-error *ngIf="(driverForm.controls.email.errors && driverForm.controls.email.errors.invalidEmail) || 
                (driverForm.controls.email.errors && driverForm.controls.email.errors.required && !isNAUser)">
              <span [translate]="'entervalide8568'">Enter valid email</span>
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>{{'licens6554' | translate | titlecase}} #</mat-label>
          <input matInput size="100" id="licenseNo" name="licenseNo" formControlName="licenseNo">
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label [translate]="'licens8466'">License Country</mat-label>
            <mat-select name="licenseCountryCode" formControlName="licenseCountryCode">
              <mat-option *ngFor="let country of countries" value={{country.code}}
                (click)="onCountrySelect(country.code,true)">
                {{country.code}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label [translate]="'licens5541'">License State</mat-label>
            <mat-select formControlName="licenseStateCode" name="licenseStateCode">
              <mat-option *ngFor="let state of states" value={{state.code}}>
                {{state.code}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'licens1742' | translate | titlecase}}</mat-label>
            <input matInput [matDatepicker]="licenseDatePicker" formControlName="licenseExpiryDate"
              name="license-expiry-dates" id="license-expiry-date">
            <mat-datepicker-toggle matSuffix [for]="licenseDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #licenseDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex="50%" appearance="outline">
            <mat-label>{{'dob9113' | translate }}</mat-label>
            <input matInput [matDatepicker]="dobDatePicker" formControlName="dob" name="dob-dates" id="dob-date">
            <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dobDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>{{'notes7747' | translate | titlecase}}</mat-label>
          <textarea matInput rows="4" cols="25" maxlength="100" id="notes" name="notes"
            formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px"
      fxLayoutAlign="space-between center" class="padding-bottom-15">
      <div>
        <button mat-raised-button class="text-uppercase" *ngIf="dialogAction != userActions.create"
          (click)="deleteDriver()" color="accent">
          {{'delete2640' | translate}}
        </button>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <button mat-raised-button class="text-uppercase secondary-btn" (click)="onCancel()">
          {{'cancel7093' | translate}}
        </button>
        <button mat-raised-button class="text-uppercase" color="accent"
          *ngIf="dialogAction === userActions.create; else edit" [disabled]="!driverForm.valid" (click)="saveDriver()">
          {{'add1375' | translate}}
        </button>
        <ng-template #edit>
          <button mat-raised-button class="text-uppercase" mat-button [disabled]="!driverForm.valid"
            (click)="saveDriver()" color="accent">
            {{'update8478' | translate}}
          </button>
        </ng-template>
      </div>
    </mat-dialog-actions>
  </form>
</div>