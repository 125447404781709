import { Component, OnInit } from '@angular/core';
import { LogEvent } from '../../model/log-event';
import { XPOConstants } from '../../xpo-constants';
import { AssetManagementService } from '../../services/asset-management.service';
import { KeystoneService } from '../../services/keystone.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-equipment',
  templateUrl: './delete-equipment.component.html',
  styleUrls: [ './delete-equipment.component.scss' ]
})
export class DeleteEquipmentComponent implements OnInit {
  public equipmentDetails: any;
  public isEquipmentDeleted: boolean;

  constructor(
    private dialogRef: MatDialogRef<DeleteEquipmentComponent>,
    private assetManagementService: AssetManagementService,
    private keystoneService: KeystoneService) {
    this.dialogRef.disableClose = true;
    this.equipmentDetails = this.dialogRef._containerInstance._config.data.equipmentDetails;
  }

  ngOnInit() {
  }

  onClose() {
    if (!this.isEquipmentDeleted) {
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.deleteEquipment,
        XPOConstants.keystoneEventAction.cancel, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.equipment);
      this.keystoneService.logEvent(logEvent);
    }
    this.dialogRef.close(this.isEquipmentDeleted);
  }

  deleteEquipment() {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.deleteEquipment,
      XPOConstants.keystoneEventAction.submit, XPOConstants.keystonePage.assetManagement, XPOConstants.keystoneSubPage.equipment);
    this.keystoneService.logEvent(logEvent);
    this.assetManagementService.deleteEquipment(this.equipmentDetails.equipmentIdentifier).then(response => {
      this.isEquipmentDeleted = true;
    });
  }

}
