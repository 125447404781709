import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { StatusFilterMob } from '../../model/status-filter-mob';
import { CodeValue } from '../../model/code-value';

@Component({
  selector: 'app-filter-dropdown-mobile-dialog',
  templateUrl: './filter-dropdown-mobile-dialog.component.html',
  styleUrls: [ './filter-dropdown-mobile-dialog.component.scss' ]
})
export class FilterDropdownMobileDialogComponent implements OnInit {
  public statusFilterObj: StatusFilterMob;
  public filterList: CodeValue[];
  public filterTxt: string;
  public initCheckedFilterStatus: string[];
  public filterReset: Subject<any>;
  public selectedFilterStatus: string[];

  constructor(public dialogRef: MatDialogRef<FilterDropdownMobileDialogComponent>) {
    this.filterReset = new Subject();
    this.dialogRef.disableClose = true;
    if (this.dialogRef._containerInstance._config.data) {
      this.statusFilterObj = this.dialogRef._containerInstance._config.data;
    }
  }

  ngOnInit() {
    this.filterList = this.statusFilterObj.filterList;
    this.filterTxt = this.statusFilterObj.filterTxt;
    this.initCheckedFilterStatus = this.statusFilterObj.initCheckedFilterStatus.map(String);
    this.selectedFilterStatus = this.statusFilterObj.initCheckedFilterStatus;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onApply() {
    this.dialogRef.close(this.selectedFilterStatus);
  }

  onFilter(selectedStatusCode) {
    this.selectedFilterStatus = selectedStatusCode;
  }

  onClear() {
    this.filterReset.next(true);
    this.selectedFilterStatus = [];
  }

}
