import { Component, OnInit, Input } from '@angular/core';
import { XPOConstants } from '../../xpo-constants';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: [ './details-tab.component.scss' ]
})
export class DetailsTabComponent implements OnInit {

  @Input() public loadDetails;
  public isTemperature = false;
  public temperatureFormat = XPOConstants.temperatureFormat;
  public isNAUser: boolean;

  constructor(
    private appSettings: AppSettings,
    private authService: AuthService) { }

  ngOnInit() {
    this.temperatureFormat = this.isNAUser ? XPOConstants.temperatureFormat : XPOConstants.euTemperatureFormat;
    const dimensionsUnit = this.appSettings.getApplicationConstant('BaseDimensionUnit');
    if (this.loadDetails) {
      for (const loadDetail of this.loadDetails) {
        if (loadDetail.temperatureHigh !== undefined && loadDetail.temperatureLow !== undefined) {
          this.isTemperature = true;
        }
        loadDetail.dimensions = (loadDetail.length && loadDetail.width && loadDetail.height) ?
          `${loadDetail.length} ${dimensionsUnit} x ${loadDetail.width}
           ${dimensionsUnit} x ${loadDetail.height} ${dimensionsUnit}` : '-';
      }
    }
  }
}
