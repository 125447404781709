import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from '../xpo-constants';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: [ './terms-and-conditions.component.scss' ]
})
export class TermsAndConditionsComponent implements OnInit {

  public isNAUser: boolean;
  public privacyPolicyUrl = XPOConstants.privacyPolicyUrl;

  constructor(
    private dialogRef: MatDialogRef<TermsAndConditionsComponent>,
    private authService: AuthService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.isNAUser = this.authService.checkNAUser();
  }

  closeDialog() {
    this.dialogRef.close('cancelled');
  }
}
