import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MyLoadsService, KeystoneService } from 'src/app/shared/services';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model';
import { TranslateService } from '@ngx-translate/core';
import { LookupService } from 'src/app/shared/services/lookup.service';
import { DateTimePipe } from '@xpoc/ngx-common';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-share-tracking-dialog',
  templateUrl: './share-tracking-dialog.component.html',
  styleUrls: [ './share-tracking-dialog.component.scss' ]
})
export class ShareTrackingDialogComponent implements OnInit {

  public shouldShowBackButton = false;
  public shouldShowCopyButton = true;
  public shouldShowPrimaryActions = true;
  public shouldShowHelpText = true;
  public linkToShare = '';
  public copyLink = '';
  public isLinkCopied = false;
  public copyButtonText = 'copy1101'; // 'Copy'
  public sharingMedium = '';
  public isSharingDone = false;
  public loadDetails: any;
  public isLoading = false;
  public shareResponse;
  public locationData;
  private dialogConfig: MatDialogConfig;
  public languageList = [];
  private languageCode: string;

  constructor(
    private dialogRef: MatDialogRef<ShareTrackingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private myLoadsService: MyLoadsService,
    private keystoneService: KeystoneService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private lookupService: LookupService,
    private datePipe: DateTimePipe,
    private authService: AuthService) {
    this.dialogConfig = new MatDialogConfig();
    this.loadDetails = this.data.loadDetails;
    this.frameLocationData();
  }

  ngOnInit() {
    if (this.authService.preferredLanguageCode) {
      const language = this.authService.preferredLanguageCode;
      this.languageCode = XPOConstants.shareTrackingLanguageCodes.indexOf(language) > -1 ? language : XPOConstants.euDefaultLanguageCode;
    }
    this.fetchTrackingLink();
    this.fetchLanguagesList();
  }

  private fetchTrackingLink() {
    this.isLoading = true;
    this.myLoadsService.getTrackingLink(this.loadDetails.alternateNumber)
      .subscribe((response) => {
        if (response.isAuthourized && response.trackingUrl) {
          this.copyLink = response.trackingUrl + '?lang=' + this.languageCode;
          this.linkToShare = response.trackingUrl;
        } else {
          this.onClose('notrackingurl');
          this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTracking, XPOConstants.keystoneEventAction.failed);
        }
        this.isLoading = false;
      });
  }
  public updateTrackingLink(language) {
    this.copyLink = this.linkToShare + '?lang=' + language;
  }
  private fetchLanguagesList() {
    const FORAPPLESS = true;
    this.lookupService.languages(FORAPPLESS)
      .then(response => {
        this.languageList = response;
      });
  }

  private frameLocationData() {
    const stopsDetails = this.loadDetails.stops;
    const noOfStops = stopsDetails.length;
    const firstStop = stopsDetails[ 0 ];
    const lastStop = stopsDetails[ noOfStops - 1 ];
    this.locationData = {
      pickUp: `${firstStop.cityName}, ${firstStop.countryName ? firstStop.countryName : firstStop.countryCode} ${firstStop.zipCode}`,
      pickUpAt: this.datePipe.transform(this.loadDetails.originPickupDate, XPOConstants.euMonthDateTimeFormat),
      drop: `${lastStop.cityName}, ${lastStop.countryName ? lastStop.countryName : lastStop.countryCode} ${lastStop.zipCode}`
    };
  }

  public onClose(closeAction: string) {
    this.dialogRef.close({ action: closeAction });
  }

  public onBack(shouldClose = false) {
    if (shouldClose) {
      this.onClose('close');
      return;
    }
    const emailOrTextKeystoneEventName = this.sharingMedium === 'email' ?
      XPOConstants.keystoneEventName.shareTrackingEmailActions : XPOConstants.keystoneEventName.shareTrackingPhoneActions;
    this.logShareTrackingKeyStone(emailOrTextKeystoneEventName, XPOConstants.keystoneEventAction.back);
    this.showCopyOption();
  }

  public onCancel() {
    this.onClose('cancel');
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTracking, XPOConstants.keystoneEventAction.cancel);
  }

  public onShareSuccess(successInfo) {
    successInfo.pageInfo = this.loadDetails.pageInfo;
    this.shareResponse = Object.assign({}, successInfo);
    this.isSharingDone = true;
  }

  public onCopy(inputLink: string) {
    const selBox = document.createElement('textarea');
    selBox.value = inputLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isLinkCopied = true;
    this.copyButtonText = this.translateService.instant('copied3657') + '!';
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTracking, XPOConstants.keystoneEventAction.copy);
  }

  private showCopyOption() {
    this.sharingMedium = '';
    this.shouldShowCopyButton = true;
    this.shouldShowPrimaryActions = true;
    this.shouldShowBackButton = false;
    this.shouldShowHelpText = true;
  }

  private hideCopyOption() {
    this.shouldShowCopyButton = false;
    this.shouldShowPrimaryActions = false;
    this.shouldShowBackButton = true;
    this.shouldShowHelpText = false;
  }

  public showEmailSharingOption() {
    this.sharingMedium = 'email';
    this.hideCopyOption();
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingEmail, XPOConstants.keystoneEventAction.show);
  }

  public showTextSharingOption() {
    this.sharingMedium = 'text';
    this.hideCopyOption();
    this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingText, XPOConstants.keystoneEventAction.show);
  }

  public performSuccessAction(action) {
    if (action === 'shareMore') {
      this.isSharingDone = false;
      this.showCopyOption();
      this.shareResponse = undefined;
      this.logShareTrackingKeyStone(XPOConstants.keystoneEventName.shareTrackingMore, XPOConstants.keystoneEventAction.click);
      return;
    }
    if (action === 'updateDispatch') {
      const dispatchDetails = {
        action: 'update-dispatch',
        loadDetails: this.loadDetails
      };
      this.dialogRef.close(dispatchDetails);
    }
  }

  private logShareTrackingKeyStone(eventName, eventAction, eventIdentifiers?) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, eventName, eventAction,
      this.loadDetails.pageInfo ? this.loadDetails.pageInfo : XPOConstants.keystonePage.loads,
      this.loadDetails.subPageInfo, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }
}
