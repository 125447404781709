import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-notifications',
  templateUrl: './admin-notifications.component.html',
  styleUrls: [ './admin-notifications.component.scss' ]
})
export class AdminNotificationsComponent implements OnInit {

  public notificationMessage: string;

  constructor(public dialogRef: MatDialogRef<AdminNotificationsComponent>) {
    this.dialogRef.disableClose = true;
    this.notificationMessage = this.dialogRef._containerInstance._config.data;
  }

  ngOnInit() {
  }

  closeNotification() {
    this.dialogRef.close('canceled');
  }

  logout() {
    sessionStorage.clear();
  }
}
