<div class="file-upload-container" [formGroup]="paperworkUploadForm">
  <div formArrayName="documents">
    <div [formGroupName]="formGroupName">
      <div class="upload-form-group">
        <div class="upload-file">
          <label class="uploader" for="fileselect" ondragover="return false;">
            {{uploadedFileName | translate}}
            <input type="file" class="hide-upload" id="fileselect" name="fileselect" [accept]="supportedFileFormats"
              (change)="fileChanged($event)" />
          </label>
        </div>
      </div>
      <mat-hint *ngIf="!uploadErrorFlag">
        <div *ngIf="!uploadErrorFlag" translate [translateParams]="{supportedFileFormats: supportedFileFormats}">
          onlyTI7846
        </div>
      </mat-hint>
      <mat-error class="margin-top-5" *ngIf="uploadErrorFlag">
        {{uploadError | translate}}
      </mat-error>
    </div>
  </div>
</div>