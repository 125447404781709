import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LiveChatService {

  private notify = new Subject<boolean>();

  constructor() {
  }

  public get notifier(): Observable<boolean> {
    return this.notify.asObservable();
  }

  public showChat() {
    this.notify.next(true);
  }

  public hideChat() {
    this.notify.next(false);
  }

}
