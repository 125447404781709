import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';
import { LogEvent } from 'src/app/shared/model';
import { XPOConstants } from 'src/app/shared';
import { AppSettings } from '../app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { KeystoneService } from 'src/app/shared/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SupportFormComponent } from '../support-form/support-form.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SignupPromoDialogComponent } from 'src/app/shared/signup-promo-dialog/signup-promo-dialog.component';
import { EuSupportFormComponent } from '../eu-support-form/eu-support-form.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: [ './contact-us.component.scss' ]
})
export class ContactUsComponent implements OnInit {
  public permissions: any = [];
  public supportHelpdeskNumber: string;
  public carrierTermsAndAgreement: string;
  public emailAddress: string;
  public emailSubject: string;
  public isTrackingEnabled: boolean;
  public trackingEmailAddress: string;
  public trackingSupportNumber: string;
  public weekendTrackingSupportNumber: string;
  public isNAUser: boolean;
  public isHidden: boolean;
  public paymentEmailAddress: string;
  public paymentSupportNumber: string;
  public PaymentQuickPayPhoneNumber: string;
  public PaymentExtension: string;
  public PaymentQuickPayExtension: string;
  public showRewards = false;
  public loadSupportEmail: string;
  public loadSupportPhoneNumber: string;
  public isUnsupportedBrowser: boolean;
  public PaymentSupportQuickPayEmailAddress: string;
  public isLoggedIn: boolean;
  @Input() public isQuickSignup: boolean;

  constructor(
    private elementRef: ElementRef,
    private appSettings: AppSettings,
    public authService: AuthService,
    public keystoneService: KeystoneService,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.isLoggedIn = this.authService.isLoggedIn;
    this.isNAUser = authService.checkNAUser();
    this.supportHelpdeskNumber = this.appSettings.getApplicationConstant('XpoConnectSupportPhoneNumber');
    this.emailAddress = this.appSettings.getApplicationConstant('XpoConnectSupportEmailAddress');
    this.emailSubject = this.appSettings.getApplicationConstant('XpoConnectCarrierSupportEmailSubject');
    if (this.isNAUser) {
      this.trackingEmailAddress = this.appSettings.getApplicationConstant('TrackingSupportEmailAddress');
      this.trackingSupportNumber = this.appSettings.getApplicationConstant('TrackingSupport');
      this.weekendTrackingSupportNumber = this.appSettings.getApplicationConstant('TrackingSupportPhoneNumber');
      this.paymentEmailAddress = this.appSettings.getApplicationConstant('PaymentSupportEmailAddress');
      this.paymentSupportNumber = this.appSettings.getApplicationConstant('PaymentSupportPhoneNumber');
      this.PaymentExtension = this.appSettings.getApplicationConstant('PaymentSupportExtension');
      this.PaymentQuickPayPhoneNumber = this.appSettings.getApplicationConstant('PaymentSupportQuickPayPhoneNumber');
      this.PaymentQuickPayExtension = this.appSettings.getApplicationConstant('PaymentSupportQuickPayExtension');
      this.loadSupportEmail = this.appSettings.getApplicationConstant('XpoConnectNoDispatchSupportEmailAddress');
      this.loadSupportPhoneNumber = this.appSettings.getApplicationConstant('XpoConnectNoDispatchSupportPhoneNumber');
      this.PaymentSupportQuickPayEmailAddress = this.appSettings.getApplicationConstant('PaymentSupportQuickPayEmailAddress');
    }
    this.permissions = (this.authService && this.authService.permissions) ? this.authService.permissions : [];
    this.isTrackingEnabled = this.permissionCheck(XPOConstants.permissionTypes.availableLoads) || !this.isLoggedIn;
    this.showRewards = this.permissionCheck(XPOConstants.permissionTypes.rewardsView);
    this.isUnsupportedBrowser = this.sharedService.isUnSupportedBrowser();
  }

  @HostListener('document:click', [ '$event.target' ])
  public supportClick(targetElement) {
    const clickedInsideSupport = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInsideSupport) {
      this.isHidden = true;
    }
  }

  ngOnInit() {
    this.isHidden = true;
  }

  onSupportClick() {
    if (this.permissionCheck('helpdesk_view') || (this.isNAUser && !this.isLoggedIn)) {
      if (this.isHidden) {
        const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.support,
          XPOConstants.keystoneEventAction.click, XPOConstants.keystonePage.support);
        if (this.isLoggedIn) {
          this.keystoneService.logEvent(logEvent);
        } else {
          this.keystoneService.logAnonymousEvent(logEvent);
        }
        if (!this.isNAUser) {
          this.dialog.open(EuSupportFormComponent, { width: '515px' });
        }
      }
      this.isHidden = !this.isHidden;
    } else {
      this.openSignupPromo();
    }
  }

  openSignupPromo() {
    const config: MatDialogConfig = { autoFocus: false, data: { isQuickSignup: this.isQuickSignup } };
    this.dialog.open(SignupPromoDialogComponent, config);
  }

  public openSupportForm() {
    if (this.isLoggedIn) {
      this.onSupportClick();
      this.dialog.open(SupportFormComponent);
    }
  }

  public permissionCheck(permission: string) {
    return (this.permissions.indexOf(permission) !== -1);
  }

}
