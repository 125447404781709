import { DateFilter } from './date-filter';

export class FomLoadFilter {
  constructor(
    public page?: number,
    public pageSize?: number,
    public dateFilter?: DateFilter,
    public locationStatuses?: string[],
    public stopStatuses?: string[],
    public selectedStatusIds?: string[],
    public searchString?: string,
    public driverName?: string
  ) { }
}
