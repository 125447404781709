<div class="equipment-dialog">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div [ngSwitch]="dialogAction" *ngIf="!showSucessMsg; else confirmHeader">
        <span class="text-bold-uppercase" *ngSwitchCase=userActions.create [translate]="'addEqu5529'">
          add equipment
        </span>
        <span class="text-bold-uppercase" *ngSwitchCase=userActions.edit [translate]="'editEq6212'">
          edit equipment
        </span>
      </div>
      <ng-template #confirmHeader>
        <span class="text-bold-uppercase" [translate]="'confirmatio8018'">
          Confirmation </span>
      </ng-template>
    </div>
    <div class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </div>
  </div>

  <div>
    <div *ngIf="!showSucessMsg; ">
      <div class="required-field">
        <span class="required-star">* </span>
        <span [translate]="'requir4016'">Required fields</span>
      </div>
      <form [formGroup]="equipmentForm">
        <mat-dialog-content class="equipment-form-block">
          <div fxLayout="column" fxLayoutGap="15px">
            <div>
              <mat-radio-group fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
                class="equipment-radio-group" aria-label="Equipment Type" formControlName="equipmentTypeCode"
                [disabled]="dialogAction !== userActions.create || equipmentTypeCode" appearance="outline">
                <mat-radio-button class="text-capitalize" value="TRUCK" (change)="onEquipmentTypeCodeChange('TRUCK')">
                  {{'truck4097' | translate}}
                </mat-radio-button>
                <mat-radio-button class="text-capitalize" value="TRAILER"
                  (change)="onEquipmentTypeCodeChange('TRAILER')">
                  {{'traile3786' | translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxLayout="row">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>{{'equipment6372' | translate | titlecase}} #</mat-label>
                <input matInput id="equipmentNumber" name="equipmentNumber" formControlName="equipmentNumber" required>
                <mat-error *ngIf="equipmentForm.controls.equipmentNumber.errors && (equipmentForm.controls.equipmentNumber.errors.invalidReferenceNo || 
                      equipmentForm.controls.equipmentNumber.errors.required)">
                  <span [translate]="'enterV3316'">Enter valid equipment #</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-entry equip-category" fxLayout="row" fxLayoutGap="15px">
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label [translate]="'equipm9466'">Equipment Category</mat-label>
                  <mat-select name="equipmentCategory" formControlName="equipmentCategory">
                    <mat-option *ngFor="let category of equipmentCategories" value={{category.code}}
                      (click)="onSelectingEquipmentCategory(category.code, true)">
                      {{category.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline"
                  *ngIf="equipmentForm && equipmentForm.controls.equipmentCategory.value">
                  <mat-label [translate]="'equipmentTy3635'">Equipment Type</mat-label>
                  <mat-select formControlName="equipmentCategoryType" name="equipmentCategoryType">
                    <mat-option *ngFor="let type of equipmentCategoryType" value={{type.code}}>
                      {{type.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div>
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>{{'equipm9686' | translate | titlecase}}</mat-label>
                <input matInput id="equipmentDescription" name="equipmentDescription"
                  formControlName="equipmentDescription" maxlength="150">
                <mat-hint>
                  <span [translate]="'max1505611'">Max of 150 characters</span>
                </mat-hint>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>{{'licens9556' | translate | titlecase}}</mat-label>
                <input matInput id="licenseCode" name="licenseCode" formControlName="licenseCode">
                <mat-error
                  *ngIf="equipmentForm.controls.licenseCode.errors && equipmentForm.controls.licenseCode.errors.required">
                  <span [translate]="'licens7377'">License plate required</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label [translate]="'licens8466'">License Country</mat-label>
                  <mat-select name="licenseCountryCode" formControlName="licenseCountryCode">
                    <mat-option *ngFor="let country of countries" value={{country.code}}
                      (click)="onSelectingCountry(country.code,true)">
                      {{country.code}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label [translate]="'licens5541'">License State</mat-label>
                  <mat-select formControlName="licenseStateCode" name="licenseStateCode">
                    <mat-option *ngFor="let state of states" value={{state.code}}>
                      {{state.code}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <mat-form-field class="form-field-input" appearance="outline">
              <mat-label>{{equipmentForm.controls.equipmentTypeCode.value | titlecase}} {{'make7307' | translate}}
              </mat-label>
              <input matInput id="make" name="make" formControlName="make">
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{equipmentForm.controls.equipmentTypeCode.value | titlecase}} {{'model41' | translate}}
                  </mat-label>
                  <input matInput id="model" name="model" formControlName="model">
                </mat-form-field>
              </div>
              <div fxFlex="50%" class="truck-year-block">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{equipmentForm.controls.equipmentTypeCode.value | titlecase}} <span
                      [translate]="'year3404'">Year</span>
                  </mat-label>
                  <mat-select formControlName="year" name="year">
                    <mat-option *ngFor="let truckYear of truckYears" value={{truckYear}}>
                      {{truckYear}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="driver-block">
              <div [hidden]="!isNAUser">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{'enterV3331' | translate | titlecase}}</mat-label>
                  <input matInput formControlName="driverSearchString" id="driver" [matAutocomplete]="driverAuto"
                    class="text-capitalize" name="driver" (focusout)="clearDriver()">
                  <mat-error *ngIf="(equipmentForm.controls.driverSearchString.errors && equipmentForm.controls.driverSearchString.errors.invalid) ||
                      (equipmentForm.controls.driverName.errors && equipmentForm.controls.driverName.errors.required)"
                    class="driver-error">
                    <span class="invalid_driver_msg" [translate]="'enterV3331'">
                      Enter valid Driver
                    </span>
                  </mat-error>
                </mat-form-field>
                <mat-autocomplete #driverAuto="matAutocomplete">
                  <mat-option *ngIf="isEmptyDriverList  && isNoDriver" [translate]="'noDriv2792'">
                    No drivers are setup
                  </mat-option>
                  <mat-option *ngIf="!isEmptyDriverList && isNoDriver" [translate]="'noDriv7746'">
                    No drivers are found
                  </mat-option>
                  <mat-option *ngFor="let driver of driverList" class="text-capitalize" [value]="driver.name"
                    (onSelectionChange)="onSelectDriver(driver)" fxLayoutAlign="space-between center">
                    <div fxLayoutAlign="space-between center">
                      <span>{{ driver.name }}</span>
                      <span class="drivexpo-logo-img" *ngIf="driver.isDriveXPO">
                        <img src="assets/img/DriveXPOlogo.png">
                      </span>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </div>

            </div>
            <div fxLayout="row" fxLayoutGap="15px" *ngIf="showtTrackingFields">
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{'tracki7352' | translate | titlecase}}</mat-label>
                  <input matInput id="trackingDevice" name="trackingDevice" formControlName="trackingDevice" required>
                  <mat-error
                    *ngIf="equipmentForm.controls.trackingDevice.errors && equipmentForm.controls.trackingDevice.errors.invalid">
                    {{equipmentForm.controls.trackingDevice.errors.invalid.error}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="50%">
                <mat-form-field class="form-field-input" appearance="outline">
                  <mat-label>{{'tracki7904' | translate | titlecase}}</mat-label>
                  <input matInput id="trackingDeviceNumber" name="trackingDeviceNumber"
                    formControlName="trackingDeviceNumber" required>
                  <mat-error
                    *ngIf="equipmentForm.controls.trackingDeviceNumber.errors && equipmentForm.controls.trackingDeviceNumber.errors.invalid">
                    {{equipmentForm.controls.trackingDeviceNumber.errors.invalid.error}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end" fxLayout="row" fxLayout.lt-md="column" class="button-group" fxLayoutGap="10px"
          fxLayoutAlign="space-between center">
          <div>
            <button mat-raised-button class="text-uppercase" *ngIf="dialogAction != userActions.create"
              (click)="deleteEquipment()" color="accent">
              {{'delete1989' | translate}}
            </button>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <button mat-raised-button class="text-uppercase secondary-btn" (click)="onClose()">
              {{'cancel7093' | translate}}
            </button>
            <button mat-raised-button class="text-uppercase" color="accent"
              *ngIf="dialogAction === userActions.create; else edit" [disabled]="!equipmentForm.valid"
              (click)="saveEquipment()">
              {{'add1375' | translate}}
            </button>
            <ng-template #edit>
              <button mat-raised-button class="text-uppercase" [disabled]="!equipmentForm.valid"
                (click)="saveEquipment()" color="accent">
                {{'update8478' | translate}}
              </button>
            </ng-template>
          </div>
        </mat-dialog-actions>
      </form>
    </div>
    <div *ngIf="showSucessMsg">
      <mat-dialog-content>
        <span [translate]="'saveEq8207'">
          Equipment details saved successfully</span>.
      </mat-dialog-content>
    </div>
  </div>
  <div *ngIf="driverAssociationDetails && !showSucessMsg" class="grid-overlay">
    <div class="association-msg-block">
      <div class="association-confirmation-block">
        <mat-dialog-content class="association-msg">
          <span>{{driverAssociationDetails}}</span>
        </mat-dialog-content>
        <mat-dialog-actions align="end" fxLayout="row" fxLayoutGap="10px" class="proceed-button footer-button-group">
          <button mat-raised-button class="text-uppercase secondary-btn" (click)="onCancel()">
            {{'cancel7093' | translate}}
          </button>
          <button mat-raised-button class="text-uppercase" (click)="onProceed()" color="accent">
            {{'procee6369' | translate}}
          </button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>