<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title">Freight at Your Fingertips</span>
    <button mat-icon-button (click)="onClose()">
        <i class="icon-close"></i>
    </button>
</div>
<mat-dialog-content fxLayout="column" fxLayoutGap="20px" class="content">
    <span>
        Reduce empty miles with XPO Connect™, a free and easy-to-use online platform designed to help you win the loads
        that best fit your equipment, routes, schedule and preferred freight profile.
    </span>
    <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50%" fxShow fxHide.lt-md>
            <img src="assets/img/signup-promo.png">
        </div>
        <div fxFlex="50%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="25px">
            <div fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="10px">
                    <i class="icon-square-filled list-icon"></i>
                    <span>Find and Book Loads — Fast and Easy</span>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <i class="icon-square-filled list-icon"></i>
                    <span>Get $50 Free by Booking Online</span>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <i class="icon-square-filled list-icon"></i>
                    <span>Bid on Dedicated Lanes</span>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <i class="icon-square-filled list-icon"></i>
                    <span>Gain Visibility and Control</span>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <i class="icon-square-filled list-icon"></i>
                    <span>View Rate Confirmation and Invoice Payment Status Online</span>
                </div>
            </div>
            <button mat-stroked-button (click)="signUp()" class="signup-btn">
                Sign Up for Free
                <i class="icon-arrow-right signup-icon"></i>
            </button>
            <span>
                Already have an account?
                <span class="sign-in" (click)="signIn()">Sign In</span>
            </span>
        </div>
    </div>
</mat-dialog-content>