<div class="support-button" fxLayout="column" fxLayoutAlign="center center" (click)="onSupportClick()"
    *ngIf="!isLoggedIn || (isLoggedIn && permissionCheck('helpdesk_view'))">
    <i class="nav-icon support-icon icon-support_icon_filled"></i>
    <span class="text-capitalize" fxShow fxHide.lt-lg [translate]="'suppor9715'">Support</span>
</div>
<div [ngClass]="{'show':!isHidden, 'menu-without-rewards': !showRewards && isLoggedIn , 'menu-dropdown-unsupported-browser' : isUnsupportedBrowser , 'menu-unauth': !isLoggedIn}"
    class="menu-dropdown">
    <div *ngIf="isNAUser" class="triangle"></div>
    <div class="contact-cont" *ngIf="isNAUser">
        <div class="contact-card">
            <div fxLayoutAlign="end" class="close">
                <i class="icon-close" (click)="onSupportClick()"></i>
            </div>
            <div class="content-div">
                <div id="contact-us-title" class="contact-title text-capitalize">
                    XPO Load Support
                    <span class="info-msg"
                        matTooltip="Contact XPO load support for any queries on load updates, lumper fee requests, rate cons, BOLs, pricing, pick up times and check-ins">
                        <i class="icon-info_btn"></i>
                    </span>
                </div>
                <div class="contact-info">
                    <div class="contact-details">
                        <div>
                            <div fxLayout="row" class="center">
                                <p id="email" fxLayoutGap="5px">
                                    <i class="icon-mail contact-mail"></i>
                                    <a class="support-link" href="mailto:{{loadSupportEmail}}?subject={{emailSubject}}"
                                        (click)="onSupportClick()">{{loadSupportEmail}}</a>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div fxLayout="row" class="center">
                                <div class="phone-number" id="phone" fxLayoutGap="5px">
                                    <i class="icon-phone contact-phone"></i>
                                    <a class="support-link"
                                        href="tel:{{loadSupportPhoneNumber}}">{{loadSupportPhoneNumber}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tracking-data" *ngIf="isNAUser">
                    <div id="contact-us-title" class="contact-title text-capitalize">
                        XPO Technical Support
                        <span class="info-msg" matTooltip="Contact XPO Technical support for any IT related queries">
                            <i class="icon-info_btn"></i>
                        </span>
                    </div>
                    <div class="contact-info">
                        <div class="contact-details">
                            <div>
                                <div fxLayout="row" class="center">
                                    <p id="email" fxLayoutGap="5px" *ngIf="isNAUser">
                                        <i class="icon-mail contact-mail"></i>
                                        <button *ngIf="isLoggedIn" class="link-btn"
                                            (click)="openSupportForm()">{{emailAddress}}</button>
                                        <a *ngIf="!isLoggedIn" class="support-link"
                                            href="mailto:{{emailAddress}}?subject={{emailAddress}}">
                                            {{emailAddress}}</a>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div fxLayout="row" class="center" *ngIf="isNAUser">
                                    <div class="phone-number" id="phone" fxLayoutGap="5px">
                                        <i class="icon-phone contact-phone"></i>
                                        <a class="support-link"
                                            href="tel:{{supportHelpdeskNumber}}">{{supportHelpdeskNumber}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tracking-data" *ngIf="isTrackingEnabled && isNAUser">
                    <div id="contact-us-title" class="contact-title text-capitalize" [translate]="'xPOtra5439'">
                        XPO tracking
                    </div>
                    <div class="contact-info">
                        <div class="contact-details">
                            <div>
                                <div fxLayout="row" class="center">
                                    <p id="email" fxLayoutGap="5px">
                                        <i class="icon-mail contact-mail"></i>
                                        <a class="support-link"
                                            href="mailto:{{trackingEmailAddress}}?subject={{emailSubject}}"
                                            (click)="onSupportClick()">{{trackingEmailAddress}}</a>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div fxLayout="row" class="center">
                                    <div class="tracking-phone-no" id="phone" fxLayoutGap="5px">
                                        <i class="icon-phone contact-phone"></i>
                                        <a class="support-link"
                                            href="tel:{{trackingSupportNumber}}">{{trackingSupportNumber}} Option 3</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tracking-data" *ngIf="isNAUser">
                    <div id="contact-us-title" class="contact-title text-capitalize" [translate]="'xPOPay8954'">
                        XPO Payment
                    </div>
                    <div class="contact-info">

                        <div class="contact-details">
                            <div>
                                <div fxLayout="row" class="center">
                                    <p id="email" fxLayoutGap="5px">
                                        <i class="icon-mail contact-mail"></i>
                                        <a class="support-link" (click)="onSupportClick()"
                                            href="mailto:{{paymentEmailAddress}}?subject={{emailSubject}}">{{paymentEmailAddress}}</a>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div fxLayout="row" class="center">
                                    <div class="tracking-phone-no" id="phone" fxLayoutGap="5px">
                                        <i class="icon-phone contact-phone"></i>
                                        <a class="support-link"
                                            href="tel:{{paymentSupportNumber}}">{{paymentSupportNumber}} </a><span
                                            class="ext_clr">Ext</span>
                                        <a class="support-link" href="tel:{{PaymentExtension}}">{{PaymentExtension}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tracking-data" *ngIf="isNAUser">
                    <div id="contact-us-title" class="contact-title text-capitalize">
                        Quick Pay
                    </div>
                    <div class="contact-info">

                        <div class="contact-details">
                            <div>
                                <div fxLayout="row" class="center">
                                    <p id="email" fxLayoutGap="5px">
                                        <i class="icon-mail contact-mail"></i>
                                        <a class="support-link" (click)="onSupportClick()"
                                            href="mailto:{{PaymentSupportQuickPayEmailAddress}}?subject={{emailSubject}}">{{PaymentSupportQuickPayEmailAddress}}</a>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div fxLayout="row" class="center">
                                    <div class="tracking-phone-no" id="phone" fxLayoutGap="5px">
                                        <i class="icon-phone contact-phone"></i>
                                        <a class="support-link"
                                            href="tel:{{PaymentQuickPayPhoneNumber}}">{{PaymentQuickPayPhoneNumber}}
                                        </a><span class="ext_clr">Ext</span>
                                        <a class="support-link"
                                            href="tel:{{PaymentQuickPayExtension}}">{{PaymentQuickPayExtension}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>