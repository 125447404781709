<div fxShow fxHide.lt-sm fxLayout="row" *ngIf="!isClosed" class="signup-banner signup-banner-bg full-width"
    fxLayoutAlign="center center">
    <div fxFlex="100%" fxLayout="row" fxLayoutAlign="center center" class="banner-content">
        <img [src]="bannerImage" class="banner-content">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <b [ngClass]="{'cursor-pointer' : banner.link}" (click)="openLink(banner.link)">{{banner.message}}</b>
            <button mat-stroked-button (click)="signIn()">Sign In</button>
            <button mat-stroked-button (click)="signUp()">Sign Up for Free</button>
        </div>
    </div>
</div>

<div fxShow.lt-sm fxHide fxLayout="row" *ngIf="!isClosed" class="signup-banner signup-banner-dxpo-bg full-width"
    fxLayoutAlign="start center">
    <div fxFlex="95%" fxLayout="row" fxLayoutAlign="start center" class="banner-content cursor-pointer"
        (click)="signUp()">
        <img fxFlex="15%" fxFlex.lt-md="20%" class="padding-15" src="assets/img/signup-banner-dxpo-icon.svg">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutGap="5px">
            <b>Book Loads on the Go</b>
            <span>Download Drive XPO for Free</span>
        </div>
    </div>
    <i class="icon-close cursor-pointer padding-bottom-15" (click)="closeBanner()"></i>
</div>