<div mat-dialog-title>
  <div class="text-bold-uppercase" fxLayout="row" fxLayoutAlign="space-between center">
    <span [translate]="'termsandAgr1735'">Terms and Agreement</span>
    <span class="dialog-close-icon">
      <i class="icon-close" (click)="onClose()"></i>
    </span>
  </div>
</div>
<div mat-dialog-content>
  <div *ngIf="isNAUser" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div>
      <span [translate]="'bychoo4425'">By choosing to click on </span><span>&nbsp;</span>
      <b [translate]="'iagree7917'">'I Agree',</b><span>&nbsp;</span>
      <span [translate]="'youagr3478'">you agree</span>
    </div>
    <div>
      <span [translate]="'tothe7504'">to the</span><span>&nbsp;</span>
      <a class="support-link" (click)=showTermsAndCondition() [translate]="'termsA2934'">Terms And Conditions</a> <span>
        of XPO Connect.</span>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px">
    <button mat-raised-button (click)="onClose()" class="text-uppercase secondary-btn">{{'cancel7093' |
      translate}}</button>
    <button mat-raised-button (click)="accept()" color="accent" class="text-uppercase">
      {{'iagree5617' | translate}}</button>
  </div>
</div>