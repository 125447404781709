import { Component, OnInit } from '@angular/core';
import * as globals from 'src/app/shared/globals';
import { TrafficAlertItem } from '../model/traffic';
import { WeatherAlert } from '../model/weather-alert';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: [ './notifications.component.scss' ]
})
export class NotificationsComponent implements OnInit {
  public weatherAlertList: WeatherAlert[];
  public trafficAlertItems: TrafficAlertItem[];

  constructor() {
    this.weatherAlertList = globals.weatherAlerts;
    this.trafficAlertItems = globals.trafficAlertItems;
  }

  ngOnInit() {
  }

  onCloseTrafficAlert(trafficItem: TrafficAlertItem) {
    this.trafficAlertItems.splice(trafficItem.index, 1);
    this.trafficAlertItems.forEach((item, index) => {
      this.trafficAlertItems[ index ].index = index;
    });
    this.refreshNotifications();
  }

  onCloseWeatherAlert(weatherItem: WeatherAlert) {
    this.weatherAlertList.splice(weatherItem.index, 1);
    this.weatherAlertList.forEach((item, index) => {
      this.weatherAlertList[ index ].index = index;
    });
    this.refreshNotifications();
  }

  onDismissNotification() {
    globals.setNotificationDismissed(true);
    this.weatherAlertList = [];
    this.trafficAlertItems = [];
    globals.setTrafficAlerts(this.trafficAlertItems);
    globals.notificationElement.innerHTML = ``;
    if (globals.notificationDotElement.classList) {
      globals.notificationDotElement.classList.remove('active');
    }
  }

  getNotificationCount() {
    let notificationsCount = this.weatherAlertList ? this.weatherAlertList.length : 0;
    notificationsCount += this.trafficAlertItems ? this.trafficAlertItems.length : 0;
    return notificationsCount ? notificationsCount : '';
  }

  refreshNotifications() {
    const notificationCount = this.weatherAlertList.length + this.trafficAlertItems.length;
    globals.setNotificationsCount(notificationCount);
    if (notificationCount === 0) {
      globals.setNotificationDismissed(true);
      globals.notificationElement.innerHTML = ``;
      if (globals.notificationDotElement.classList) {
        globals.notificationDotElement.classList.remove('active');
      }
    } else {
      globals.notificationElement.innerHTML = `<span class="notification-count">${globals.notificationsCount}</span>`;
    }
  }
}
