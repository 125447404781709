<div fxLayout="row" fxLayoutGap="5px" class="profile-section">
    <div fxLayout="row" fxLayoutAlign="center center" class="profile-icon {{tiers |lowercase}}-background"
        (click)="openMenu()">
        {{nameAbbreviation}}
    </div>
    <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" class="carrier-name" fxHide.lt-sm fxShow>
        <span #carrier_name class="line-ellipsis" [matTooltip]="carrierName" *ngIf="carrierName.length"
            [matTooltipDisabled]="carrierName.length < 14" [matTooltipPosition]="'below'">
            {{carrierName}}
        </span>
        <span class="{{tiers |lowercase}}-txt-color line-ellipsis cursor-pointer" #loyalty [matTooltip]="tiers"
            [matTooltipDisabled]="!sharedService.isEllipsisActive(loyalty)" [matTooltipPosition]="'below'"
            *ngIf="showRewards" (click)="navigatetoLoyalty()">
            <span>{{rating}}</span>
            <i class="star-icon icon-star"></i>
            <span>{{tiers}}</span>
        </span>
    </div>
</div>

<div [ngClass]="{'show':!isHidden}" class="menu-dropdown">
    <div class="menu-title text-uppercase">
        <span [translate]="'myAccount1734'">My Account</span>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="profile-details">
        <div class="acc-name-btn text-uppercase  {{tiers |lowercase}}-background" fxLayoutAlign="center center">
            {{nameAbbreviation}}
        </div>
        <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start start">
            <div fxLayout="row">
                <span [translate]="'welcom725'">
                    Welcome
                </span>
                <span>,</span>
            </div>
            <div class="text-bold-capitalize">{{userName}}</div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px" class="rewards-details" *ngIf="showRewards">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%"
            class="text-bold-capitalize full-width padding-top-bottom-10">
            <div>
                Carrier rewards balance:
            </div>
            <div class="margin-left-5">
                {{balanceCredit | xpocCurrency: baseCurrencyCode: true : tenandId : '1.0-0'}}
            </div>
        </div>
        <div fxLayout="row" fxFlex="100%" (click)="navigatetoRewards()" fxLayoutGap="5px"
            class="rewards-cards full-width {{tiers |lowercase}}-background">
            <div fxLayout="column" fxFlex="38%" fxLayoutGap="5px">
                <div class="reward-score text-capitalize">carrier score</div>
                <div class="reward-score text-bold">{{rating}}</div>
            </div>
            <div fxLayout="column" fxFlex="52%" fxLayoutGap="5px">
                <div class="reward-score text-capitalize" *ngIf="tiers">carrier rewards
                </div>
                <div fxLayout="row" fxLayoutGap="5px" class="reward-score text-bold" *ngIf="tiers">
                    <span>{{tiers}}</span>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <app-star-rating class="star-rating" [starcount]="num"></app-star-rating>
                    </div>
                </div>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end center">
                <i class="menu-angle-icon icon-chevron-right"></i>
            </div>
        </div>
    </div>
    <div class="menu-list-block">
        <ul>
            <li (click)="onProfileClick()" *ngIf="permissionCheck('manage_profile')">
                {{'profileSett6766' | translate}}
            </li>
            <li (click)="onPrivacyClick()">
                <a [attr.href]="privacyPolicyLink" target="blank">
                    {{'privacyPoli3634' | translate}}
                </a>
            </li>
            <li *ngIf="!isNAUser">
                <a [attr.href]="euCookiePolicyLink" target="blank">
                    {{'cookiePolic8907' | translate}}
                </a>
            </li>
            <li (click)="onSwitchCarrier()" *ngIf="isMultiplePartnersAccount">
                Switch Carrier
            </li>
            <li (click)="onFeedbackClick()" *ngIf="permissionCheck('provide_feedback')">
                {{'feedba8626' | translate}}
            </li>
        </ul>
    </div>
    <div class="menu-signout-block">
        <form ngNoForm action="/session/end" method="POST">
            <button id="account-signout-btn" type="submit" mat-raised-button color="accent" class="text-uppercase"
                (click)="logout()">
                {{'signOut6874' | translate}}
            </button>
        </form>
    </div>
</div>