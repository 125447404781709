import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/user-authentication/auth.service';

@Component({
  selector: 'app-carrier-requirements',
  templateUrl: './carrier-requirements.component.html',
  styleUrls: [ './carrier-requirements.component.scss' ]
})
export class CarrierRequirementsComponent implements OnInit {

  @Input() load: any;
  public isNAUser: boolean;

  constructor(
    public authService: AuthService
  ) {
    this.isNAUser = this.authService.checkNAUser();
  }

  ngOnInit() {
  }

}
