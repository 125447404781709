export class XPOConstants {
  public static xpoAuthCookie = 'xpo-connect-authz';
  public static readonly privacyPolicyUrl = 'https://xpo.com/privacy-policy';
  public static readonly loadBoardToText = 'to';
  public static readonly loadBoardTodestinationText = 'To';
  public static readonly loadBoardFromText = 'From';
  public static readonly loadBoardHazmatText = 'hazmat';
  public static readonly loadBoardCustomsBrokerText = 'customs broker';
  public static readonly loadBoardOnlyweekendloadsText = 'only weekend loads';
  public static readonly loadBoardNoweekendloadsText = 'no weekend loads';
  public static readonly dateTime24Format = 'MM/DD/YYYY HH:mm';
  public static readonly dateTime = 'MM/DD/YYYY . HH:mm';
  public static readonly dateTime12HrFormat = 'MM/DD/YYYY hh:mm A';
  public static readonly euDateTimeFormat = 'DD/MM/YYYY HH:mm';
  public static readonly dateFormat = 'MM/DD/YYYY';
  public static readonly euDateFormat = 'DD/MM/YYYY';
  public static readonly dateMonthFormat = 'MMM DD, YYYY';
  public static readonly euDateMonthFormat = 'D MMM YYYY';
  public static readonly timeFormat = 'H:mm';
  public static readonly dbDateFormat = 'YYYY-MM-DD';
  public static readonly exchangeRateDateFormat = 'YYYYMMDD';
  public static readonly dayMonthDateMoment = 'ddd, MMM DD';
  public static readonly dayDateMothYearFormat = 'ddd, DD MMM YYYY';
  public static readonly dialDateFormat = 'EEE, MMM d';
  public static readonly euDialDateFormat = 'EEE, d MMM';
  public static readonly stringDateMonthFormat = 'MMM DD, YYYY';
  public static readonly monthDateTimeFormat = 'MMM DD, hh:mm A';
  public static readonly euMonthDateTimeFormat = 'MMM DD, HH:mm';
  public static readonly monthDateTimeSplitFormat = 'MMM DD YYYY • hh:mm A';
  public static readonly euMonthDateTimeSplitFormat = 'DD MMM YYYY • HH:mm';
  public static readonly dbTimeStamp = 'T00:00:00';
  public static readonly timeformat = 'hh:mm A';
  public static readonly euTimeFormat = 'HH:mm';
  public static readonly dayMontFormat = 'DD/MM';
  public static readonly montDayFormat = 'MM/DD';
  public static readonly defaultCurrency = 'USD';
  public static readonly defaultEUCurrencyCode = 'EUR';
  public static readonly temperatureFormat = '°F';
  public static readonly euTemperatureFormat = '°C';
  public static readonly defaultDistanceUnit = 'MI';
  public static readonly euDefaultDistanceUnit = 'KM';
  public static readonly defaultPageNo = 1;
  public static readonly i18nEn = 'en-US';
  public static readonly i18nFr = 'fr-FR';
  public static readonly i18nEs = 'es-ES';
  public static readonly i18nPl = 'pl-PL';
  public static readonly commonMessageSetting = 'messageSettings';
  public static readonly i18nFmName = 'messageSettings_FM';
  public static readonly i18nTM = 'messageSettings_TM';
  public static readonly i18nLmName = 'messageSettings_LM';
  public static readonly unAuthPageConfigs = [
    {
      url: '/loads/load-tenders/email-accept?tenderGuid=', hideNavBar: true, showUnauthMenu: false,
      showSignUpBanner: false, showAlerts: false, quickSignup: false
    },
    {
      url: '/loads/load-tenders/email-reject?tenderGuid=', hideNavBar: true, showUnauthMenu: false,
      showSignUpBanner: false, showAlerts: false, quickSignup: false
    },
    {
      url: '/loads/load-tenders/email-error', hideNavBar: true, showUnauthMenu: false, showSignUpBanner: false,
      showAlerts: false, quickSignup: false
    },
    {
      url: '/loads/available-loads/email-buyitnow?tenderGuid=', hideNavBar: false, showUnauthMenu: true,
      showSignUpBanner: true, showAlerts: true, quickSignup: true
    },
    {
      url: 'email-unsubscribe', hideNavBar: true, showUnauthMenu: false, showSignUpBanner: false, showAlerts: false,
      quickSignup: false
    },
    {
      url: 'registration', hideNavBar: true, showUnauthMenu: false, showSignUpBanner: false, showAlerts: false, quickSignup: false
    },
    {
      url: 'asset-management/driver-signup-confirmation', hideNavBar: true, showUnauthMenu: false,
      showSignUpBanner: false, showAlerts: false, quickSignup: false
    },
    {
      url: '/rate-confirmation', hideNavBar: false, showUnauthMenu: true, showSignUpBanner: true, showAlerts: true, quickSignup: true
    },
    {
      url: 'loads/bids/counter-offer', hideNavBar: false, showUnauthMenu: true, showSignUpBanner: true, showAlerts: true, quickSignup: true
    }
  ];
  public static readonly defaultPhoneType = 'MOBILE';
  public static readonly pleaseWait = 'Please Wait..';
  public static readonly errorInGettingData = 'Error in getting Data';
  public static readonly support = 'Support';
  public static readonly amountText = 'Amount';
  public static readonly dataUnavailable = 'Data unavailable';
  public static readonly defaultPhoneNoLengthEU = 15;
  public static readonly defaultPhoneNoLengthNA = 12;
  public static readonly defaultPageSize = 100;
  public static readonly adminRequestPageSize = 10;
  public static readonly defaultLanguageCode = 'en-US';
  public static readonly euDefaultLanguageCode = 'en-GB';
  public static readonly euCarrierContestTermsLanguageCode = [ 'en-US', 'fr-FR' ];
  public static readonly shareTrackingLanguageCodes = [ 'en-US', 'es-ES', 'fr-FR', 'pl-PL', 'ru-RU', 'ro-RO', 'de-DE', 'sk-SK', 'sr-Cyrl-CS', 'it-IT', 'pt-PT' ];
  public static languageCodes = [ 'en-US', 'es-ES', 'fr-FR', 'pl-PL', 'ru-RU', 'ro-RO', 'de-DE', 'sk-SK', 'sr-Cyrl-CS', 'it-IT', 'pt-PT', 'uk-UA', 'tr-TR' ];
  public static readonly trackingPhoneNumber = 'TRACKINGPHONENUMBER';
  public static readonly dispatchFrequency = 30;
  public static readonly defaultTruckTrackingDevice = 'XPOCONNECTCARRIER';
  public static readonly startingModelYear = 1950;
  public static readonly confirmationTimeoutInterval = 3000;
  public static readonly stringTimeFormatAmPm = 'h:mm a';
  public static readonly defaultDriverRole = 'NO_ACCESS';
  public static readonly defaultEuDriverRole = 'DRIVER_BASIC';
  public static readonly defaultCountryCode = 'USA';
  public static readonly fomPageSize = 200;
  public static readonly tenderListPageSize = 25;
  public static readonly disableAlert = 'Dismiss all Traffic and Weather alerts';
  public static readonly defaultLaneRadius = 100;
  public static readonly defaultLaneRadiusEU = 100;
  public static readonly availableLoadMapPageSize = 200;
  public static readonly availableLoadMapPageSizeEU = 1000;
  public static readonly emailRegex = /^[_a-z0-9]+(.[_a-z0-9]+)@[a-z0-9-]+(.[a-z0-9-]+)(.[a-z]{2,4})$/i;
  public static readonly emailIDRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly minZipCodeLength = 4;
  public static readonly tenderCheckedStatus = [ '1' ];
  public static readonly applicationCode = 'marketplace_portal';
  public static readonly fomStopStatusCode = [ 'OnTime', 'PossibleDelay', 'Dlyd', 'NotMvng', 'NotTrkg', 'Dlvrd', 'TrkgNotStarted' ];
  public static readonly fomLocationCode = [ 'AtOrigin', 'EnRoute', 'AtStp', 'AtDelivery', 'Dlvrd', 'TrkgNotStarted' ];
  public static readonly flexLayoutBreakpoints = [ 'xs', 'sm', 'md', 'lg', 'xl', 'lt-lg' ];
  public static readonly fileFormats = [ 'tiff', 'tif', 'pdf' ];
  public static readonly euPODFormats = [ 'jpeg', 'jpg', 'pdf' ];
  public static readonly trackingMethodTypeCode = { truck: 'TRUCK', trailer: 'TRAILER', mobile: 'MOBILE', dtm: 'DTM', atl: 'ATL' };
  public static readonly equipmentTypeCode = { truck: 'TRUCK', trailer: 'TRAILER' };
  public static readonly notApplicable = 'NA';
  public static readonly defaultKpiFilterType = 'LastSixWeeks';
  public static readonly weekly = 'Weekly';
  public static readonly selelectedPeriodForKPI = 'LastOneYear';
  public static readonly deadheads = [ 5, 10, 25, 50, 75, 100, 150, 200, 300, 500 ];
  public static readonly defaultEuMileRadius = 150;
  public static readonly successStatusCode = 200;
  public static readonly compareLoadsNotesNotesWordMaxLength = 40;
  public static readonly defaultXPORelation = 'Carrier';
  public static readonly validationRequiredTooltipMsg = `Carrier must call XPO after a winning bid or counteroffer to confirm
   that it meets load requirements prior to finalizing load assignment`;
  public static buyNowCheckboxMsg = 'I agree to call XPO to confirm that I meet load requirements prior to finalizing load assignment.';
  public static bidOrCounterOfrCheckboxMsg = `I agree to call XPO after a winning bid or counteroffer to confirm that
   I meet load requirements prior to finalizing load assignment.`;
  public static readonly loadListPageSize = 25;
  public static readonly defaultBestDescribe = 'OWNER';
  public static readonly connectSelfRegSource = 'carriersignup';
  public static readonly manualBid = 'MANUALBID';
  public static readonly availableLoadListPageSize = 20;
  public static readonly defaultSavedSearch = 'defaultSavedSearch';
  public static readonly map = 'map';
  public static readonly signOut = 'signOut';
  public static readonly noRecordsFound = 'No records found';
  public static readonly roundOffFormat = '1.0-0';
  public static readonly dateTimeFormat = 'MM/DD/YY, hh:mm A';
  public static readonly euSupportType = [
    { text: 'New User/Driver Setup', translationKey: 'newUserDriv1613' },
    { text: 'Log in Issues', translationKey: 'loginIssues4402' },
    { text: 'Missing Orders/Loads', translationKey: 'missingOrde4982' },
    { text: 'Bidding/Booking Issues', translationKey: 'biddingBook6352' },
    { text: 'Tracking/Check calls', translationKey: 'trackingChe9137' },
    { text: 'Other issues', translationKey: 'otherissues8322' } ];
  public static readonly tripLength = {
    step: 10,
    max: 3500,
    euMax: 9999,
    min: 0
  };
  public static readonly ratePerMile = {
    min: 0,
    max: 3.5,
    step: 0.1
  };
  public static readonly appStoreLink = {
    euAndroid: 'https://play.google.com/store/apps/details?id=com.xpo.DriveXPOEU',
    euIos: 'https://apps.apple.com/us/app/drive-xpo/id1427976786?ls=1',
    naIos: 'https://itunes.apple.com/gb/app/drivexpo/id1268107798?mt=8',
    naAndroid: 'https://play.google.com/store/apps/details?id=com.xpo.DriveXPO&hl=en_GB'
  };

  public static readonly routeEtaStatus = {
    onTime: 'On Time',
    delay: 'Possible Delay',
    late: 'Late'
  };

  public static readonly locationTypes = {
    country: 'Country',
    state: 'State',
    city: 'City',
    zipcode: 'ZipCode'
  };

  public static readonly assignedLoadsStatues = { 1: 'Scheduled', 2: 'In Progress', 3: 'Delivered' };
  public static readonly loadsCheckedStatus = [ 'scheduled', 'inprogress' ];
  public static readonly allLoadStatuses = [ 'delivered', 'scheduled', 'inprogress' ];
  public static readonly additionalReqNA = [ 'C-TPAT', 'Smartway', 'TSA', 'PIP', 'FAST', 'CSA', 'Customs Broker',
    'TWIC Card', 'UIA', 'Drop and Hook', 'Hazmat' ];
  public static readonly loadTenderStatuses = { 1: 'available', 2: 'accepted', 3: 'cancelled', 4: 'expired', 5: 'rejected' };
  public static readonly equipmentTypeList = [
    'Dry Van',
    'Reefer',
    'Flatbed',
    'Tractor Only'
  ];
  public static readonly equipmentTypeEUList = [
    'Flatbed',
    'Reefer',
    'Tanker',
    'Tautliner standard'
  ];

  public static readonly globalNotificationActions = {
    create: 'create',
    edit: 'edit',
    delete: 'delete',
    confirm: 'confirm'
  };
  public static readonly registrationStatusCodes = {
    underSupportReview: 'UNDERSUPPORTREVIEW',
    underAdminReview: 'UNDERADMINREVIEW',
    approvedByAdmin: 'APPROVEDBYADMIN',
    rejectedByAdmin: 'REJECTEDBYADMIN',
    acceptedByAdmin: 'ACCEPTEDBYADMIN'
  };
  public static readonly defaultWeightUnit = {
    WeightUnitNA: 'LBS',
    WeightUnitEU: 'KGS',
  };

  public static readonly loadPriceSourceCodes = {
    WTP: 'WTP',
    DMP: 'DMP',
    MNC: 'MNC'
  };

  public static readonly loadBidTenderStatus = {
    counteroffer: 'CounterOffer',
    portalBuynow: 'PortalBuyItNow',
    portalPlaceBid: 'PortalPlaceBid',
    buyNow: 'BuyItNow',
    placeBid: 'PlaceBid',
    portalManualCustomerBid: 'PortalBidManualCustomer'
  };

  public static readonly xpoInfo = [
    { code: 'EMAIL', name: 'Email' },
    { code: 'APPSTORE', name: 'App Store' },
    { code: 'FB', name: 'Facebook / Instagram' },
    { code: 'WEBSEARCH', name: ' Web Search' },
    { code: 'CARRIER', name: 'Another Carrier/Driver' },
    { code: 'XPOREP', name: 'XPO Rep' },
    { code: 'OTHER', name: 'Other' }
  ];

  public static laneSettings = {
    suggestionsLimit: 0,
    badgeShowLimit: 1,
    selectionLimit: 5
  };

  public static readonly browsers = {
    chrome: { name: 'chrome', url: 'https://www.google.com/chrome/' },
    firefox: { name: 'edge', url: 'https://www.microsoft.com/en-us/edge' },
    edge: { name: 'firefox', url: 'https://www.mozilla.org/en-US/firefox/' }
  };

  public static readonly typeOfSupport = [
    { code: 'New User Setup', name: 'New User Setup' },
    { code: 'Log In Issues', name: 'Log In Issues' },
    { code: 'Issues With Documents', name: 'Issues With Documents' },
    { code: 'Missing Orders/Loads', name: 'Missing Orders/Loadsd' },
    { code: 'Training', name: 'Training' },
    { code: 'Insurance Updates', name: 'Insurance Updates' },
    { code: 'Other', name: 'Other' }
  ];

  public static readonly notificationEntity = {
    overAllNotification: 1,
    emailNotification: 2,
    smsNotification: 3,
  };

  public static readonly orderDocType = {
    carrierRateConfirmation: 'CARRIERRATECONFIRMATION'
  };

  public static readonly notificationEnabledPages = {
    bids: 'BID',
    savedSearch: 'SAVEDSEARCH',
    tracking: 'TRACKING'
  };

  public static readonly countryListNA = [
    { code: 'USA', name: 'USA', id: 1 },
    { code: 'CAN', name: 'CAN', id: 2 }
  ];
  public static readonly defaultCarrierRole = {
    roleCode: 'ADMIN',
    roleName: 'Admin'
  };
  public static readonly currencySymbols = {
    USD: '$',
    CAD: 'CA$',
    EUR: '€',
    GBP: '£',
    CHF: 'CHF',
    PLN: 'zł',
    MAD: 'MAD',
    CZK: 'Kč',
    RON: 'RON'
  };
  public static readonly postalCodeRegexp = {
    USA: /^\d{5}([\-]?\d{4})?$/,
    UK: /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/,
    DE: /\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/,
    CAN: /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
    FRA: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
    IT: /^(V-|I-)?[0-9]{5}$/,
    AU: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
    NL: /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/,
    ES: /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/,
    DNK: /^([D|d][K|k]( |-))?[1-9]{1}[0-9]{3}$/,
    SE: /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/,
    BEL: /^[1-9]{1}[0-9]{3}$/,
    IND: /^\d{6}$/,
    DEFAULT: /^[0-9A-Za-z ]{5}[- ]?[0-9A-Za-z]{0,4}$/i
  };

  public static readonly kpiUnits = {
    count: 'COUNT',
    currency: 'CURRENCY',
    percentage: 'PERCENTAGE'
  };

  public static readonly flexBreakpoints = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    lt_md: 'lt-md'
  };

  // Bids Page

  public static bidsListPageSize = 25;
  public static readonly bidsActiveCheckedStatusNA = [ 'BIDREVIEW', 'OPENCO' ];
  public static readonly bidsActiveCheckedStatusEU = [ 'BIDREVIEW' ];
  public static readonly bidsCheckedStatusNA = [ 'BIDREVIEW', 'OPENCO', 'BIDAPPROVED',
    'BIDDECLINED', 'BIDEXPIRED', 'ACCEPTEDCO', 'REJECTEDCO', 'EXPIREDCO' ];
  public static readonly bidsCheckedStatusEU = [ 'BIDREVIEW', 'BIDAPPROVED', 'BIDDECLINED', 'BIDEXPIRED', 'BIDCANCELLED' ];
  public static readonly pageSizeOptions = [ 25, 50, 75, 100, 150, 200 ];
  public static readonly bidsFilterNA = [
    { code: 'BIDREVIEW', name: 'Bid Under Review' },
    { code: 'BIDAPPROVED', name: 'Bid Approved' },
    { code: 'BIDDECLINED', name: 'Bid Declined' },
    { code: 'BIDEXPIRED', name: 'Bid Expired' },
    { code: 'OPENCO', name: 'Open Counteroffer' },
    { code: 'ACCEPTEDCO', name: 'Counteroffer Accepted' },
    { code: 'REJECTEDCO', name: 'Counteroffer Rejected' },
    { code: 'EXPIREDCO', name: 'Counteroffer Expired' }
  ];
  public static readonly bidsFilterEU = [
    { code: 'BIDREVIEW', name: 'bidunderrev2512' },
    { code: 'BIDAPPROVED', name: 'bidApp2815' },
    { code: 'BIDDECLINED', name: 'bidDec4642' },
    { code: 'BIDEXPIRED', name: 'bidExpired2043' },
    { code: 'BIDCANCELLED', name: 'bidcancelle7074' }
  ];

  public static counterOfferAction = {
    accepted: 'Accepted',
    rejected: 'Rejected'
  };

  public static readonly counterOfferError = {
    loadNotAvailable: 1,
    accepted: 3,
    rejected: 4,
    notAvailable: 5,
    timeOut: 6,
    unableToAccept: 7,
    invalidAmount: 8,
    offerExpiredCheckNewOffer: 9
  };

  public static readonly availableLoadsTabCodes = {
    recommended: 'recommended-tab',
    all: 'all-loads-tab'
  };

  public static readonly statusToShowBINBtn = [];
  public static readonly pbiUserRole = 'Connect';
  public static readonly pbiReportUrl = 'https://app.powerbi.com/reportEmbed?';
  public static readonly defaultNoteType = 1;
  public static readonly defaultAttachmentType = 2;
  public static readonly dateTimeFormatSmall = 'MM/dd/yyyy, HH:mm';
  public static readonly filiingDateAlert = 'Viewing claims for last 6 months';
  public static readonly responseStatusOk = 200;
  public static readonly statusToShowCounterOfferBtn = [ 'OPENCO' ];
  public static readonly availLoadsNotesWordMaxLength = 190;
  public static readonly dateSmall = 'MM/dd/yyyy';
  public static readonly livechatGroupId = '5';
  public static readonly paymentDateAlert = 'You can only view payments for the past 60 days.  Please revise your date range.';

  public static readonly dialogActions = {
    cancelled: 'canceled',
    deleted: 'deleted',
    error: 'error',
    saved: 'saved',
    viewDetails: 'view details',
    planNextMove: 'Plan Next Move'
  };

  public static readonly driveXPOUrls = {
    android: 'https://play.google.com/store/apps/details?id=com.xpo.DriveXPO&hl=en_IN',
    ios: 'https://apps.apple.com/us/app/drive-xpo-find-book-loads/id1268107798'
  };

  public static readonly claimStatus = {
    new: 1,
    open: 2,
    reopened: 3,
    resolved: 4,
    closed: 5
  };

  public static readonly etaStatus = {
    onTime: 'on time',
    possibleDelay: 'possible delay',
    late: 'late',
    default: 'default',
    unavailable: 'unavailable'
  };

  public static readonly bidStatus = {
    rejected: 0,
    accepted: 1,
    retry: 2,
    noStatus: 3
  };

  public static readonly errorCode = {
    unAuthorized: 401,
    forbidden: 403,
    notFound: 404,
    entityError: 422,
    internalServerError: 500
  };

  public static readonly roleCodes = [
    'USER',
    'USER_BIDDING',
    'DRIVER_BASIC',
    'DRIVER_LOADBOARD',
    'DISPATCHER',
    'ACCT_OFFICE'
  ]

  public static readonly apiKeys = {
    dimension1: 'Dimension1',
    dimension2: 'Dimension2',
    dimension3: 'Dimension3',
    dimension4: 'Dimension4',
    dimension5: 'Dimension5',
    dimension6: 'Dimension6',
    dimension7: 'Dimension7',
    dimension8: 'Dimension8',
    dimension10: 'Dimension10'
  };

  public static readonly livechatKeys = {
    customLivechatUrl: 'https://www.livechatinc.com/utm-builder/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_'
  };

  public static readonly dispatchConfigurationKeys = {
    mandateDispatchUpdateThresholdInHours: 'MandateDispatchUpdateThresholdInHours',
    businessStartTime: 'BusinessStartTime',
    businessEndTime: 'BusinessEndTime'
  };

  public static readonly topMenuSource = [
    {
      code: 'dashboard', link: '/dashboard', page: 'dashboard', permission: [], toggle: false, translationKey: 'dashboard4769',
      subItem: [
        {
          code: 'overview', permission: [ 'dashboard_view' ], link: '/dashboard/overview', page: 'overview',
          translationKey: 'overvi9564'
        },
        {
          code: 'analytics', permission: [ 'analytics_view' ], link: '/dashboard/analytics', page: 'analytics',
          translationKey: 'analyt9420'
        }
      ]
    },
    {
      code: 'loads', link: '/loads', page: 'loads', permission: [], toggle: false, translationKey: 'loadLa3896',
      subItem: [
        {
          code: 'availableLoads', permission: [ 'load_view' ], link: '/loads/available-loads', page: 'available-loads',
          translationKey: 'availableLo8569'
        },
        {
          code: 'lanes', permission: [ 'dedicated_lanes_view' ], link: '/loads/lanes', page: 'lanes', translationKey: 'Dedicated Lanes',
          isNewItem: false
        },
        { code: 'bids', permission: [ 'load_bids_view' ], link: '/loads/bids', page: 'bids', translationKey: 'bids3836' },
        {
          code: 'tenders', permission: [ 'load_tenders' ], link: '/loads/load-tenders', page: 'load-tenders',
          translationKey: 'tenders9114'
        },
        {
          code: 'assignedLoads', permission: [ 'load_assigned_view' ],
          link: '/loads/my-loads', page: 'my-loads', translationKey: 'myLoads4288'
        },
      ]
    },
    {
      code: 'tracking',
      link: '/tracking', permission: [ 'load_tracking_view' ], page: 'tracking', toggle: false, translationKey: 'tracking7364',
      subItem: []
    },
    {
      code: 'assetManagement', link: '/asset-management', page: 'asset-management', permission: [], toggle: false,
      translationKey: 'assetManage1361',
      subItem: [
        {
          code: 'drivers', permission: [ 'manage_asset' ], link: '/asset-management/drivers', page: 'drivers',
          translationKey: 'driver4040'
        },
        {
          code: 'equipment', permission: [ 'manage_equipment' ], link: '/asset-management/equipment', page: 'equipment',
          translationKey: 'equipment6372'
        },
      ]
    },
    {
      code: 'administration', link: '/administration', page: 'administration', permission: [], toggle: false,
      translationKey: 'admini1032',
      subItem: [
        { code: 'users', permission: [ 'view_users' ], link: '/administration/users', page: 'users', translationKey: 'users4811' },
        { code: 'roles', permission: [ 'manage_roles' ], link: '/administration/roles', page: 'roles', translationKey: 'roles801' },
        {
          code: 'requests', permission: [ 'connect_access_review' ], link: '/administration/requests', page: 'user-access-requests',
          translationKey: 'userAccessR8281'
        },
        {
          code: 'carrierSetup', permission: [ 'partner_setup' ], link: '/administration/carrier-setup', page: 'carrier-setup',
          translationKey: 'carrie3818'
        },
        {
          code: 'financialManagement', permission: [ 'manage_finance' ], link: '/administration/finance-management',
          page: 'finance-management', translationKey: 'financ7595'
        },
      ]
    }
  ];

  public static readonly errorMessages = {
    unAuthorized: 'You are not authorized to access this page. Please contact the administrator',
    forbidden: 'We apologize for the inconvenience. You have not been granted access to this page. ' +
      'Please contact XPO Connect Support at ',
    notFound: 'We\'re sorry, but we can\'t find what you\'re looking for.',
    internalServerError: 'We apologize for the inconvenience. We are working to correct this issue'
  };

  public static readonly businessUnits = {
    brokerage: 'Brokerage',
    globalFreightForwarding: 'GlobalFreightForwarding',
    lmx: 'LastMile',
    ltlEuSharpIV: 'LTLEUSharpIV',
    managedTrans: 'ManagedTrans'
  };

  public static readonly reportTypeId = {
    lmx: 1,
    xpoc: 2
  };

  public static readonly pbiReportCategory = {
    general: 'general',
    claims: 'claims',
    operations: 'operations',
    selfService: 'self-service'
  };

  public static readonly pbiUser = {
    brokerageUser: 'CarrierConnectPowerBI@XPO.COM'
  };

  public static readonly permissionTypes = {
    loadDetail: 'load_accessorials_view',
    manageRoles: 'manage_roles',
    availableLoads: 'load_view',
    analyticsView: 'analytics_view',
    showLoads: 'load_available_manage',
    loadTenders: 'load_tenders',
    viewUsers: 'view_users',
    manageUsers: 'manage_user',
    dashboard: 'dashboard_view',
    feedback: 'provide_feedback',
    financialManagement: 'manage_finance',
    assetManagement: 'manage_asset',
    manageSystemNotification: 'manage_system_notification',
    partnerSetup: 'partner_setup',
    manageEquipment: 'manage_equipment',
    rewardsView: 'loyalty_view',
    accessReview: 'connect_access_review',
    dedicatedLanesView: 'dedicated_lanes_view',
    assignedLoads: 'load_assigned_view',
    bids: 'load_bids_view',
    tracking: 'load_tracking_view',
    manageProfile: 'manage_profile',
    loadAssignedAction: 'load_assigned_action',
    assetAction: 'asset_action'
  };

  public static readonly keystoneLayer = {
    frontEnd: 'FE',
    email: 'EM',
    mobileInterface: 'MI',
    backEnd: 'BE'
  };

  public static readonly keystoneEventName = {
    availableLoadBreadCrumbNavigation: 'Breadcrumb - Available loads Nav',
    availableLoadInitialFetch: 'Loadboard initial fetch',
    availableLoadNoResults: 'Loadboard no results',
    noRecommendations: 'No recommendations available',
    availableLoadSingleRecord: 'Loadboard single load',
    availableLoadPartialPage: 'Loadboard partial page',
    availableLoadPaginationChange: 'Loadboard pagination',
    availableLoadClearFilter: 'Loadboard filter',
    availableLoadOriginModeChange: 'Loadboard origin search mode change',
    availableLoadDestinationModeChange: 'Loadboard destination search mode change',
    availableLoadOriginDestinationSwap: 'Loadboard origin and destination filter swap',
    availableLoadOriginLaneChange: 'Loadboard origin lane change',
    availableLoadDestinationLaneChange: 'Loadboard destination lane change',
    availableLoadInitialFetchRefresh: 'Loadboard initial fetch refresh',
    similarLoadFetch: 'Fetch similar loads',
    noSimilarLoadResult: 'No similar loads are available',
    dashboardOriginLaneChange: 'Dashboard origin lane change',
    carrierCompetitionTC: 'Carrier competition t&c',
    findLoads: 'Find loads',
    getStarted: 'Get started',
    closetnc: 'close tnc',
    cancelBid: 'Cancel bid',
    cancelBidSuccess: 'Cancel bid success',
    cancelBidFailed: 'Cancel bid failed',
    carrierCompetitionDetails: 'Contest details',
    visitCovidResource: 'Show COVID-19 Resources',
    findMyFirstLoad: 'Find my first load',
    supportRequest: 'Support request',
    sortByHeaviestWeight: 'Sort by weight descending',
    sortByLightestWeight: 'Sort by weight ascending',
    sortByShortestDistance: 'Sort by distance ascending',
    sortByLongestDistance: 'Sort by distance descending',
    sortByNewestLoad: 'Sort by load creation descending',
    sortByEarliestPickup: 'Sort by pickup ascending',
    sortByShortestDeadhead: 'Sort by Deadhead ascending',
    sortByLongestDeadhead: 'Sort by Deadhead descending',
    nonCompetitor: 'non competitor popup',
    bidRefresh: 'Refresh bids page',
    createNewUser: 'Create New User',
    createRole: 'Create Role',
    placeNewBid: 'Place New Bid',
    contestDismissiveBanner: 'Dismiss Carrier competition banner',
    modifyUser: 'Modify User',
    deleteUser: 'Delete User',
    savedSearch: 'Saved Search',
    editSearch: 'Edit Search',
    deleteSearch: 'Delete Search',
    buyItNow: 'Buy It Now',
    placeBid: 'Place Bid',
    bidFailure: 'Bid Failure',
    binFailure: 'BIN failure',
    tender: 'Tender',
    viewDetails: 'View Details',
    addRole: 'Add Role',
    updateDispatch: 'Update Dispatch',
    selfRegistrationBack: 'Self-Registration back',
    selfRegistrationNext: 'Self-Registration next',
    selfRegistrationLogo: 'Self-Registration logo',
    selfRegistration: 'Self-Registration',
    quickSelfRegistration: 'Quick Self-Registration',
    appStore: 'App Store',
    newsRoom: 'Dashboard NewsRoom',
    pressRelease: 'Dashboard PressRelease',
    googlePlayStore: 'Google Play Store',
    counterOffer: 'Counter Offer',
    supportEmailForm: 'Support Email Form',
    programAndNews: 'XPO Program and News',
    activities: 'My Activities',
    dashboardSettings: 'Dashboard Settings',
    searchByDriver: 'Search By Driver',
    SMSNotification: 'SMS/Email Notification',
    globalNotification: 'Global Notification',
    teamTracker: 'Plan Next Move',
    teamTrackerDriverFilterApplied: 'Team Tracker Driver Filter Apply',
    teamTrackerLoadFilterApplied: 'Team Tracker Load Filter Apply',
    teamTrackerViewOrderDetail: 'Team Tracker Load Details',
    bidsViewOrderDetail: 'Bids View Load Details',
    bidsFindSimilarLoads: 'Bids Find Similar Loads',
    reloadAfterTransaction: 'Transaction Reloads',
    teamTrackerUpdateDispatch: 'Team Tracker Update Dispatch Info',
    availabeLoadRefresh: 'Available Load Refresh',
    availabeLoadReload: 'Available Load Reload',
    rateConReload: 'Rate confirmation to available loads reloads navigation',
    quickSignupReload: 'Quicksignup from Rate confirmation reloads option',
    quickSignupCOFilter: 'Quicksignup from Counter offer filter',
    signupFromReload: 'Navigate to Signup page from Rate confirmation reloads option',
    tripLength: 'Trip Length Filter',
    noOverlap: 'No Overlap',
    loadOverlap: 'Load Overlap',
    transitOverlap: 'Transit Overlap',
    multipleDocumentUpload: 'Multiple Paperwork Upload',
    applySorting: 'Apply Sorting',
    notes: 'Notes',
    carrierRqmAndNotes: 'Carrier Requirement And Notes',
    carrierRqm: 'Carrier Requirements',
    dashboardOpportunities: 'Opportunities with XPO',
    bidsDetails: 'Bid Details',
    myLoadsDownload: 'My Loads Download',
    tendersDownload: 'Tenders Download',
    kpi: 'KPI',
    availableLoadsDetails: 'Available Loads Details',
    dedicatedLaneDetails: 'Dedicated Lane Details',
    dedicatedLaneInfo: 'Dedicated Lane Information',
    bidsStatusFilterApplied: 'Bids Status Filter Applied',
    bidsSearchTextFilterApplied: 'Bids Search Text Filter Applied',
    tenderStatusFilterApplied: 'Tender Status Filter Applied',
    availableLoadsFilterApplied: 'Available Loads Filter Applied',
    savedSearchDetails: 'Saved Search Details',
    applySavedSearch: 'Applied Saved Search',
    deleteSavedSearch: 'Delete Saved Search',
    editSavedSearch: 'Edit Saved Search',
    openRecentSearch: 'open recent search list',
    viewRecentSearchLoad: 'View recently searched load',
    createSavedSearch: 'Create Saved Search',
    myLoadsDetailsView: 'My Loads Details View',
    myLoadsStatusFilterApplied: 'My Loads Status Filter Applied',
    myLoadsDriverFilterApplied: 'My Loads Driver Filter Apply',
    myLoadsPlanNextMove: 'My Load Plan Next Move',
    myLoadsDetailsDispatchUpdate: 'My Load Details Dispatch Update',
    myLoadsDetailsDispatchCancel: 'My Load Details Dispatch Cancel',
    myLoadsDetailsDispatchSave: 'My Load Details Dispatch Save',
    myLoadsDispatch: 'My Load DispatchInfo',
    myLoadsDispatchInfoCancel: 'My Load DispatchInfo Cancel',
    myLoadsDispatchInfoSave: 'My Load DispatchInfo Save',
    trackingListDetails: 'Tracking List Details',
    trackingMapDetails: 'Tracking Map Details',
    trackingMapPlanNextMove: 'Tracking Map Plan Next Move',
    trackingLocationFilter: 'Tracking Location Filter',
    trackingStatusFilter: 'Tracking Status Filter',
    localOpportunitiesWithXpo: 'Local Opportunities With XPO',
    nationalOpportunitiesWithXpo: 'National Opportunities With XPO',
    dashboardTender: 'Dashboard to Tender Navigation',
    dashboardTracking: 'Dashboard to Tracking Navigation',
    dashboardLoads: 'Dashboard to Loads Navigation',
    dashboardBids: 'Dashboard to Bids Navigation',
    dashboardAvailableLoads: 'Dashboard to AvailableLoads Navigation',
    createDriver: 'Create Driver',
    editDriver: 'Edit Driver',
    deleteDriver: 'Delete Driver',
    deleteEquipment: 'Delete Equipment',
    editEquipment: 'Edit Equipment',
    createEquipment: 'Create Equipment',
    documentDetails: 'Document Details',
    uploadPOD: 'Upload POD',
    shareTracking: 'Share Tracking Link',
    viewReload: 'View Reload',
    myLoadDetailsUploadPOD: 'My Load Details Upload POD',
    postCheckcallPODUpload: 'Check call POD upload',
    myLoadDetailsDownload: 'My Load Details Download Document',
    uploadPODLater: 'Upload POD dismiss',
    fileSizeExceeded: 'File size limit exceeded',
    shareTrackingEmail: 'Share Tracking Link via Email',
    shareTrackingText: 'Share Tracking Link via Text',
    shareTrackingWhatsapp: 'Share Tracking Link via Whatsapp',
    shareTrackingMore: 'Share More Tracking Link',
    shareTrackingPhoneActions: 'Share Tracking Phone',
    shareTrackingEmailActions: 'Share Tracking Email',
    viewRateCon: 'View Rate Confirmation',
    addDocument: 'Add Document',
    replaceDocument: 'Replace Document',
    deleteDocument: 'Delete Document',
    downloadDocument: 'Download Document',
    tenderDetails: 'Tender Details',
    tenderReject: 'Tender Reject',
    tenderAccept: 'Tender Accept',
    profileGeneralInfo: 'Terms And Agreement',
    signOut: 'Sign Out',
    signIn: 'Sign In',
    signUp: 'Sign Up From Signup Banner',
    profileSettings: 'Profile & Settings',
    feedback: 'Feedback',
    privacyPolicy: 'Privacy Policy',
    switchCarrierConfirm: 'Switch Carrier Confirmed',
    switchCarrierCancel: 'Switch Carrier Cancelled',
    support: 'View Support',
    notificationSettingsAndPreferences: 'Notification Settings And Preferences',
    carrierSetup: 'Carrier Setup',
    createDriverFromDispatch: 'Create Driver From Dispatch',
    createDriverFromDetailsViewDispatch: 'Create Driver From Details View Dispatch',
    acceptTcAgree: 'Accept terms and conditions click agree',
    acceptTcCancel: 'Accept terms and conditions click cancel',
    viewTc: 'View terms and conditions',
    dashboardCarrierRewards: 'Dashboard to carrier rewards navigation',
    carrierRewardsToDashboard: 'Carrier Rewards to Dashboard Navigation',
    toFuelProgramPage: 'Navigate to Fuel Card Program page',
    feedbackEmail: 'Navigate to email client',
    loyaltySummaryTocarrierRewards: 'Loyalty Summary to Carrier Rewards navigation',
    carrierRewardsToloyaltySummary: ' Carrier Rewards to Loyalty Summary navigation',
    enrollFuelCardProgram: 'Enroll for XPO Fuel Card Program',
    feedbackLink: 'Feedback Email',
    viewSummary: 'View Summary',
    backToRewardsHome: 'back button to home',
    close: 'Close',
    clickTcLink: 'View terms and conditions click',
    clickAccept: 'Accept terms and conditions click',
    autoAccept: 'Auto accept loyalty terms and conditions through book now / place bid',
    acceptLoyaltyCounterOffer: 'Auto accept loyalty terms and conditions through counter offer acceptance',
    clickCancel: 'Cancel terms and conditions click',
    arrivedOrigin: 'Origin Arrival',
    departedOrigin: 'Departed Orrigin',
    arrivedDestination: 'Destination Arrival',
    departedDestination: 'Departed Destination',
    planNextMoveFilterRecords: 'Plan Next Move Filter',
    planNextMoveAvailableLoadsClick: 'Available loads near destination count',
    planNextMovePendingBidsClick: 'Pending bids count',
    planNextMovePendingCounterOfferClick: 'Pending counter offer count',
    planNextMovePendingTenderClick: 'Pending tender count',
    navigateToCarrierScoreAnalytics: 'Navigate to carrier score analytics page',
    openAdvancedSearch: 'Open advanced search filter panel',
    closeAdvancedSearch: 'Close advanced search filter panel',
    applyAdvancedSearchFilter: 'Apply advanced search filter',
    clearAllAdvancedSearchFilter: 'Clear all advanced search filter',
    adminApprove: 'Admin approve',
    adminReject: 'Admin reject',
    viewAnalytics: 'View Analytics page',
    getRateCon: 'Get Rate Con',
    searchByOrder: 'Search by order',
    navigateToChromeDownloadPage: 'Navigate to Google Chrome download page',
    navigateToEdgeDownloadPage: 'Navigate to Microsoft Edge download page',
    navigateToFirefoxDownloadPage: 'Navigate to Mozilla Firefox download page',
    unsupportedBrowser: 'Unsupported browser page is loaded',
    recommendedNav: 'Dashboard to Availableloads - Recommended tab navigation',
    navigateToCarrierSummaryAnalytics: 'Navigate to carrier summary analytics page',
    dispatchInfoCancel: 'Dispatch information popup cancel',
    dispatchInfoSkipForNow: 'Dispatch information popup skipped for now',
    maintananceTierSavings: 'Navigated to rewards page from dashboard news and programs section for Maintenance and Tire Savings',
    rewardTierBonus: 'Navigated to rewards page from dashboard news and programs section for quarterly bonus',
    dashboardToLanes: 'Dashboard to dedicated lanes navigation',
    dashboardToAvailableLoads: 'Dashboard to available loads navigation',
    editUser: 'Edit user',
    sendPasswordReset: 'Password reset request',
    placeBidLane: 'Place Bid Dedicated Lane',
    reviewBidLane: 'Review Dedicated Lane Bid Details',
    openUserActions: 'User admin open user actions',
    opportunitiesViewAllLoads: 'opportunites-view all loads',
    opportunitesSearch: 'opportunities-search',
    opportunitiesAggregate: 'Opportunities-Aggregate',
    opportunitesViewFilterLoads: 'opportunities-view filter loads',
    opportunitesViewLoadCluster: 'opportunities-view load cluster',
    opportunitiesViewSingleLoad: 'opportunities-view single load',
    openMyLoadsActions: 'My loads open actions',
    updatePhoneNumber: 'Loads - Update Profile',
    rateconpagevisit: 'Rate confirmation page visited',
    rateconload: 'Rate confirmation pdf loaded',
    rateconprint: 'Rate confirmation pdf print menu click',
    rateconshare: 'Rate confirmation pdf share menu click',
    rateconhistory: 'Rate confirmation history option click',
    rateconsharepopupcancel: 'Rate confirmation pdf share popup cancel',
    rateconsharepopupemailsent: 'Rate confirmation pdf share popup email sent',
    ratecondownload: 'Rate confirmation pdf download menu click',
    ratecondispatch: 'Add dispatch information from Rate confirmation details',
    signupPromoCancel: 'Connect signup promotion popup cancel',
    signupPromoSignUp: 'Signup click from Connect signup promotion popup',
    quickSignupPromoSignUp: 'Quick-Signup click from Connect signup promotion popup',
    signupPromoSignIn: 'SignIn from Connect signup promotion popup',
    signupBannerSignIn: 'SignIn from Connect signup banner',
    bannerLinkClick: 'Banner message link click',
    navBarSignUp: 'Sign up from Connect menu bar',
    navBarQuickSignUp: 'Quick Sign up click from Connect menu bar',
    navBarSignIn: 'Sign In from Connect menu bar',
    signupBannerClick: 'Signup from Connect signup promotion banner',
    quickSignupBannerClick: 'Quick-Signup from Connect signup promotion banner',
    androidBannerClick: 'Drive XPO Android Url Click',
    iosBannerClick: 'Drive XPO IOS Url Click',
    signupBannerClose: 'Close Connect signup promotion banner',
    rateconHistoryPopupClose: 'Rate confirmation history poupup close',
    ratecondownloadForPrint: 'Rate confirmation pdf downloaded for print',
    rateconVersionSelectedFromHistory: 'Rate confirmation version selected from view history poupup',
    rateconEsign: 'Rate confirmation e-sign button click',
    rateconsignpopupcancel: 'Rate confirmation e-signature popup cancelled',
    rateconsignpopupclose: 'Rate confirmation e-signature popup closed after e-signed',
    rateconEsignSubmit: 'Rate confirmation e-signature submit clicked',
    esignTerms: 'Terms and condition opened from rate con e-sign popup',
    esignPrivacy: 'Privacy policy opened from rate con e-sign popup',
    quickSignupSignIn: 'SignIn from Quick signup popup',
    quickSignupCancel: 'Quick signup cancelled',
    quickSignupClose: 'Quick signup closed after signup',
    quickSignupTerms: 'Terms and condition opened from quick signup popup',
    quickSignupPrivacy: 'Privacy policy opened from quick signup popup',
    resetPasswordQuickSignup: 'Reset password clicked after registration from Quick signup poup',
    loginAfterQuickSignup: 'Login after registration from Quick signup poup(user existing case)',
    quickSignupFail: 'Quick signup registration not successful',
    dashboardTocontest: 'Dashboard to carrier contest navigation',
    noRecommendationTabSwitch: 'No recommendations to all loads tab switch',
    availableloadMapExpandView: 'Available loads lane and stop map expanded view',
    rateConMapExpandView: 'Rate confirmation stop map expanded view',
    loadSoldOut: 'Load sold out',
    loadAlreadyAssigned: 'Load already assigned',
    noLoadAvailable: 'no loads available',
    viewSavedSearch: 'View saved search',
    addSavedSearch: 'Add saved search',
    replaceSavedSearch: 'Replace saved search',
    noSimilarLoadSaveSearch: 'No exact/similar results - save search',
    noSimilarLoadNewSearch: 'No exact/similar results - start a new search',
    saveSearch: 'No exact results - save search',
    startNewSearch: 'No exact results - start a new search',
    saveNotificationSettings: 'Save notification',
    viewSettings: 'View settings from saved search',
    accessNotificationSettings: 'Access notification',
    viewMoreSimilarLoads: 'Similar Loads>View More',
    similarLoadsAvailable: 'Similar loads in initial list',
    startNewSearchWhenloadSoldOut: 'Sold out - start a new search',
    newSearchForloadSoldOutHavingSimilarLoads: 'Sold out/similar loads - start a new search',
    postTruck: 'Post a truck link click',
    postTruckClear: 'Clear - post truck form',
    postTruckSubmit: 'Post a truck submit click',
    postTruckCancel: 'Close - post truck form'
  };

  public static readonly stopTypes = {
    pickUp: 'Pickup',
    delivery: 'Delivery'
  };

  public static readonly keystoneEventAction = {
    view: 'View',
    initiate: 'Initiate',
    submit: 'Submit',
    cancel: 'Cancel',
    autoClose: 'Auto Close',
    logout: 'Logout',
    approved: 'Approved',
    rejected: 'Rejected',
    submitted: 'Submitted',
    underReview: 'Under Review',
    errorOrTimeOut: 'Error OR Time Out',
    click: 'Click',
    confirm: 'Confirm',
    accepted: 'Accepted',
    collapsed: 'Collapsed',
    expanded: 'Expanded',
    show: 'Show',
    hide: 'Hide',
    search: 'Search',
    assignDriver: 'Assign Driver',
    enabled: 'Enabled',
    disabled: 'Disabled',
    assignNewDriver: 'Assign New Driver',
    upload: 'Upload',
    apply: 'Apply',
    download: 'download',
    create: 'Create',
    delete: 'Delete',
    edit: 'Edit',
    clear: 'Clear',
    copy: 'Copy',
    back: 'Back',
    failed: 'Failed',
    rmisEmailSent: 'Manual setup - New Carrier RMIS Sent - Compliance review',
    manualSetupNoDispatch: 'Manual setup - No dispatch carrier - Support review',
    manualSetupActive: 'Manual setup - Active carrier contact associated to a different carrier in FO - Support review',
    automateCarrierSetup: 'Automate setup - Active carrier email matches',
    termsAgreementDialog: 'Terms & Agreement popup shown',
    loyaltyTermsAgreementDialog: 'Loyalty terms & Agreement popup shown',
    tcAccepted: 'Terms and conditions accepted',
    tcCancelled: 'Terms and conditions cancelled',
    carrierRewardsToDashboard: 'Carrier Rewards to Dashboard Navigation',
    toFuelProgramPage: 'Navigate to Fuel Card Program page',
    feedbackEmail: 'Navigate to email client',
    loyaltySummaryTocarrierRewards: 'Loyalty Summary to Carrier Rewards navigation',
    carrierRewardsToloyaltySummary: ' Carrier Rewards to Loyalty Summary navigation',
    navToRewards: 'Tier upgrade notification popup to carrier rewards navigation',
    upgradeNotificationClosed: 'Tier upgrade notification popup closed',
    loyaltyTcAccepted: 'Loyalty terms and conditions accepted',
    loyaltyTcCancelled: 'Loyalty terms and conditions cancelled',
    navigateToLoyaltyTcDialog: 'Navigate to carrier rewards terms and conditions popup',
    requestWithinSLA: 'Self registration retry within SLA',
    adminNotSetupSupportReview: 'Admin not setup - Support review',
    adminApprovalNoMatchCarrier: 'Admin approval - New User',
    adminApprovalSecondaryContact: 'Admin approval - user exists as secondary contact',
    adminApprovalDifferentCarrier: 'Admin approval - user exists with a different carrier in PM',
    passwordResetEmail: 'Password Reset Email',
    carrierRewardsToCarrierScoreAnalytics: 'Carrier Rewards page to Carrier Score Analytics navigation',
    myloadToFinance: 'My Load to Finanace Management navigation',
    carrierRewardsToCarrierSummaryAnalytics: 'Carrier Rewards page to Carrier Summary Analytics navigation',
    loadUnavailableForBid: 'Load not available',
    coExist: 'CO exists',
    coAccepted: 'CO already accepted',
    coRejected: 'CO already rejected',
    coNotAvailable: 'CO not available',
    bidUnderReview: 'Bid under review',
    loadUnAssigned: 'Load unassigned',
    invalidBid: 'Invalid Bid Amount',
    genericException: 'Generic Exception'
  };

  public static readonly keystonePage = {
    availableLoads: 'available-loads',
    loads: 'loads',
    myLoadDetails: 'my-load-Details',
    tracking: 'tracking',
    adminUsers: 'admin/users',
    freightOnMove: 'freight-on-move',
    autoOffers: 'auto-offers',
    registration: 'registration',
    dashboard: 'dashboard',
    counterOffer: 'counter-offer',
    counterOfferNew: 'counter-offer-unauth',
    bid: 'bids',
    financeManagement: 'finance-management',
    tenders: 'load-tenders',
    assetManagement: 'asset-management',
    globalNotification: 'global-notification-pop-up',
    feedback: 'feedback',
    support: 'support',
    signOut: 'sign-out',
    contestDetails: 'contest Details',
    contesttnc: 'contest tnc',
    privacyPolicy: 'privacy-policy',
    profileSettings: 'profile-settings',
    acceptTcDialog: 'accept-terms-and-conditions-popup',
    switchCarrierDialog: 'switch-carrier-popup',
    rewardsProgram: 'rewards-program',
    rewardsSummary: 'rewards-program-summary',
    loyalty: 'loyalty',
    bidCancelDialog: 'cancel-bid-dialog',
    upgradeNotification: 'Tier upgrade notification popup',
    acceptloyaltyTcDialog: 'Accept loyalty terms and conditions popup',
    planNextMoveDialog: 'Plan next move popup',
    adminRequests: 'admin-requests',
    analytics: 'analytics',
    unsupportedBrowser: 'unsupported-browser',
    lanes: 'dedicated-lanes',
    rateConfirmation: 'rate-confirmation',
    documentDialog: 'Upload document pop up'
  };

  public static readonly keystoneSubPage = {
    roles: 'Roles',
    users: 'Users',
    availableLoads: 'Available Loads',
    savedSearch: 'Saved Search',
    recentSearch: 'Recent Search',
    map: 'Map',
    list: 'List',
    nonCompetitoPopUp: 'non-competitor popUp',
    viewDetails: 'View Details',
    driver: 'Driver',
    equipment: 'Equipment',
    carrierSetup: 'Carrier Setup',
    signupPromo: 'Connect signup promotion popup',
    rateConfirmationSharePopup: 'Rate confirmation share popup',
    rateConfirmationDownloadPopup: 'Rate confirmation download popup',
    rateConfirmationHistoryPopup: 'Rate confirmation history popup',
    menu: 'Connect navigation menu',
    signupBanner: 'Connect signup top banner',
    rateConfirmationeSignPopup: 'Rate confirmation e-signature popup',
    quickSignupPopup: 'Quick signup popup',
    loadsmappopup: 'Load lane and stop map expanded view popup',
  };

  public static readonly eventCategory = {
    dashboard: 'Dashboard',
    tenders: 'Tenders',
    assigned: 'Assigned Loads',
    avaiable: 'Available Loads',
    admin: 'Admin',
    roles: 'Roles',
    profile: 'Profile',
    general: 'General',
    error: 'Error',
    spDashboard: 'SP Dashboard',
    spRouteList: 'SP Route List',
    spRouteDetail: 'SP Route Detail',
    spMetrics: 'SP Metrics',
    spEmployees: 'SP Employees',
    spRouteMetrics: 'SP Route Metrics',
    spProfile: 'SP Profile',
    spCompanyPerformance: 'SP Company Performance'
  };

  public static readonly eventAction = {
    submit: 'Submission',
    cancel: 'Cancel',
    review: 'Review',
    download: 'Download',
    initiateDispatch: 'InitiateDispatch',
    dispatch: 'Dispatch',
    initiateBid: 'InitiateBid',
    initiateBuyItNow: 'InitiateBuyItNow',
    bid: 'Bid',
    buyItNow: 'BuyItNow',
    new: 'New',
    modify: 'Modify',
    delete: 'Delete',
    eAccept: 'Email Accept',
    eReject: 'Email Reject',
    accept: 'Accept',
    reject: 'Reject',
    weather: 'Weather Alert',
    traffic: 'Traffic Alert',
    weatherRadar: 'Weather Radar',
    traficLanes: 'Traffic Lanes',
    login: 'Login Session',
    logout: 'Logout Session',
    refresh: 'Refresh Session',
    fetchWeatherAlert: 'Fetch Weather Alert',
    availableLoadMarker: 'Available Load Marker',
    hoverAvailableLoad: 'Hover Available Load',
    view: 'View',
    get: 'Get',
    filterChange: 'Filter Change',
    nameSearch: 'Name Search'
  };

  public static readonly driverStatus = {
    noOverlap: 'NOOVERLAP',
    loadOverlap: 'UNLOAD_LOADOVERLAP',
    transitOverlap: 'TRANSITOVERLAP'
  };

  public static readonly userActions = {
    create: 'create',
    edit: 'edit',
    delete: 'delete',
    confirm: 'confirm',
    refresh: 'refresh',
    continue: 'continue',
    cancel: 'cancel',
    assignNewDriver: 'assign new driver'
  };

  public static readonly orderFinancialStatus = {
    new: 'New',
    open: 'Open',
    pending: 'Pending',
    processing: 'Processing'
  };

  public static readonly invoiceStatus = {
    pending: 'Pending',
    posted: 'Posted',
    approved: 'Approved',
    void: 'Void',
    paid: 'Paid'
  };

  public static readonly invoiceMessages = {
    voidMessage: 'Any questions contact apinvoices@xpo.com or +1 (855) 976-5623  Ext 5223133.',
    noInvoiceMessage: 'Invoice is not yet available.',
    invoiceDefault: 'Invoice is approved and pending payment. Contact apinvoices@xpo.com or +1 (855) 976-5623  Ext 5223133 if any question.'
  };

  public static readonly eventListener = {
    zoomChanged: 'zoom_changed',
    projectionChanged: 'projection_changed'
  };

  public static readonly validationMessages = {
    invalidPhoneNumber: 'Invalid Phone Number',
    invalidCaptcha: 'Invalid ReCaptcha'
  };

  public static readonly dispatchStopStatusCode = [ 'OnTime', 'PossibleDelay', 'Dlyd', 'NotMvng', 'NotTrkg', 'Dlvrd', 'TrkgNotStarted' ];

  public static readonly teamTrackerStatus = {
    noDriverNoLoad: 1,
    noLoads: 2,
    noDriverMapped: 3,
    noLoadMappedToDriver: 4,
    loadInDifferentStatus: 5
  };

  public static readonly loadTransactionFlag = {
    placeBid: 'BID',
    buyLoad: 'BIN',
    counterOffer: 'BID'
  };

  public static readonly phoneCountryCodes = [
    { value: '+1', name: 'USA (+1)' },
    { value: '+1', name: 'Canada (+1)' },
    { value: '+52', name: 'Mexico (+52)' }
  ];

  public static readonly checkCallNumbers = {
    disabled: 0,
    first: 1,
    second: 2,
    third: 3,
    fourth: 4
  };

  public static readonly recentSearches = {
    recentSearchIdentifier: '-recentSearches',
    recentSearchCount: 10
  };

  public static readonly addDriverMsgs = {
    addBtn: 'addDriaddDri3537',
    inviteBtn: 'sendInsendIn4104',
    addBtnSuccess: 'newDri2983',
    inviteBtnSuccess: 'invita6747'
  };

  public static readonly requirementCode = {
    live: 'LIVE',
    drop: 'DROP',
    loadUnload: 'LOADUNLOAD',
    dropTrailer: 'DROPTRAILER',
    dropHook: 'DROPHOOK',
    dropTrailerLabel: 'DROP TRAILER',
    dropHookLabel: 'DROP AND HOOK'
  };

  public static readonly bidErrorTypes = {
    loadUnavailable: 1,
    counterOfferExist: 2,
    counterOfferAccepted: 3,
    counterOfferRejected: 4,
    counterOfferUnavailable: 5,
    bidUnderReview: 6,
    carrierUnassigned: 7,
    invalidBidAmount: 8
  };

  public static readonly dedicatedLaneInfo = [
    {
      question: 'What is a Dedicated Lane?',
      answer: `If you are approved for a Dedicated Lane,
       loads for that lane will automatically be assigned to you whenever they become available for an agreed-upon rate.`
    },
    {
      question: 'What are the benefits of a Dedicated Lane?',
      answer: `With a Dedicated Lane, you can save time searching
       for loads on your most desired lanes and avoid the risk that another carrier may book the load before you see it.`
    },
    {
      question: 'How does it work?',
      answer: `Click any lane to view the details. You can place a bid on any lane using the bid form below.
       Tell us how many loads you can accept each week, when you can start, and for how long.`
    },
    {
      question: 'What happens after I place a bid?',
      answer: `An XPO representative will reach out to discuss your bid with you and determine
       if they can approve you for the Dedicated Lane,
        If you wish to change any terms on your bid after you place your bid,
         simply discuss your desired changes with the XPO representative.`
    },
    {
      question: 'What else should I know about Dedicated Lanes?',
      answer: `Note that load volumes may fluctuate from week to week depending on the needs of the shipper
       so actual load volume may vary from estimated load volume in total and frequency.`
    }
  ];

  public static readonly rateConBanner = [
    {
      code: 'RateConBanner5',
      banner: 'banner_image1.png',
      message: 'Get Consistent Loads with Dedicated Lanes'
    },
    {
      code: 'RateConBanner1',
      banner: 'banner_image1.png',
      message: 'Find Dedicated Lane Opportunities'
    },
    {
      code: 'RateConBanner3',
      banner: 'banner_image1.png',
      message: 'Find & Book 1000s of Loads'
    },
    {
      code: 'RateConBanner4',
      banner: 'banner_image1.png',
      message: 'Find Hazmat Loads in XPO Connect'
    },
    {
      code: 'RateConBanner60',
      banner: 'banner_image1.png',
      message: 'View More Freight in XPO Connect'
    },
    {
      code: 'RateConBanner2',
      banner: 'banner_image1.png',
      message: 'Find Your Next Opportunity'
    },
    {
      code: 'RateConBanner15',
      banner: 'banner_image3.png',
      message: 'Get Consistent Loads with Dedicated Lanes'
    },
    {
      code: 'RateConBanner11',
      banner: 'banner_image3.png',
      message: 'Find Dedicated Lane Opportunities'
    },
    {
      code: 'RateConBanner12',
      banner: 'banner_image3.png',
      message: 'Find & Book 1000s of Loads'
    },
    {
      code: 'RateConBanner13',
      banner: 'banner_image3.png',
      message: 'Find Hazmat Loads in XPO Connect'
    },
    {
      code: 'RateConBanner14',
      banner: 'banner_image3.png',
      message: 'View More Freight in XPO Connect'
    },
    {
      code: 'RateConBanner61',
      banner: 'banner_image3.png',
      message: 'Find Your Next Opportunity'
    },
    {
      code: 'RateConBanner16',
      banner: 'banner_image4.png',
      message: 'Get Consistent Loads with Dedicated Lanes'
    },
    {
      code: 'RateConBanner17',
      banner: 'banner_image4.png',
      message: 'Find Dedicated Lane Opportunities'
    },
    {
      code: 'RateConBanner19',
      banner: 'banner_image4.png',
      message: 'Find & Book 1000s of Loads'
    },
    {
      code: 'RateConBanner20',
      banner: 'banner_image4.png',
      message: 'Find Hazmat Loads in XPO Connect'
    },
    {
      code: 'RateConBanner18',
      banner: 'banner_image4.png',
      message: 'View More Freight in XPO Connect'
    },
    {
      code: 'RateConBanner62',
      banner: 'banner_image4.png',
      message: 'Find Your Next Opportunity'
    },
    {
      code: 'RateConBanner25',
      banner: 'banner_image5.png',
      message: 'Get Consistent Loads with Dedicated Lanes'
    },
    {
      code: 'RateConBanner21',
      banner: 'banner_image5.png',
      message: 'Find Dedicated Lane Opportunities'
    },
    {
      code: 'RateConBanner23',
      banner: 'banner_image5.png',
      message: 'Find & Book 1000s of Loads'
    },
    {
      code: 'RateConBanner63',
      banner: 'banner_image5.png',
      message: ' Find Hazmat Loads in XPO Connect'
    },
    {
      code: 'RateConBanner24',
      banner: 'banner_image5.png',
      message: 'View More Freight in XPO Connect'
    },
    {
      code: 'RateConBanner22',
      banner: 'banner_image5.png',
      message: 'Find Your Next Opportunity'
    },
    {
      code: 'RateConBanner29',
      banner: 'banner_image6.png',
      message: 'Get Consistent Loads with Dedicated Lanes'
    },
    {
      code: 'RateConBanner26',
      banner: 'banner_image6.png',
      message: 'Find Dedicated Lane Opportunities'
    },
    {
      code: 'RateConBanner28',
      banner: 'banner_image6.png',
      message: 'Find & Book 1000s of Loads'
    },
    {
      code: 'RateConBanner30',
      banner: 'banner_image6.png',
      message: 'Find Hazmat Loads in XPO Connect'
    },
    {
      code: 'RateConBanner64',
      banner: 'banner_image6.png',
      message: 'View More Freight in XPO Connect'
    },
    {
      code: 'RateConBanner27',
      banner: 'banner_image6.png',
      message: 'Find Your Next Opportunity'
    },
    {
      code: 'RateConBanner35',
      banner: 'banner_image7.png',
      message: 'Get Consistent Loads with Dedicated Lanes'
    },
    {
      code: 'RateConBanner31',
      banner: 'banner_image7.png',
      message: 'Find Dedicated Lane Opportunities'
    },
    {
      code: 'RateConBanner32',
      banner: 'banner_image7.png',
      message: 'Find & Book 1000s of Loads'
    },
    {
      code: 'RateConBanner65',
      banner: 'banner_image7.png',
      message: 'Find Hazmat Loads in XPO Connect'
    },
    {
      code: 'RateConBanner33',
      banner: 'banner_image7.png',
      message: 'View More Freight in XPO Connect'
    },
    {
      code: 'RateConBanner34',
      banner: 'banner_image7.png',
      message: 'Find Your Next Opportunity'
    },
    {
      code: 'RateConBanner36',
      banner: 'banner_image1.png',
      message: 'New! See Your Recommended Loads in XPO Connect'
    },
    {
      code: 'RateConBanner37',
      banner: 'banner_image1.png',
      message: 'New! Maintenance and Tire Savings',
      link: 'carrier/rewards-program'
    },
    {
      code: 'RateConBanner38',
      banner: 'banner_image1.png',
      message: 'New! Get $20 off Blue Tiger Headsets',
      link: 'https://bluetigerusa.com/discount/XPO?redirect=%2Fcollections%2Fdrive-series-bluetooth-headsets'
    },
    {
      code: 'RateConBanner39',
      banner: 'banner_image1.png',
      message: 'New! 24/7 Roadside Assistance',
      link: 'https://connect.xpo.com/carrier-rewards/truck-maintenance/'
    },
    {
      code: 'RateConBanner40',
      banner: 'banner_image3.png',
      message: 'New! See Your Recommended Loads in XPO Connect'
    },
    {
      code: 'RateConBanner41',
      banner: 'banner_image3.png',
      message: 'New! Maintenance and Tire Savings',
      link: 'carrier/rewards-program'
    },
    {
      code: 'RateConBanner42',
      banner: 'banner_image3.png',
      message: 'New! Get $20 off Blue Tiger Headsets',
      link: 'https://bluetigerusa.com/discount/XPO?redirect=%2Fcollections%2Fdrive-series-bluetooth-headsets'
    },
    {
      code: 'RateConBanner43',
      banner: 'banner_image3.png',
      message: 'New! 24/7 Roadside Assistance',
      link: 'https://connect.xpo.com/carrier-rewards/truck-maintenance/'
    },
    {
      code: 'RateConBanner44',
      banner: 'banner_image4.png',
      message: 'New! See Your Recommended Loads in XPO Connect'
    },
    {
      code: 'RateConBanner45',
      banner: 'banner_image4.png',
      message: 'New! Maintenance and Tire Savings',
      link: 'carrier/rewards-program'
    },
    {
      code: 'RateConBanner46',
      banner: 'banner_image4.png',
      message: 'New! Get $20 off Blue Tiger Headsets',
      link: 'https://bluetigerusa.com/discount/XPO?redirect=%2Fcollections%2Fdrive-series-bluetooth-headsets'
    },
    {
      code: 'RateConBanner47',
      banner: 'banner_image4.png',
      message: 'New! 24/7 Roadside Assistance',
      link: 'https://connect.xpo.com/carrier-rewards/truck-maintenance/'
    },
    {
      code: 'RateConBanner48',
      banner: 'banner_image5.png',
      message: 'New! See Your Recommended Loads in XPO Connect'
    },
    {
      code: 'RateConBanner49',
      banner: 'banner_image5.png',
      message: 'New! Maintenance and Tire Savings',
      link: 'carrier/rewards-program'
    },
    {
      code: 'RateConBanner50',
      banner: 'banner_image5.png',
      message: 'New! Get $20 off Blue Tiger Headsets',
      link: 'https://bluetigerusa.com/discount/XPO?redirect=%2Fcollections%2Fdrive-series-bluetooth-headsets'
    },
    {
      code: 'RateConBanner51',
      banner: 'banner_image5.png',
      message: 'New! 24/7 Roadside Assistance',
      link: 'https://connect.xpo.com/carrier-rewards/truck-maintenance/'
    },
    {
      code: 'RateConBanner52',
      banner: 'banner_image6.png',
      message: 'New! See Your Recommended Loads in XPO Connect'
    },
    {
      code: 'RateConBanner53',
      banner: 'banner_image6.png',
      message: 'New! Maintenance and Tire Savings',
      link: 'carrier/rewards-program'
    },
    {
      code: 'RateConBanner54',
      banner: 'banner_image6.png',
      message: 'New! Get $20 off Blue Tiger Headsets',
      link: 'https://bluetigerusa.com/discount/XPO?redirect=%2Fcollections%2Fdrive-series-bluetooth-headsets'
    },
    {
      code: 'RateConBanner55',
      banner: 'banner_image6.png',
      message: 'New! 24/7 Roadside Assistance',
      link: 'https://connect.xpo.com/carrier-rewards/truck-maintenance/'
    },
    {
      code: 'RateConBanner56',
      banner: 'banner_image7.png',
      message: 'New! See Your Recommended Loads in XPO Connect'
    },
    {
      code: 'RateConBanner57',
      banner: 'banner_image7.png',
      message: 'New! Maintenance and Tire Savings',
      link: 'carrier/rewards-program'
    },
    {
      code: 'RateConBanner58',
      banner: 'banner_image7.png',
      message: 'New! Get $20 off Blue Tiger Headsets',
      link: 'https://bluetigerusa.com/discount/XPO?redirect=%2Fcollections%2Fdrive-series-bluetooth-headsets'
    },
    {
      code: 'RateConBanner59',
      banner: 'banner_image7.png',
      message: 'New! 24/7 Roadside Assistance',
      link: 'https://connect.xpo.com/carrier-rewards/truck-maintenance/'
    }
  ];

  public static readonly availableLoadsSortOptions = {
    earliestPickup: {
      code: 'PCKUP-ASC', name: 'earliestpic5214', column: 'originPickupDateTimeInUTC', order: 'ASC',
      keystoneEventName: XPOConstants.keystoneEventName.sortByEarliestPickup, default: true
    },
    newestLoad: {
      code: 'ODRNO-DESC', name: 'newestload5786', column: 'number', order: 'DESC',
      keystoneEventName: XPOConstants.keystoneEventName.sortByNewestLoad, default: true
    },
    heaviestLoad: {
      code: 'WEIGHT-DESC', name: 'heaviestloa7184',
      column: 'weight', order: 'DESC', keystoneEventName: XPOConstants.keystoneEventName.sortByHeaviestWeight, default: true
    },
    lightestLoad: {
      code: 'WEIGHT-ASC', name: 'lightestloa5545',
      column: 'weight', order: 'ASC', keystoneEventName: XPOConstants.keystoneEventName.sortByLightestWeight, default: true
    },
    shortestDistance: {
      code: 'DISTANCE-ASC', name: 'shortestdis1918', column: 'distance', order: 'ASC',
      keystoneEventName: XPOConstants.keystoneEventName.sortByShortestDistance, default: true
    },
    longestDistance: {
      code: 'DISTANCE-DESC', name: 'longestdist8575', column: 'distance', order: 'DESC',
      keystoneEventName: XPOConstants.keystoneEventName.sortByLongestDistance, default: true
    },
    nearestPickup: {
      code: 'DEADHD-ASC', name: 'nearestPick3152', column: 'originLocationGeo', order: 'ASC',
      keystoneEventName: XPOConstants.keystoneEventName.sortByShortestDeadhead, default: false
    }
  };

  public static readonly daysList = [
    {
      name: 'MON',
      code: 'Monday',
      translationKey: 'mon8959'
    },
    {
      name: 'TUE',
      code: 'Tuesday',
      translationKey: 'tue9359'
    },
    {
      name: 'WED',
      code: 'Wednesday',
      translationKey: 'wed6391'
    },
    {
      name: 'THU',
      code: 'Thursday',
      translationKey: 'thu3444'
    },
    {
      name: 'FRI',
      code: 'Friday',
      translationKey: 'fri9290'
    },
    {
      name: 'SAT',
      code: 'Saturday',
      translationKey: 'sat845'
    },
    {
      name: 'SUN',
      code: 'Sunday',
      translationKey: 'sun4033'
    } ];

  public static readonly postTruckTimeFormat = {
    startTime: "00:00:00",
    endTime: "23:59:59"
  };
  public static readonly businessUnitCode = "CONNECTCARRIER";
  public static readonly euZipcodeType = "Zipcode";

  public static readonly internalRepCode = 'INTERNAL_REP';
}

export enum PartnerType {
  customer = 1,
  vendor = 2
}

export enum TenantId {
  NA = 1,
  EU = 2
}

export enum SystemId {
  Unknown = 0,
  XpoPlatform = 1,
  FreightOptimizer = 2,
  RailOptimizer = 3,
  Dms = 4,
  TrkPlus = 5,
  ManagedTrans = 6,
  Lmx = 7
}

