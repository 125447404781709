import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { XPOConstants } from '../xpo-constants';

@Component({
  selector: 'app-driver-status-dialog',
  templateUrl: './driver-status-dialog.component.html',
  styleUrls: [ './driver-status-dialog.component.scss' ]
})
export class DriverStatusDialogComponent implements OnInit {
  public driverStatus = XPOConstants.driverStatus;
  public status: string;

  constructor(private dialogRef: MatDialogRef<DriverStatusDialogComponent>) {
    this.dialogRef.disableClose = true;
    this.status = this.dialogRef._containerInstance._config.data;
  }

  ngOnInit() { }

  submit(action) {
    this.dialogRef.close(action);
  }
}
