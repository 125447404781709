import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-carrier',
  templateUrl: './switch-carrier.component.html',
  styleUrls: [ './switch-carrier.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SwitchCarrierComponent {

  constructor(
    private dialogRef: MatDialogRef<SwitchCarrierComponent>) {
    this.dialogRef.disableClose = true;
  }

  onClose() {
    this.dialogRef.close('cancelled');
  }

  onAccept() {
    this.dialogRef.close('switchUser');
  }

}
