import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { AppState } from 'src/app/shared/app.state';
import { SideMenuSource } from 'src/app/shared/model/side-menu-source';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from 'src/app/shared';
import { AppSettings } from '../app-settings';
import { ErrorService } from 'src/app/error-message/services/error.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoyaltyService } from 'src/app/shared/services/loyalty.service';
import { KeystoneService } from 'src/app/shared/services/keystone.service';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { AcceptTermsAndConditionsComponent } from 'src/app/shared/accept-terms-and-conditions/accept-terms-and-conditions.component';
import { Observable, Subscription } from 'rxjs';
import { map, buffer, pluck, filter } from 'rxjs/operators';
import { UpgradeNotificationComponent } from 'src/app/shared/upgrade-notification/upgrade-notification.component';
import { LogEvent } from 'src/app/shared/model';
import { AdminNotificationsComponent } from '../admin-notifications/admin-notifications.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { AvailableLoadsService } from 'src/app/shared/services';
import { SignupPromoDialogComponent } from 'src/app/shared/signup-promo-dialog/signup-promo-dialog.component';
import { QuickSignupDialogComponent } from 'src/app/shared/quick-signup-dialog/quick-signup-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  providers: [ AppState ],
  styleUrls: [ './layout.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnDestroy, OnInit {
  private dialogConfig: MatDialogConfig;
  public isMenuCollapsed = false;
  public permissions: any = [];
  public topMenuSource: SideMenuSource[];
  public sideMenuCreated = false;
  public liveChatParameter: any = {};
  public pageName: string;
  public userEmail: string;
  public partnerName: string;
  public partnerIdentifier: string;
  public businessUnit: string;
  public name: string;
  public isLoyaltyEnabled = false;
  public upgradeDetail: any;
  public pageInfo: string;
  public navbarItemSubscription: Subscription;
  public isUnsupportedBrowser = false;
  public isNAUser: boolean;
  public breadCrumbMenuItems: any;
  public hideNavBar = false;
  public isLoggedIn = false;
  public availableLoadAccess: boolean;
  public myLoadAccess: boolean;
  public isUnAuthMenu = false;
  public showSignUpBanner = false;
  private signupBannerSubscription: Subscription;
  public showAlerts = true;
  public isQuickSignup = false;
  public carrierCompetionSubscription: Subscription;
  public showBanner: boolean;
  public bannerData: string;

  constructor(
    private state: AppState,
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
    public appSettings: AppSettings,
    private dialog: MatDialog,
    private loyaltyService: LoyaltyService,
    private userSettingsService: UserSettingsService,
    private keystoneService: KeystoneService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    public availableLoadsService: AvailableLoadsService
  ) {
    this.isUnsupportedBrowser = this.sharedService.isUnSupportedBrowser();
    this.dialogConfig = new MatDialogConfig();
    this.translateService.setDefaultLang(XPOConstants.defaultLanguageCode);
    this.isLoggedIn = authService.isLoggedIn;
    this.isNAUser = this.authService.checkNAUser();
    if (authService.preferredLanguageCode) {
      let language = authService.preferredLanguageCode;
      language = XPOConstants.languageCodes.indexOf(language) > -1 ? language : XPOConstants.defaultLanguageCode;
      this.translateService.use(language);
    }
    if (appSettings.isUnauthorized) {
      if (this.isLoggedIn) {
        this.errorService.changeMessage(XPOConstants.errorCode.unAuthorized);
        this.router.navigate([ '/error-message' ]);
      } else {
        window.location.href = appSettings.apiUrl.replace('api/', 'session/create');
      }
    } else if (!this.isLoggedIn && appSettings.isUnAutheticatedPage) {
      this.permissions = [];
      this.checkUnAuthPageConfig();
      this.topMenuSource = this.isUnAuthMenu ?
        XPOConstants.topMenuSource.filter(menu =>
          menu.permission.length === 0 || menu.permission.length > 0) : null;
      this.isMenuCollapsed = true;
      this.createSideMenu();
      return;
    } else {
      if (appSettings.isUnAutheticatedPage) {
        this.checkUnAuthPageConfig();
      }
      this.isNAUser = this.authService.checkNAUser();
      this.navbarItemSubscription = this.sharedService.getNavbarItemSubject().subscribe(obj => {
        this.breadCrumbMenuItems = {
          ...this.breadCrumbMenuItems,
          pageInfo: obj.pageInfo
        };
      });
      this.carrierCompetionSubscription = this.sharedService.getCarrierCompetitionBanner().subscribe(obj => {
        this.bannerData = obj;
        this.showBanner = false;
      });
      this.topMenuSource = XPOConstants.topMenuSource;
      this.permissions = this.authService.permissions;
      this.createSideMenu();
      this.isLoyaltyEnabled = this.permissions.indexOf(XPOConstants.permissionTypes.rewardsView) !== -1;
    }
    this.sharedService.getFlexBreakPoint().subscribe((breakpoint) => {
      this.createSideMenu();
    });
    this.state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  ngOnInit() {
    this.authService.trackingPermission = 0;
    this.translateService.onLangChange.subscribe((event) => {
      this.createSideMenu();
    });
    const navigationEnd = this.router.events.pipe(filter(evt => evt instanceof NavigationEnd));
    this.router.events.pipe(
      filter(evt => evt instanceof ActivationEnd),
      pluck('snapshot'),
      pluck('data'),
      buffer(navigationEnd),
      map((bcData: any[]) => bcData.reverse())
    ).subscribe(routeData => {
      this.checkUnAuthPageConfig();
      if (this.router.url !== '/loads/available-loads/details') {
        this.availableLoadsService.clearFilter();
      }
      this.breadCrumbMenuItems = {
        menuItem: routeData && routeData.length ? routeData[ routeData.length - 1 ].menuItem : '',
        subMenuItem: routeData && routeData.length ? routeData[ routeData.length - 1 ].subMenuItem : '',
        enableSubMenuAction: routeData && routeData.length ? routeData[ routeData.length - 1 ].enableSubMenuAction : false,
        menuTranslationKey: routeData && routeData.length ? routeData[ routeData.length - 1 ].menuTranslationKey : '',
        subMenuTranslationKey: routeData && routeData.length ? routeData[ routeData.length - 1 ].subMenuTranslationKey : '',
      };
    });
    if (this.isLoggedIn) {
      this.userEmail = this.authService.userProfile.email;
      this.partnerName = this.authService.userProfile.currentPartner.partnerName;
      this.partnerIdentifier = this.authService.partnerIdentifier;
      this.businessUnit = this.authService.userProfile.businessUnit;
      this.name = this.authService.userProfile.firstName;
      const alertcodes = this.authService.userProfile.notificationAlertTypeCodes;
      this.liveChatParameter = {
        licenseId: this.appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension8),
        name: this.name,
        page: this.pageName,
        subPage: this.pageName,
        partnerIdentifier: this.partnerIdentifier,
        businessUnit: this.businessUnit,
        layer: 'EF',
        id: 'logged',
        userEmailId: this.userEmail,
        partnerName: this.partnerName,
        group: XPOConstants.livechatGroupId,
        enabledBusinessUnit: XPOConstants.businessUnits.brokerage
      };
      if (this.isUnsupportedBrowser) {
        this.router.navigate([ '/unsupported-browser' ]);
      }
      if (alertcodes && alertcodes.indexOf('TCN') === -1 && this.showAlerts) {
        this.showAcceptTermsAndConditionDialog();
      }
      if (this.isLoyaltyEnabled && this.showAlerts) {
        this.upgradeInfo();
      }
      if (this.showAlerts) {
        this.userSettingsService.getPopupNotification({ pageNumber: 1, pageSize: 1 }).subscribe(response => {
          const notificationList = response.globalSystemNotifications;
          if (notificationList.length > 0) {
            const data = notificationList.length > 0 ? notificationList[ 0 ].message : '';
            const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.globalNotification,
              XPOConstants.keystoneEventAction.view, XPOConstants.keystonePage.globalNotification);
            this.keystoneService.logEvent(logEvent);
            this.dialogConfig.width = '450px';
            this.dialogConfig.data = data;
            this.dialog
              .open(AdminNotificationsComponent, this.dialogConfig)
              .afterClosed();
          }
        });
      }
    }
    this.signupBannerSubscription = this.sharedService.getShowSignUpBannerSubject().subscribe(value => {
      this.showSignUpBanner = value;
    });
  }

  public reloadCurrentRoute() {
    const currentRouteUrl = this.router.url;
    this.router.navigateByUrl(currentRouteUrl);
  }

  upgradeInfo() {
    this.loyaltyService.getUpgradeDetails().subscribe(data => {
      this.upgradeDetail = data || '';
      const config: MatDialogConfig = {
        width: '450px',
        autoFocus: false
      };
      if (data) {
        this.dialog.open(UpgradeNotificationComponent, config);
      }
    });
  }

  public hideMenu(): void {
    this.state.notifyDataChanged('menu.isCollapsed', true);
  }

  public menuClick(i: number) {
    this.topMenuSource.forEach((menu, index) => {
      menu.toggle = index !== i ? false : menu.toggle;
    });
    this.topMenuSource[ i ].toggle = !this.topMenuSource[ i ].toggle;
  }

  createSideMenu() {
    const topPermissionList = [];
    this.topMenuSource = JSON.parse(JSON.stringify(this.topMenuSource));
    if (this.permissions.length) {
      this.topMenuSource.forEach(p => {
        if (p.subItem.length) {
          const subItem = [];
          p.subItem.forEach(item => {
            if (item.permission.length) {
              item.permission.forEach(q => {
                if (this.permissions.some(t => t === q)) {
                  if (item.code === 'roles') {
                    if (screen.width >= 768) {
                      subItem.push(item);
                    }
                  } else {
                    subItem.push(item);
                  }
                }
              });
            }
          });
          if (subItem.length) {
            p.subItem = subItem;
            topPermissionList.push(p);
          }
        } else {
          if (p.permission.length) {
            p.permission.forEach(q => {
              if (this.permissions.some(t => t === q)) {
                topPermissionList.push(p);
              }
            });
          }
        }
      });
      this.topMenuSource = topPermissionList;
      this.permissions.forEach(t => {
        if (t === XPOConstants.permissionTypes.tracking) {
          this.authService.trackingPermission = 1;
        }
      });
    }
    if (this.topMenuSource) {
      this.topMenuSource.forEach(topMenuSource => {
        topMenuSource.name = this.translateService.instant(topMenuSource.translationKey);
        topMenuSource.subItem.forEach(subItem => {
          subItem.name = this.translateService.instant(subItem.translationKey);
        });
      });
    }
    this.sideMenuCreated = true;
  }

  showAcceptTermsAndConditionDialog() {
    this.dialog.open(AcceptTermsAndConditionsComponent);
  }

  private checkUnAuthPageConfig(): void {
    const currentURL = window.location.href;
    const pageConfig = XPOConstants.unAuthPageConfigs.find((config) => currentURL.indexOf(config.url) > -1);
    if (pageConfig) {
      this.hideNavBar = pageConfig.hideNavBar;
      this.showAlerts = pageConfig.showAlerts;
      if (this.hideNavBar) {
        this.isUnAuthMenu = false;
        this.showSignUpBanner = false;
        this.isQuickSignup = false;
      } else {
        if (!this.isLoggedIn) {
          this.isUnAuthMenu = pageConfig.showUnauthMenu;
          this.showSignUpBanner = pageConfig.showSignUpBanner;
          if (this.showSignUpBanner) {
            this.sharedService.setShowSignUpBannerSubject(true);
          }
          this.isQuickSignup = pageConfig.quickSignup;
        }
      }
    }
  }

  openSignupPromo(urlPath?: string) {
    if (this.isUnAuthMenu) {
      const config: MatDialogConfig = { autoFocus: false, data: { isQuickSignup: this.isQuickSignup } };
      if (urlPath) {
        const prevUrl = this.appSettings.apiUrl.replace('api/', 'carrier' + urlPath);
        config.data.prevUrl = prevUrl;
      }
      this.dialog.open(SignupPromoDialogComponent, config);
    }
  }

  signUp() {
    if (this.isQuickSignup && this.sharedService.partnerIdentifierUnAuth) {
      this.logKeystone(XPOConstants.keystoneEventName.navBarQuickSignUp, XPOConstants.keystoneEventAction.click);
      const config: MatDialogConfig = { autoFocus: false };
      this.dialog.open(QuickSignupDialogComponent, config);
    } else {
      this.logKeystone(XPOConstants.keystoneEventName.navBarSignUp, XPOConstants.keystoneEventAction.click);
      this.router.navigate([ '/registration' ], { queryParams: { source: this.router.url.split('?')[ 0 ].substr(1) } });
    }
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const page = this.router.url.split('?')[ 0 ].substr(1);
    const logEvent = new LogEvent(
      XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction, page, XPOConstants.keystoneSubPage.menu);
    this.keystoneService.logAnonymousEvent(logEvent);
  }

  ngOnDestroy() {
    if (this.navbarItemSubscription) {
      this.navbarItemSubscription.unsubscribe();
    }
    if (this.signupBannerSubscription) {
      this.signupBannerSubscription.unsubscribe();
    }
    if (this.carrierCompetionSubscription) {
      this.carrierCompetionSubscription.unsubscribe();
    }
  }
}
