<ul class="xpo-representative" *ngFor="let representative of representatives; let i=index;">
  <li>
    <div fxLayout="row">
      <div id="representative-name-title" fxFlex="40%">
        <b [translate]="'name9933'">Name</b>
      </div>
      <div id="representative-name" fxFlex="60%">
        <span>{{representative.name}}</span>
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row">

      <div id="representative-phone-title" fxFlex="40%">
        <b [translate]="'phone5880'">Phone</b>
      </div>
      <div id="representative-phone" fxFlex="60%">
        <span>{{representative.phoneNumber}}</span>
      </div>
    </div>
  </li>
  <li>
    <div fxLayout="row">
      <div id="representative-email-title" fxFlex="40%">
        <b [translate]="'email8704'">Email</b>
      </div>
      <div id=" representative-email" fxFlex="60%">
        <span>
          <a href="mailto:{{representative.email}} ">{{representative.email}}</a>
        </span>
      </div>
    </div>
  </li>
</ul>