export class LaneFilter {
    constructor(
        public location: string,
        public longitude: number,
        public latitude: number,
        public type?: string,
        public unit?: string,
        public radius?: number
    ) { }
    
}
