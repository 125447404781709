export class DateFilter {
  constructor(
    public pickStartDate: Date,
    public pickEndDate: Date,
    public deliveryStartDate: Date,
    public deliveryEndDate: Date,
    public receivedFromDate?: string,
    public receivedToDate?: string,
    public pickupStartDate?: string,
    public pickupEndDate?: string,
    // variables for Assigned Load
    public pickupStartDateString?: string,
    public pickupEndDateString?: string,
    public deliveryStartDateString?: string,
    public deliveryEndDateString?: string
  ) { }
}
