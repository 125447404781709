import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { KeystoneService } from 'src/app/shared/services';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model';
import { Validations } from '@xpoc/ngx-common';
import { AppSettings } from 'src/app/core/app-settings';
import { TermsAndConditionsComponent } from 'src/app/shared/terms-and-conditions/terms-and-conditions.component';
import { SharedService } from '../services/shared.service';
import { SelfRegistration } from 'src/app/self-registration/model/self-registration';
import { KeyValue } from '../model/key-value';
import { DashboardService } from '../../dashboard/services/dashboard.service';
import { RegistrationService } from 'src/app/self-registration/service/registration.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-quick-signup-dialog',
  templateUrl: './quick-signup-dialog.component.html',
  styleUrls: [ './quick-signup-dialog.component.scss' ]
})
export class QuickSignupDialogComponent implements OnInit {

  public quickSignupForm: FormGroup;
  public isLoading = true;
  public isFormValid = true;
  public xpoSiteKey: string;
  public captchaCallbackVal: string;
  public isSuccess: boolean;
  public isSubmitted: boolean;
  public supportContact: any;
  public phoneCountryCodes = XPOConstants.phoneCountryCodes;
  public regStatusCodes = XPOConstants.registrationStatusCodes;
  public selectedCountryCode: string;
  public eventIdentifiers: any;
  public isTermsAndConditions: boolean;
  public dotNumber: string;
  public mcNumber: string;
  public regResponse: any;
  public email: string;
  public isError = false;
  public statusCodes = XPOConstants.registrationStatusCodes;
  public request: SelfRegistration;
  public tenandId: number;
  public sourceComment: string;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(
    private dialogRef: MatDialogRef<QuickSignupDialogComponent>,
    private formBuilder: FormBuilder,
    private keystoneService: KeystoneService,
    private appSettings: AppSettings,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private registrationService: RegistrationService,
    private router: Router,
    public authService: AuthService
  ) {
    this.tenandId = this.authService.tenantId;
    this.dialogRef.disableClose = true;
    this.xpoSiteKey = this.appSettings.getApplicationConstant(XPOConstants.apiKeys.dimension5);
    this.isSubmitted = false;
    this.supportContact = {
      email: this.appSettings.getApplicationConstant('XpoConnectSupportEmailAddress'),
      emailSubject: this.appSettings.getApplicationConstant('XpoConnectCarrierSupportEmailSubject'),
      phone: this.appSettings.getApplicationConstant('XpoConnectSupportPhoneNumber')
    };
    this.sourceComment = `Quick Sign Up from connect webpage. Details on XPO Contract/Insurance are auto set by the system`;
    this.eventIdentifiers = this.data && this.data.eventIdentifiers ? this.data.eventIdentifiers : {};
  }

  ngOnInit() {
    this.quickSignupForm = this.createForm();
    const partnerIdentifier = this.sharedService.partnerIdentifierUnAuth;
    this.eventIdentifiers = { ...this.eventIdentifiers, partnerIdentifier };
    this.captchaCallbackVal = '';
    this.dashboardService.getCarrierDetails(partnerIdentifier).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.dotNumber = response.dotNumber || '';
        this.mcNumber = response.mcNumber || '';
      },
      error: (error) => {
        this.isLoading = false;
      }
    });
  }

  private createForm(): FormGroup {
    const newForm = this.formBuilder.group({
      recaptchaReactive: new FormControl(null),
      firstName: [ '', [ Validations.firstName, Validators.required ] ],
      lastName: [ '', [ Validations.lastName, Validators.required ] ],
      email: [ '', [ Validations.email, Validators.required ] ],
      countryCode: [ '', [ Validations.countryCode, Validators.required ] ],
      userPhone: [ '', [ Validations.phone, Validators.required ] ],
      termsAndConditions: [ '', Validators.required ]
    });
    return newForm;
  }

  onSelectCountryCode(event: MatSelectChange) {
    this.selectedCountryCode = event.value ? this.phoneCountryCodes.filter((item) => item.value === event.value)[ 0 ].name : '';
  }

  signIn() {
    this.dialogRef.close();
    this.logKeystone(XPOConstants.keystoneEventName.quickSignupSignIn, XPOConstants.keystoneEventAction.click);
    this.sharedService.setPreviousRoute();
    window.location.href = this.appSettings.apiUrl.replace('api/', 'session/create');
  }

  resolved(captchaResponse: string) {
    this.captchaCallbackVal = captchaResponse;
    this.quickSignupForm.get('recaptchaReactive').setErrors(null);
  }

  submitRegistration() {
    this.request = this.createRequest();
    this.logRegInitKeystone();
    this.registrationService.userRegistration(this.request).subscribe({
      next: ((response) => {
        this.regResponse = response;
        this.isSubmitted = true;
        if (this.regResponse.isSuccess) {
          this.logRegResponseKeystone();
        } else {
          this.logKeystone(XPOConstants.keystoneEventName.quickSignupFail, XPOConstants.keystoneEventAction.failed);
          this.isError = true;
        }
      }),
      error: (error) => {
        if (error && error.status === 400 && error.error) {
          if (error.error.message === XPOConstants.validationMessages.invalidPhoneNumber) {
            this.quickSignupForm.get('userPhone').setErrors({ invalidPhone: true });
            this.captchaElem.resetCaptcha();
            this.isSubmitted = false;
          } else if (error.error.message === XPOConstants.validationMessages.invalidCaptcha) {
            this.quickSignupForm.get('recaptchaReactive').setErrors({ invalidCaptcha: true });
            this.captchaCallbackVal = null;
            this.captchaElem.resetCaptcha();
            this.isSubmitted = false;
          } else {
            this.logKeystone(XPOConstants.keystoneEventName.quickSignupFail, XPOConstants.keystoneEventAction.failed);
            this.dialogRef.close(this.isSubmitted);
            this.router.navigate([ '/error-message' ]);
          }
        } else {
          this.logKeystone(XPOConstants.keystoneEventName.quickSignupFail, XPOConstants.keystoneEventAction.failed);
          this.dialogRef.close(this.isSubmitted);
          this.router.navigate([ '/error-message' ]);
        }
      }
    });
  }

  createRequest() {
    const xpoRequirements = true;
    const xpoContract = true;
    const formValues = this.quickSignupForm.value;
    const sourceComments = this.sourceComment.substring(0, 100);
    const bestDescribe = 'DISPATCHER';
    const xpoInfo = 'OTHER';
    const questionDetails = [];
    this.email = formValues.email;
    this.captchaCallbackVal = this.captchaCallbackVal ? this.captchaCallbackVal : '';
    questionDetails.push(new KeyValue('question-one', xpoContract.toString()));
    questionDetails.push(new KeyValue('question-two', xpoRequirements.toString()));
    questionDetails.push(new KeyValue('question-three', xpoRequirements.toString()));
    questionDetails.push(new KeyValue('question-four', xpoRequirements.toString()));
    const request = new SelfRegistration(formValues.firstName, formValues.lastName, formValues.countryCode + formValues.userPhone,
      this.email, this.dotNumber, questionDetails, this.captchaCallbackVal, bestDescribe, xpoInfo,
      sourceComments, this.mcNumber, xpoContract, xpoRequirements, xpoRequirements, xpoRequirements);
    return request;
  }

  public onClose() {
    const eventName = this.isSubmitted ? XPOConstants.keystoneEventName.quickSignupClose :
      XPOConstants.keystoneEventName.quickSignupCancel;
    const eventAction = this.isSubmitted ? XPOConstants.keystoneEventAction.click :
      XPOConstants.keystoneEventAction.cancel;
    this.logKeystone(eventName, eventAction);
    this.dialogRef.close(this.isSubmitted);
  }

  termsAndConditions(event: any) {
    this.isTermsAndConditions = event.checked;
  }

  openTermsConditions() {
    this.logKeystone(XPOConstants.keystoneEventName.quickSignupTerms, XPOConstants.keystoneEventAction.click);
    this.dialog.open(TermsAndConditionsComponent);
  }

  openPrivacyPolicy() {
    this.logKeystone(XPOConstants.keystoneEventName.quickSignupPrivacy, XPOConstants.keystoneEventAction.click);
    window.open(XPOConstants.privacyPolicyUrl, '_blank');
  }

  public reset() {
    this.logKeystone(XPOConstants.keystoneEventName.resetPasswordQuickSignup, XPOConstants.keystoneEventAction.click);
    this.registrationService.passwordRest({ emailAddress: this.email }).subscribe((result) => {
      this.onClose();
    });
  }

  public login() {
    this.logKeystone(XPOConstants.keystoneEventName.loginAfterQuickSignup, XPOConstants.keystoneEventAction.click);
    window.location.href = this.appSettings.apiUrl.replace('api/', 'session/create');
    this.onClose();
  }

  private logRegInitKeystone() {
    this.eventIdentifiers = {
      ...this.eventIdentifiers,
      ...this.request,
      countryCode: this.selectedCountryCode,
      role: this.request.roleCode,
      knownThrough: this.request.sourceCode,
      knownThroughOthersText: this.request.sourceComments,
      navigationSource: window.location.href,
      navigationSourceName: 'quick-signup'
    };
    this.logKeystone(XPOConstants.keystoneEventName.quickSelfRegistration, XPOConstants.keystoneEventAction.submit);
  }

  private logRegResponseKeystone() {
    const response = this.regResponse;
    let keystoneEventAction: string;
    if (response.isRMISEmailSent) {
      keystoneEventAction = XPOConstants.keystoneEventAction.rmisEmailSent;
    } else if (response.isUserExisting) {
      keystoneEventAction = XPOConstants.keystoneEventAction.passwordResetEmail;
    } else if (response.needsManualSetup) {
      if (response.isActive) {
        if (response.path === 'NOMATCHCARRIER' && response.registrationStatusCode === this.regStatusCodes.underAdminReview) {
          keystoneEventAction = XPOConstants.keystoneEventAction.adminApprovalNoMatchCarrier;
        } else if (response.path === 'SECONDARYCONTACTSAMECARRIER' &&
          response.registrationStatusCode === this.regStatusCodes.underAdminReview && !response.doesEmailExistsInPartnerMaster) {
          keystoneEventAction = XPOConstants.keystoneEventAction.adminApprovalSecondaryContact;
        } else if (response.path === 'SECONDARYCONTACTSAMECARRIER' &&
          response.registrationStatusCode === this.regStatusCodes.underAdminReview &&
          response.doesEmailExistsWithDifferentCarrierInPartnerMaster) {
          keystoneEventAction = XPOConstants.keystoneEventAction.adminApprovalDifferentCarrier;
        } else if ((response.path === 'NOMATCHCARRIER' || response.path === 'SECONDARYCONTACTSAMECARRIER')
          && response.registrationStatusCode === this.regStatusCodes.underSupportReview) {
          keystoneEventAction = XPOConstants.keystoneEventAction.adminNotSetupSupportReview;
        } else if (response.path === 'DIFFERENTCARRIER' && response.registrationStatusCode === this.regStatusCodes.underSupportReview) {
          keystoneEventAction = XPOConstants.keystoneEventAction.manualSetupActive;
        }
      } else {
        if (response.carrierStatusCode === 'ND') {
          keystoneEventAction = XPOConstants.keystoneEventAction.manualSetupNoDispatch;
        }
      }
    } else if (response.isSetupAndActive) {
      keystoneEventAction = XPOConstants.keystoneEventAction.automateCarrierSetup;
    } else if (response.isRequestWithinSLA) {
      keystoneEventAction = XPOConstants.keystoneEventAction.requestWithinSLA;
    }
    const { path, carrierStatusCode, registrationStatusCode } = response;
    this.eventIdentifiers = { ...this.eventIdentifiers, path, carrierStatusCode, registrationStatusCode };
    this.logKeystone(XPOConstants.keystoneEventName.quickSelfRegistration, keystoneEventAction);
  }

  private logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const page = this.router.url.split('?')[ 0 ].substr(1);
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction,
      page, XPOConstants.keystoneSubPage.quickSignupPopup, this.eventIdentifiers);
    this.keystoneService.logAnonymousEvent(logEvent);
  }
}
