
export class DispatchDashboardRequest {
  constructor(
    public latitude?: number,
    public location?: number,
    public longitude?: number,
    public radius?: number,
    public unit?: string
  ) { }
}
