import { Component, OnInit } from '@angular/core';
import { LogEvent } from 'src/app/shared/model';
import { XPOConstants } from 'src/app/shared';
import { Feedback } from 'src/app/shared/model/feedback';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { MatDialogRef } from '@angular/material/dialog';
import { KeystoneService } from 'src/app/shared/services';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: [ './feedback-form.component.scss' ]
})
export class FeedbackFormComponent implements OnInit {
  public userPhoneNumber: string;
  public userEmail: string;
  public feedbacks: Feedback[];

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private dialogRef: MatDialogRef<FeedbackFormComponent>,
    private keystoneService: KeystoneService) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.userPhoneNumber = this.authService.userProfile ? this.authService.userProfile.phoneNumber : '';
    this.userEmail = this.authService.userProfile ? this.authService.userProfile.email : '';
    this.initFeedbackForm();
  }

  initFeedbackForm() {
    this.feedbacks = [];
    this.feedbacks.push(new Feedback('question-one', ''));
    this.feedbacks.push(new Feedback('question-two', ''));
    this.feedbacks.push(new Feedback('question-three', ''));
    this.feedbacks.push(new Feedback('question-four', ''));
    this.feedbacks.push(new Feedback('question-five', ''));
    this.feedbacks.push(new Feedback('question-six', ''));
    this.feedbacks.push(new Feedback('question-seven', ''));
    this.feedbacks.push(new Feedback('question-eight', ''));
  }

  onSubmitFeedback() {
    this.dashboardService.sendCarrierFeedback(this.feedbacks).then((response) => {
      this.logKeystone(XPOConstants.keystoneEventAction.submit);
      this.dialogRef.close('submit');
    });
  }

  onClose() {
    this.logKeystone(XPOConstants.keystoneEventAction.cancel);
    this.dialogRef.close('canceled');
  }

  logKeystone(keystoneEventAction: string) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.feedback,
      keystoneEventAction, XPOConstants.keystonePage.feedback);
    this.keystoneService.logEvent(logEvent);
  }
}
