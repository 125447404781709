import { Load } from './load';

export class LoadResponse {
  constructor(
    public currentPage: number,
    public items: Load[],
    public pageSize: number,
    public totalItemCount: number,
    public totalPagesCount: number
  ) { }
}
