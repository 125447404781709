import { Injectable } from '@angular/core';
import { PartnerType, SystemId, XPOConstants } from '../shared/xpo-constants';
import { Subject } from 'rxjs';
import { Permission } from '../shared/model/permission.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public partnerIdentifier;
  public partnerCode;
  public partnerName;
  public partnerRole;
  public userProfile;
  public permissions;
  public isLoggedIn: boolean;
  public userPhoneNumber: string;
  public username: string;
  public businessUnit: string;
  public tenantId;
  public preferredCurrencyCode;
  public preferredLanguageCode;
  public homeLocation = '/dashboard/overview';
  public userProfileSubject = new Subject();
  private isNAUser: boolean;
  public trackingPermission: number;
  private availableLoadAccess: boolean;
  private myLoadAccess: boolean;
  private bidAccess: boolean;
  private trackingAccess: boolean;

  constructor() {
  }

  checkNAUser() {
    return this.isNAUser;
  }

  setIsNAUser(isNAUser: boolean) {
    this.isNAUser = isNAUser;
  }

  setPermissions(permissions: Permission[]) {
    this.permissions = permissions.map(permission => {
      return permission.code;
    });
  }
  checkModulePermission(data) {
    if (data) {
      if (this.permissions.findIndex(permission => permission === data) > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  checkAvailableLoadPermission() {
    return this.availableLoadAccess;
  }
  checkMyLoadPermission() {
    return this.myLoadAccess;
  }
  checkBidsPermission() {
    return this.bidAccess;
  }
  checkTrackingPermission() {
    return this.trackingAccess;
  }

  setUserProperties(userProfile) {
    this.partnerIdentifier = userProfile.currentPartner ? userProfile.currentPartner.partnerIdentifier : '';
    this.partnerCode = userProfile.currentPartner ? userProfile.currentPartner.partnerCode : '';
    this.partnerName = userProfile.currentPartner ? userProfile.currentPartner.partnerName : '';
    const partner = userProfile.partnerRoles.find(partner =>
      partner.partnerIdentifier === this.partnerIdentifier);
    this.partnerRole = partner ? partner.roleCode : null;
    this.userProfile = userProfile;
    this.username = userProfile.username;
    this.businessUnit = userProfile.businessUnit ? userProfile.businessUnit : '';
    this.userPhoneNumber = userProfile.phoneNumber;
    this.tenantId = userProfile.tenantId;
    this.preferredCurrencyCode = userProfile.preferredCurrencyCode;
    this.preferredLanguageCode = userProfile.preferredLanguageCode;
    this.setPermissions(userProfile.permissions);
    this.availableLoadAccess = this.checkModulePermission(XPOConstants.permissionTypes.availableLoads);
    this.myLoadAccess = this.checkModulePermission(XPOConstants.permissionTypes.assignedLoads);
    this.bidAccess = this.checkModulePermission(XPOConstants.permissionTypes.bids);
    this.trackingAccess = this.checkModulePermission(XPOConstants.permissionTypes.tracking);
    const partnerIdSplits = this.partnerIdentifier.split('-');
    if (partnerIdSplits && (partnerIdSplits[ 1 ] === PartnerType.vendor.toString())) {
      this.isLoggedIn = true;
      this.homeLocation = '/dashboard/overview';
    }
    this.userProfileSubject.next(this.userProfile);
  }

  getUserProfile(): Subject<any> {
    return this.userProfileSubject;
  }

}
