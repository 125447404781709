export class Traffic {
    authenticationResultCode: string;
    brandLogoUri: string;
    copyright: string;
    resourceSets: ResourceSet[];
    statusCode: number;
    statusDescription: string;
    traceId: string;
}

export class Point {
    type: string;
    coordinates: number[];
}

export class ToPoint {
    type: string;
    coordinates: number[];
}

export class Resource {
    __type: string;
    point: Point;
    description: string;
    end: Date;
    incidentId: any;
    lastModified: Date;
    roadClosed: boolean;
    severity: number;
    source: number;
    start: Date;
    toPoint: ToPoint;
    type: number;
    verified: boolean;
}

export class ResourceSet {
    estimatedTotal: number;
    resources: Resource[];
}

export class TrafficAlertItem {
    constructor(
        public description: string,
        public roadClosed: boolean,
        public lastModified: string,
        public index: number) { }
}
