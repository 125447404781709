<mat-dialog-content fxLayout="row" fxLayoutAlign="center center" class="content">
    <div>{{notificationMessage}}</div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" align="end" fxLayoutGap="15px">
    <form ngNoForm action="/session/end" method="POST">
        <button mat-raised-button id="logout-btn" type="submit" class="text-bold text-uppercase" (click)="logout()"
            color="accent">{{'logOut933' | translate}}</button>
    </form>
    <button mat-raised-button id="close-btn" type="submit" class="text-bold text-uppercase secondary-btn"
        (click)="closeNotification()">{{'close7039' | translate}}</button>
</mat-dialog-actions>