import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { XPOConstants } from '../shared/xpo-constants';
import { AppSettings } from '../core/app-settings';
import { ErrorService } from '../error-message/services/error.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {
  constructor(
    private appSettings: AppSettings,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  canActivate(id: string, data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // if the current route is public allow with out authentication.
      if (typeof data !== 'undefined' && data.isPublic) {
        return resolve(true);
      }
      // Authenticate user.
      this.authenticateUser(data)
        .then(response => {
          return resolve(response);
        })
        .catch(error => {
          this.authService.isLoggedIn = false;
          this.errorService.changeMessage(XPOConstants.errorCode.unAuthorized);
          this.router.navigate([ '/error-message' ]);
        });
    });
  }

  canActivateChild(id: string, data: any): Promise<boolean> {
    return this.canActivate(id, data);
  }

  // authenticateUser will check wthere  user is already logged in.
  authenticateUser(data?: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.authService.isLoggedIn) {
        const routeBeforeLogin = sessionStorage.getItem('routeBeforeLogin');
        const currentRoute = this.router.url;
        if (routeBeforeLogin && routeBeforeLogin !== currentRoute) {
          this.router.navigate([ routeBeforeLogin ]);
          sessionStorage.removeItem('routeBeforeLogin');
        }
        if (data && data.hasOwnProperty('permissionType')) {
          const isPermitted = this.checkPermission(
            this.authService.partnerRole,
            data
          );
          return resolve(isPermitted);
        } else if (data) {
          return resolve(true);
        }
      } else {
        this.authService.isLoggedIn = false;
        this.errorService.changeMessage(XPOConstants.errorCode.unAuthorized);
        this.router.navigate([ '/error-message' ]);
      }
    });
  }

  private checkPermission(role: string, data: any) {
    const permissions = this.authService.permissions;
    if (data) {
      if (permissions.findIndex(permission => permission === data.permissionType) > -1) {
        return true;
      } else {
        this.errorService.changeMessage(XPOConstants.errorCode.forbidden);
        this.router.navigate([ '/error-message' ]);
        return false;
      }
    }
  }
  redirectToLogin(currentLocation?: any) {
    if (currentLocation) {
      sessionStorage.setItem('routeBeforeLogin', currentLocation);
    }
    window.location.href = this.appSettings.apiUrl.replace(
      'api/',
      'session/create'
    );
  }

  redirectToError() {
    this.router.navigate([ '/error-message' ]);
  }

}
