/**
 * Model class which contains the google map config/constant properties
 */
export class MapConstants {
  // TODO Move it to environment.ts later
  static readonly AERIS_WEATHER_PI_URL = 'https://api.aerisapi.com/batch?';
  static readonly AERIS_CLIENT_ID = 'Nfmp4pjRCNeKG1g3UArmb';
  static readonly AERIS_CLIENT_SECRET = 'fYD0vzXhAvQOSIq1Qw0j7GhPucNraCPXPEfNDEQX';
  static readonly AERIS_API_KEY: string = MapConstants.AERIS_CLIENT_ID + '_' + MapConstants.AERIS_CLIENT_SECRET;

  // Right now centering the map to USA\Europe based on tenantId and setting the zoom level to 5
  // Once the markers are plotted, the zoom level will be changed to accommodate all the markers
  static readonly NA_DEFAULT_LOCATION: any = {
    lat: 39,
    lng: -95
  };

  static readonly EU_DEFAULT_LOCATION: any = {
    lat: 47.373878,
    lng: 8.545094
  };

  static readonly DEFAULT_ZOOM_LEVEL: number = 5;

  static readonly MAP_OPTIONS: any = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: true,
    gestureHandling: 'greedy',
    center: MapConstants.NA_DEFAULT_LOCATION,
    zoom: MapConstants.DEFAULT_ZOOM_LEVEL,
    minZoom: 3,
    scrollwheel: true,
    panControl: true,
    zoomControl: true
  };

  static readonly TRUCK_PATH_1: string = `
        M19.2,29.9h-6.4c-0.3,0-0.5-0.2-0.5-0.5V13.9c0-0.3,0.2-0.5,0.5-0.5h6.4c0.3,0,0.5,0.2,0.5,0.5v15.5
        C19.7,29.7,19.5,29.9,19.2,29.9z
        M13.5,10.3l-0.1-1c0,0,1-0.4,2.6-0.4s2.6,0.4,2.6,0.4l0,1l0.5-0.8c0,0,0.2-0.9,0-1.8C18.9,7,18,6.7,17.6,6.7
        s-2.2,0-3.1,0C14,6.7,13,7,12.9,7.8c-0.1,1,0.1,1.8,0.1,1.8L13.5,10.3z
        M13.2,10.2V10c0,0-0.3,0-0.6,0.1c-0.3,0.1-0.4,0.3-0.4,0.3s0,0.1,0.1,0.1C12.4,10.5,13.2,10.2,13.2,10.2z
        M18.8,10.2V10c0,0,0.3,0,0.6,0.1c0.3,0.1,0.4,0.3,0.4,0.3s0,0.1-0.1,0.1C19.6,10.5,18.8,10.2,18.8,10.2z
    `;
  static readonly TRUCK_PATH_2: string = `
        M14.6,13.2h4.2c0,0,0.4,0,0.4-0.4s-0.2-1.9-0.4-2.1c-0.2-0.2-2.4-0.4-2.8-0.4s-2.3,0.1-2.7,0.4
        c-0.4,0.3-0.4,2.2-0.4,2.2s-0.1,0.4,0.4,0.4C13.8,13.3,14.6,13.2,14.6,13.2z
    `;
  static readonly TRUCK_PATH_3: string = `
        M13.5,10.5l-0.1-1.2c0.2-0.1,1.2-0.4,2.6-0.4c1.4,0,2.4,0.3,2.6,0.4l-0.1,1.3c-0.7-0.2-2.2-0.3-2.5-0.3
        C15.6,10.3,14.2,10.4,13.5,10.5z
    `;
  static readonly CIRCLE_PATH: string = `
        M16,32C7.2,32,0,24.8,0,16C0,7.2,7.2,0,16,0c8.8,0,16,7.2,16,16C32,24.8,24.8,32,16,32z M16,0.1
            C7.2,0.1,0.1,7.2,0.1,16S7.2,31.9,16,31.9c8.8,0,15.9-7.1,15.9-15.9S24.8,0.1,16,0.1z
        M16,0.1C7.2,0.1,0.1,7.2,0.1,16c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9C31.9,7.2,24.8,0.1,16,0.1z
    `;
  static readonly TRUCK_PATH_1_COLOR: string = '#D8D8D8';
  static readonly TRUCK_PATH_2_COLOR: string = '#706F6F';
  static readonly TRUCK_PATH_3_COLOR: string = '#1D1D1B';

  // ETA status color
  static readonly ON_TIME_COLOR: string = '#008D36';
  static readonly POSSIBLE_DELAY_COLOR: string = '#E94E1B';
  static readonly LATE_COLOR: string = '#E30613';
  static readonly DELAY_COLOR: string = '#D50000';

  static readonly ON_TIME_STATUS: string = '#00c853';
  static readonly DELAY_STATUS: string = '#d50000';
  static readonly GREY_STATUS: string = '#858585';
  static readonly DEFAULT_STATUS: string = '#0091ea';

  static readonly ON_TIME_TEXT: string = 'On Time';
  static readonly POSSIBLE_DELAY_TEXT: string = 'Possible Delay';
  static readonly LATE_TEXT: string = 'Late';


  static readonly ON_TIME_COLOR_STRING: string = 'green';
  static readonly POSSIBLE_DELAY_COLOR_STRING: string = 'orange';
  static readonly LATE_COLOR_STRING: string = 'red';
  static readonly DEFAULT_TRUCK_COLOR_STRING: string = 'blue';
  static readonly GREY_TRUCK_COLOR_STRING: string = 'grey';
}
