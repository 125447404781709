<mat-sidenav-container class="container-side-nav" fullscreen>
  <mat-sidenav #sidenav mode="over" class="side-nav-container">
    <div class="connect-logo">
      <img src="assets/img/xpo-connect-logo.png" class="logo-block">
    </div>
    <ul>
      <li fxLayoutAlign="start center" *ngFor="let menu of topMenuSource;let i=index">
        <div *ngIf="menu.subItem.length > 0; else singleMenu" class="full-width">
          <div fxLayoutAlign="start center" class="padding-top-15 cursor-pointer" (click)="menuClick(i)">
            <span class="toggle-menu-icon">
              <i [ngClass]="{'icon-chevron-down':menu.toggle, 'icon-chevron-right' :!menu.toggle}"></i>
            </span>
            <span class="menu-name">{{menu.name | translate}}</span>
          </div>
          <div *ngIf="menu.toggle" class="mobile-sub-menu text-bold-capitalize">
            <ul>
              <li *ngFor="let subMenu of menu.subItem">
                <div fxLayout="row" class="cursor-pointer" (click)="sidenav.toggle()">
                  <a [routerLink]="[subMenu.link]" *ngIf="!isUnAuthMenu;else unAuthMenuItem">
                    {{ subMenu.name|translate}}
                    <span *ngIf="subMenu.isNewItem" class="new-menu">NEW</span>
                  </a>
                  <ng-template #unAuthMenuItem>
                    <span (click)="openSignupPromo(subMenu.link)">{{ subMenu.name | translate}}
                      <span *ngIf="subMenu.isNewItem" class="new-menu">NEW</span>
                    </span>
                  </ng-template>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ng-template #singleMenu>
          <div class="padding-top-15 cursor-pointer mobile-single-menu" (click)="sidenav.toggle()">
            <a [routerLink]="[menu.link]" *ngIf="!isUnAuthMenu;else unAuthSingleMenu">{{menu.name | translate}}</a>
            <ng-template #unAuthSingleMenu>
              <span (click)="openSignupPromo(menu.link)">{{menu.name | translate}}</span>
            </ng-template>
          </div>
        </ng-template>
      </li>
    </ul>
  </mat-sidenav>
  <div class="layout-container">
    <app-signup-banner *ngIf="showSignUpBanner" [isQuickSignup]="isQuickSignup"></app-signup-banner>
    <!-- TBD: Handle the conditions -->
    <app-advertisement-banner *ngIf="showBanner&&!isNAUser" [bannerData]="bannerData"></app-advertisement-banner>
    <div class="navbar-block">
      <ng-container *ngIf="!hideNavBar">
        <app-navbar [source]="topMenuSource" [isUnAuthMenu]="isUnAuthMenu" [isQuickSignup]="isQuickSignup"
          (sideNavTrigger)="sidenav.toggle()">
        </app-navbar>
        <div fxLayout="row" class="nav-details" *ngIf="isLoggedIn && !isUnsupportedBrowser">
          <span class="hamburger-menu cursor-pointer" fxHide fxShow.lt-lg fxLayoutAlign="center center">
            <i id="lines" class="icon-mobile_hamburger_thin" (click)="sidenav.toggle()"></i>
          </span>
          <app-bread-crumb [menuItems]="breadCrumbMenuItems" (subMenuItemTrigger)="reloadCurrentRoute()">
          </app-bread-crumb>
        </div>
        <div *ngIf="!this.isLoggedIn" fxLayout="row" fxLayoutAlign="center center" class="reward-details"
          (click)="signUp()" fxHide fxShow.lt-sm>
          <div fxLayout="row" fxLayoutAlign="center center" class="circle-icon">
            <i class="icon-star"></i>
          </div>
          <span class="text-bold">Get $50 FREE with XPO Rewards</span>
        </div>
      </ng-container>
    </div>
    <div class="content-container router-outlet-container" [ngClass]="{
      'unauth-container': hideNavBar, 
      'unauth-container-with-nav': !isLoggedIn && !hideNavBar && !showSignUpBanner, 
      'unauth-container-with-nav-banner': !isLoggedIn && !hideNavBar && showSignUpBanner,
      'banner-container':showBanner && !isNAUser}">
      <router-outlet></router-outlet>
    </div>
  </div>
  <appCustomLiveChatWidget *ngIf="isLoggedIn" [liveChatParameter]='liveChatParameter'>
  </appCustomLiveChatWidget>
  <app-cookie-banner></app-cookie-banner>
</mat-sidenav-container>