import { DateFilter } from './date-filter';
import { OptionFilter } from './option-filter';

export class LoadFilter {
    constructor(
        public searchString: string,
        public page: number,
        public pageSize: number,
        public dateFilter?: DateFilter,
        public optionFilter?: OptionFilter,
        public selectedStatusIds?: string[],
        public tripBrokerageStatusTypeCodes?: string[],
        public driverName?: string,
        public isDelayCheckCall?: boolean
    ) { }
}
