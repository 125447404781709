import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { DateTimePipe, MultiSelectModel, Validations } from '@xpoc/ngx-common';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { XPOConstants } from 'src/app/shared';
import { AvailableLoadsService, KeystoneService } from 'src/app/shared/services';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { LogEvent } from '../model';
import { PostTruckRequestParams } from '../model/post-truck-request-params';

@Component({
  selector: 'app-post-truck-dialog',
  templateUrl: './post-truck-dialog.component.html',
  styleUrls: [ './post-truck-dialog.component.scss' ]
})
export class PostTruckDialogComponent implements OnInit {
  public originOptions: any[];
  public postTruckForm: FormGroup;
  public destMultiSelectOptions: MultiSelectModel;
  public equipments: any[];
  private originSearchSubscription: Subscription;
  private destinationSearchSubscription: Subscription;
  public deadheads: any;
  public distanceUnit: string;
  public destinations: any;
  private now: number = new Date().getTime();
  public minDate: Date = this.addDays(this.now, 0);
  public maxStartDate: Date = this.addDays(this.now, 90);
  public minEndDate: Date = this.addDays(this.now, 0);
  public maxEndDate: Date = this.addDays(this.now, 7);
  public isNAUser: boolean;
  public isPostTruckSuccess: boolean = false;
  public email: boolean;
  private dateFormat: string;
  public defaultLaneRadius: any;



  constructor(
    private formBuilder: FormBuilder,
    public translateService: TranslateService,
    private availableLoadsService: AvailableLoadsService,
    private dialogRef: MatDialogRef<PostTruckDialogComponent>,
    private dateAdapter: DateAdapter<any>,
    private authService: AuthService,
    public datePipe: DateTimePipe,
    public keystoneService: KeystoneService
  ) {
    this.dialogRef.disableClose = true;
    this.deadheads = XPOConstants.deadheads;
  }

  ngOnInit() {
    this.email = true;
    this.isNAUser = this.authService.checkNAUser();
    this.distanceUnit = this.isNAUser ? XPOConstants.defaultDistanceUnit : XPOConstants.euDefaultDistanceUnit;
    this.defaultLaneRadius = this.isNAUser ? XPOConstants.defaultLaneRadius : XPOConstants.defaultLaneRadiusEU;
    this.initializePostTruckForm();
    this.getEquipmentList();
    this.originSearchSubscription = this.postTruckForm.get('origin').valueChanges.pipe(
      distinctUntilChanged()).subscribe({
        next: (origin: any) => { this.onOriginChange(origin); }
      });
    this.destinationSearchSubscription = this.postTruckForm.get('destination').valueChanges.pipe(
      distinctUntilChanged()).subscribe({
        next: (destination: any) => { this.onDestinationChange(destination); }
      });
    this.dateAdapter.setLocale('en-GB');
    this.dateFormat = XPOConstants.dateFormat;
  }

  private initializePostTruckForm() {
    this.postTruckForm = this.formBuilder.group({
      origin: [ '', [ Validations.required ] ],
      destination: [ '' ],
      originRadius: this.defaultLaneRadius,
      destinationRadius: this.defaultLaneRadius,
      startDate: [ '', [ Validations.required ] ],
      endDate: [ '', [ Validations.required ] ],
      equipment: [ '', [ Validations.required ] ]
    });
    this.postTruckForm.get('endDate').disable();
  }

  getEquipmentList() {
    this.equipments = this.dialogRef._containerInstance._config.data.equipmentDetails;
  }


  private onOriginChange(location: any) {
    if (location) {
      let locationValue: string;
      if (typeof location !== 'object') {
        locationValue = location.trim();
        this.getLocations(locationValue).then((locations: any[]) => {
          this.getOriginOptions(locations);
        });
      }
    }
  }
  private onDestinationChange(location: any) {
    if (location) {
      let locationValue: string;
      if (typeof location === 'object') {
        const locationType = this.isNAUser ? XPOConstants.locationTypes.state : XPOConstants.locationTypes.country;
        if (location.type === locationType) {
          this.postTruckForm.get('destinationRadius').disable();
        } else {
          this.postTruckForm.get('destinationRadius').enable();
        }
      } else {
        locationValue = location.trim();
        this.getLocations(locationValue).then((locations: any[]) => {
          this.getDestinationOptions(locations);
        });
      }
    } else {
      this.postTruckForm.get('destinationRadius').enable();
    }
  }

  getLocations(searchText: string) {
    return this.availableLoadsService.getLocationAutoComplete(searchText.trim());
  }


  getAutocompleteDisplayValue(option: any): string {
    return option && option.label ? option.label : '';
  }
  getEquipmentDisplayValue(option: any): string {
    return option && option.name ? option.name : '';
  }

  addDays(date: number, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  postTruck() {
    const postTruckRequestObj: PostTruckRequestParams = {
      businessUnitCode: XPOConstants.businessUnitCode,
      carrierCode: this.authService.partnerIdentifier,
      userIdentifier: this.authService.username,
      originLocations: this.getLocationDetails(this.postTruckForm.value.origin, true),
      destinationLocations: this.getLocationDetails(this.postTruckForm.value.destination, false),
      equipmentCategory: this.postTruckForm.value.equipment.name,
      startDate: this.getFormattedTime(true),
      endDate: this.getFormattedTime(false),
      isAvailable: true,
      isSms: false,
      isEmail: this.email,
    };
    this.availableLoadsService.updatePostTruckDetails(postTruckRequestObj).subscribe(response => {
      if (response) {
        this.logKeystone(XPOConstants.keystoneEventName.postTruckSubmit,
          XPOConstants.keystoneEventAction.submit);
        this.dialogRef.updateSize("308px", "auto");
        this.isPostTruckSuccess = true;
      }
    });
  }

  closeDialog() {
    this.logKeystone(XPOConstants.keystoneEventName.postTruckCancel,
      XPOConstants.keystoneEventAction.click);
    this.dialogRef.close();
  }

  private getOriginOptions(locations) {
    const cityType = XPOConstants.locationTypes.city;
    const zipCodeType = this.isNAUser ? XPOConstants.locationTypes.zipcode : XPOConstants.euZipcodeType;
    const filteredOptions = locations.filter(origin => origin.type === zipCodeType || origin.type === cityType);
    this.originOptions = filteredOptions && filteredOptions.length ? filteredOptions : [];
  }

  private getDestinationOptions(locations) {
    const locationType = this.isNAUser ? XPOConstants.locationTypes.country : XPOConstants.locationTypes.state;
    const filteredOptions = locations.filter(origin => origin.type !== locationType);
    this.destinations = filteredOptions && filteredOptions.length ? filteredOptions : [];
  }

  validateOrigin() {
    const originValue = this.postTruckForm.get('origin').value;
    if (originValue && typeof originValue !== 'object') {
      this.postTruckForm.patchValue({
        'origin': '',
      });
    }
  }

  validateDestination() {
    const destinationValue = this.postTruckForm.get('destination').value;
    if (destinationValue && typeof destinationValue !== 'object') {
      this.postTruckForm.patchValue({
        'destination': '',
      });
    }
  }

  startDateSelected(dateSelected) {
    this.postTruckForm.patchValue({
      'endDate': '',
    });
    this.postTruckForm.get('endDate').disable();
    this.now = new Date(dateSelected.value).getTime();
    this.minEndDate = this.addDays(this.now, 0)
    this.maxEndDate = this.addDays(this.now, 7);
    this.postTruckForm.get('endDate').enable();
  }

  onClear() {
    this.logKeystone(XPOConstants.keystoneEventName.postTruckClear,
      XPOConstants.keystoneEventAction.click);
    this.postTruckForm.reset();
    this.postTruckForm.patchValue({
      'originRadius': this.defaultLaneRadius,
      'destinationRadius': this.defaultLaneRadius,
    });
    this.postTruckForm.get('endDate').disable();
  }

  private getLocationDetails(location, isOrigin) {
    let locationDetailsObj: any = {};
    if (location) {
      let formattedLocationValue = location.value.split(',');
      locationDetailsObj.radius = isOrigin ? this.postTruckForm.get('originRadius').value :
        this.postTruckForm.get('destinationRadius').value;
      locationDetailsObj.latitude = location.lat;
      locationDetailsObj.longitude = location.lon;
      if (location.type == XPOConstants.locationTypes.city) {
        locationDetailsObj.city = formattedLocationValue[ 0 ];
        locationDetailsObj.state = formattedLocationValue[ 1 ].trim();
        locationDetailsObj.country = this.isNAUser ? XPOConstants.defaultCountryCode : formattedLocationValue[ 2 ].trim();
        locationDetailsObj.zipCode = "";
      } else if (location.type == XPOConstants.locationTypes.country) {
        locationDetailsObj.city = "";
        locationDetailsObj.state = "";
        locationDetailsObj.country = this.isNAUser ? XPOConstants.defaultCountryCode : location.value.trim();
        locationDetailsObj.zipCode = "";
      }
      else if (location.type == XPOConstants.locationTypes.state) {
        locationDetailsObj.city = "";
        locationDetailsObj.state = location.value.trim();
        locationDetailsObj.country = "";
        locationDetailsObj.zipCode = "";
      } else {
        locationDetailsObj.city = formattedLocationValue[ 0 ].split('(')[ 1 ];
        locationDetailsObj.state = this.isNAUser ? formattedLocationValue[ 1 ].split(')')[ 0 ].trim() : formattedLocationValue[ 1 ].trim();
        locationDetailsObj.country = this.isNAUser ? XPOConstants.defaultCountryCode : formattedLocationValue[ 2 ].split(')')[ 0 ].trim();
        locationDetailsObj.zipCode = formattedLocationValue[ 0 ].split('(')[ 0 ].trim();
      }
    } else {
      locationDetailsObj = {};
    }

    return locationDetailsObj;
  }

  emailToggle(event: MatSlideToggleChange) {
    this.email = event.checked;
  }

  getFormattedTime(isStartDate) {
    let unformattedDate = isStartDate ? this.postTruckForm.value.startDate : this.postTruckForm.value.endDate;
    let formattedDate = isStartDate ? this.datePipe.transform(unformattedDate, this.dateFormat) + ' ' + XPOConstants.postTruckTimeFormat.startTime :
      this.datePipe.transform(unformattedDate, this.dateFormat) + ' ' + XPOConstants.postTruckTimeFormat.endTime;
    return formattedDate;
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string, eventIdentifiers?: any) {
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction,
      XPOConstants.keystonePage.availableLoads, this.availableLoadsService.selectedTabConfig.code, eventIdentifiers);
    this.keystoneService.logEvent(logEvent);
  }

  ngOnDestroy() {
    if (this.originSearchSubscription) {
      this.originSearchSubscription.unsubscribe();
    }
    if (this.destinationSearchSubscription) {
      this.destinationSearchSubscription.unsubscribe();
    }
  }
}
