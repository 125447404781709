import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-error-dialog',
  templateUrl: './success-error-dialog.component.html',
  styleUrls: [ './success-error-dialog.component.scss' ]
})
export class SuccessErrorDialogComponent implements OnDestroy, OnInit {

  public title: string;
  public message: string;
  public isSuccess = false;
  public isError = false;
  public actionText = 'ok757';
  public isAutoClosingEnabled = false;
  public autoCloseDuration = 5000;
  public autoCloseTimer;

  constructor(
    private dialogRef: MatDialogRef<SuccessErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = this.data.message;
    this.title = this.data.title;
    this.isSuccess = typeof this.data.isSuccess !== 'undefined' ? this.data.isSuccess : false;
    this.isError = typeof this.data.isError !== 'undefined' ? this.data.isError : false;
    this.isAutoClosingEnabled = typeof this.data.isAutoClosingEnabled !== 'undefined' ? this.data.isAutoClosingEnabled : false;
    this.autoCloseDuration = ((typeof this.data.autoCloseDuration !== 'undefined') && this.isAutoClosingEnabled) ?
      this.data.autoCloseDuration : this.autoCloseDuration;
    if (this.isAutoClosingEnabled) {
      this.startTimer();
    }
  }

  ngOnInit() {
  }

  private startTimer() {
    this.autoCloseTimer = setTimeout(() => { this.onClose(); }, this.autoCloseDuration);
  }

  private clearTimer() {
    if (this.autoCloseTimer) {
      clearTimeout(this.autoCloseTimer);
    }
  }

  public onClose() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.clearTimer();
  }
}
