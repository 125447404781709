import { Location } from './location';

export class DashboardLoad {
  constructor(
    public loadNumber: number,
    public origin: Location,
    public rate: number,
    public ratePerMile: number,
    public ratePerTotalMiles: number,
    public description: string,
    public pieces: number,
    public weight: number,
    public equipmentType: string,
    public containerNumber: string,
    public trailerNumber: string
  ) { }
}
