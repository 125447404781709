import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: [ './circle-progress.component.scss' ]
})
export class CircleProgressComponent implements OnChanges {
  @Input() isBig = false;
  @Input() value = 0;
  @Input() color = 'green';
  @Input() shouldShowValue = true;
  constructor() { }

  ngOnChanges() {
    this.value = Math.round(this.value);
  }

}
