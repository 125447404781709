<div class="add-driver-container" *ngIf="!showSucessMsg; else successMsg">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div [ngSwitch]="dialogAction">
        <span class="text-bold-uppercase" *ngSwitchCase=userActions.create [translate]="'create8840'">
          create driver
        </span>
        <span class="text-bold-uppercase" *ngSwitchCase=userActions.edit [translate]="'update6341'">
          update driver
        </span>
      </div>
      <span class="dialog-close-icon">
        <i class="icon-close" (click)="onClose()"></i>
      </span>
    </div>
    <div *ngIf="!assetAction" class="error-message" fxLayout="row" fxLayoutGap="5px">
      <div class="icon-exclaim"><i class="icon-exclaim_btn"></i></div>
      <div [translate]="'youdonothav2323'" *ngIf="this.dialogAction === this.userActions.create ;else editDriver">You do
        not have permission to to add new driver.</div>
      <ng-template #editDriver>
        <div [translate]="'youdonothav3305'">You do not have permission to to edit/delete driver.</div>
      </ng-template>
    </div>
  </div>
  <div class="required-field">
    <span class="required">* </span>
    <span [translate]="'requir4016'">Required fields</span>
  </div>
  <form [formGroup]="driverForm">
    <mat-dialog-content>
      <div class="add-driver-form-container" fxLayout="column">
        <mat-form-field class="form-field-input" appearance="outline">
          <mat-label [translate]="'role6898'">Role</mat-label>
          <mat-select id="role" name="role" formControlName="role" (selectionChange)="onSelectRole($event)">
            <ng-container *ngFor="let roles of driverRoles">
              <mat-option *ngIf="roles.isActive || isNAUser" [value]="roles.code">
                {{roles.name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-field-input" appearance="outline">
          <mat-label>{{'firstName9858' | translate}}</mat-label>
          <input matInput size="100" id="firstName" name="firstName" formControlName="firstName" required>
          <mat-error
            *ngIf="driverForm.controls.firstName.errors &&
                       (driverForm.controls.firstName.errors.invalidName || driverForm.controls.firstName.errors.required)">
            <span [translate]="'entervalidf5618'">Enter valid first name</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field-input" appearance="outline">
          <mat-label>{{'lastName1947' | translate}}</mat-label>
          <input matInput size="100" id="lastName" name="lastName" formControlName="lastName" required>
          <mat-error
            *ngIf="driverForm.controls.lastName.errors && 
                      (driverForm.controls.lastName.errors.invalidName || driverForm.controls.lastName.errors.required)">
            <span [translate]="'entervalidl6237'">Enter valid last name</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field-input" appearance="outline">
          <mat-label>{{emailAddressPlaceholder}}</mat-label>
          <input matInput size="100" id="emailAddress" name="emailAddress" formControlName="emailAddress" required>
          <mat-error
            *ngIf="driverForm.controls.emailAddress.errors &&
                       (driverForm.controls.emailAddress.errors.invalidEmail || driverForm.controls.emailAddress.errors.required)">
            <span [translate]="'entervalide8568'"> Enter valid email </span>
          </mat-error>
        </mat-form-field>
        <div *ngIf="isNAUser;else euPhoneBlock;">
          <mat-form-field class="form-field-input" appearance="outline">
            <mat-label>{{'phoneNumber9259' | translate}}</mat-label>
            <input matInput size="100" id="phoneNumber" name="phoneNumber" formControlName="phoneNumber"
              (keyup)="formatPhone()" required>
            <mat-error *ngIf="driverForm.controls.phoneNumber.errors && 
            (driverForm.controls.phoneNumber.errors.invalidPhone || driverForm.controls.phoneNumber.errors.required)">
              <span [translate]="'enterV5093'">Enter valid phone number</span>
            </mat-error>
          </mat-form-field>
        </div>
        <ng-template #euPhoneBlock>
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="35%">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label class="text-capitalize" [translate]="'countryCode5026'">Country Code</mat-label>
                <mat-select id="countryCode" name="countryCode" formControlName="countryCode" required>
                  <mat-option *ngFor="let country of countryList" [value]="country.callingCode">
                    {{country.description}}&nbsp;{{country.callingCode}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="65%">
              <mat-form-field class="form-field-input" appearance="outline">
                <mat-label>{{'phoneNumber9259' | translate}}</mat-label>
                <input matInput size="100" id="phoneNumber" name="phoneNumber" formControlName="phoneNumber"
                  (keyup)="formatPhone()" required>
              </mat-form-field>
            </div>
          </div>
          <mat-error *ngIf="(driverForm.controls.countryCode.errors || driverForm.controls.phoneNumber.errors)">
            <span *ngIf="(driverForm.controls.phoneNumber.touched && driverForm.controls.phoneNumber.errors && 
            (driverForm.controls.phoneNumber.errors.invalidPhone && !driverForm.controls.phoneNumber.errors.required))"
              [translate]="'enterV5093'">
              Enter valid phone number
            </span>
            <span *ngIf="((driverForm.controls.countryCode.touched || driverForm.controls.phoneNumber.touched) &&
            ((driverForm.controls.countryCode.errors && driverForm.controls.countryCode.errors.required) ||
             (driverForm.controls.phoneNumber.errors && driverForm.controls.phoneNumber.errors.required 
             && !driverForm.controls.phoneNumber.errors.invalidPhone))
            )" [translate]="'countr9700'">
              Phone requires both a Country Code and Phone Number
            </span>
          </mat-error>
        </ng-template>
      </div>
    </mat-dialog-content>
  </form>
  <mat-dialog-actions align="end" *ngIf="dialogAction!= userActions.edit;else updateDriverOptions;">
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-raised-button class="text-uppercase secondary-btn" type="button" [disabled]="disableBtns"
          (click)="onClear()">
          {{'clear3546' | translate}}
        </button>
        <button mat-raised-button class="text-uppercase" (click)="createNewDriver()" type="button"
          [disabled]="!driverForm.valid || disableBtns || !assetAction" color="accent">
          {{submitBtn | translate}}
        </button>
      </div>
      <div *ngIf="isUserExist">
        <mat-error [translate]="'userAl5767'">User already exist
        </mat-error>
      </div>
    </div>
  </mat-dialog-actions>
</div>
<ng-template #updateDriverOptions>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
    class="padding-bottom-15">
    <div>
      <button mat-raised-button class="text-uppercase wrapped-btn padding-5 update-action-btn" type="button"
        [disabled]="disableBtns || !assetAction" (click)="deleteDriver()" color="accent">
        {{'delete2640' | translate}}
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <button mat-raised-button class="text-uppercase update-action-btn secondary-btn" type="button"
        [disabled]="disableBtns" (click)="cancelDriverEdit()">
        {{'cancel7093' | translate}}
      </button>
      <button mat-raised-button class="text-uppercase update-action-btn" (click)="updateDriver()" type="button"
        [disabled]="!driverForm.valid || disableBtns || !assetAction" color="accent">
        {{'update8478' | translate}}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #successMsg>
  <div class="driver-sucess-msg" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
    <i class="icon-Yes"></i>
    <span class="text-capitalize">
      {{successMessage | translate}}
    </span>
  </div>
</ng-template>