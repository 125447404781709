import { Component, OnInit, Input } from '@angular/core';
import { KeystoneService } from 'src/app/shared/services';
import { XPOConstants } from 'src/app/shared';
import { LogEvent } from 'src/app/shared/model';
import { Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { QuickSignupDialogComponent } from '../quick-signup-dialog/quick-signup-dialog.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/core/app-settings';

@Component({
  selector: 'app-signup-banner',
  templateUrl: './signup-banner.component.html',
  styleUrls: [ './signup-banner.component.scss' ]
})
export class SignupBannerComponent implements OnInit {

  public isClosed: boolean;
  @Input() isQuickSignup: boolean;
  public banner: any;
  public bannerImage: string;

  constructor(
    private keystoneService: KeystoneService,
    private sharedService: SharedService,
    private router: Router,
    private dialog: MatDialog,
    private appSettings: AppSettings
  ) { }

  ngOnInit() {
    this.isClosed = false;
    const num = Math.floor(Math.random() * XPOConstants.rateConBanner.length);
    this.banner = XPOConstants.rateConBanner[ num ];
    this.bannerImage = 'assets/img/' + this.banner.banner;
  }

  closeBanner() {
    this.sharedService.setShowSignUpBannerSubject(false);
    this.logKeystone(XPOConstants.keystoneEventName.signupBannerClose, XPOConstants.keystoneEventAction.cancel);
    this.isClosed = true;
  }

  signUp() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      this.logKeystone(XPOConstants.keystoneEventName.androidBannerClick, XPOConstants.keystoneEventAction.click);
      window.open(XPOConstants.driveXPOUrls.android);
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      this.logKeystone(XPOConstants.keystoneEventName.iosBannerClick, XPOConstants.keystoneEventAction.click);
      window.open(XPOConstants.driveXPOUrls.ios);
    } else if (this.isQuickSignup && this.sharedService.partnerIdentifierUnAuth) {
      this.logKeystone(XPOConstants.keystoneEventName.quickSignupBannerClick, XPOConstants.keystoneEventAction.click);
      const config: MatDialogConfig = { autoFocus: false };
      this.dialog.open(QuickSignupDialogComponent, config);
    } else {
      this.logKeystone(XPOConstants.keystoneEventName.signupBannerClick, XPOConstants.keystoneEventAction.click);
      this.router.navigate([ '/registration' ], { queryParams: { source: this.router.url.split('?')[ 0 ].substr(1) } });
    }
  }

  signIn() {
    this.logKeystone(XPOConstants.keystoneEventName.signupBannerSignIn, XPOConstants.keystoneEventAction.click);
    this.sharedService.setPreviousRoute();
    window.location.href = this.appSettings.apiUrl.replace('api/', 'session/create');
  }

  openLink(link: string) {
    if (link) {
      this.logKeystone(XPOConstants.keystoneEventName.bannerLinkClick, XPOConstants.keystoneEventAction.click);
      if (link.startsWith("http")) {
        window.open(link, "_blank");
      } else {
        window.location.href = this.appSettings.apiUrl.replace('api/', link);
      }
    }
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const page = this.router.url.split('?')[ 0 ].substr(1);
    const eventIdentifiers = {
      bannerCode: this.banner.code,
      bannerMessage: this.banner.message,
      bannerImage: this.banner.banner,
      partnerIdentifier: this.sharedService.partnerIdentifierUnAuth,
      orderNumber: this.sharedService.orderNumberUnAuth
    };
    const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction,
      page, XPOConstants.keystoneSubPage.signupBanner, eventIdentifiers);
    this.keystoneService.logAnonymousEvent(logEvent);
  }
}

