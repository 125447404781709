<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <span class="text-bold-uppercase" *ngIf="data.dialogTitle">{{data.dialogTitle}}</span>
    <button mat-icon-button (click)="onClose()" class="margin-left-auto"><i class="icon-close"></i></button>
</div>
<mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="text-align-center">
        <ng-container *ngIf="data.dialogIcon" [ngSwitch]="data.dialogIcon">
            <i *ngSwitchCase="'success_icon'" class="icon-Yes success-icon"></i>
            <i *ngSwitchCase="'error_icon'" class="icon-info1 error-icon"></i>
        </ng-container>
        <div class="status-heading">{{data.dialogMsg}}</div>
        <div class="status-sub">{{data.dialogSubMsg}}</div>
    </div>
</mat-dialog-content>