import { Location } from './location';

export class TruckLoad {
  public currentlocationWeather?: CurrentLocationWeather;
  constructor(
    public truckNumber: number,
    public orderNumber: number,
    public status: string,
    public estimatedTimeOfArrivalStatus: string,
    public origin: string,
    public destination: string,
    public originPickupDate: string,
    public originPickupTime: string,
    public destinationDeliveryDate: string,
    public destinationDeliveryTime: string,
    public currentLocation: Location,
    public nextStop: Location,
    public estimatedTimeOfArrivalToNextStopDate: string,
    public estimatedTimeOfArrivalToNextStopTime: string,
    public estimatedTimeOfArrivalToNextStopTimeStamp: string,
    public stopStatus?: string,
    public loadIdentifier?: number,
    public originLocation?: Location,
    public destinationLocation?: Location,
    public isTrackable?: boolean,
    public driverName?: string,
    public equipmentCategoryName?: string,
    public destinationScheduledArrivalEarly?: string,
    public tripNumber?: string,
    public isTravelOrder?: boolean,
    public tripCode?: string
  ) { }
}

export class CurrentLocationWeather {
  public weatherIcon: string;
  public weatherText: string;
  public weatherTextShort: string;
  public weatherPlace: string;
  public dateTime: string;
}
