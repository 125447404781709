import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { XPOConstants } from '../shared';
import { UserAuthenticationService } from './user-authentication.service';
import { AuthService } from './auth.service';
import { ErrorService } from '../error-message/services/error.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { SessionMessageComponent } from './session-message/session-message.component';
import { SharedService } from '../shared/services/shared.service';
import { AppSettings } from '../core/app-settings';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  public dialogConfig: MatDialogConfig;

  constructor(
    private userAuthService: UserAuthenticationService,
    private errorService: ErrorService,
    private authService: AuthService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private appSetttings: AppSettings,
    public cookieService: CookieService
  ) {
    this.dialogConfig = new MatDialogConfig();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipRedirect = request.headers.get('skipRedirect');
    request = request.clone({
      headers: request.headers.delete('skipRedirect')
    });
    if (this.appSetttings.apiKey !== '') {
      const xApiKey = this.appSetttings.apiKey;
      request = request.clone({
        headers: request.headers.set('x-api-key', `${xApiKey}`)
      });
    }
    const userToken = this.cookieService.get("connectToken");
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${userToken}`)
    });
    console.log('intercept request header with token ', request);

    if (this.authService.userProfile != null) {
      const tokenExpiry = this.authService.userProfile.sessionExpiry;
      if (tokenExpiry) {
        const tokenExpiryDate = new Date(tokenExpiry).getTime();
        const currentDateTime = Date.now();
        if (tokenExpiryDate < currentDateTime) {
          this.dialogConfig.data = {
            idleState: true,
            lastPing: null,
            timedOut: true
          };
          this.dialog.open(SessionMessageComponent, this.dialogConfig);
          return EMPTY;
        }
      }
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // do changes to response if you want
        }
      }, (error: any) => {
        if (error instanceof HttpErrorResponse) {
          /** If the error code is Unauthorized or Forbidden user should always be redirected */
          if (error.status === XPOConstants.errorCode.unAuthorized ||
            error.status === XPOConstants.errorCode.forbidden || !skipRedirect) {
            if (error.status === XPOConstants.errorCode.unAuthorized) {
              // saving previous route if it's not an error page
              if (window.location.href.indexOf('error') === -1) {
                this.sharedService.setPreviousRoute();
              }
              this.authService.isLoggedIn = false;
            }
            if (error.status !== XPOConstants.errorCode.entityError) {
              const errorCode = error.status ? error.status : null;
              this.errorService.changeMessage(errorCode);
              this.userAuthService.redirectToError();
            }
          }
        }
      }));
  }
}
