<div fxLayout="row">
  <div fxFlex="80%">
    <h3 [translate]="'feedbackand8791'" mat-dialog-title class="text-uppercase">
      feedback and suggestions</h3>
  </div>
  <div fxFlex="20%" fxLayoutAlign="end center">
    <i class="icon-close" (click)="onClose()"></i>
  </div>
</div>
<div class="feedback-dialog-content">
  <form #feebackForm="ngForm" (ngSubmit)="onSubmitFeedback()">
    <mat-dialog-content class="question-block">
      <div class="feedback-optional-question">
        <div>
          <p>
            <span>1. </span>
            <span [translate]="'whatwouldyo6712'">What would you like us to know about
              your experience using XPO Connect</span>
            <span>?</span>
          </p>
          <mat-form-field class="form-field-input">
            <input matInput [(ngModel)]="feedbacks[0].response"  id="feedback-q1"  name="feedback-q1"
               placeholder="{{'pleaseenter8215' | translate}}" />
          </mat-form-field>
        </div>
        <div>
          <p>
            <span>2. </span>
            <span [translate]="'whatwouldma4250'">What would make you more likely to book more loads using XPO
              Connect</span>
            <span>?</span>
          </p>
          <mat-form-field class="form-field-input">
            <input matInput [(ngModel)]="feedbacks[1].response" id="feedback-q2"  name="feedback-q2"
               placeholder="{{'pleaseenter8215' | translate}}" />
          </mat-form-field>
        </div>
      </div>
      <div class="feedback-option-block">
        <div fxLayout="row" fxLayout.lt-md="column" class="feedback-option-head">
          <div fxFlex="45%"></div>
          <div fxFlex="55%" fxLayoutAlign="space-between center">
            <div [translate]="'stronglyDis299'">Strongly Disagree</div>
            <div [translate]="'stronglyAgr2025'">Strongly Agree</div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="question-head">
          <div [translate]="'pleaseranky3729'" fxFlex="45%">
            Please rank your level of agreement with each statement:
          </div>
          <div fxFlex="55%" class="rankings mobile-ranking">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div class="ranking-block"> 1</div>
              <div class="ranking-block"> 2</div>
              <div class="ranking-block"> 3</div>
              <div class="ranking-block"> 4</div>
              <div class="ranking-block"> 5</div>
              <div class="ranking-block"> 6</div>
              <div class="ranking-block"> 7</div>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="question-white-bg">
          <div fxFlex="45%">
            <span>3. </span>
            <span [translate]="'thiswebsite4446'">This web site meets my requirements.</span>
          </div>
          <div fxFlex="55%" class="mobile-ranking">
            <mat-radio-group fxLayout="row" fxLayoutAlign="space-between center" [(ngModel)]="feedbacks[2].response"
              id="feedback-q3"  name="feedback-q3" class="hide-label">
              <mat-radio-button value="1"></mat-radio-button>
              <mat-radio-button value="2"></mat-radio-button>
              <mat-radio-button value="3"></mat-radio-button>
              <mat-radio-button value="4"></mat-radio-button>
              <mat-radio-button value="5"></mat-radio-button>
              <mat-radio-button value="6"></mat-radio-button>
              <mat-radio-button value="7"></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="question-grey-bg">
          <div fxFlex="45%">
            <span>4. </span>
            <span [translate]="'usingthiswe8633'">Using this web site is a frustration experience.</span>
          </div>
          <div fxFlex="55%" class="mobile-ranking">
            <mat-radio-group fxLayout="row" fxLayoutAlign="space-between center" [(ngModel)]="feedbacks[3].response"
              id="feedback-q4"  name="feedback-q4" class="hide-label">
              <mat-radio-button value="1"></mat-radio-button>
              <mat-radio-button value="2"></mat-radio-button>
              <mat-radio-button value="3"></mat-radio-button>
              <mat-radio-button value="4"></mat-radio-button>
              <mat-radio-button value="5"></mat-radio-button>
              <mat-radio-button value="6"></mat-radio-button>
              <mat-radio-button value="7"></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="question-white-bg">
          <div fxFlex="45%">
            <span>5. </span>
            <span [translate]="'thiswebsite8352'">This web site is easy to use.</span>
          </div>
          <div fxFlex="55%" class="mobile-ranking">
            <mat-radio-group fxLayout="row" fxLayoutAlign="space-between center" [(ngModel)]="feedbacks[4].response"
              id="feedback-q5"  name="feedback-q5" class="hide-label">
              <mat-radio-button value="1"></mat-radio-button>
              <mat-radio-button value="2"></mat-radio-button>
              <mat-radio-button value="3"></mat-radio-button>
              <mat-radio-button value="4"></mat-radio-button>
              <mat-radio-button value="5"></mat-radio-button>
              <mat-radio-button value="6"></mat-radio-button>
              <mat-radio-button value="7"></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="question-grey-bg">
          <div fxFlex="45%">
            <span>6. </span>
            <span [translate]="'ihavetospen6621'">I have to spend too much time correcting things with this web
              site.</span>
          </div>
          <div fxFlex="55%" class="mobile-ranking">
            <mat-radio-group fxLayout="row" fxLayoutAlign="space-between center" [(ngModel)]="feedbacks[5].response"
              id="feedback-q6"  name="feedback-q6" class="hide-label">
              <mat-radio-button value="1"></mat-radio-button>
              <mat-radio-button value="2"></mat-radio-button>
              <mat-radio-button value="3"></mat-radio-button>
              <mat-radio-button value="4"></mat-radio-button>
              <mat-radio-button value="5"></mat-radio-button>
              <mat-radio-button value="6"></mat-radio-button>
              <mat-radio-button value="7"></mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="feedback-optional-question">
        <div>
          <p>
            <span>7. </span>
            <span [translate]="'wedlovetoco1732'">We'd love to continue the conversation. How about you</span>
            <span>?</span>
          </p>
          <mat-radio-group fxLayout="column" fxLayoutGap="15px" [(ngModel)]="feedbacks[6].response" id="feedback-q7"
             name="feedback-q7">
            <mat-radio-button value="Yes, XPO may reach out to me">{{'yesXPO9424' | translate}}</mat-radio-button>
            <mat-radio-button value="Not at this time">{{'notatthisti7014' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div>
          <p>
            <span>8. </span>
            <span [translate]="'pleasetellu5463'">Please tell us the best way to contact you</span>
          </p>
          <mat-form-field class="form-field-input">
            <mat-label>{{'select9935' | translate}}</mat-label>
            <mat-select [(ngModel)]="feedbacks[7].response" id="feedback-q8"  name="feedback-q8">
              <mat-option>{{'select9935' | translate}} </mat-option>
              <mat-option [value]="userPhoneNumber"> {{userPhoneNumber}} </mat-option>
              <mat-option [value]="userEmail"> {{userEmail}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button class="text-uppercase" color="accent">{{'submit4579' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>