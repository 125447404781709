<div class="avail-load-notes" *ngIf="loadDetails && 
((loadDetails.notes && loadDetails.notes.length>0) || 
(loadDetails.orderRequirements && loadDetails.orderRequirements.length>0) ||
(loadDetails.stopRequirements && loadDetails.stopRequirements.length>0)); else nodata ">
    <div *ngIf="loadDetails.orderRequirements && loadDetails.orderRequirements.length>0" class="special-req">
        <div *ngFor="let orderReq of loadDetails.orderRequirements">
            <div *ngFor="let reqParam of orderReq.requirementParameters" fxLayout="column" fxLayoutAlign="center start"
                fxLayoutGap="10px" class="special-req-details">
                <div fxFlex="25%" class="category-label text-bold-capitalize">
                    {{orderReq.requirementCategoryName}}
                </div>
                <div fxFlex="75%" fxLayout="column" fxLayoutAlign="center start" class="params-label">
                    <span>{{reqParam.requirementParameterName}}</span>
                    <div *ngIf="reqParam.configurableParameters.length>0">
                        <ul>
                            <li *ngFor="let configParam of reqParam.configurableParameters; let i = index">
                                {{configParam.configurableParameterName}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loadDetails.stopRequirements && loadDetails.stopRequirements.length>0" class="special-req">
        <div *ngFor="let stopReq of loadDetails.stopRequirements">
            <div *ngFor="let reqParam of stopReq.requirementParameters" fxLayout="column" fxLayoutAlign="center start"
                fxLayoutGap="10px" class="special-req-details">
                <div fxFlex="25%" class="category-label text-bold-capitalize">
                    {{stopReq.requirementCategoryName}} <span>({{stopReq.stopLevelCode | titlecase}})</span>
                </div>
                <div fxFlex="75%" fxLayout="column" fxLayoutAlign="center start" class="params-label">
                    <span>{{reqParam.requirementParameterName}}</span>
                    <div *ngIf="reqParam.configurableParameters.length>0">
                        <ul>
                            <li *ngFor="let configParam of reqParam.configurableParameters; let i = index">
                                {{configParam.configurableParameterName}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap.xs="5px" class="notes-header" *ngIf="loadDetails.notes.length>0">
        <div fxFlex="35%" class="text-capitalize notes-bold" id="notes-date" [translate]="'date8215'">Date</div>
        <div fxFlex="25%" class="text-capitalize notes-bold" id="notes-time" [translate]="'time896'">time</div>
        <div fxFlex="50%" class="text-capitalize notes-bold" id="notes-message" [translate]="'message839'"> Message
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap.xs="5px" class="note" *ngFor="let note of loadDetails.notes; let i=index">
        <div fxFlex="35%" id="avail-load-note-date">{{note.modificationDate| xpocDateTime: dateTimeFormat}}</div>
        <div fxFlex="15%" id="avail-load-note-time">{{note.modificationTime}}</div>
        <div fxFlex="50%" id="avail-load-note-message" *ngIf="!isViewMore[i]">{{note.text}}
            <div class="view-less-btn">
                <button class="btn-link" *ngIf="note.text.length>notesWordLength" (click)="clickedViewLess(i)">
                    {{'viewless3675' | translate}}
                </button>
            </div>
        </div>
        <div fxFlex="70%" id="avail-load-note-message" *ngIf="isViewMore[i]">{{note.text | slice:0:notesWordLength}}
            <button class="btn-link" (click)="clickedViewMore(i)">
                {{'viewmore1122' | translate}}
            </button>
        </div>
    </div>
</div>
<ng-template #nodata>
    <div id="no-notes" class="notes-box">
        <i class="icon-info"></i>
        <p class="notes-err-msg" [translate]="'nonotesfoun1222'">
            No notes found
        </p>
    </div>
</ng-template>