import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '../services/notifications.service';
import { LogEvent } from '../model';
import { XPOConstants } from '..';
import { KeystoneService } from '../services';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';


@Component({
  selector: 'app-accept-terms-and-conditions',
  templateUrl: './accept-terms-and-conditions.component.html',
  styleUrls: [ './accept-terms-and-conditions.component.scss' ]
})
export class AcceptTermsAndConditionsComponent implements OnInit {

  public isNAUser: boolean;
  public username: string;

  constructor(
    private dialogRef: MatDialogRef<AcceptTermsAndConditionsComponent>,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private keystoneService: KeystoneService,
    private dialog: MatDialog) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.isNAUser = this.authService.checkNAUser();
  }

  accept() {
    const data = {
      notificationAlertTypeCode: 'TCN',
      username: this.authService.userProfile ? this.authService.userProfile.username : '',
      partnerIdentifier: this.authService.partnerIdentifier
    };
    this.notificationsService.userAlertNotification(data).subscribe();
    this.logKeystone(XPOConstants.keystoneEventName.acceptTcAgree, XPOConstants.keystoneEventAction.tcAccepted);
    this.dialogRef.close();
  }

  onClose() {
    this.logKeystone(XPOConstants.keystoneEventName.acceptTcCancel, XPOConstants.keystoneEventAction.tcCancelled);
    this.dialogRef.close('cancelled');
  }

  showTermsAndCondition() {
    this.logKeystone(XPOConstants.keystoneEventName.viewTc, XPOConstants.keystoneEventAction.termsAgreementDialog);
    this.dialog.open(TermsAndConditionsComponent);
  }

  logKeystone(keystoneEventName: string, keystoneEventAction: string) {
    const logEvent = new LogEvent(
      XPOConstants.keystoneLayer.frontEnd, keystoneEventName, keystoneEventAction, XPOConstants.keystonePage.acceptTcDialog);
    this.keystoneService.logEvent(logEvent);
  }
}
