import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AvailableLoad } from '../model/available-load';
import { AppSettings } from 'src/app/core/app-settings';
import { AvailableLoadsRequest } from '../model/available-loads-request';
import { AvailableLoadsResponse } from '../model/available-loads-response';
import { SavedSearchResponse } from '../model/saved-search-response';
import { AvailableLoadsReviewed } from '../model/available-loads-reviewed';
import { AvailableLoadEmailResponse } from '../model/available-load-email-response';
import { ExchangeObject } from '../model/exchange-object';
import { LoadboardGridFilter, SortFilter, DateFilter, TripLengthParam } from '../model';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { XPOConstants } from '../xpo-constants';
import { PostTruckRequestParams } from '../model/post-truck-request-params';
@Injectable({
  providedIn: 'root'
})
export class AvailableLoadsService {

  private savedSearchSubject = new Subject<any>();
  public refreshSavedSearchList = new Subject<any>();
  public updateSearchSubject = new Subject<any>();
  private baseUrl: string;
  public savedSearchObject: any;
  public savedSearchLoadCount: number;
  public selectedLoad: AvailableLoad;
  public isRecommendedLoad: boolean;
  public isFromReloads = false;
  public parentLoadNumber: string;
  public refreshAvailableLoadGrid = new Subject<any>();
  public aggregateMarkerClick = new Subject<any>();
  public loadBoardFilterObj: LoadboardGridFilter;
  public sortFilterObj: SortFilter[] = [];
  public isHotDealFiltered: boolean;
  public opportunityWidgetFilter: any;
  public availableLoadCurrentFilter: LoadboardGridFilter;
  public availableLoadCurrentSort: SortFilter[];
  public selectedTabConfig: any;
  public toTab: string;
  public switchTab = new Subject<any>();
  public resetFilterListener = new Subject<boolean>();
  public tripNumber: string;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService) {
    this.baseUrl = this.appSettings.apiUrl;
  }

  setRefreshGridSubject(refreshGrid) {
    this.refreshAvailableLoadGrid.next(refreshGrid);
  }

  getRefreshGridSubject() {
    return this.refreshAvailableLoadGrid.asObservable();
  }

  public applyAggregateMarkerFilter(selectedMarker) {
    this.aggregateMarkerClick.next(selectedMarker);
  }

  getSimilarLoads(body: AvailableLoadsRequest): Observable<AvailableLoadsResponse> {
    const url = this.getUri(`similar/availableloads`);
    return this.http
      .post(url, body, { headers: this.getHeaders().append('skipRedirect', 'true'), withCredentials: true })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getAvailableLoads(body: AvailableLoadsRequest): Observable<AvailableLoadsResponse> {
    const url = this.getUri(`search`);
    return this.http
      .post(url, body, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }
  getAllAvailableLoads(body: AvailableLoadsRequest): Observable<AvailableLoadsResponse> {
    const url = this.getUri(`opportunity`);
    return this.http
      .post(url, body, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  public getEUCountryLoadAggregate(body): Observable<any> {
    const url = this.getUri(`loadcountryaggregation`);
    return this.http
      .post(url, body, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  public getEquipmentAggregate(body): Promise<any> {
    const url = this.getUri(`loadequipmentaggregation`);
    return this.http
      .post(url, body, this.getOptions())
      .toPromise()
      .then((response: any) => {
        let processedResponse = response;
        processedResponse = response.map((equipment) => {
          equipment.formattedName = `${equipment.name} (${equipment.count})`;
          return equipment;
        });
        return processedResponse;
      })
      .catch(this.handleError);
  }

  getRecommendedLoads(body: AvailableLoadsRequest): Observable<AvailableLoadsResponse> {
    let url = this.getUri(`recommendations`);
    if (this.appSettings.getApplicationConstant('UseCarrierRecommendationFromCouchbase') == 'true') {
      url = this.getUri(`recommendations/v2`);
    }
    return this.http
      .post(url, body, { headers: this.getHeaders().append('skipRedirect', 'true') })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getRecommendedLoadsTag(body: string[]): Observable<any> {
    return this.http
      .post(this.getUri(`recommended/tag`), body, { headers: this.getHeaders().append('skipRedirect', 'true') })
      .pipe(catchError(this.handleError));
  }

  getReloadsCount(body: AvailableLoadsRequest): Observable<AvailableLoadsResponse> {
    const url = this.getUri(`reloads/count`);
    return this.http
      .post(url, body, { headers: this.getHeaders().append('skipRedirect', 'true'), withCredentials: true })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  refreshAvailableLoads(loadCount, refreshObj): Observable<any> {
    const url = this.baseUrl + 'loads/refresh';
    let params = new HttpParams();
    params = params.append('currentLoadsCount', loadCount);
    return this.http
      .post(url, refreshObj, this.getOptions(params))
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getSavedSearch(body: any): Promise<SavedSearchResponse> {
    const url = this.baseUrl + 'savedsearch/viewsavesearch';
    return this.http
      .post(url, body, this.getOptions())
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }


  setEmailNotification(body: any): Promise<any> {
    const url = this.baseUrl + 'savedsearch/setfilternotification';
    return this.http
      .post(url, body, { headers: this.getHeaders(), withCredentials: true, observe: 'response', responseType: 'text' })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  createSavedSearch(body: any) {
    const url = this.baseUrl + 'savedsearch';
    return this.http
      .post(url, body, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise().then(response => {
        return response;
      });
  }

  updateSavedSearch(body: any) {
    const url = this.baseUrl + 'savedsearch';
    return this.http
      .put(url, body, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise().then(response => {
        return response;
      });
  }

  setReviewedLoads(body: AvailableLoadsReviewed): Promise<string> {
    const url = this.getUri(`tripreview`);
    return this.http
      .post(url, body, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise()
      .then(response => {
        return response.status;
      })
      .catch(error => {
        return null;
      });
  }

  getSavedList(): Observable<any> {
    return this.savedSearchSubject.asObservable();
  }

  getRefreshedSavedSearchList(): Observable<any> {
    return this.refreshSavedSearchList.asObservable();
  }

  refreshSavedList() {
    const url = this.baseUrl + 'savedsearch/view';
    this.http.post(url, { search: '' }, { headers: new HttpHeaders({ skipRedirect: 'true' }) })
      .toPromise()
      .then(response => {
        this.savedSearchSubject.next(response);
      })
      .catch(error => {
        this.savedSearchSubject.next({ items: [] });
        console.log('Error on get Saved list:', error);
      });
  }

  deleteSavedSearch(filterId): Promise<any> {
    const url = this.baseUrl + 'savedsearch/' + filterId;
    return this.http
      .delete(url, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .toPromise()
      .then(response => {
        return response.status.toString();
      })
      .catch(error => {
        return null;
      });
  }

  setBidAmount(filterObject: any): Observable<any> {
    const url = this.getUri(`bid`);
    return this.http
      .post(url, filterObject, { headers: this.getHeaders().append('skipRedirect', 'true') })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  setBidAmountWithOutAuth(filterObject: any): Observable<any> {
    const url = this.baseUrl + 'tenders/bid';
    return this.http
      .post(url, filterObject, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  // body is the tenderResponseId for authenticated codes
  checkBidStatus(identifier: any): Observable<any> {
    const url = this.getUri('tenderresponsestatus/' + identifier);
    return this.http
      .get(url, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  // CreatedId is the tenderResponseId for non-authenticated codes
  checkBidStatusWithOutAuth(identifier: any): Observable<any> {
    const url = this.baseUrl + 'tenders/tenderresponsestatus/' + identifier;
    return this.http
      .get(url, { headers: this.getHeaders(), observe: 'response', responseType: 'text' })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  public sendBINNotification(binRequest) {
    const url = this.baseUrl + 'notifications/buyitnow';
    return this.http
      .post(url, binRequest, { headers: this.getHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getLocationAutoComplete(search): any {
    const isNAUser = this.authService.checkNAUser();
    let url: any;
    url = this.baseUrl + 'lookups/locations/search';
    const searchObj = {
      searchString: search
    };
    return this.http.post(url, searchObj).toPromise()
      .then(response => {
        return response;
      }).catch(error => { });
  }

  getLoadForEmailView(request): Promise<AvailableLoadEmailResponse> {
    const url = this.baseUrl + 'tenders/gettenderloaddetails';
    let params = new HttpParams();
    params = params.append('tenderGuid', request.tenderGuid);
    params = params.append('tripCode', request.tripCode);
    if (request.isManuallyAccepted) {
      params = params.append('isManuallyAccepted', request.isManuallyAccepted);
    }
    return this.http.get(url, { params })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  getLoadFromGuid(guid: string): Promise<any> {
    const url = this.baseUrl + 'authtenders/' + guid + '/details';
    return this.http.get(url)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  }

  getCurrentDestination(driverId): Observable<any> {
    const url = this.baseUrl + 'drivers/' + driverId + '/currenttripdestination';
    return this.http.get(url, this.getOptions())
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  convertAmount(exchangeObject: ExchangeObject): Observable<any> {
    const url = this.baseUrl + '/commons/exchangerate';
    let params = new HttpParams();
    params = params.append('exchangeRateDate', exchangeObject.exchangeRateDate);
    params = params.append('fromCurrencyCode', exchangeObject.fromCurrencyCode);
    params = params.append('toCurrencyCode', exchangeObject.toCurrencyCode);
    params = params.append('amount', exchangeObject.amount);
    return this.http.get(url, { params })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  getUnavailableLoads(requestObject): Observable<any> {
    const url = this.baseUrl + 'loads/unavailable';
    const headers = new HttpHeaders({ skipRedirect: 'true' });
    return this.http
      .post(url, requestObject, { headers, withCredentials: true })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  defaultAvailableLoadsFilter() {
    const dateFilter = new DateFilter(null, null, null, null);
    const defaultWeightUnit = this.authService.checkNAUser() ?
      XPOConstants.defaultWeightUnit.WeightUnitNA : XPOConstants.defaultWeightUnit.WeightUnitEU;
    const defaultLaneRadius = this.authService.checkNAUser() ? XPOConstants.defaultLaneRadius : XPOConstants.defaultLaneRadiusEU;
    const minTripLength = +this.appSettings.getApplicationConstant('MinimumDistanceInAvailableLoadsFilter');
    const maxTripLength = +this.appSettings.getApplicationConstant('MaximumDistanceInAvailableLoadsFilter') > 0 ?
      +this.appSettings.getApplicationConstant('MaximumDistanceInAvailableLoadsFilter') : XPOConstants.tripLength.max;
    const loadboardGridFilter = new LoadboardGridFilter(1, XPOConstants.availableLoadListPageSize, dateFilter, [], defaultLaneRadius, [],
      defaultLaneRadius, [], [], [], [], [], [], false, defaultWeightUnit, null,
      new TripLengthParam(minTripLength, maxTripLength), 0, null, null, 0, null, [], [], false, null, null);
    return loadboardGridFilter;
  }

  saveLatestAppliedFilter(loadBoardFilterObj: LoadboardGridFilter) {
    this.availableLoadCurrentFilter = loadBoardFilterObj;
  }

  saveLatestAppliedSort(sortFilterObj: SortFilter[]) {
    this.availableLoadCurrentSort = sortFilterObj;
  }

  getCurrentAppliedFilter() {
    return this.availableLoadCurrentFilter;
  }

  getCurrentAppliedSort() {
    return this.availableLoadCurrentSort;
  }

  setTripNumber(tripNumber) {
    this.tripNumber = tripNumber;
  }

  getTripNumber() {
    return this.tripNumber;
  }
  clearFilter() {
    this.availableLoadCurrentFilter = null;
  }

  resetFilter() {
    this.resetFilterListener.next(true);
  }

  clearSort() {
    this.availableLoadCurrentSort = null;
  }

  getCurrentTab() {
    return this.selectedTabConfig ? this.selectedTabConfig.code : XPOConstants.availableLoadsTabCodes.all;
  }

  setTabBeforeNavigation(tab: string) {
    this.toTab = tab;
  }

  clearTabAfterNavigation() {
    this.toTab = null;
  }

  triggerTabSwitch(tabcode: string) {
    this.switchTab.next(tabcode);
  }

  switchTabEvent() {
    return this.switchTab.asObservable();
  }

  private getHeaders(obj?: any): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }

  private getUri(endpoint?: string): string {
    return this.baseUrl + 'availableloads/' + endpoint;
  }

  private getOptions(params?: HttpParams) {
    const options = { headers: this.getHeaders(), params, withCredentials: true };
    return options;
  }

  public updateMassNotification(req): Observable<any> {
    const url = this.baseUrl + 'savedsearch/notificationpreference';
    return this.http.post(url, req, { headers: new HttpHeaders() })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  updatePostTruckDetails(reqParams: PostTruckRequestParams) {
    const url = this.baseUrl + 'truckpostings';
    return this.http
      .post(url, reqParams, { headers: this.getHeaders().append('skipRedirect', 'true'), withCredentials: true })
      .pipe(response => {
        return response;
      }, catchError(this.handleError));
  }

}
