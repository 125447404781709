import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SharedService } from '../shared/services/shared.service';

/**
 * LandingPageGuard implements angular CanActivate Guard for dynamically loading the initial landing page
 * on an empty path.
 */
@Injectable({
  providedIn: 'root'
})
export class LandingPageGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // inorder to safe gaurd an extra data 'isDefaultRoute' is passed along with the route

    if (typeof route.data !== 'undefined' && route.data.isDefaultRoute) {
      // naviagte accordingly.
      if (this.sharedService.isUnSupportedBrowser()) {
        this.router.navigate([ '/unsupported-browser' ]);
      } else if (sessionStorage.getItem('previousRoute')) {
        window.open(sessionStorage.getItem('previousRoute'), '_self');
        sessionStorage.removeItem('previousRoute');
      } else {
        this.router.navigate([ this.authService.homeLocation ]);
      }
      return true;
    }
    return false;
  }
}
