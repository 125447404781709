<div class="search-box-wrapper" fxLayoutAlign="start center">
  <span class="search-btn" (click)="onChange($event)">
    <i class="icon-search_thin"></i>
  </span>
  <input class="search-input" id="load-search-box-input" placeholder="{{placeholder | translate | titlecase}}"
    [(ngModel)]="searchBoxModel" (keyup.enter)="onChange($event)" (blur)="onChange($event)" maxlength="40"
    [readonly]="readOnly" />
  <span *ngIf="searchBoxModel && enableClose && !readOnly" class="cursor-pointer" (click)="onClose($event)">
    <i class="icon-close"></i>
  </span>
</div>