
export class SupportRequest {
    constructor(
        public carrierIdentifierNumber: string,
        public firstName: string,
        public lastName: string,
        public emailAddress: string,
        public phoneNumber: string,
        public supportType: string,
        public otherSupportType: string,
        public representative: string,
        public comments: string
    ) { }
}
