
export class Stop {

  public city: string;
  public cityName: string;
  public state: string;
  public stateCode: string;
  public zipcode: number;
  public stopId: number;
  public locationId: number;
  public address1: string;
  public zipCode: string;
  public countryCode: string;
  public sequence: number;
  public number: number;
  public actualDepartureTime: Date;
  public scheduledArrivalTime: Date;
  public scheduledArrivalEarly: string;
  public scheduledArrivalLate: string;
  public stopTypeName: string;
  public scheduledArrivalString: string;
  public latitude: number;
  public longitude: number;
  public type: string;
  public actualArrival?: string;
  public actualDeparture?: string;
  public locationName?: string;
  public street?: string;
  public scheduledTime?: Date;
  public arrivedAt?: Date;
  public departedAt?: Date;
  public countryName?: string;
  public locationAddress1?: string;
  public stopInfo = '';
  public enableCheckCallOption = false;
  public siteName?: string;
  public commodities?: string;
  public checkCallPermittedTime?: string;
  public actualArrivalOffset?: string;
  public actualDepartureOffset?: string;
  public isArrivalCheckCallPending?: boolean;
  public isDepartureCheckCallPending?: boolean;

  constructor(stopInstance) {
    this.mapStopInstance(stopInstance);
  }

  private mapStopInstance(stopInstance) {
    this.city = stopInstance.city;
    this.cityName = stopInstance.cityName;
    this.state = stopInstance.state;
    this.stateCode = stopInstance.stateCode;
    this.zipcode = stopInstance.zipCode;
    this.stopId = stopInstance.stopId;
    this.locationId = stopInstance.locationId;
    this.address1 = stopInstance.address1;
    this.zipCode = stopInstance.zipCode;
    this.countryCode = stopInstance.countryCode;
    this.sequence = stopInstance.sequence;
    this.number = stopInstance.number;
    this.actualDepartureTime = stopInstance.actualDepartureTime;
    this.scheduledArrivalTime = stopInstance.scheduledArrivalTime;
    this.scheduledArrivalEarly = stopInstance.scheduledArrivalEarly;
    this.scheduledArrivalLate = stopInstance.scheduledArrivalLate;
    this.stopTypeName = stopInstance.stopTypeName;
    this.scheduledArrivalString = stopInstance.scheduledArrivalString;
    this.latitude = stopInstance.latitude;
    this.longitude = stopInstance.longitude;
    this.type = stopInstance.type;
    this.locationName = typeof (stopInstance.locationName) !== 'undefined' ? stopInstance.locationName : '';
    this.street = typeof (stopInstance.street) !== 'undefined' ? stopInstance.street : '';
    this.scheduledTime = typeof (stopInstance.scheduledTime) !== 'undefined' ? stopInstance.scheduledTime : '';
    this.actualArrival = typeof (stopInstance.actualArrival) !== 'undefined' ? stopInstance.actualArrival : '';
    this.actualDeparture = typeof (stopInstance.actualDeparture) !== 'undefined' ? stopInstance.actualDeparture : '';
    this.arrivedAt = typeof (stopInstance.arrivedAt) !== 'undefined' ? stopInstance.arrivedAt : '';
    this.departedAt = typeof (stopInstance.departedAt) !== 'undefined' ? stopInstance.departedAt : '';
    this.countryName = typeof (stopInstance.countryName) !== 'undefined' ? stopInstance.countryName : '';
  }
}
