import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExchangeObject } from '../model/exchange-object';
import { LogEvent } from '../model/log-event';
import { LoadBid } from '../model/load-bid';
import { AvailableLoad } from '../model/available-load';
import { Currency } from '../model/currency';
import { Subscription } from 'rxjs';
import { Representative } from '../model/representative';
import { AvailableLoadEmailResponse } from '../model/available-load-email-response';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AvailableLoadsService } from '../services/available-loads.service';
import { UserProfileService } from '../services/user-profile.service';
import { LookupService } from '../services/lookup.service';
import { AppSettings } from 'src/app/core/app-settings';
import { GoogleAnalyticsEventsService } from 'src/app/google-analytics/google-analytics-events.service';
import { Router } from '@angular/router';
import { KeystoneService } from '../services/keystone.service';
import { DateTimePipe as DatePipe } from '@xpoc/ngx-common';
import { CurrencyFormatPipe as CurrencyPipe } from '@xpoc/ngx-common';
import { XPOConstants, TenantId } from '../xpo-constants';
import { LoyaltyService } from '../services/loyalty.service';
import { CreditRedemption } from '../model/credit-redemption';
@Component({
  selector: 'app-place-bid',
  templateUrl: './place-bid.component.html',
  styleUrls: [ './place-bid.component.scss' ]
})
export class PlaceBidComponent implements OnInit, OnDestroy {

  public getBidAmount: boolean;
  public onProcessing: boolean;
  public bidAmount: number;
  public comment: string;
  public load: AvailableLoad;
  public isSubmitted = false;
  public isError = false;
  public currencies: Currency[];
  public currency: string = XPOConstants.defaultCurrency;
  public placeBidCheckpoint = false;
  public bidPlacedLoadNo: number;
  private isUnauthenticated = false;
  private guid;
  public buyPrice;
  public loadNumber;
  public loadBid: LoadBid;
  private pendingRequest: Subscription;
  private intervalTimer;
  private bidCounter = 0;
  public bidStatuses = XPOConstants.bidStatus;
  public currentStatus = XPOConstants.bidStatus.noStatus;
  public carrierRep: Representative;
  private eventIdentifiers: any;
  private landingPage: string;
  private landingSubPage: string;
  private keystoneEventName: string;
  public autoOffersLoad: AvailableLoadEmailResponse;
  public errorType: number;
  public actionConfirmed = true;
  public isNAUser: boolean;
  public convertedBidAmount: string;
  public defaultCurrency = XPOConstants.defaultEUCurrencyCode;
  public preferredCurrency: string;
  public preferredCurrencySymbol: string;
  public pendingExchangeRequest: Subscription;
  public currencyMsg: string;
  public dialogTimer: any;
  public isCreditToRedeem = false;
  public creditToRedeem;
  public showRedeemOption: boolean;
  public hasAlreadyRedeemed = false;
  public checkboxMsg: string = XPOConstants.bidOrCounterOfrCheckboxMsg;
  public tenandId: number;
  public currencyPattern;
  public priceCurrency: string;
  public minBidPrice: number;
  public validationMsg: string;
  public isBidRestricted = true;

  constructor(
    private userAuthService: AuthService,
    public dialogRef: MatDialogRef<PlaceBidComponent>,
    public availableLoadsService: AvailableLoadsService,
    private userProfileService: UserProfileService,
    private lookup: LookupService,
    public appSettings: AppSettings,
    private authService: AuthService,
    public gaService: GoogleAnalyticsEventsService,
    public router: Router,
    private keystoneService: KeystoneService,
    public datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private loyaltyService: LoyaltyService) {
    this.dialogRef.disableClose = true;
    this.tenandId = this.authService.tenantId;
  }

  ngOnInit() {
    this.startDialogTimer();
    this.isNAUser = this.authService.checkNAUser();
    this.appSettings.loadMessage(XPOConstants.commonMessageSetting).then(response => {
      this.currencyMsg = this.appSettings.getMessage('euCurrencyMessage');
    });
    if (this.authService.tenantId === TenantId.EU) {
      this.preferredCurrency = (this.authService && this.authService.userProfile) ? this.authService.userProfile.preferredCurrencyCode
        : XPOConstants.defaultEUCurrencyCode;
    }
    this.landingPage = this.dialogRef._containerInstance._config.data.landingPage;
    this.landingSubPage = this.dialogRef._containerInstance._config.data.landingSubPage;
    this.showRedeemOption = this.dialogRef._containerInstance._config.data.showRedeemOption ?
      this.dialogRef._containerInstance._config.data.showRedeemOption : false;
    this.isUnauthenticated = this.dialogRef._containerInstance._config.data.isUnauthenticated;
    this.guid = this.dialogRef._containerInstance._config.data.tenderGuid;
    this.buyPrice = this.dialogRef._containerInstance._config.data.buyPrice;
    this.load = this.dialogRef._containerInstance._config.data;
    this.loadNumber = this.load.number;
    this.eventIdentifiers = {};
    this.currencyPattern = this.isNAUser ? '^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$' :
      '^(0*[1-9][0-9]*((\.|\,)[0-9]+)?|0+(\.|\,)[0-9]*[1-9][0-9]*)$';
    if (!this.isUnauthenticated) {
      this.buyPrice = this.load.dmpRate;
      this.eventIdentifiers.tripCode = this.load.alternateNumber;
    } else {
      this.loadNumber = this.dialogRef._containerInstance._config.data.orderNo;
      this.eventIdentifiers.tripCode = this.dialogRef._containerInstance._config.data.tripCode;
      this.autoOffersLoad = this.dialogRef._containerInstance._config.data.autoOffersLoad;
    }
    this.eventIdentifiers.isValidationRequired = this.dialogRef._containerInstance._config.data.isValidationRequired || false;
    this.lookup.currencies().then(response => {
      this.currencies = response.filter(x => x.code !== 'CAD');
      this.currency = this.currencies[ 0 ] ? this.currencies[ 0 ].code : '';
      this.currency = this.isNAUser ? this.currency : XPOConstants.defaultEUCurrencyCode;
    }).catch();
    this.priceCurrency = this.isNAUser ? XPOConstants.defaultCurrency : XPOConstants.defaultEUCurrencyCode;
    if (this.load && this.load.salesRep) {
      this.carrierRep = this.load.salesRep; // Kept the RHS as salesRep as the json has dependency with backend api
    }
    this.intialiseFlags();
    this.eventIdentifiers.orderNumber = this.loadNumber;
  }

  intialiseFlags() {
    this.getBidAmount = true;
    this.onProcessing = false;
    this.isSubmitted = false;
    this.isError = false;
  }

  onBidAmountChange(bidAmount) {
    if (this.isNAUser) {
      this.validationMsg = `Please check your bid amount of $${+this.bidAmount},
       which does not appear to be a valid bid amount for this load.`;
      this.minBidPrice = parseInt(this.appSettings.getApplicationConstant('MinimumBidPrice'), 10);
      this.isBidRestricted = +this.bidAmount > 0 && +this.bidAmount <= this.minBidPrice;
    }
  }

  onClose() {
    this.clearDialogTimer();
    this.dialogRef.close({
      placeBidCheckpoint: this.placeBidCheckpoint,
      bidPlacedLoadNo: (this.currentStatus === XPOConstants.bidStatus.accepted) ? this.bidPlacedLoadNo : ''
    });
    if (this.getBidAmount) {
      if (!this.isUnauthenticated) {
        const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.placeBid,
          XPOConstants.keystoneEventAction.cancel, this.landingPage, this.landingSubPage, this.eventIdentifiers);
        this.keystoneService.logEvent(logEvent);
      } else {
        const logEvent = new LogEvent(XPOConstants.keystoneLayer.email, XPOConstants.keystoneEventName.placeBid,
          XPOConstants.keystoneEventAction.cancel, this.landingPage, this.landingSubPage, this.eventIdentifiers);
        this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
      }
    }
  }

  placeBid() {
    this.clearDialogTimer();
    const bidAmountValueWithoutComma = this.bidAmount.toString().replace(',', '.');
    this.gaService.emitEvent(XPOConstants.eventCategory.avaiable, XPOConstants.eventAction.bid, 'Placed a bid', 10);
    this.onProcessing = true;
    this.getBidAmount = false;
    this.placeBidCheckpoint = true;
    this.eventIdentifiers.dmpAmount = this.buyPrice;
    this.eventIdentifiers.dmpCurrencyCode = this.currency;
    this.eventIdentifiers.submittedAmount = bidAmountValueWithoutComma;
    this.eventIdentifiers.submittedCurrencyCode = this.currency;
    this.eventIdentifiers.isHazmat = this.isUnauthenticated ? this.autoOffersLoad.orderItems[ 0 ].isHazmat : this.load.isHazmat;
    this.eventIdentifiers.numberOfStops = this.isUnauthenticated ? this.autoOffersLoad.stopItems.length : this.load.stops.length;
    this.eventIdentifiers.equipmentCategory = this.isUnauthenticated ?
      this.autoOffersLoad.orderItems[ 0 ].equipmentCategory : this.load.equipmentCategory;
    this.eventIdentifiers.equipmentType = this.isUnauthenticated ?
      this.autoOffersLoad.orderItems[ 0 ].equipmentType : this.load.equipmentType;
    if (this.isUnauthenticated) {
      this.callUnauthenticatedBid('Bid');
      if (this.dialogRef._containerInstance._config.data.carrierRep) {
        this.carrierRep = this.dialogRef._containerInstance._config.data.carrierRep;
      }
    } else {
      this.loadBid = new LoadBid(this.load.alternateNumber, +bidAmountValueWithoutComma, this.currency, this.comment);
      this.loadBid.loadId = this.load.id;
      this.loadBid.loadSystemId = this.load.number;
      this.loadBid.carrierRepUserName = this.load.salesRep.userName;
      this.loadBid.carrierRepContactEmail = this.load.salesRep.email;
      this.loadBid.buyPrice = this.load.dmpRate;
      this.buyPrice = this.load.dmpRate;
      this.loadBid.tenderStatus = this.load.priceSourceCode === XPOConstants.loadPriceSourceCodes.MNC ? XPOConstants.loadBidTenderStatus.portalManualCustomerBid : this.load.bidStatus;
      this.loadBid.responseStatus = 'Bid';
      this.loadBid.transactionFlag = XPOConstants.loadTransactionFlag.placeBid;
      this.loadBid.priceSourceCode = this.load.priceSourceCode;
      this.loadBid.wtpEntityId = this.load.wtpEntityId;
      this.eventIdentifiers.transactionFlag = XPOConstants.loadTransactionFlag.placeBid;
      this.eventIdentifiers.priceSourceCode = this.load.priceSourceCode;
      this.eventIdentifiers.wtpEntityId = this.load.wtpEntityId;
      this.eventIdentifiers.genericDMPRate = this.load.genericDMPRate;
      if (!this.userAuthService.userProfile || this.userAuthService.username === '') {
        this.userProfileService.getUserProfile().then(user => {
          this.loadBid.contactName = user.username;
          this.setBid(this.loadBid);
        });
      } else {
        this.loadBid.contactName = this.userAuthService.username;
        this.setBid(this.loadBid);
      }
    }
  }

  setBid(loadBidObj: any) {
    this.availableLoadsService.setBidAmount(loadBidObj)
      .subscribe(bidResponse => {
        this.bidPlacedLoadNo = loadBidObj.loadSystemId;
        if (bidResponse.isSuccess) {
          this.startDialogTimer();
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.frontEnd, XPOConstants.keystoneEventName.placeBid,
            XPOConstants.keystoneEventAction.submit, this.landingPage, this.landingSubPage, this.eventIdentifiers);
          this.keystoneService.logEvent(logEvent);
          if (this.isCreditToRedeem && this.isNAUser && !this.hasAlreadyRedeemed) {
            const creditRedemption = new CreditRedemption(this.load.alternateNumber, this.creditToRedeem);
            this.loyaltyService.creditRedemption(creditRedemption).subscribe();
          }
          if (loadBidObj.amount > loadBidObj.buyPrice || loadBidObj.priceSourceCode === XPOConstants.loadPriceSourceCodes.MNC) {
            this.onProcessing = false;
            this.isSubmitted = true;
            this.currentStatus = XPOConstants.bidStatus.rejected;
          } else {
            this.intervalTimer = setInterval(() => {
              this.checkBid(bidResponse.createdId);
            }, parseInt(this.appSettings.getApplicationConstant('XpoConnectBidTimer'), 10));
          }
        } else {
          this.startDialogTimer();
          this.errorType = bidResponse.errorType;
          this.isSubmitted = true;
          this.currentStatus = XPOConstants.bidStatus.noStatus;
          if (bidResponse.errors && bidResponse.errors.length) {
            this.eventIdentifiers = { messages: bidResponse.errors.map((err: any) => err.Message), ...this.eventIdentifiers };
          }
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.placeBid,
            XPOConstants.keystoneEventAction.errorOrTimeOut, this.landingPage, this.landingSubPage, this.eventIdentifiers);
          this.keystoneService.logEvent(logEvent);
          this.resetProcessing();
        }
      }, error => {
        this.handleError();
      });
  }

  checkBid(tenderResponseId: any) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.availableLoadsService.checkBidStatus(tenderResponseId)
      .subscribe(response => {
        const status = +response.body;
        if (status === XPOConstants.bidStatus.accepted || status === XPOConstants.bidStatus.rejected) {
          this.isSubmitted = true;
          this.currentStatus = status;
          this.bidCounter = this.bidCounter + parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10);
          this.keystoneEventName = status === XPOConstants.bidStatus.accepted ?
            XPOConstants.keystoneEventAction.approved : XPOConstants.keystoneEventAction.underReview;
          this.resetProcessing();
        }
      }, error => {
        this.handleError();
      });

    // Check bid status will continue until timer expires or get Acceptance or Rejection for a bid
    if (this.bidCounter > parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10)) {
      this.resetProcessing();
      this.startDialogTimer();
      // Showing error message after trying to set a bid
      if (!this.isSubmitted) {
        this.isError = true;
        this.isSubmitted = true;
        this.keystoneEventName = XPOConstants.keystoneEventAction.errorOrTimeOut;
      }
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.placeBid,
        this.keystoneEventName, this.landingPage, this.landingSubPage, this.eventIdentifiers);
      this.keystoneService.logEvent(logEvent);
    } else {
      this.bidCounter++;
    }
  }

  private callUnauthenticatedBid(action) {
    const bidAmountValueWithoutComma = this.bidAmount.toString().replace(',', '.');
    const req: any = {
      responseStatus: action,
      tenderStatus: this.load.priceSourceCode === XPOConstants.loadPriceSourceCodes.MNC ? XPOConstants.loadBidTenderStatus.portalManualCustomerBid : XPOConstants.loadBidTenderStatus.placeBid,
      tenderGuid: this.guid,
      amount: +bidAmountValueWithoutComma,
      comments: this.comment,
      currencyCode: this.currency,
      transactionFlag: XPOConstants.loadTransactionFlag.placeBid,
      priceSourceCode: this.autoOffersLoad.priceSourceCode,
      wtpEntityId: this.autoOffersLoad.wtpEntityId,
      buyPrice: this.buyPrice
    };
    this.eventIdentifiers.transactionFlag = XPOConstants.loadTransactionFlag.placeBid;
    this.eventIdentifiers.priceSourceCode = this.autoOffersLoad.priceSourceCode;
    this.eventIdentifiers.wtpEntityId = this.autoOffersLoad.wtpEntityId;
    this.eventIdentifiers.buyPrice = this.buyPrice;
    this.setBidWithoutAuth(req);
  }

  setBidWithoutAuth(loadBidObj: any) {
    this.availableLoadsService.setBidAmountWithOutAuth(loadBidObj)
      .subscribe(bidResponse => {
        if (bidResponse.isSuccess) {
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.email, XPOConstants.keystoneEventName.placeBid,
            XPOConstants.keystoneEventAction.submit, this.landingPage, this.landingSubPage, this.eventIdentifiers);
          this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
          if (loadBidObj.amount > this.buyPrice || loadBidObj.priceSourceCode === XPOConstants.loadPriceSourceCodes.MNC) {
            this.onProcessing = false;
            this.isSubmitted = true;
            this.currentStatus = XPOConstants.bidStatus.rejected;
          } else {
            this.intervalTimer = setInterval(() => { this.checkBidWithoutAuth(bidResponse.createdId); },
              parseInt(this.appSettings.getApplicationConstant('XpoConnectBidTimer'), 10));
          }
        } else {
          this.startDialogTimer();
          if (bidResponse.errors && bidResponse.errors.length) {
            this.eventIdentifiers = { messages: bidResponse.errors.map((err: any) => err.Message), ...this.eventIdentifiers };
          }
          const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.placeBid,
            XPOConstants.keystoneEventAction.errorOrTimeOut, this.landingPage, this.landingSubPage, this.eventIdentifiers);
          this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
          this.errorType = bidResponse.errorType;
          this.isSubmitted = true;
          this.currentStatus = XPOConstants.bidStatus.noStatus;
          this.resetProcessing();
        }
      }, error => {
        this.handleError();
      });
  }

  checkBidWithoutAuth(tenderResponseId: any) {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    this.pendingRequest = this.availableLoadsService.checkBidStatusWithOutAuth(tenderResponseId)
      .subscribe(response => {
        const status = +response.body;
        if (status === XPOConstants.bidStatus.accepted || status === XPOConstants.bidStatus.rejected) {
          this.isSubmitted = true;
          this.currentStatus = status;
          this.bidCounter = this.bidCounter + parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10);
          this.keystoneEventName = (status === XPOConstants.bidStatus.accepted) ?
            XPOConstants.keystoneEventAction.approved : XPOConstants.keystoneEventAction.underReview;
        }
      }, error => {
        this.handleError();
      });

    // Check bid status will continue until timer expires or get Acceptance or Rejection for a bid
    if (this.bidCounter > parseInt(this.appSettings.getApplicationConstant('XpoConnectBidRetry'), 10)) {
      this.resetProcessing();
      this.startDialogTimer();
      // Showing error message after trying to set a bid
      if (!this.isSubmitted) {
        this.isError = true;
        this.isSubmitted = true;
        this.keystoneEventName = XPOConstants.keystoneEventAction.errorOrTimeOut;
      }
      const logEvent = new LogEvent(XPOConstants.keystoneLayer.backEnd, XPOConstants.keystoneEventName.placeBid,
        this.keystoneEventName, this.landingPage, this.landingSubPage, this.eventIdentifiers);
      this.keystoneService.logEmailEvent(logEvent, this.autoOffersLoad.carrierCode);
    } else {
      this.bidCounter++;
    }
  }

  resetProcessing() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.onProcessing = false;
    this.bidCounter = 0;
  }

  handleError() {
    this.resetProcessing();
    this.onClose();
    this.router.navigate([ '/error-message' ]);
  }

  confirmAction(event) {
    this.actionConfirmed = event.isRequire && event.isValid && event.isAppointmentConfirmed ? false : true;
    this.isCreditToRedeem = event.isCreditToRedeem;
    this.creditToRedeem = event.creditToRedeem;
    this.hasAlreadyRedeemed = event.hasAlreadyRedeemed;
  }

  convertAmount() {
    const bidAmountValue = { value: this.bidAmount };
    const bidAmountWithoutComma = bidAmountValue.value.toString().replace(',', '.');
    const bidAmountValueWithoutComma = +bidAmountWithoutComma;
    if (!this.isNAUser && bidAmountValueWithoutComma && !isNaN(bidAmountValueWithoutComma)
      && this.preferredCurrency !== XPOConstants.defaultEUCurrencyCode) {
      const currentDate = this.datePipe.transform(new Date(), XPOConstants.exchangeRateDateFormat);
      const exchangeObject =
        new ExchangeObject(currentDate, XPOConstants.defaultEUCurrencyCode, this.preferredCurrency,
          bidAmountWithoutComma);
      if (this.pendingExchangeRequest) {
        this.pendingExchangeRequest.unsubscribe();
      }
      this.pendingExchangeRequest = this.availableLoadsService.convertAmount(exchangeObject).subscribe(value => {
        this.convertedBidAmount = this.currencyPipe.transform(value, this.preferredCurrency, false, this.authService.tenantId);
        this.preferredCurrencySymbol = XPOConstants.currencySymbols[ this.preferredCurrency ];
      });
    } else {
      this.convertedBidAmount = '';
      this.preferredCurrencySymbol = '';
    }
  }

  startDialogTimer() {
    this.dialogTimer = setTimeout(() => {
      this.onClose();
    }, parseInt(this.appSettings.getApplicationConstant('XpoConnectBidConfirmationModelTimer'), 10));
  }

  clearDialogTimer() {
    if (this.dialogTimer) {
      clearInterval(this.dialogTimer);
    }
  }

  ngOnDestroy() {
    if (this.dialogTimer) {
      clearInterval(this.dialogTimer);
    }
  }
}
