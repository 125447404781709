import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/core/app-settings';
import { XPOConstants } from 'src/app/shared/xpo-constants';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { MediaObserver } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  public partnerIdentifier: string;
  public apiUrl: string;
  public userName = '';
  public breakpoint: Observable<string>;
  public navbarItemConfig = {};
  public navbarItemSubject = new Subject<any>();
  public showSignUpBanner: boolean;
  public showSignUpBannerSubject = new Subject<any>();
  public partnerIdentifierUnAuth: string;
  public orderNumberUnAuth: string;
  public partnerIdUnAuthSubject = new Subject<any>();
  public carrierCompetitionSubject = new Subject<any>();
  public isFromSavedSearch = false;

  constructor(
    private http: HttpClient,
    private appSettings: AppSettings,
    private authService: AuthService,
    private mediaObserver: MediaObserver
  ) {
    this.partnerIdentifier = authService.partnerIdentifier;
    this.apiUrl = this.appSettings.apiUrl;
    if (authService.userProfile !== null && authService.userProfile !== undefined) {
      this.userName = authService.userProfile.username;
    }
    this.showSignUpBanner = false;
    this.partnerIdentifierUnAuth = null;
    this.orderNumberUnAuth = null;
  }

  getPartnerDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      let partner;
      const user = this.authService.userProfile;
      const partnerName = user.currentPartner ? user.currentPartner.partnerName : '';
      partner = {
        partnerName,
        roleCode: this.authService.partnerRole
      };
      resolve(partner);
    });
  }

  public getFlexBreakPoint() {
    const grid = XPOConstants.flexLayoutBreakpoints;
    let start: string;
    grid.forEach((mqAlias) => {
      if (this.mediaObserver.isActive(mqAlias)) {
        start = mqAlias;
      }
    });
    this.breakpoint = this.mediaObserver.asObservable()
      .pipe(map(change => change[ 'mqAlias' ]))
      .pipe(startWith(start));
    return this.breakpoint;
  }

  setUserProfile(userProfileInfo: any): Promise<any> {
    const url = this.getUri('userProfile');
    return this.http
      .put(url, userProfileInfo, {
        observe: 'response',
        responseType: 'text'
      })
      .toPromise()
      .then((response) => {
        this.userProfile(); /** Updates userprofile stored in AuthService */
        return response.status;
      })
      .catch(this.handleError);
  }

  updateUserPassword(userPassword: any): Promise<any> {
    const url = this.getUri('userProfile', null, 'password');
    return this.http
      .put(url, userPassword, {
        observe: 'response',
        responseType: 'text'
      })
      .toPromise()
      .then((response) => {
        return response.status;
      })
      .catch(this.handleError);
  }

  userProfile() {
    const url = this.getUri('userProfile');
    this.http
      .get(url, { withCredentials: true })
      .toPromise()
      .then(userProfile => {
        this.authService.userProfile = userProfile;
        this.authService.setUserProperties(userProfile);

      })
      .catch(this.handleError);
  }

  public isMobileDevice(): boolean {
    if (window.innerWidth <= 767) {
      return true;
    }
    /* var userAgent = navigator.userAgent || navigator.vendor;
    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        return true;
    }*/
    return false;
  }

  isUnSupportedBrowser() {
    const ua = navigator.userAgent;
    /* IE -  MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  }

  setPartnerIdUnAuth(partnerIdentifier: string, orderNumber: string) {
    this.partnerIdentifierUnAuth = partnerIdentifier;
    this.orderNumberUnAuth = orderNumber;
    this.partnerIdUnAuthSubject.next(partnerIdentifier);
  }

  clearPartnerIdUnAuth() {
    this.partnerIdentifierUnAuth = null;
    this.orderNumberUnAuth = null;
    this.partnerIdUnAuthSubject.next(null);
    this.partnerIdUnAuthSubject.complete();
  }

  getPartnerIdUnAuth() {
    return this.partnerIdUnAuthSubject.asObservable();
  }

  setShowSignUpBannerSubject(value: boolean) {
    this.showSignUpBanner = value;
    this.showSignUpBannerSubject.next(value);
  }

  getShowSignUpBannerSubject() {
    return this.showSignUpBannerSubject.asObservable();
  }

  setNavbarItemSubject(obj) {
    this.navbarItemConfig = { ...this.navbarItemConfig, ...obj };
    this.navbarItemSubject.next(this.navbarItemConfig);
  }

  getNavbarItemSubject() {
    return this.navbarItemSubject.asObservable();
  }

  setPreviousRoute(href?: string) {
    const url = href || window.location.href;
    sessionStorage.setItem('previousRoute', url);
  }

  isEllipsisActive(el: any): boolean {
    return el ? el.offsetWidth < el.scrollWidth : false;
  }

  isMultiLineEllipsisActive(el: any): boolean {
    return el ? el.scrollHeight > el.clientHeight : false;
  }

  // Constructs the uri to use for the endpoint
  private getUri(uri, type?, values?): string {
    if (type) {
      uri += '/' + type;
    }
    if (Array.isArray(values)) {
      values.forEach((v) => uri += '/' + v);
    } else {
      if (values) {
        uri += '/' + values;
      }
    }
    return this.appSettings.apiUrl + uri;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }

  // Constructs the headers collection to use
  private getHeaders(obj?: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    for (const h in obj || {}) {
      if (obj.hasOwnProperty(h)) {
        headers.append(h, obj[ h ]);
      }
    }
    return headers;
  }
  setCarrierCompetitionBanner(obj) {
    this.carrierCompetitionSubject.next(obj);
  }
  getCarrierCompetitionBanner() {
    return this.carrierCompetitionSubject.asObservable();
  }
}
