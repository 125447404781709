<div fxLayout="column" class="success-container" fxLayoutGap="10px">
    <div align="end">
        <i class="icon-close cursor-pointer" (click)="onClose()"></i>
    </div>
    <div *ngIf="loadDetails.trackingMethodInfo=='DTM';else applessTracking">
        <div *ngIf="!trackingSuccess" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                <div>
                    <i class="icon-Yes success-icon"></i>
                </div>
                <div class="text-bold">
                    <span class="success-message" [translate]="'thankyouDis7196'">
                        Thank you. Dispatch information successfully updated.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #applessTracking>
        <div *ngIf="isLoading;else trackingDetails">
            <div class="loader">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <ng-template #trackingDetails>
            <form [formGroup]="dispatchTrackingForm" *ngIf="dispatchTrackingForm && isTrackingLink">
                <div *ngIf="dispatchLoadTrackingInfo.dispatchShareTrackingLink;else:trackingSuccessDisplay"
                    fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                    <div>
                        <i class="icon-Yes success-icon"></i>
                    </div>
                    <div *ngIf="!trackingSuccess" fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                            <div class="text-bold">
                                <span class="success-message" [translate]="'thankyouDis7196'">
                                    Thank you. Dispatch information successfully updated.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #trackingSuccessDisplay>
                    <div *ngIf="!trackingSuccess" fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="10px">
                            <div>
                                <i class="icon-Yes success-icon"></i>
                            </div>
                            <div class="text-bold">
                                <span class="success-message" [translate]="'thankyouDis7196'">
                                    Thank you. Dispatch information successfully updated.
                                </span>
                            </div>
                            <div class="text-bold">
                                <span class="tracking-message" [translate]="'pleaseshare2964'">
                                    Please share tracking link with your Driver
                                </span>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center"
                            class="share-link-container margin-top-bottom-20" fxFlex="100%">
                            <div fxFlex="10%" class="link-icon">
                                <i fxLayoutAlign="center center" class="icon-insert-link icon-font-size"></i>
                            </div>
                            <div fxFlex="85%" class="link-box">
                                <a mat-button color="accent" class="text-overflow-ellipsis" [attr.href]="copyLink"
                                    target="_blank">
                                    {{copyLink}}
                                </a>
                            </div>
                            <div *ngIf="shouldShowCopyButton" fxFlex="12%">
                                <button mat-raised-button type="button" color="accent" class="text-uppercase"
                                    (click)="onCopy(copyLink)" id="copy-btn"
                                    [ngClass]="{'btn-green': isLinkCopied, 'cursor-pointer': !isLinkCopied}">
                                    {{copyButtonText | translate}}
                                </button>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
                            <div class="line" fxFlex="45%">

                            </div>
                            <div class="text-bold-uppercase padding-left-5" fxFlex="5%">
                                <span [translate]="'or3105'">OR</span>
                            </div>
                            <div class="line" fxFlex="50%">
                            </div>
                        </div>
                        <div fxFlex="100%">
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
                                <span class="text-bold " [translate]="'sharevia4908'">
                                    Share via
                                </span>
                                <div fxFlex="10%"></div>
                            </div>
                        </div>
                        <div>
                            <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%" fxLayoutAlign="start center"
                                *ngIf="dispatchTrackingForm">
                                <mat-radio-group fxLayout="row" fxLayoutGap="20px" formControlName="mode"
                                    (change)="modeChange(dispatchTrackingForm.value.mode)">
                                    <mat-radio-button value="EMAIL">
                                        <span>{{'email8704'|translate}}</span>
                                    </mat-radio-button>
                                    <mat-radio-button value="SMS">
                                        <span>{{'text7427'|translate}}</span>
                                    </mat-radio-button>
                                    <mat-radio-button value="WHATSAPP">
                                        <span>{{'whatsappSpa9642'|translate}}</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="this.dispatchTrackingForm.value.mode=='EMAIL'">
                            <div fxFlex="100%">
                                <mat-form-field class="form-field-input" appearance="outline">
                                    <mat-label>{{'email8704' | translate}}</mat-label>
                                    <input matInput placeholder="{{'email8704' | translate}}" formControlName="email"
                                        required>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxFlex="100%" *ngIf="this.dispatchTrackingForm.value.mode=='SMS'">
                            <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
                                <mat-form-field fxFlex="40%" class="form-field-input" appearance="outline">
                                    <mat-label [translate]="'countryCode5026'" class="text-capitalize">country code
                                    </mat-label>
                                    <mat-select formControlName="countryCode" required>
                                        <mat-option *ngFor="let country of countryList" value="{{country.callingCode}}">
                                            {{country.description}} {{country.callingCode}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="form-field-input" appearance="outline" fxFlex="60%">
                                    <mat-label [translate]="'phoneNumber9259'">Phone Number</mat-label>
                                    <input matInput placeholder="Phone Number" formControlName="phoneNumber" required>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxFlex="100%" *ngIf="this.dispatchTrackingForm.value.mode=='WHATSAPP'">
                            <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
                                <mat-form-field fxFlex="40%" class="form-field-input" appearance="outline">
                                    <mat-label [translate]="'countryCode5026'" class="text-capitalize">country code
                                    </mat-label>
                                    <mat-select formControlName="countryCode" required>
                                        <mat-option *ngFor="let country of countryList" value="{{country.callingCode}}">
                                            {{country.description}} {{country.callingCode}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="form-field-input" appearance="outline" fxFlex="60%">
                                    <mat-label [translate]="'phoneNumber9259'">Phone Number</mat-label>
                                    <input matInput placeholder="Phone Number" formControlName="phoneNumber" required>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-dialog-actions align="end" *ngIf="!trackingSuccess">
                            <button (click)="dispatcherTracking()" [class.btn-spinner]="isSaving" id="save-profile-btn"
                                class="text-uppercase mat-raised-button mat-accent" mat-raised-button
                                [disabled]="!dispatchTrackingForm.valid || shareTrackingDisabled"
                                type="button">{{'send3670'|translate}}</button>
                        </mat-dialog-actions>
                    </div>
                    <div *ngIf="trackingSuccess">
                        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                            <div>
                                <i class="icon-Yes success-icon"></i>
                            </div>
                            <div>
                                <span [translate]="'loadNo3662'" class="text-bold tracking-message">load
                                    #</span>&nbsp;<span
                                    class="text-bold tracking-message">{{dispatchLoadTrackingInfo.isTravelOrder ?
                                    dispatchLoadTrackingInfo.alternateNumber :
                                    dispatchLoadTrackingInfo.number}}</span>
                            </div>
                            <div fxLayout="column" class="text-bold tracking-message" fxLayoutAlign="center center">
                                <span class="successmessagr">{{successMessage | translate}}</span>
                            </div>
                            <div class="tracking-message" fxLayout="row" fxLayoutGap="5px">
                                <div class="email">
                                    <i class="icon-mail" *ngIf="dispatchTrackingForm.value.mode==='EMAIL'"></i>
                                </div>
                                <div>
                                    <span>{{recipientInfo}}</span>
                                </div>
                            </div>
                            <div>
                                <span [translate]="'pleasenotet3281'">Please note this link expires 30 days after load
                                    delivery date</span><span>.</span>
                            </div>
                            <div *ngIf="!dispatchLoadTrackingInfo.dispatchShareTrackingLink">
                                <button (click)="shareMore()" class="text-uppercase mat-raised-button mat-accent"
                                    mat-raised-button>{{'shareMore6403'|translate}}</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </form>
            <div *ngIf="!isTrackingLink">
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
                        <div class="text-bold">
                            <span class="success-message" [translate]="'weareunable8302'">
                                We are unable to retrieve the tracking URL. Please try again later.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>