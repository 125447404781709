<div>
  <div fxLayout="row" class="padding-right-left-15 margin-top-15">
    <h4 fxFlex="95%" mat-dialog-title>
      <span class="text-bold-capitalize">switch carrier confirmation</span>
    </h4>
    <div class="close-clear-form-icon">
      <i (click)="onClose()" class="icon-close cursor-pointer"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="message padding-right-left-15" ngClass.gt-sm="margin-bottom-10">
      Are you sure you want to switch to another carrier?
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center"
    class="margin-top-15 padding-15 action-bar full-width">
    <button mat-raised-button fxFlex="45%" (click)="onClose()" class="text-bold-uppercase secondary-btn">
      cancel
    </button>
    <button mat-raised-button fxFlex="45%" color="accent" (click)="onAccept()" class="text-bold-uppercase">
      confirm
    </button>
  </div>
</div>