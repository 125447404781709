import { Representative } from './representative';
import { Stop } from './stop';
import { OrderItems } from './order-items';

class Error {
  constructor(
    public ClassName: string,
    public Message: string,
    public Data: string,
    public InnerException: string,
    public HelpURL: string,
    public StackTraceString: string
  ) { }
}

class TenderResponse {
  constructor(
    public isSuccess: boolean,
    public isBusinessException: boolean,
    public errors: Error[],
    public orderCode: string
  ) { }
}
export class AvailableLoadEmailResponse {
  constructor(
    public tenderId?: number,
    public orderItems?: OrderItems[],
    public stopItems?: Stop[],
    public reps?: Representative[],
    public tenderResponse?: TenderResponse,
    public buyPrice?: number,
    public carrierCode?: string,
    public counterOfferAmount?: number,
    public buyPriceCurrencyCode?: string,
    public carrierContactEmail?: string,
    public orderCode?: string,
    public dmpAmount?: number,
    public dmpCurrencyCode?: string,
    public numberOfStops?: number,
    public isHazmat?: boolean,
    public equipmentCategory?: string,
    public equipmentType?: string,
    public isValidationRequired?: boolean,
    public isCounterOfferExist?: boolean,
    public isEligibleForPlaceBid?: boolean,
    public priceSourceCode?: string,
    public wtpEntityId?: string,
    public isValidCounterOfferRequest?: boolean,
    public latestCounterOfferStatus?: string
  ) { }
}
