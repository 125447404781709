export class WeatherAlert {
    constructor(
        public place: string,
        public tempC: number,
        public tempF: number,
        public humidity: number,
        public weather: string,
        public icon: string,
        public isDay: boolean,
        public lastModified: string,
        public index: number
    ) {
    }
}

export class TruckLocation {
    constructor(
        public lat: number,
        public long: number
    ) {
    }
}
