import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { XPOConstants } from '../xpo-constants';
import { AppSettings } from 'src/app/core/app-settings';
import { AuthService } from 'src/app/user-authentication/auth.service';
import { LoyaltyService } from '../services/loyalty.service';

@Component({
  selector: 'app-load-requirements',
  templateUrl: './load-requirements.component.html',
  styleUrls: [ './load-requirements.component.scss' ]
})
export class LoadRequirementsComponent implements OnInit {
  @Input() load: any;
  @Input() isUnAuthenticated: boolean;
  @Input() isValidationRequired: boolean;
  @Input() showRedeemOption: boolean;
  @Input() checkboxValidationMsg: string;
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  public currency: string;
  public loadDetailsToggle: boolean;
  public loadStopDetailsToggle: boolean;
  public loadRequirementsToggle: boolean;
  public rateInformationToggle: boolean;
  public notesToggle: boolean;
  public dateFormat: string;
  public isMultipleCommodities: boolean;
  public commodity: string;
  public dimensions: string;
  public temperatureRange: string;
  public ratePerDistance: number;
  public ratePerDistanceWithDeadhead: number;
  public dateTimeFormat: string;
  public isNAUser: boolean;
  public btnDisable: any;
  public tenandId: number;
  public remainingCredits: number;
  public creditToRedeem: number;
  public isCreditDisabled = false;
  public hasAlreadyRedeemed = false;
  public notesWordLength: number;
  public weightUnit: string;

  constructor(
    private appSettings: AppSettings,
    private authService: AuthService,
    private loyaltyService: LoyaltyService
  ) {
    this.tenandId = this.authService.tenantId;
    this.currency = XPOConstants.defaultCurrency;
    this.loadDetailsToggle = true;
    this.loadStopDetailsToggle = true;
    this.loadRequirementsToggle = true;
    this.rateInformationToggle = true;
    this.notesToggle = true;
    this.isNAUser = this.authService.checkNAUser();
    this.btnDisable = {
      isRequire: false,
      isValid: false,
      isCreditToRedeem: false,
      creditToRedeem: 0,
      isAppointmentConfirmed: false
    };
    this.notesWordLength = XPOConstants.compareLoadsNotesNotesWordMaxLength;
  }

  ngOnInit() {
    this.dateFormat = this.isNAUser ? XPOConstants.dateFormat : XPOConstants.euDateFormat;
    this.dateTimeFormat = this.isNAUser ? XPOConstants.dateTime24Format : XPOConstants.euDateTimeFormat;
    this.weightUnit = this.appSettings.getApplicationConstant('BaseWeightUnit');
    if (this.isUnAuthenticated) {
      this.mapLoadData();
      this.btnDisable.isValid = true;
      this.btnDisable.isAppointmentConfirmed = true;
    } else {
      const dimensionsUnit = this.appSettings.getApplicationConstant('BaseDimensionUnit');
      this.btnDisable.isValid = (this.load.isValidationRequired && this.isValidationRequired) ? false : true;
      this.btnDisable.isAppointmentConfirmed = this.isNAUser ? this.load.isAppointmentConfirmed : true;
      if (this.load.commodities && this.load.commodities.length > 1) {
        this.isMultipleCommodities = true;
      } else {
        const commodity = this.load.commodities[ 0 ];
        this.commodity = commodity.commodityName;
        this.dimensions = (commodity.length && commodity.width && commodity.height) ?
          `${commodity.length} ${dimensionsUnit} x ${commodity.width} ${dimensionsUnit} x ${commodity.height} ${dimensionsUnit}` : '-';
        this.temperatureRange = (commodity.temperatureLow && commodity.temperatureHigh) ?
          `${commodity.temperatureLow} - ${commodity.temperatureHigh}` : '-';
        this.ratePerDistance = this.load.ratePerDistance;
        this.ratePerDistanceWithDeadhead = this.load.ratePerDistanceWithDeadhead;
      }
    }

    if (this.showRedeemOption && this.isNAUser) {
      this.loyaltyService.getCredits(this.load.alternateNumber).subscribe((response) => {
        this.remainingCredits = response.credits.remainingCreditAmount;
        this.creditToRedeem = response.credits.creditAmountToRedeem;
        this.isCreditDisabled = response.isDisabled;
        this.hasAlreadyRedeemed = response.hasAlreadyRedeemed;
      });
    }
  }

  confirmAction(event) {
    this.btnDisable.isRequire = event.checked;
    this.confirm.emit(this.btnDisable);
  }

  isValidationConfirmAction(event) {
    this.btnDisable.isValid = event.checked;
    this.confirm.emit(this.btnDisable);
  }

  isAppointmentConfirmAction(event) {
    this.btnDisable.isAppointmentConfirmed = event.checked;
    this.confirm.emit(this.btnDisable);
  }

  addCredit(event) {
    this.btnDisable.isCreditToRedeem = event.checked;
    this.btnDisable.creditToRedeem = this.creditToRedeem;
    this.btnDisable.hasAlreadyRedeemed = this.hasAlreadyRedeemed;
    this.confirm.emit(this.btnDisable);
  }

  mapLoadData() {
    let load;
    const stops = [];
    load = {
      notes: this.load.autoOffersLoad.hotNotes,
      weight: this.load.autoOffersLoad.totalWeight,
      weightUnit: this.appSettings.getApplicationConstant('BaseWeightUnit'),
      isCPAT: this.load.autoOffersLoad.isCPAT,
      isCSA: this.load.autoOffersLoad.isCSA,
      isCustomsBroker: this.load.autoOffersLoad.isCustomsBroker,
      isDropAndHook: this.load.autoOffersLoad.isDropAndHook,
      isFAST: this.load.autoOffersLoad.isFAST,
      isHazmat: this.load.autoOffersLoad.isHazmat,
      isPIP: this.load.autoOffersLoad.isPIP,
      isSmartway: this.load.autoOffersLoad.isSmartway,
      isTSA: this.load.autoOffersLoad.isTSA,
      isTWICcard: this.load.autoOffersLoad.isTWICcard,
      isUIIAcard: this.load.autoOffersLoad.isUIIAcard,
      requirements: this.load.autoOffersLoad.requirements
    };
    if (this.load.autoOffersLoad.orderItems && this.load.autoOffersLoad.orderItems.length) {
      const commodity = this.load.autoOffersLoad.orderItems[ 0 ];
      load.equipmentType = commodity.equipmentType;
      if (this.load.autoOffersLoad.orderItems.length > 1) {
        this.isMultipleCommodities = true;
      } else {
        this.commodity = commodity.name;
        this.dimensions = commodity.dimensions;
        this.temperatureRange = (commodity.temperatureMin && commodity.temperatureMax) ?
          `${commodity.temperatureMin} - ${commodity.temperatureMax}` : '-';
      }
    }
    this.load.autoOffersLoad.stopItems.forEach(stop => {
      const stopDetails = {
        cityName: stop.city,
        stateCode: stop.stateCode,
        zipCode: stop.zipCode,
        scheduledArrivalEarly: stop.scheduledEarlyArrivalTime ? stop.scheduledEarlyArrivalTime : stop.scheduledArrivalTime,
        scheduledArrivalLate: stop.scheduledLateArrivalTime,
      };
      stops.push(stopDetails);
    });
    load.stops = stops;
    this.load = load;
  }
}
