<div class="terms-and-condition">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span id="terms-and-conditions-title">Terms and Agreement</span>
      <span>
        <i class="icon-close cursor-pointer" id="close-user-icon" (click)="closeDialog()">
        </i>
      </span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="terms-block" id="terms-and-conditions">
      <div class="content" *ngIf="isNAUser; else otherTerms;">
        <p>Please read these Terms and Conditions (the “Terms”) carefully before using XPO Connect provided by
          Broker
          (as defined in the Motor Carrier Transportation Agreement (the “Agreement”), also referred to in
          these Terms
          as “XPO”). These Terms supplement your responsibilities as a Carrier or Carrier Representative (each
          as
          defined in the Agreement). If you are not a Carrier or Carrier Representative, you may not access
          and use XPO
          Connect.
        </p>

        <p>Your access to and use of XPO Connect is conditioned on your acceptance of and compliance with these
          Terms.
        </p>
        <p><b>By accessing or using XPO Connect you agree to be bound by these Terms. If you disagree with any
            part of
            the Terms then you may not access and use XPO Connect.</b>
        </p>
        <p>These Terms are effective as of the date of your initial use of XPO Connect. If there is a conflict
          between
          the terms of the Agreement and these Terms, the Agreement will control. These Terms do not modify
          the
          requirements set forth in any load tender or shipper instructions, and any use of XPO Connect must
          comply with
          those instructions and the Agreement. You may use XPO Connect if you want to, but XPO does not
          require you to
          use XPO Connect.</p>
        <p>
          <b>Acknowledgement:</b>This Agreement is between you and XPO. XPO is solely responsible for XPO
          Connect and
          the content thereof.</p>
        <p>
          <b>Scope of License:</b>Your access and use of XPO Connect and these Terms are personal to you, and
          you may
          not transfer the license XPO has granted to you or assign these Terms to someone else.</p>
        <p>
          <b>Personal Information and Privacy.</b> XPO Connect stores and processes personal data that you
          have provided
          to XPO. It’s your responsibility to keep your devices and access to XPO Connect secure.The <a
            [href]="privacyPolicyUrl">XPO Privacy Policy</a> governs XPO’s use of your personal
          information.
        </p>

        <p>
          <b>Location Services.</b>XPO Connect may access location services. XPO Connect stores and processes
          personal
          data that you have provided to us.</p>

        <p>
          <b>Compliance with the Law.</b> You are required to follow all applicable federal, state, local and
          other
          laws, including traffic laws, and you agree you will not use XPO Connect in violation of any of
          those laws.
        </p>

        <p>
          <b>Internet Connections.</b>. Certain functions of XPO Connect will require an active internet
          connection. XPO
          cannot take responsibility for XPO Connect not working at full functionality if you don’t have
          internet
          access.</p>

        <p>
          <b>Third-Party Services.</b> XPO does not take responsibility for the way you use XPO Connect.
          Further, when
          you’re using XPO Connect, it’s important to bear in mind that, although XPO endeavors to ensure XPO
          Connect is
          updated and correct at all times, XPO Connect does rely at least in part on third parties and
          third-party
          services to function, and XPO cannot guarantee the accuracy of such services. It is up to you to
          ensure the
          services are accurate. XPO accepts no liability for any loss, direct or indirect, you experience as
          a result
          of relying wholly on the functionality of XPO Connect.
        </p>
        <p>Carrier is solely responsible for each Carrier Representative assigned an administrative role, a
          dispatcher
          role, or both, in connection with Carrier’s use of XPO Connect and for maintaining the access and
          rights of
          all Carrier Representatives. Carrier agrees to periodically validate the users with access to XPO
          Connect on
          Carrier’s behalf and to remove user access if a user or Carrier Representative is no longer a
          representative,
          agent, or employee of Carrier. Carrier is, and expressly agrees to be, fully responsible and liable
          for the
          use of XPO Connect by any Carrier Representative, even if a Carrier Representative is no longer a
          representative, agent, or employee of Carrier. Carrier agrees to indemnify, defend and hold XPO
          harmless for
          all claims, demands, costs, damages (including special, indirect or consequential damages), fines,
          penalties,
          losses, liabilities (including reasonable attorneys’, accountants’, and experts’ fees and
          disbursements and
          other costs of defense, investigation and settlement, costs of containment, cleanup and remediation
          of spills,
          releases or other environmental contamination, and costs of enforcement of indemnity obligations),
          judgments,
          penalties, fines and other amounts (collectively “Claims”) relating to or arising out of the use of
          XPO
          Connect by Carrier or a Carrier Representative regardless of whether a Carrier Representative
          remains a
          representative, agent, or employee of Carrier.</p>

        <p>
          <b>Changes.</b>XPO is committed to ensuring that XPO Connect is as useful and efficient as possible.
          For that
          reason, XPO reserves the right to make changes to XPO Connect or to charge for its services, at any
          time and
          for any reason. XPO will never charge you for XPO Connect or its services without making it very
          clear to you
          exactly what you’re paying for.</p>

        <p>
          <b>Updates, Maintenance and Support.</b>At some point we may wish to update XPO Connect. XPO does
          not promise
          that it will always update XPO Connect or work on all browsers or older versions of browsers. XPO
          may stop
          providing XPO Connect and may terminate use of it at any time without giving notice of termination
          to you.
          Unless XPO tells you otherwise, upon any termination, (a) the rights and licenses granted to you in
          these
          terms will end; (b) you must stop using XPO Connect.</p>

        <p>
          <b>Links to Other Web Sites.</b>XPO Connect may contain links to third-party web sites or services
          that are
          not owned or controlled by XPO. XPO has no control over, and assumes no responsibility for, the
          content,
          privacy policies, or practices of any third-party web sites or services. You further acknowledge and
          agree
          that XPO shall not be responsible or liable, directly or indirectly, for any damage or loss caused
          by or
          alleged to be caused by or in connection with use of or reliance on any such content, goods or
          services
          available on or through any such third-party web sites or services.</p>
        <p>
          <b>Warranty.</b>You expressly acknowledge and agree that use of XPO Connect is at your sole risk,
          and that the
          entire risk as to satisfactory quality, performance, accuracy, and effort is with you. XPO will not
          have any
          liability for any claims, losses, damages, costs, or expenses attributable to any failure to
          conform, if any.
          To the maximum extent permitted by applicable law, XPO Connect is provided “as is” and “as
          available,” with
          all faults and without warranty of any kind, and XPO hereby disclaims all warranties and conditions
          with
          respect to XPO Connect, whether express, implied, or statutory, including, but not limited to, the
          implied
          warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a
          particular purpose,
          of accuracy, of quiet enjoyment, and of non-infringement of third-party rights. XPO does not warrant
          against
          interference with your enjoyment of XPO Connect, that the functions contained in or services
          performed or
          provided by XPO Connect will meet your requirements, that the operation of XPO Connect will be
          uninterrupted
          or error-free, or that defects in XPO Connect will be corrected. No oral or written information or
          advice
          given by XPO or its authorized representative shall create a warranty.</p>
        <p>
          <b>Limitation of Liability.</b>
          To the extent not prohibited by law, in no event shall XPO be liable for personal injury or any
          incidental,
          special, indirect, or consequential damages whatsoever, including, without limitation, damages for
          loss of
          profits, loss of data, business interruption, or any other commercial damages or losses, arising out
          of or
          related to your use or inability to use XPO Connect, however caused, regardless of the theory of
          liability
          (contract, tort, or otherwise) and even if XPO has been advised of the possibility of such damages.
          Some
          jurisdictions do not allow the limitation of liability for personal injury, or of incidental or
          consequential
          damages, so this limitation may not apply to you. In no event shall XPO’s total liability to you for
          all
          damages (other than as may be required by applicable law in cases involving personal injury) exceed
          the amount
          of fifty dollars ($50.00). The foregoing limitations will apply even if the above-stated remedy
          fails of its
          essential purpose.
        </p>
        <p>
          <b>Intellectual Property Rights.</b>You may not send it on to anyone else, and you’re not allowed to
          copy or
          modify XPO Connect, any part of XPO Connect, or XPO’s trademarks in any way. You’re not allowed to
          attempt to
          extract the source code of XPO Connect, and you also shouldn’t try to translate XPO Connect into
          other
          languages, or make derivative versions. XPO Connect itself, and all the trade marks, copyright,
          database
          rights and other intellectual property rights related to it belong to XPO or its third-party
          licensors. In the
          event that a third-party claims XPO Connect or your possession and use of XPO Connect infringes on
          any third
          party’s intellectual property rights, XPO will be solely responsible for the investigation, defense,
          settlement, and discharge of any such intellectual property infringement claim.
        </p>
        <p>
          <b>Legal Compliance.</b>You represent and warrant that (i) you are not located in a country that is
          subject to
          a U.S. Government embargo or that has been designated by the U.S. Government as a “terrorist
          supporting”
          country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted
          parties.</p>
        <p>
          <b>Changes to the Terms.</b>XPO reserves the right, in its sole discretion, to modify or replace
          these Terms
          at any time.
        </p>

        <p>
          <b>Contact Us.</b> If you have any questions about these Terms, please contact XPO: Five American
          Lane
          Greenwich,
          CT 06831;&nbsp;
          <a href="tel:(734) 757-1631">(734) 757-1631</a>;&nbsp;
          <a href="mailto:XPOConnectSupport@xpo.com">XPOConnectSupport@xpo.com</a>
        </p>
      </div>
      <ng-template #otherTerms>
        <div class="content">
          <p>
            <b>Article 1. Glossary</b> <br />
            <b>"Carrier"</b>: the carrier in a contractual relationship with XPO, which performs transportation on
            behalf of XPO.
          </p>
          <p>
            <b>"Client"</b>: the company in a contractual relationship with XPO, for which XPO performs transportation
            and logistics.
          </p>
          <p>
            <b>"Contract"</b>: the contract between XPO and Carrier and/or the contract between XPO and Client,
            regardless the form of such contract (e.g. long-term contract; frame contract with punctual orders etc.).
          </p>
          <p>
            <b>"Data"</b>: the data of Carrier / Client processed by XPO Connect.
          </p>
          <p>
            <b>"Personal Data"</b>: any information relating to an identified or identifiable natural person i.e.
            employees, agents, representatives of Carrier / Client.
          </p>
          <p>
            <b>"Terms of Use"</b>: these terms of use whose purpose is to define under which conditions Carrier / Client
            can use XPO Connect.
          </p>
          <p>
            <b>"XPO Connect"</b>: the platform designed by XPO for Carrier / Client.
          </p>
          <p>
            <b>"XPO"</b>: XPO Logistics Europe, a joint stock company under French law registered with the Trade and
            Companies Register of Lyon under number 309 645 539 and whose registered office is at 192 avenue Thiers
            69006 Lyon, FRANCE and its affiliates (any entity that, directly or indirectly through one or more
            intermediaries, controls, is controlled by, or is under common control of XPO Logistics Europe, where
            “control” has the meaning of articles L.233-3 and L.233-16 of the French Code of Commerce).
          </p>
          <p>
            <b>Article 2. Acceptation and modification of the Terms of Use</b> <br />
            <b>2.1.</b> Carrier / Client acknowledges that (i) a Contract is a prerequisite for requesting the access to
            XPO Connect and (ii) by requesting such access, Carrier / Client accepts the Terms of Use.
          </p>
          <p>
            <b>2.2.</b> XPO reserves the right to modify at any time the Terms of Use. Such modifications will be
            notified to Carrier /
            Client through XPO Connect and shall enter info force one (1) month after this notification. In the case
            Carrier / Client disagrees with the modifications, Carrier / Client may terminate the Terms of Use in the
            conditions set forth in article 8.
          </p>
          <p>
            <b>Article 3. Duration</b> <br />
            The Terms of Use are effective as of the first access of Carrier / Client to XPO Connect for an indefinite
            period, it being specified that Carrier / Client or XPO can terminate the Terms of Use at any time by
            providing the other Party with at least fifteen (15) days prior written notice by e-mail.
          </p>
          <p>
            <b>Article 4. Use of XPO Connect</b> <br />
            <b>4.1. General provisions</b> <br />
            <b>4.1.1.</b> The use of XPO Connect requires a login and a password (“Access codes”). Carrier / Client is
            solely responsible for the use of such Access codes and shall ensure their confidentiality. XPO shall not be
            liable for any damages or losses arising for an unauthorized communication of the Access codes to a third
            party.
          </p>
          <p>
            <b>4.1.2.</b> The use of XPO Connect by Carrier / Client is free.
          </p>
          <p>
            <b>4.1.3.</b> XPO Connect is a tool to facilitate the performance of the Contract. According to the
            Contract, the use of XPO Connect can be mandatory or optional for the performance of the Contract. In any
            event, in case of any difficulty relating to XPO Connect:
          </p>
          <ul class="uo-list">
            <li>- Client shall not suspend its obligations under the Contract, in particular its payment obligation;
            </li>
            <li>- Carrier shall not suspend its obligations under the Contract. If XPO Connect does not work, Carrier
              shall contact XPO by the alternative ways of communication provided in the Contract and remains liable for
              any breach of its obligations.
            </li>
          </ul>
          <p>
            XPO shall not be held liable in respect of the Terms of Use and/or the Contract for any malfunction of XPO
            Connect.
          </p>
          <p>
            <b>4.1.4.</b> XPO Connect provides real-time information to Carrier / Client. However, Carrier / Client
            acknowledges that XPO shall not be liable for the reliability of the information.
          </p>
          <p>
            <b>4.1.5.</b> XPO provides a support for XPO Connect that Carrier / Client can reach by phone or e-mail.
          </p>
          <p>
            <b>4.1.6.</b> Carrier / Client can load Data on XPO Connect to benefit from it. Carrier / Client may not use
            the XPO Connect for any purpose that is racist, discriminatory, obscene, indecent, hateful, malicious,
            pornographic, potentially damaging to minors, defamatory, libelous, fraudulent, treasonous, threatening,
            abusive, excessively violent, promotes the use of violence, or is otherwise harmful to others, unlawful or
            against official regulations or requirements. Carrier / Client will not use or save such data on XPO
            Connect.
          </p>
          <p>
            <b>4.1.7.</b> Carrier / Client can create different administrator and user accounts which require the
            following Personal Data of the account holder: name, first name, e-mail address and phone number. Client /
            Carrier is responsible for the use of XPO Connect by its employees, agents and representatives and agrees to
            ensure that their use of XPO Connect complies with these Terms of Use, all applicable laws and regulations
            and the Contract.
          </p>
          <p>
            <b>4.2. Specific provisions for Clients</b> <br />
            <b>4.2.1.</b>
            XPO Connect provides a dashboard to Clients. Clients can follow their past and current orders and the
            details of them. In particular, Clients can follow-up the performance of the transportation services by XPO
            (loading and unloading) and have access to shipment information.
          </p>
          <p>
            <b>4.2.2.</b>
            XPO Connect enables Clients to give XPO the information (e.g. address of delivery, time etc) requested in
            the Contract so that XPO can perform the Contract under conditions of it.
          </p>
          <p>
            <b>4.2.3.</b>
            XPO Connect does not create any direct contractual relationship between Clients and Carriers.
          </p>
          <p>
            <b>4.3.</b> Specific provisions for Carriers <br />
            <b>4.3.1.</b>
            XPO Connect provides a dashboard to Carriers. Carriers can follow their past and current loads and the
            details of them. In particular, Carriers can be informed of the status of loads (loading and unloading).
          </p>
          <p>
            <b>4.3.2.</b>
            XPO Connect enables Carriers to give XPO the information requested in the Contract so that Carriers can be
            possibly selected by XPO for a load under the conditions set forth in the Contract.
          </p>
          <p>
            <b>4.3.3.</b>
            Carriers are informed that XPO Connect does not create any obligation for XPO to select them for a load.
          </p>
          <p>
            <b>4.3.4.</b>
            Carriers can create “profile” for their drivers. The creation of profile may require the following Personal
            Data: name, first name, e-mail address and phone number of the driver, this last Personal Data being not
            mandatory. If a driver leaves the company, for any reason, Carrier has the obligation to delete its profile
            created in XPO Connect. Carrier shall defend, indemnify and hold harmless XPO in case of a claim arising
            from a lack of deletion of the profile.
          </p>
          <p>
            <b>4.3.5.</b>
            XPO Connect allows Carriers to send a link to their drivers upon their profile creation so that they can
            download the application called “Drive XPO”, also developed by XPO to track their loads.
          </p>
          <p>
            <b>Article 5. Intellectual property</b> <br />
            <b>5.1.</b>
            XPO is and remains owner of all intellectual property rights of XPO Connect. These Terms of Use results in
            no transfer of intellectual property rights for the benefit of Client / Carrier. XPO grants Client /
            Carrier, for the duration of the Terms of Use a non-assignable, non-exclusive, worldwide and personal
            license to use XPO Connect.
          </p>
          <p>
            <b>5.2.</b>
            Client / Carrier is in particular prohibited to:
          </p>
          <ul class="uo-list">
            <li>- carry out any form of reproduction or representation of XPO Connect or its documentation;</li>
            <li>- alter or obscure in any way whatsoever the trademarks, distinctive signs, authorship references
              attached to XPO Connect;</li>
            <li>- modify or attempt to circumvent any XPO Connect protection device.</li>
          </ul>
          <p>
            <b>Article 6. Confidentiality</b> <br />
            <b>6.1.</b>
            The Parties agree to consider as strictly confidential the Data and / or information, in any form whatsoever
            and / or medium, which will have been expressly qualified as confidential when submitted to the other Party,
            it being required that the Disclosing Party provide proof that it has so qualified said data and / or
            information (hereinafter “Confidential Information”).
          </p>
          <p>
            <b>6.2.</b>
            Each Party undertakes to take the same protective measures concerning the Confidential Information as with
            its own confidential information and, in particular, not to disclose them to third parties in any form or
            medium, for any reason whatsoever and to require any employee or third party likely to know the Confidential
            Information for purposes of performing the Terms of Use and/or the Contract, the same commitment to
            confidentiality.
          </p>
          <p>
            <b>6.3.</b>
            This confidentiality undertaking will last for the duration of the Terms of Use and for five (5) years after
            its termination, regardless of the cause.
          </p>
          <p>
            <b>Article 7. Protection of Personal Data</b> <br />
            <b>7.1.</b>
            The Personal Data are subject to the French Law No. 78-17 of January 6, 1978 relating to personal data and
            the EU General Data Protection Regulation No. 2016/679 of April 27, 2016 (the “Applicable legislation”). In
            this article 7, the following terms shall have the meaning defined in the Applicable legislation: “data
            controller”; “data subject”; “processing”.
          </p>
          <p>
            <b>7.2.</b>
            Client / Carrier is data controller of Personal Data. Therefore, Client / Carrier warrants it has respected
            all its obligations as data controller under the Applicable legislation. Client / Carrier shall defend,
            indemnify and hold harmless XPO in case of a claim arising from a data subject relating to the breach of
            such obligations. However, once Client / Carrier creates accounts in accordance with article 4.1.7 or
            profiles under article 4.2.3, XPO becomes data controller of the processing of such Personal Data.
            Therefore, XPO is in particular in charge to inform the data subjects according to the Applicable
            legislation.
          </p>
          <p>
            <b>Article 8. Termination – Consequences</b> <br />
            <b>8.1.</b>The Terms of Use may be terminated by a Party in case of a breach by the other Party. The
            termination will be effective immediately after a notice per e-mail.
          </p>
          <p>
            <b>8.2.</b> In case of the end of the Terms of Use, regardless the cause:
          </p>
          <ul class="uo-list">
            <li>- Client / Carrier may not use XPO Connect anymore.</li>
            <li>- The ongoing orders / loads will not be impacted.</li>
          </ul>
          <p>
            <b>Article 9. Force majeure</b> <br />
            The obligations of the Parties will be automatically suspended in case of force majeure with the definition
            of article 1218 of the French Civil Code. The Party noting the event will promptly notify the other Party of
            its inability to perform its obligations and justify it. The obligations of the suspension will in no case
            be a cause of liability for non-performance of the obligation in question or lead to the payment of damages
            or penalties. However, upon the end of the event suspending their reciprocal obligations, the Parties shall
            make every effort to resume as quickly as possible the normal performance of their contractual obligations.
          </p>
          <p>
            In the case of this event persisting for a period exceeding ninety (90) days, one or the other Party may,
            without liability, terminate the Terms of Use by notifying the other Party of this decision by e-mail,
            neither Party having the right to claim for any indemnity.
          </p>
          <p>
            <b>Article 10. Assignment</b> <br />
            None of the Parties is authorized to assign its status as party to the Terms of Use to a third party without
            the prior written consent of the other Party. In case of assignment, it shall respect the conditions of the
            articles 1216 to 1216-3 of the French Civil Code. By way of derogation, each Party may, subject to prior
            notice to the other Party and under its sole responsibility, freely assign its status as party to the Terms
            of Use to any third party in which it holds, directly or indirectly, the majority of the share capital.
          </p>
          <p>
            <b>Article 11. Final provisions</b> <br />
            <b>11.1.</b>
            The Terms of Use constitute the entire agreement between the Parties as to its purpose. It replaces any
            previous written or verbal proposals or agreements between the Parties and excludes the application of any
            other document such as, for example, the Client's terms of purchase.
          </p>
          <p>
            <b>11.2.</b>
            In the event that any provision of the Terms of Use be declared ineffective or void, in any way and for any
            reason whatsoever, it would be deemed unwritten and shall not invalidate the other provisions.
          </p>
          <p>
            <b>11.3.</b>
            Any waiver of either of the Parties to invoke any of its rights under the terms of the Terms of Use shall
            not constitute a waiver of those rights in the future.
          </p>
          <p>
            <b>11.4.</b>
            The Parties agree that any correspondence between them under the Terms of Use by e-mail shall be deemed
            written correspondence and will be valid as evidence that can be produced in court.
          </p>
          <p>
            <b>11.5.</b>
            In case of translation of the Terms of Use, only the French version shall prevail.
          </p>
          <p>
            <b>Article 12. Applicable law – Jurisdiction</b> <br />
            The Terms of Use are governed exclusively by French law.
            Any dispute concerning the interpretation, performance and / or termination of the Terms of Use must be
            subject of an attempt by the Parties to reach an amicable solution. In the absence of an amicable solution,
            the litigation will be the exclusive competence of Tribunal de commerce de Lyon, notwithstanding pluralities
            of defendants.
          </p>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>
</div>